import React, { useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label } from 'recharts';
import { CustomizedXAxisLabel, CustomizedYAxisLabel, AxisLabel, findStyleSetting, capitalizeAndConvert, CustomLineReportTooltip } from './GraphComponents';
import styles from './Graph.module.scss';
import { GRAPH_TYPES } from '../../../Constants/reporting';
import { LINE_GRAPH_SPEC } from '../SpecificationSettings';
import { result } from 'lodash';

const updateGraphDate = (data) => {
  const outputData = [];

  data.forEach(dateData => {
    dateData.values.forEach(dateEntry => {
      const date = dateEntry.date;
      let utilizationData = 0;
      let notUtilizationData = 0;

      dateEntry.vehicleUtilizationData.forEach(entry => {
        if (entry.isUtilized) {
          utilizationData += entry.vehicleCount;
        } else {
          notUtilizationData += entry.vehicleCount;
        }
      });

      outputData.push({
        "date": date,
        "utilized": utilizationData,
        "not Utilized": notUtilizationData
      });
    });
  });
  return outputData

}

const modifyGraphData = (data, graphSpecification) => {
  let result = [];
   //to check for utilization report
  if (graphSpecification === 'CLIENT_VEHICLE_UTILIZATION') {
    result = updateGraphDate(data)
  } else {
    for (let i = 0; i < data.length; i++) {
      if (data[i]?.values) {
        for (let j = 0; j < data[i].values.length; j++) {
          if (i === 0) {
            result[j] = {
              date: data[i].values[j].date,
              [data[i].eventName]: data[i].values[j].count
            }
          } else {
            result[j] = { ...result[j], [data[i].eventName]: data[i].values[j].count }
          }
        }
      }
    }
  }

  return result;
}

const createTooltipData = (data) => {
  const outputData = [];
  data.forEach(dateData => {
    dateData.values.forEach(dateEntry => {
      const date = dateEntry.date;
      const utilizationData = {};

      dateEntry.vehicleUtilizationData.forEach(entry => {
        // Exclude "Driver Assigned" from the output
        if (entry.useDescription !== "Driver Assigned") {
          if (!utilizationData[entry.useDescription]) {
            utilizationData[entry.useDescription] = 0;
          }
          utilizationData[entry.useDescription] += entry.vehicleCount;
        }
      });

      const outputObject = {
        "date": date,
        ...utilizationData
      };

      outputData.push(outputObject);
    });
  });
  return outputData
}


const LineGraph = ({ height, width, graphData: gData, isModal, styleType, graphSpecification }) => {

  const [graphData, setGraphData] = useState({
    data: [],
    lines: [],
  })
  const [tooltipData, setTooltipData] = useState([])

  const settings = findStyleSetting(styleType, "line");
  const lineSpecSettings = LINE_GRAPH_SPEC[graphSpecification];
  const COLORS = ['#66929E', '#E7401E', '#38caa2', '#ebcb91', '#e9d1bb']


  useEffect(() => {
    let result = [];
    const data = modifyGraphData(gData, graphSpecification);
    //to check for utilization report
    if (graphSpecification === 'CLIENT_VEHICLE_UTILIZATION') {
      result = createTooltipData(gData)

    }
    if (result) {
      setTooltipData(result)
    }
    if (data) {
      const lines = data[0] ? Object.keys(data[0]).filter(val => val !== "date") : [];
      setGraphData({
        data: data,
        lines: lines,
      })
    }

  }, [])


  return (
    <div className={styles.container}>
      <ResponsiveContainer width={width} height={height} className={styles.graphArea}>
        <LineChart
          width={width}
          height={height}
          data={graphData?.data}
          margin={settings?.margin}
        >
          <Legend
            iconSize="6"
            layout="horizontal"
            verticalAlign="bottom"
            align="center"
            wrapperStyle={settings?.legend?.wrapperStyle}
          />
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" tick={<CustomizedXAxisLabel {...settings?.xAxisValue} />} domain={['2020-09-12', '2020-12-12']} />
          <YAxis label={<AxisLabel axisType='yAxis' {...settings.yAxisLabel} domain={[0, 20000]}>{lineSpecSettings?.y_axis_label}</AxisLabel>} tick={<CustomizedYAxisLabel {...settings.yAxisValue} />} />
          {/* <Tooltip/> */}
          {
            graphData?.lines?.map((item, index) =>
              <Line key={item} name={capitalizeAndConvert(item)} type="monotone" strokeWidth={2} dataKey={item} stroke={COLORS[index % COLORS.length]} isAnimationActive={false} />
            )
          }
          <Tooltip content={<CustomLineReportTooltip utilizationTooltipData={tooltipData} />} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}


export default React.memo(LineGraph)