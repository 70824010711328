import React, { useState, useEffect, useCallback } from 'react';
import clsx from 'clsx';
import styles from './LevelFilters.module.scss';
import {
    Spacing,
    Grid,
    GridItem,
    Text,
    Tabs,
    Tab,
    Heading
} from "@leaseplan/ui";
import LPDrawer from '../../../Common/LPDrawer/LPDrawer';
import { getLabel } from '../../../utils/index';
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import POIpopUpFilterComponent from '../../../Common/PopupFilter/POIpopUpFilter';
import { useDispatch, useSelector } from 'react-redux';
import MapPopUpFilter from '../../../Common/PopupFilter/MapPopUpFilter';
import { makeStyles } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import { saveClientInfo } from '../../../Actions/clientConfig.action';
import "react-confirm-alert/src/react-confirm-alert.css";
import { confirmAlert } from 'react-confirm-alert';
import { addToast } from '../../../Actions/toasts';
import { fetchUserDefaultConfig } from '../../../Actions/filters.action';

const useStyles = makeStyles((theme) => ({
    gRlWW: {
        minWidth: 0,
        width: '70%',
    }


}));

const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

const LAYERS = [
    {
        label: 'National Account',
        value: 'ALL_NAS',
    },
    {
        label: 'EV Charging Station',
        value: 'ALL_EVCS',
    },
    {
        label: 'Amazon Delivery Station',
        value: 'AMZ_DS',
    },
    {
        label: 'Delivery Station',
        value: 'ALL_DS',
    },
    {
        label: 'LP Delivery Location',
        value: 'LP_DL',
    }

]


const LevelFilters = ({
    defaultData,
    data,
    prismicData,
    isSideDrawerOpen,
    setIsSideDrawerOpen,
    onLayerFilterChange,
    poiFilters,
    onLevelFilterChange,
    levelFilter,
    ...props
}) => {
    const [optionSelected, setOptionSelected] = useState({});
    const [filterData, setFilterData] = useState({});
    const [clientPoiFilters, setClientPoiFilters] = useState([]);
    const [poiPopUPPlayload, setPoiPopUPPlayload] = useState([]);
    const [mapPopUPPlayload, setMapPopUPPlayload] = useState([]);

    const poiFiltersSelector = useSelector(state => state.filters.poi);
    const filtersSelectordefaultValue = useSelector(state => state.filters.defaultConfig);
    const authSelector = useSelector(state => state.auth.user.clientUserDefaultDto);
    const clientHQInfoSelector = useSelector(state => state.clientConfig);
    const classes = useStyles();
    const dispatch = useDispatch();

    // useEffect(() => {
    //     console.log('coming')
    //     let pannelArray = []
    //     let poiDefaultValueArray = []
    //     if (filtersSelectordefaultValue && filtersSelectordefaultValue.data) {
    //         if (filtersSelectordefaultValue.data.showFleetOverview == 0) {
    //             pannelArray.push('fleet_overview')
    //         }
    //         if (filtersSelectordefaultValue.data.showLatestEvent == 0) {
    //             pannelArray.push('latest_events')
    //         }
    //         console.log(JSON.stringify(pannelArray))
    //         sessionStorage.setItem("pannelArray", JSON.stringify(pannelArray))
    //         if (filtersSelectordefaultValue.data.showEvChargingStations == 1) {
    //             poiDefaultValueArray.push('ALL_EVCS')
    //         } if (filtersSelectordefaultValue.data.showFuelStations == 1) {
    //             poiDefaultValueArray.push('ALL_FS')
    //         } if (filtersSelectordefaultValue.data.showNationalAccountShops == 1) {
    //             poiDefaultValueArray.push('ALL_NAS')
    //         }
    //     }
    //     sessionStorage.setItem("POI", JSON.stringify(poiDefaultValueArray))
    // }, [filtersSelectordefaultValue])


    // default setting from user api i.e when user first login to oneconnect POI and panel setting will be done.
    // useEffect(() => {

    //     if (authSelector) {
    //         let pannelArray = []
    //         let poiDefaultValueArray = []
    //         if (authSelector.showFleetOverview == 1) {
    //             pannelArray.push('fleet_overview')
    //         }
    //         if (authSelector.showLatestEvents == 1) {
    //             pannelArray.push('latest_events')
    //         }
    //         console.log(JSON.stringify(pannelArray))
    //         sessionStorage.setItem("pannelArray", JSON.stringify(pannelArray))
    //         if (authSelector.showEvChargingStations == 1) {
    //             poiDefaultValueArray.push('ALL_EVCS')
    //         } if (authSelector.showFuelStations == 1) {
    //             poiDefaultValueArray.push('ALL_FS')
    //         } if (authSelector.showNationalAccountShops == 1) {
    //             poiDefaultValueArray.push('ALL_NAS')
    //         }

    //         sessionStorage.setItem("POI", JSON.stringify(poiDefaultValueArray))
    //         sessionStorage.setItem("POIvalue", JSON.stringify(poiDefaultValueArray))
    //     }

    // }, [authSelector]);

    useEffect(() => {
        const { isLoading, data } = poiFiltersSelector;
        if (!isLoading && data.length) {
            const filters = [];
            data.forEach(row => {
                filters.push({
                    label: row.filterLabel,
                    value: row.filterType,
                    checked: ''
                })
            })
            setClientPoiFilters(filters);
        }
    }, [poiFiltersSelector]);

    useEffect(() => {
        const tempData = {};
        Object.keys(data).forEach((level) => {

            let filteredArray = [];
            data[level].forEach(filter => {
                filteredArray.push({ value: filter[0], label: filter[1] });
            })

            tempData[level] = filteredArray;
        });

        setFilterData(tempData);
    }, [data])

    const shouldOpenDrawer = (isOpened) => {
        setIsSideDrawerOpen(isOpened);
    };

    const handleChange = (selected, level) => {
        let options = { ...optionSelected };
        options[level] = selected;
        setOptionSelected(options);
    };




    const generateZipcodePayload = () => {
        // console.log('levelview')
        // console.log(poiPopUPPlayload)
        // console.log(mapPopUPPlayload)
        let levelFilterArray = Object.values(levelFilter)
        let result = []
        for (let index = 0; index < levelFilterArray.length; index++) {
            let element = [];
            levelFilterArray[index].map(a => {
                element.push(a.value)
            })
            result.push(element.join(", "))
        }
        let obj = {

            level1: result[0] ? result[0] : "",
            level2: result[1] ? result[1] : "",
            level3: result[2] ? result[2] : "",
            level4: result[3] ? result[3] : "",
            level5: result[4] ? result[4] : "",
            level6: result[5] ? result[5] : "",
            level7: result[6] ? result[6] : "",
            level8: result[7] ? result[7] : "",
            level9: result[8] ? result[8] : "",
            level10: result[10] ? result[9] : "",
            zip: sessionStorage.getItem("zipPayload") ? sessionStorage.getItem("zipPayload") : filtersSelectordefaultValue.data.zip,
            timezoneId: sessionStorage.getItem("timeZonePayload") ? sessionStorage.getItem("timeZonePayload") : filtersSelectordefaultValue.data.timezoneId,
            showNationalAccountShops: sessionStorage.getItem("showNationalAccountShops") ? sessionStorage.getItem("showNationalAccountShops") : filtersSelectordefaultValue.data.showNationalAccountShops == 1 ? 1 : 0,
            showEvChargingStations: sessionStorage.getItem("showEvChargingStations") ? sessionStorage.getItem("showEvChargingStations") : filtersSelectordefaultValue.data.showEvChargingStations == 1 ? 1 : 0,
            showFuelStations: sessionStorage.getItem("showFuelStations") ? sessionStorage.getItem("showFuelStations") : filtersSelectordefaultValue.data.showFuelStations == 1 ? 1 : 0,
            showFleetOverview: sessionStorage.getItem("setHideLeftSection") ? sessionStorage.getItem("setHideLeftSection") : filtersSelectordefaultValue.data.showFleetOverview,
            showLatestEvents: sessionStorage.getItem("setHideRightSection") ? sessionStorage.getItem("setHideRightSection") : filtersSelectordefaultValue.data.showLatestEvents,
            mapStyle: sessionStorage.getItem("mapStylePayload") ? sessionStorage.getItem("mapStylePayload") : filtersSelectordefaultValue.data.mapStyle,

        }
        const obj3 = { ...poiPopUPPlayload, ...obj }
        // const obj3 =  ({
        //     zip: "30009",
        //     timezoneId: "",
        //     autoUpdateTimezoneId: 0,
        //     showFleetOverview: 1,
        //     showLatestEvents: 1,
        //     mapStyle: "",
        //     showNationalAccountShops: 1,
        //     showEvChargingStations: 1,
        //     showFuelStations: 1,
        //     level1: "BHC",
        //     level2: "1001",
        //     level3: "",
        //     level4: "",
        //     level5: "",
        //     level6: "",
        //     level7: "",
        //     level8: "",
        //     level9: "",
        //     level10: ""
        // })
        return obj3
        //  {
        //     zip: "30009",
        //     timezoneId: "",
        //     autoUpdateTimezoneId: 0,
        //     showFleetOverview: 1,
        //     showLatestEvents: 1,
        //     mapStyle: "",
        //     showNationalAccountShops: '1',
        //     showEvChargingStations: '1',
        //     showFuelStations: '1',
        //     level1Description: "BHC",
        //     level2Description: "1001",
        //     level3Description: "",
        //     level4Description: "",
        //     level5Description: "",
        //     level6Description: "",
        //     level7Description: "",
        //     level8Description: "",
        //     level9Description: "",
        //     level10Description: ""
        // };
    };

    const applyFilterButton = () => {
        var payloadRequest = generateZipcodePayload()
        dispatch(saveClientInfo(payloadRequest));
        // setIsSideDrawerOpen(false);
    }

    return (
        <Spacing>
            <LPDrawer
                // title={getLabel('filters', 'Filters', prismicData)}
                shouldOpenDrawer={shouldOpenDrawer}
                shouldDrawerOpened={setIsSideDrawerOpen}
                isDisabled={false}
                dynamicWidth={true}
                saveActionBtn={applyFilterButton}
            >
                <Grid direction="row">
                    <Tabs isFixed={true} >

                        <Tab className={classes.gRlWW}
                            key={"Map"}
                            index={0}
                            title={getLabel('map', 'Map', prismicData)}
                            centered={true}
                        >

                            <div className={classes.gRlWW}>
                                <MapPopUpFilter
                                    heading={getLabel("map", "Map", prismicData)}
                                    data={clientPoiFilters}
                                    onFilterChange={onLayerFilterChange}
                                    values={poiFilters}
                                    mapPopUPPlayload={setMapPopUPPlayload}
                                />
                            </div>
                        </Tab>
                        <Tab className={classes.gRlWW}
                            key={"Level Hierarchy"}
                            index={1}
                            title={getLabel('level_hierarchy', 'Level Hierarchy', prismicData)}
                            centered={true}
                        >
                            <div className={classes.gRlWW}>
                                <SettingsIcon size='xs' className={styles.settingsIcon} htmlColor={'#989a9a'} />
                                <Text component="p" style={{ padding: '5px 5px', marginTop: '-2.5em', marginLeft: '2em' }} size='xs' color="#989a9a" >DEFAULT SETTINGS</Text>
                                <br />
                                <Spacing pv={.5} />
                                <Heading component="p" size="xs" color="petrolBlue" gutter={false}>Level hierarchy</Heading>
                                <Spacing pv={.5}>
                                    <Text className={styles.locationText} size='xs' color="petrolBlue" >Select the level groups you want visible on the map by default.</Text>
                                </Spacing>
                                <Grid>
                                    {
                                        Object.keys(filterData).map((level) => {
                                            if (filterData[level].length) {
                                                return (
                                                    <>
                                                        <GridItem
                                                            span={{ mobile: 12, tablet: 12 }}
                                                        >
                                                            <Text key={`text_${level}`} component="p" size="s" color="petrolBlue" style={{ fontWeight: "bold" }} gutter>{`Level ${level}`}</Text>
                                                            <ReactSelect
                                                                options={filterData[level]}
                                                                isMulti
                                                                closeMenuOnSelect={false}
                                                                hideSelectedOptions={false}
                                                                components={{
                                                                    Option
                                                                }}
                                                                styles={{ color: '#ccdbdf' }}
                                                                onChange={(selected) => onLevelFilterChange(selected, level)}
                                                                allowSelectAll={true}
                                                                value={levelFilter[level]}
                                                            />
                                                        </GridItem>
                                                    </>
                                                )
                                            } else {
                                                return null;
                                            }
                                        })
                                    }
                                </Grid>
                            </div>
                        </Tab>
                        <Tab
                            key={"Poi Types"}
                            index={0}
                            title={getLabel('poi_types', 'POI Types', prismicData)}
                            centered={true}
                            textColor={'red'}
                        >

                            <div className={classes.gRlWW}>
                                <POIpopUpFilterComponent
                                    // defaultDatavalue={defaultData}
                                    heading={getLabel("poi types", "POI Types", prismicData)}
                                    data={clientPoiFilters}
                                    onFilterChange={onLayerFilterChange}
                                    values={poiFilters}
                                    poiPopUPPlayload={setPoiPopUPPlayload}
                                />
                            </div>
                        </Tab>


                    </Tabs>


                </Grid>
            </LPDrawer>
        </Spacing>
    )
}

LevelFilters.propTypes = {

}

export default LevelFilters
