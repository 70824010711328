import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export default function useWindowDimensions() {
    let noOfRows;
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    if (windowDimensions.width > 1635 && windowDimensions.width < 3000) {
        if (windowDimensions.width < 2032) {
            noOfRows = 4
        } else {
            if (windowDimensions.width < 2431) {
                noOfRows = 5
            } else {
                noOfRows = 6
            }
        }

    } else {
        if (windowDimensions.width < 840) {
            noOfRows = 1
        } else {
            if (windowDimensions.width > 1237) {
                noOfRows = 3
            } else {
                noOfRows = 2
            }

        }
    }

    if (windowDimensions.width > 3000) {
        noOfRows = 7
    }


    return { windowDimensions, noOfRows };
}