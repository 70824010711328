import React, { useEffect, useState } from 'react';
import {
    Grid,
    GridItem,
    Heading,
    Text,
    Paper,
    Spacing,
    Timeline,
    TimelineItem,
    CardCollapse,
    CardCollapseContent,
    CardCollapseHeader,
    CardCollapseHeaderContentSmall,
} from '@leaseplan/ui';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLocationMaskingEvents, fetchVehicleHealth, fetchVoltageEvents, setSelectedEventsUId } from '../../../Actions/vehicle.action';
import { Accordion, AccordionDetails, AccordionSummary, IconButton } from '@material-ui/core';
import RowEvents from './VehicleEvents/RowEvents';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TopVan from '../../../Asset/Images/car-blue.png';
import { convertSecondsInHoursAndMinutes, getLabel, getOffsetTimeZone } from '../../../utils';
import { COLOR_CODE_VEHICLE } from '../../../Constants/DriverEvents';
import styles from './VehicleHealth.module.scss';
import moment from 'moment';

import batteryVoltsIcon from '../../../Asset/NewEventsIcons/battery-volts.svg';
import timelapseIcon from '../../../Asset/NewEventsIcons/timelapse.svg';
import ArrowRightAltRoundedIcon from '@material-ui/icons/ArrowRightAltRounded';
import NavigationRoundedIcon from '@material-ui/icons/NavigationRounded';

import Popup from 'reactjs-popup';
import { insertInitalDriverDetails } from '../../../Actions/driver.action';

const ReadMore = ({ children, heading }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    return (
        <p style={{ display: 'inline', width: '100%', textAlign: 'justify' }}>
            <span style={{ color: '#004a5d', fontWeight: 'bold' }}>{heading}</span>
            {isReadMore ? text.slice(0, 150) : text}
            <span onClick={toggleReadMore} style={{ cursor: 'pointer', color: 'rgb(192,192,192)' }}>
                {isReadMore ? " ...read more" : " show less"}
            </span>
        </p>
    );
};

const contentStyle = {
    borderRadius: '5px',
    marginBottom: 10,
    width: 300
};
const overlayStyle = {};
const arrowStyle = { color: '#FFF' }; // style for an svg element

const VehiclePressureRange = ({ topView, lf, rf, lb, rb }) => (
    <Grid justifyContent="center">
        <GridItem>
            <Spacing p={2} pr={0}>
                <Grid direction='column'>
                    <GridItem>
                        <Text component="span" size={'s'} fontWeight={'bold'} gutter={false}>LF</Text>
                        <Text component="span" size={'s'} gutter={false}>{lf ? ` = ${lf} psi` : ' --'}</Text>
                    </GridItem>
                    <Spacing m={1} />
                    <GridItem>
                        <Text component="span" size={'s'} fontWeight={'bold'} gutter={false}>LB</Text>
                        <Text component="span" size={'s'} gutter={false}>{lb ? ` = ${lb} psi` : ' --'}</Text>
                    </GridItem>
                </Grid>
            </Spacing>
        </GridItem>
        <GridItem>
            <img src={topView} alt={'Vehicle icon'} className={styles.topView} />
        </GridItem>
        <GridItem>
            <Spacing p={2} pl={0}>
                <Grid direction='column'>
                    <GridItem>
                        <Text component="span" size={'s'} fontWeight={'bold'} gutter={false}>RF</Text>
                        <Text component="span" size={'s'} gutter={false}>{rf ? ` = ${rf} psi` : ' --'}</Text>
                    </GridItem>
                    <Spacing m={1} />
                    <GridItem>
                        <Text component="span" size={'s'} fontWeight={'bold'} gutter={false}>RB</Text>
                        <Text component="span" size={'s'} gutter={false}>{rb ? ` = ${rb} psi` : ' --'}</Text>
                    </GridItem>
                </Grid>
            </Spacing>
        </GridItem>
    </Grid>
)

const RecallDetail = ({ data }) => {
    return (
        <Grid justifyContent='space-between'>
            <Timeline>
                <TimelineItem status="normal" label={moment(data.nhtsaRecallDate).format('MM/DD/YYYY')}>
                    <CardCollapse status="success" className={styles.cardCollapse}>
                        <CardCollapseHeader>
                            <CardCollapseHeaderContentSmall
                                title={data.recallCategory}
                                label={data.recallStatus}
                            />
                        </CardCollapseHeader>
                        <CardCollapseContent>

                            <GridItem>
                                <Text component="span" size={'s'} gutter={false}>
                                    <ReadMore heading={'Defect : '}>
                                        {data.defectSummary}
                                    </ReadMore>
                                </Text>
                            </GridItem>

                            <GridItem>
                                <Text component="span" size={'s'} gutter={false}>
                                    <ReadMore heading={'Consequence : '}>
                                        {data.consequenceSummary}
                                    </ReadMore>
                                </Text>
                            </GridItem>

                            <GridItem>
                                <Text component="span" size={'s'} gutter={false}>
                                    <ReadMore heading={'Corrective : '}>
                                        {data.correctiveSummary}
                                    </ReadMore>
                                </Text>
                            </GridItem>

                        </CardCollapseContent>
                    </CardCollapse>
                </TimelineItem>
            </Timeline>
            {/* <Grid justifyContent='space-between' direction='row'>
                    <GridItem>
                        <Text component="span" size={'s'} color={'midOrange'} fontWeight={'bold'} gutter={false}>{data.recallCategory}</Text>
                    </GridItem>
                    <GridItem>
                        <Text component="span" size={'xs'} fontWeight='bold' color={data.recallStatus === "Closed" ? "error": "grassGreen"} gutter={false}>{data.recallStatus}</Text>
                    </GridItem>
                </Grid>
                <Text component="span" size={'xs'} gutter={false}>{moment(data.nhtsaRecallDate).format('MM/DD/YYYY')}</Text>
            <GridItem>
                <Text component="span" size={'s'} gutter={false}>
                    <ReadMore heading={'Defect : '}>
                        {data.defectSummary}
                    </ReadMore>
                </Text>
            </GridItem>
            <GridItem>
                <Text component="span" size={'s'} gutter={false}>
                    <ReadMore heading={'Consequence : '}>
                        {data.consequenceSummary}
                    </ReadMore>
                </Text>
            </GridItem>
            <GridItem>
                <Text component="span" size={'s'} gutter={false}>
                    <ReadMore heading={'Corrective : '}>
                        {data.correctiveSummary}
                    </ReadMore>
                </Text>
            </GridItem> */}
        </Grid>
    )
}

const LocationMaskingDetail = ({ data, selectedEvent }) => {
    let Time = getOffsetTimeZone(data.activeFrom, data.timezoneShortName ? data.timezoneShortName : sessionStorage.getItem('TimezoneShortname'), data.utcOffset ? data.utcOffset : sessionStorage.getItem('TimezoneOffset'))
    let dispatch = useDispatch()
    const onHandleClick = (Data) => {
        sessionStorage.setItem("selectedDate", Time.split(" ")[3])
        dispatch(insertInitalDriverDetails({ deviceId: Data.deviceId, selectedDate: Time.split(" ")[3] }));
        dispatch(setSelectedEventsUId(Data));
    }
    return (
        <div className={styles.wrapper}>
            <Grid>
                <GridItem span={9}>
                    <Spacing ph={1} >
                        <Text component="p" size={'s'} fontWeight={'bold'} className={styles.heading} gutter={false}>Location Masking</Text>
                        <Text component="p" size={'xs'} fontWeight={'bold'} color={'petrolBlue'} className={styles.heading} gutter={false}>Activation Location :</Text>
                        <Text component="p" size="xs" color={'petrolBlue'} gutter>{data.startAddress ? data.startAddress : '-- '}</Text>
                        <Text component="p" size={'xs'} fontWeight={'bold'} color={'petrolBlue'} className={styles.heading} gutter={false}>Deactivation Location :</Text>
                        <Text component="p" size="xs" color={'petrolBlue'} gutter>{data.endAddress ? data.endAddress : '-- '}</Text>
                    </Spacing>
                    <Spacing pv={.5} />
                    <div className={styles.tripDuration}>
                        <Text component="p" size="xs" color={'petrolBlue'} gutter>{data.activeFrom ? getOffsetTimeZone(data.activeFrom, data.timezoneShortName, data.utcOffset) : '-- '}</Text>
                        <ArrowRightAltRoundedIcon className={styles.arrowIcon} />
                        <Text component="p" size="xs" color={'petrolBlue'} gutter>{data.activeTo ? getOffsetTimeZone(data.activeTo, data.timezoneShortName, data.utcOffset) : ' --'}</Text>
                    </div>
                </GridItem>
                <GridItem span={3} >
                    <IconButton onClick={(e) => onHandleClick(data)}>
                        <NavigationRoundedIcon htmlColor={'#ee4c14'} style={{ transform: 'rotate(45deg)', color: (selectedEvent != null && selectedEvent === data.eventUid) ? '#ee4c14' : '#004a5d' }} />
                    </IconButton>
                </GridItem>
            </Grid>
            <div className={styles.tripDuration}>
                <div className={styles.tripDetails}>
                    <div className={styles.left}>
                        <div className={styles.element}>
                            <img src={timelapseIcon} className={styles.timelapseIcon} />
                            <Text component="p" size="xs" color={'petrolBlue'} gutter>{data.durationSeconds ? convertSecondsInHoursAndMinutes(data.durationSeconds) : '--'}</Text>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const BatteryDetail = ({ data, selectedEvent }) => {
    let Time = getOffsetTimeZone(data.activeFrom, data.timezoneShortName ? data.timezoneShortName : sessionStorage.getItem('TimezoneShortname'), data.utcOffset ? data.utcOffset : sessionStorage.getItem('TimezoneOffset'))
    let dispatch = useDispatch()
    const [isOpenPopup, setIsOpenPopup] = useState(true)
    const onHandleClick = (Data) => {
        sessionStorage.setItem("selectedDate", Time.split(" ")[3])
        dispatch(insertInitalDriverDetails({ deviceId: Data.deviceId, selectedDate: Time.split(" ")[3] }));
        dispatch(setSelectedEventsUId(Data));
    }
    return (
        <div className={styles.wrapper}>
            <Grid>
                <GridItem span={9}>
                    <Spacing ph={1} >
                        <Text component="p" size={'s'} fontWeight={'bold'} className={styles.heading} gutter={false}>Low Voltage</Text>
                    </Spacing>
                    <Spacing pv={.5} />
                    <div className={styles.tripDuration}>
                        <Text component="p" size="xs" color={'petrolBlue'} gutter>{data.activeFrom ? getOffsetTimeZone(data.activeFrom, data.timezoneShortName, data.utcOffset) : '-- '}</Text>
                        <ArrowRightAltRoundedIcon className={styles.arrowIcon} />
                        <Text component="p" size="xs" color={'petrolBlue'} gutter>{data.activeTo ? getOffsetTimeZone(data.activeTo, data.timezoneShortName, data.utcOffset) : ' --'}</Text>
                    </div>
                </GridItem>
                <GridItem span={3} >
                    <IconButton onClick={(e) => onHandleClick(data)}>
                        <NavigationRoundedIcon htmlColor={'#ee4c14'} style={{ transform: 'rotate(45deg)', color: (selectedEvent != null && selectedEvent === data.eventUid) ? '#ee4c14' : '#004a5d' }} />
                    </IconButton>
                </GridItem>
            </Grid>
            <div className={styles.tripDuration}>
                <div className={styles.tripDetails}>
                    <div className={styles.left}>
                        <div className={styles.element}>
                            <img src={batteryVoltsIcon} className={styles.batteryIcon} />
                            <Text component="p" size="xs" color={'petrolBlue'} gutter>{data.averageEventDataValue ? data.averageEventDataValue.toFixed(2) + ' volts' : '--'}
                            </Text>
                        </div>
                        <div className={styles.element}>
                            <img src={timelapseIcon} className={styles.timelapseIcon} />
                            <Text component="p" size="xs" color={'petrolBlue'} gutter>{data.durationSeconds ? convertSecondsInHoursAndMinutes(data.durationSeconds) : '--'}</Text>
                        </div>
                        <div className={styles.element}>
                            <Popup className={styles.popup} trigger=
                                {<h5 component="p" size="xs" style={{ textDecoration: "underline", cursor: "pointer", color: "#ee4c14" }}>learn more</h5>}
                                position="top center" {...{ contentStyle }}>
                                <div className={styles.PopupHeaderContainer}>
                                    <Text component="p" size="xs" color="petrolBlue" gutter> Low Voltage Alert </Text>
                                </div>
                                <Spacing ph={1} >
                                    <Spacing pv={.5} />
                                    <Text component="p" size="xs" color="petrolBlue" gutter> The car's battery is experiencing low voltage levels, indicating insufficient power.
                                        This can result in starting difficulties and potential electrical system problems. </Text>
                                    <Spacing pv={1} />
                                    <Spacing ph={1} pv={.5}>
                                        <Grid direction='row'>
                                            <img src={batteryVoltsIcon} className={styles.batteryIcon} />
                                            <Spacing ph={1} />
                                            <Text component="p" size="xs" color="petrolBlue" gutter>Lowest voltage the car reached </Text>
                                        </Grid>
                                    </Spacing>
                                    <Spacing ph={1} pv={1.5}>
                                        <Grid direction='row'>
                                            <img src={timelapseIcon} className={styles.timelapseIcon} />
                                            <Spacing ph={1} />
                                            <Text component="p" size="xs" color="petrolBlue" gutter> Total duration of the event </Text>
                                        </Grid>
                                    </Spacing>
                                    <Spacing pv={.5} />
                                </Spacing>
                            </Popup>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const VehicleHealthInfo = ({ fuelEconomy, averageMPH, oilLifeRemaining }) => (
    <Grid direction='column' gutter={false}>
        <GridItem>
            <Spacing p={1}>
                <Grid direction='row' justifyContent={'space-between'} gutter={false}>
                    <GridItem>
                        <Text component="p" size={'xs'} fontWeight={'bold'} color={'steel'} className={styles.heading} gutter={false}>FUEL ECONOMY</Text>
                    </GridItem>
                    <GridItem>
                        <Text component="p" size={'xs'} fontWeight={'bold'} color={'petrolBlue'} className={styles.heading} gutter={false}>{fuelEconomy ? `${fuelEconomy} mpg` : '--'}</Text>
                    </GridItem>
                </Grid>
            </Spacing>
        </GridItem>
        <GridItem>
            <Spacing p={1}>
                <Grid direction='row' justifyContent={'space-between'} gutter={false}>
                    <GridItem>
                        <Text component="p" size={'xs'} fontWeight={'bold'} color={'steel'} className={styles.heading} gutter={false}>AVERAGE MPH</Text>
                    </GridItem>
                    <GridItem>
                        <Text component="p" size={'xs'} fontWeight={'bold'} color={'petrolBlue'} className={styles.heading} gutter={false}>{averageMPH ? `${averageMPH} mph` : '--'}</Text>
                    </GridItem>
                </Grid>
            </Spacing>
        </GridItem>
        <GridItem>
            <Spacing p={1}>
                <Grid direction='row' justifyContent={'space-between'} gutter={false}>
                    <GridItem>
                        <Text component="p" size={'xs'} fontWeight={'bold'} color={'steel'} className={styles.heading} gutter={false}>OIL LIFE REMAINING</Text>
                    </GridItem>
                    <GridItem>
                        <Text component="p" size={'xs'} fontWeight={'bold'} color={'petrolBlue'} className={styles.heading} gutter={false}>{oilLifeRemaining ? `${oilLifeRemaining} %` : '--'}</Text>
                    </GridItem>
                </Grid>
            </Spacing>
        </GridItem>
    </Grid>
)

const VehicleEventsInfo = (props) => {
    return (
        <Grid gutter={false}>
            <GridItem span={12}>
                <Paper>
                    <Spacing mb={1}>
                        <Accordion disabled={props?.voltageEvents?.data?.length > 0 ? false : true}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <RowEvents color={COLOR_CODE_VEHICLE['recall']} type={'Battery'} Eventdata={props?.voltageEvents?.data} number={props?.voltageEvents?.data?.length} enablePopup={false} />
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid direction='column'>
                                    {
                                        props?.voltageEvents?.data?.length > 0 && (
                                            props.voltageEvents.data.map((data) =>
                                                <GridItem key={data.campaignNumber}>
                                                    <BatteryDetail data={data} selectedEvent={props?.setSelectedEvents?.data} />
                                                </GridItem>
                                            ))
                                    }
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Spacing>
                </Paper>
            </GridItem>
            <GridItem span={12}>
                <Paper>
                    <Spacing mb={1}>
                        <Accordion disabled={props?.locationMaskingEvents?.data?.length > 0 ? false : true}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <RowEvents color={COLOR_CODE_VEHICLE['recall']} type={'System Status'} Eventdata={props?.locationMaskingEvents?.data} number={props?.locationMaskingEvents?.data?.length} enablePopup={false} />
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid direction='column'>
                                    {
                                        props?.locationMaskingEvents?.data?.length > 0 && (
                                            props.locationMaskingEvents.data.map((data) =>
                                                <GridItem key={data.campaignNumber}>
                                                    <LocationMaskingDetail data={data} selectedEvent={props?.setSelectedEvents?.data} />
                                                </GridItem>
                                            ))
                                    }
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Spacing>
                </Paper>
            </GridItem>
            {/* <GridItem span={12}>
                <Paper>
                    <Spacing mb={1}>
                        <Accordion disabled={props.serviceLightOnIndicator === 'Y' ? false : true}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <RowEvents color={COLOR_CODE_VEHICLE['checkengine']} type={'check_engine_light'} number={props.serviceLightOnIndicator === 'Y' ? props.serviceLightOnIndicatorDayCount : ''} enablePopup={false} />
                            </AccordionSummary>
                            <AccordionDetails>
                                <Text component="p" size={'s'} color={'petrolBlue'} gutter>{props.serviceLightOnIndicator === 'Y' ? `Engine light indicator has been on from ${moment.utc(props.serviceLightOnIndicatorDate).format('MM/DD/YYYY')}` : ''}</Text>
                            </AccordionDetails>
                        </Accordion>
                    </Spacing>
                </Paper>
            </GridItem> */}
            <GridItem span={12}>
                <Paper>
                    <Spacing mb={1}>
                        <Accordion disabled={props.overdueMaintenanceIndicator === 'Y' ? false : true}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <RowEvents color={COLOR_CODE_VEHICLE['overdue']} type={'overdue'} number={props.overdueMaintenanceIndicator === 'Y' ? props.milesMaintenanceOverdue : ''} enablePopup={false} />
                            </AccordionSummary>
                            <AccordionDetails>
                                <Text component="p" size={'xs'} color={'petrolBlue'} gutter>{`Miles until next service (${props.milesMaintenanceOverdue} miles)`}</Text>
                            </AccordionDetails>
                        </Accordion>
                    </Spacing>
                </Paper>
            </GridItem>
            {/* <GridItem span={12}>
                <Paper>
                    <Spacing mb={1}>
                        <Accordion disabled={props.tirePressureLowIndicator === 'Y' ? false : true}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <RowEvents color={COLOR_CODE_VEHICLE['tmps']} type={'TMPS'} number={props.tirePressureLowIndicator === 'Y' ? props.tirePressureLowIndicatorDayCount : ''} enablePopup={false} />
                            </AccordionSummary>
                            <AccordionDetails>
                                <Text component="p" size={'xs'} color={'petrolBlue'} gutter>{props.tirePressureLowIndicator === 'Y' ? `Tire pressure indicator has been on from ${moment.utc(props.tirePressureLowIndicatorDate).format('MM/DD/YYYY')}` : ''}</Text>
                            </AccordionDetails>
                        </Accordion>
                    </Spacing>
                </Paper>
            </GridItem> */}
            <GridItem span={12}>
                <Paper>
                    <Spacing mb={1}>
                        <Accordion disabled={props?.vehicleRecallDtoList?.length > 0 ? false : true}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <RowEvents color={COLOR_CODE_VEHICLE['recall']} type={'Recall'} number={props?.vehicleRecallDtoList?.length} enablePopup={false} />
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid>
                                    {
                                        props?.vehicleRecallDtoList?.map((data) =>
                                            <GridItem key={data.campaignNumber}>
                                                <RecallDetail data={data} />
                                            </GridItem>
                                        )
                                    }
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Spacing>
                </Paper>
            </GridItem>
            {/* <GridItem span={12}>
                <Paper>
                    <Spacing mb={1}>
                        <Accordion disabled={props.oilChangeDueIndicator === 'Y' ? false : true}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <RowEvents color={COLOR_CODE_VEHICLE['oillife']} type={'oil_life'} number={props.oilChangeDueIndicator == 'Y' ? props.oilChangeDueIndicatorDayCount : ''} enablePopup={false} />
                            </AccordionSummary>
                            <AccordionDetails>
                                <Text component="p" size={'xs'} color={'petrolBlue'} gutter>{props.oilChangeDueIndicator == 'Y' ? `Oil change due indicator has been on from ${moment.utc(props.oilChangeDueIndicatorDate).format('MM/DD/YYYY')}` : ''}</Text>
                            </AccordionDetails>
                        </Accordion>
                    </Spacing>
                </Paper>
            </GridItem> */}
            {/* <GridItem span={12}>
                <Paper>
                    <Spacing mb={1}>
                        <Accordion disabled={props.deviceUnusedIndicator === 'Y' ? false : true}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <RowEvents color={COLOR_CODE_VEHICLE['device']} type={'device_status'} number={props.deviceUnusedIndicator == 'Y' ? props.deviceUnusedIndicatorDayCount : props.deviceOfflineIndicatorDayCount} enablePopup={false} />
                            </AccordionSummary>
                            <AccordionDetails>
                                <Text component="p" size={'xs'} color={'petrolBlue'} gutter>Device unused/offline indicator has been on from {props.deviceUnusedIndicator == 'Y' ? moment.utc(props.deviceUnusedIndicatorDate).format('MM/DD/YYYY') : moment.utc(props.deviceOfflineIndicatorDate).format('MM/DD/YYYY')}</Text>
                            </AccordionDetails>
                        </Accordion>
                    </Spacing>
                </Paper>
            </GridItem> */}
        </Grid>
    )
}


const VehicleHealth = ({ prismicData, vin, driverId }) => {

    const dispatch = useDispatch();
    const [vehicleHealthData, setVehicleHealthData] = useState(null);
    const vehicleHealthSelector = useSelector(state => state.vehicle);
    const initalSelectedDriverData = useSelector((state) => state.driver.initialDriverDetails);
    useEffect(() => {
        if (initalSelectedDriverData?.deviceId == driverId) {
            dispatch(fetchVehicleHealth({
                vin: vin
            }))
            dispatch(fetchVoltageEvents(sessionStorage.getItem("urlEndPoint") ? sessionStorage.getItem("urlEndPoint").split("/")[3] : driverId))
            dispatch(fetchLocationMaskingEvents(sessionStorage.getItem("urlEndPoint") ? sessionStorage.getItem("urlEndPoint").split("/")[3] : driverId))
        }
    }, [dispatch, vin]);

    useEffect(() => {
        const { isLoading, data } = vehicleHealthSelector.details;
        if (!isLoading && data) {
            setVehicleHealthData(vehicleHealthSelector);

        }
    }, [vehicleHealthSelector]);

    return (
        <div className={styles.container}>
            <Spacing mb={2}>
                <Heading component="p" size={'xs'} color={'petrolBlue'} gutter>{getLabel('vehicle_diagnostics', 'Vehicle Diagnostics', prismicData)}</Heading>
            </Spacing>
            <VehiclePressureRange topView={TopVan} lf={vehicleHealthData?.tirePressureLeftFront} rf={vehicleHealthData?.tirePressureRightFront} lb={vehicleHealthData?.tirePressureLeftRear} rb={vehicleHealthData?.tirePressureRightRear} />
            <VehicleHealthInfo fuelEconomy={vehicleHealthData?.averageMpg} averageMPH={vehicleHealthData?.averageMph} oilLifeRemaining={vehicleHealthData?.oilLifePercent} />
            <VehicleEventsInfo {...vehicleHealthData} />
        </div>
    )
}

VehicleHealth.propTypes = {

}

export default VehicleHealth
