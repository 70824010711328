import { S, F } from "../utils/actions";
import CONSTANTS from '../Constants/filters'


//Status can be LIVE or OLD representing whether the data is real time or not
const INITIAL_STATE = {
    search: {
        isLoading: false,
        data: {},
        error: null,
    },
    poi: {
        isLoading: false,
        data: [],
        error: null,
    },
    driverInsights: {
        isLoading: false,
        data: [],
        error: null,
    },
    vehicleInsights: {
        isLoading: false,
        data: [],
        error: null,
    },
    tripAdmin: {
        isLoading: false,
        data: [],
        error: null,
    }
};

const filters = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CONSTANTS.FETCH_FILTERS:
            return {
                ...state,
                search: {
                    data: {},
                    isLoading: true,
                    error: null
                }
            };
        case S(CONSTANTS.FETCH_FILTERS):
            return {
                ...state,
                search: {
                    isLoading: false,
                    error: null,
                    data: action.payload
                }
            };
        case CONSTANTS.FETCH_USER_DEFAULT_CONFIG:
            return {
                ...state,
                defaultConfig: {
                    data: {},
                    isLoading: true,
                    error: null
                }
            };
        case S(CONSTANTS.FETCH_USER_DEFAULT_CONFIG):
            return {
                ...state,
                defaultConfig: {
                    isLoading: false,
                    error: null,
                    data: action.payload
                }
            };
        case CONSTANTS.PUT_POI_FILTER_SESSION:
            return {
                ...state,
                putPoiFilterSession: {
                    data: [],
                    isLoading: true,
                    error: null
                }
            };
        case S(CONSTANTS.PUT_POI_FILTER_SESSION):
            return {
                ...state,
                putPoiFilterSession: {
                    data: action.payload,
                    isLoading: false,
                    error: null
                }
            };
        case CONSTANTS.FETCH_POI_FILTERS:
            return {
                ...state,
                poi: {
                    data: [],
                    isLoading: true,
                    error: null
                }
            };
        case S(CONSTANTS.FETCH_POI_FILTERS):
            return {
                ...state,
                poi: {
                    data: action.payload,
                    isLoading: false,
                    error: null
                }
            };
        case F(CONSTANTS.FETCH_POI_FILTERS):
            return {
                ...state,
                poi: {
                    data: [],
                    isLoading: true,
                    error: action.error
                }
            };
        case CONSTANTS.FETCH_DRIVER_INSIGHTS_FILTERS:
            return {
                ...state,
                driverInsights: {
                    data: [],
                    isLoading: true,
                    error: null
                }
            };
        case S(CONSTANTS.FETCH_DRIVER_INSIGHTS_FILTERS):
            return {
                ...state,
                driverInsights: {
                    data: action.payload,
                    isLoading: false,
                    error: null
                }
            };
        case F(CONSTANTS.FETCH_DRIVER_INSIGHTS_FILTERS):
            return {
                ...state,
                driverInsights: {
                    data: [],
                    isLoading: true,
                    error: action.error
                }
            };
        case CONSTANTS.FETCH_VEHICLE_INSIGHTS_FILTERS:
            return {
                ...state,
                vehicleInsights: {
                    data: [],
                    isLoading: true,
                    error: null
                }
            };
        case S(CONSTANTS.FETCH_VEHICLE_INSIGHTS_FILTERS):
            return {
                ...state,
                vehicleInsights: {
                    data: action.payload,
                    isLoading: false,
                    error: null
                }
            };
        case F(CONSTANTS.FETCH_VEHICLE_INSIGHTS_FILTERS):
            return {
                ...state,
                vehicleInsights: {
                    data: [],
                    isLoading: true,
                    error: action.error
                }
            };

        case CONSTANTS.RESET_FILTERS:
            return {
                ...state,
                search: {
                    isLoading: false,
                    data: {},
                    error: null
                }
            }
        case CONSTANTS.FETCH_TRIP_ADMIN_FILTERS:
            return {
                ...state,
                tripAdmin: {
                    data: [],
                    isLoading: true,
                    error: null
                }
            };
        case S(CONSTANTS.FETCH_TRIP_ADMIN_FILTERS):
            return {
                ...state,
                tripAdmin: {
                    data: action.payload,
                    isLoading: false,
                    error: null
                }
            };
        case F(CONSTANTS.FETCH_TRIP_ADMIN_FILTERS):
            return {
                ...state,
                tripAdmin: {
                    data: [],
                    isLoading: true,
                    error: action.error
                }
            };
        default:
            return state;
    }
}

export default filters;