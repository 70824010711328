import React, {useState, useEffect, useCallback, useRef} from 'react';
import styles from './UpdateTrips.module.scss';
import {
    Spacing,
    Grid,
    GridItem,
    Text,
    Heading,
    Tabs,
    Tab
  } from "@leaseplan/ui";
import LPDrawer from '../../../Common/LPDrawer/LPDrawer';
import FieldManager from '../../../Common/FieldManager/FieldManager';
import { getLabel, convertSecondsInHoursAndMinutes, checkFormValidation, getOffsetTimeZone } from '../../../utils';
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import RoadIcon from '../../../Asset/Images/road.png';
import LocalTaxiRoundedIcon from '@material-ui/icons/LocalTaxiRounded';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import CarIconSmall from '../../../Asset/Images/car.png';
import { SidebarConstant } from './SidebarConstant';
import { fetchDriverSearch, updateProjectCode } from '../../../Actions/tripAdmin.action';
import { useDispatch, useSelector } from 'react-redux';
import SelectSearch from 'react-select-search';
import './react.select-search.css';
import ArrowRightAltRoundedIcon from '@material-ui/icons/ArrowRightAltRounded';
import { InputBase } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';


const TripDetail = ({totalSelected, tripStartAddress, distance, driveTime, eventCount, firstName, lastName, startTime, endTime, timezoneShortName, utcOffset, year, make, model,type}) => {
  return (
    <div className={styles.selectedTripsList}>
      {/* {
        totalSelected === 1 ?
         <> */}
          {/* <Text component="p" size="s" color="petrolBlue" gutter={false}>{tripStartAddress}</Text> */}
          {/* <div className={styles.map}>

          </div> */}
         <div className={styles.imageAndName}>
        <Heading component="span" size="xxs" color={'petrolBlue'} variant={'300'} gutter>{`${year ??  ''} ${make ?? ''} ${model ?? ''}`} </Heading>
        </div>
          <div className={styles.left}>
          
            
            <div className={styles.element}>
                <img src={RoadIcon} className={styles.roadIcon} alt={'Road icon'}/>
                <Text component="p" size="xxs" color={'petrolBlue'} gutter>{getOffsetTimeZone(startTime, timezoneShortName, utcOffset)}</Text>
                        <ArrowRightAltRoundedIcon className={styles.arrowIcon} />
                <Text component="p" size="xxs" color={'petrolBlue'} gutter>{getOffsetTimeZone(endTime, timezoneShortName, utcOffset)}</Text>
                </div>
                
                
              {/* <Text component="span" size="xs" color="petrolBlue" className={styles.plateNo} gutter>{plateNo}</Text> */}
                {/* <Text component="p" size="xs" color={'petrolBlue'} gutter>{`${parseFloat(distance).toFixed(2)} Miles`}</Text> */}
            

            
            {/* <div className={styles.element}>
            
                <LocalTaxiRoundedIcon htmlColor={'#ee4c14'} fontSize={'small'}/>
                <Text component="p" size="xs" color={'petrolBlue'} gutter>{`${convertSecondsInHoursAndMinutes(driveTime)}`}</Text>
            </div>
            <div className={styles.element}>
                <WarningRoundedIcon htmlColor={'#ee4c14'} fontSize={'small'}/>
                <Text component="p" size="xs" color={'petrolBlue'} gutter>{eventCount}</Text>
            </div> */}
          </div>
          <div className={styles.column}>
                <Text component="span" size="s" color={'petrolBlue'}  gutter>{firstName+' '+lastName}</Text>
               </div> 
          
          {/* </> */}
        {/*  : <div className={styles.multipleTripListItem}>
          <img src={RoadIcon} className={styles.roadIcon} alt={'Road icon'}/>
          <Text component="p" size="s" color="petrolBlue" gutter={false}>{tripStartAddress}</Text>
        </div>
      } */}
      
    </div>
  )
}

const Vehicles = ({totalSelected, year, make, model, vin, plateNo, unitNo, vehicleType}) => {
  return (
    <div className={styles.vehicleDetails}>
      {
        totalSelected === 1 ? <>
          <div className={styles.imageAndName}>
            {/* <img src={CarIconSmall} className={styles.image} alt={'Vehicle icon'}/> */}
              <Text component="span" size="s" color={'petrolBlue'} gutter>{`${year ??  ''} ${make ?? ''} ${model ?? ''}`}</Text>
         
              {/* <Text component="p" size="xs" color={'petrolBlue'} gutter>{`VIN ${vin}`}</Text> */}
            </div>

          {/* <div className={styles.carNo}>
            <div className={styles.row}>
              <Text component="p" size="xs" color="petrolBlue" gutter>Plate</Text>
              <Text component="span" size="xs" color="petrolBlue" className={styles.plateNo} gutter>{plateNo}</Text>
            </div>
            <div className={styles.row}>
              <Text component="p" size="xs" color="petrolBlue" gutter>Unit</Text>
              <Text component="span" size="xs" color="white" className={styles.unit}>{unitNo}</Text>
            </div>
            <div className={styles.row}>
              <Text component="p" size="xs" color="petrolBlue" gutter>Type</Text>
              <Text component="span" size="xs" color="white" className={styles.unit} gutter>{vehicleType}</Text>
            </div>                          
          </div> */}
        </> : <>
          <div className={styles.imageAndNameSmaller}>
            <img src={CarIconSmall} className={styles.image} alt={'Vehicle icon'}/>
            <div className={styles.name}>
              <Text component="span" size="s" color={'petrolBlue'} gutter>{`${year ??  ''} ${make ?? ''} ${model ?? ''}`}</Text>

              <div className={styles.carNoSmaller}>
                <div className={styles.rowText}>
                  <Text component="p" size="xs" color="petrolBlue" gutter>Plate</Text>
                  <Text component="span" size="xxs" color="petrolBlue" className={styles.plateNo} gutter>{plateNo}</Text>
                </div>
                <div className={styles.rowText}>
                  <Text component="p" size="xs" color="petrolBlue" gutter>Unit</Text>
                  <Text component="span" size="xxs" color="white" className={styles.unit}>{unitNo}</Text>
                </div>
                <div className={styles.rowText}>
                  <Text component="p" size="xs" color="petrolBlue" gutter>Type</Text>
                  <Text component="span" size="xxs" color="white" className={styles.unit} gutter>{vehicleType}</Text>
                </div>                          
              </div>
            </div>
          </div>

          
        </>
      }
    </div>
  )
}




// const changeColor=(e) =>{
// console.log(e)
// }

const UpdateTrips = ({
    allTrips,
    selectedTrips,
    prismicData,
    isSideDrawerOpen,
    setIsSideDrawerOpen,
    // potentialDrivers,
    potentialDriversSearch,
    ...props
}) => {
  const dispatch = useDispatch();
  const authSelector = useSelector(state => state.auth);
    const [selectedTripsDetails, setSelectedTripsDetails] = useState([]);
    const [sideDrawerData, setSideDrawerData] = useState({
      ...SidebarConstant
    });
    const [isSideBarFormInvalid, setIsSideBarFormInvalid] = useState(true);
    const [showSearchAreaGrey, setshowSearchAreaGrey] = useState(false);
    const [selectedDriver, setSelectedDriver] = useState();
    const [searchItem, setSearchItem] = useState('');
    const searchPageSelector = useSelector(state => state.searchPage);
    const [searchString, setSearchString] = useState('');
    const [showSearchArea, setshowSearchArea] = useState(false);

    const ref = useRef()

    useEffect(() => {
      const checkIfClickedOutside = e => {
        // If the menu is open and the clicked target is not within the menu,
        // then close the menu
        if (showSearchArea && ref.current && !ref.current.contains(e.target)) {
          setshowSearchArea(false);
        }
      }
  
      document.addEventListener("mousedown", checkIfClickedOutside)
  
      return () => {
        // Cleanup the event listener
        document.removeEventListener("mousedown", checkIfClickedOutside)
      }
    }, [showSearchArea])

    

    useEffect(() => {
      const trips = []
      Object.keys(selectedTrips).forEach(tripId => {
        const tripIndex = allTrips.findIndex(trip => trip.tripUid === Number(tripId));
        if(tripIndex > -1) {
          trips.push(allTrips[tripIndex]);
        }
      });

      setSelectedTripsDetails(trips);
      for (let index = 0; index <= trips.length - 1; index++) {
        if (trips[index].type != 'P') {
          setshowSearchAreaGrey(true);
          break;
        }
      }

      //Resetting the values of form 
      let sideDrawerInputData = { ...sideDrawerData };

      sideDrawerInputData.inputs.forEach(row => {
        row.value = "";
        row.validationState = "pristine" // error state
      });

      setSideDrawerData(sideDrawerInputData);
    }, []);
 

    const handleInfoChange = (event, index) => {
      let sideDrawerInputData = { ...sideDrawerData };

      sideDrawerInputData.inputs[index] = {
          ...sideDrawerInputData.inputs[index],
          ...event,
          value: event.value,
      };

      setSideDrawerData(sideDrawerInputData);
      setIsSideBarFormInvalid(checkFormValidation(sideDrawerInputData));
      
  }

  const SearchRow = ({ id, columns, rowData }) => {
    const history = useNavigate();
    const getDriverNumber = () => {
      setSearchString(rowData.firstName+' '+rowData.lastName)
      setshowSearchArea(false);
      setSelectedDriver(rowData.driverRecordNumber)
      setIsSideBarFormInvalid(false)
  }
    return (
  
      <div className={styles.dataItem}>
        <Spacing pv={.5} ph={1}/>
        <div className={styles.fontstyle} onClick={getDriverNumber}>{rowData.firstName+' '+rowData.lastName}</div>
        </div>
    )
  }

    const shouldOpenDrawer = (isOpened) => {
      setIsSideDrawerOpen(isOpened);     
    };

    const saveActionBtn = () => {
      let sideDrawerInputData = { ...sideDrawerData };
      const requestPayload = {};
      sideDrawerInputData.inputs.forEach((item) => {
        if(item.alias === 'project_code') {
          requestPayload.projectCode = item.value? item.value: ""
        }
        if(item.alias === 'personal_trip') {
          requestPayload.personalFlag = item.value === 'yes' ? 'Y' : 'N'
        }
      });

      // if(requestPayload.personalFlag === 'N') {
      //   return
      // }
      requestPayload.driverNumber = selectedDriver? selectedDriver : "";
      requestPayload.userId = authSelector.user.authUser.username;
      requestPayload.tripIds = Object.keys(selectedTrips);

      dispatch(updateProjectCode(requestPayload));
      setIsSideDrawerOpen(false);
    };

    const onLevelFilterChange = () => {

    }

    const handleChange = (event) => {
      setshowSearchArea(true);
        dispatch(fetchDriverSearch(
  
            {
              search: event.target.value,
            }
        ))
        // setSearchItem(event.target.value);
    }

    const handleFilter = (items) => {
      return (searchValue) => {
        // if (searchValue.length === 0) {
        //   return potentialDrivers;
        // }
        const updatedItems = items.filter((item) => {
            return item.name.toLowerCase().includes(searchValue.toLowerCase());
          });
        return updatedItems;
      };
    };

    const onDriverSelected = (value) => {
      setSearchString(value.target.value);
      handleChange(value)
      // setSelectedDriver(value);
    }

    return (
        <Spacing>
            <LPDrawer
                title={getLabel('trip_details', 'Trip Details', prismicData)}
                shouldOpenDrawer={shouldOpenDrawer}
                shouldDrawerOpened={setIsSideDrawerOpen}
                isDisabled={isSideBarFormInvalid}
                dynamicWidth={false}
                saveActionBtn={saveActionBtn}
                primaryBtnText={"Save"}
            >
                <Grid direction="row">
                    <div className={styles.tripDetails}>
                    {
                        selectedTripsDetails.length > 1 ? <div className={styles.selectedTripsHeader}>
                          <Heading component="p" size="xs" color="petrolBlue" gutter>Selected Trips</Heading>
                           <div className={styles.selectedTripCount}>
                            <Text component="p" size="s" color="petrolBlue" gutter>{selectedTripsDetails.length}</Text>
                          </div>
                          <Spacing pv={2} ph={1}/>
                        </div> : null
                      }
                      {
                        selectedTripsDetails.map((tripDetails) => {
                         return (
                            <TripDetail
                              totalSelected={selectedTripsDetails.length}
                              tripStartAddress={tripDetails.tripStartAddress}
                              distance={tripDetails.distance}
                              driveTime={tripDetails.driveTime}
                              eventCount={tripDetails.eventCount}
                              startTime={tripDetails.startTime}
                              endTime={tripDetails.endTime}
                              firstName={tripDetails.firstName}
                              lastName={tripDetails.lastName}
                              timezoneShortName={tripDetails.timezoneShortName}
                              utcOffset={tripDetails.utcOffset}
                              year={tripDetails.year}
                              make={tripDetails.make}
                              model={tripDetails.model}
                            />
                          )
                        })
                      }
                      {/* {
                        selectedTripsDetails.length > 1 ? <Heading component="p" size="xs" color="petrolBlue" gutter>Vehicles</Heading> : null
                      }
                      {
                        selectedTripsDetails.map((tripDetails) => {
                          return (
                            <Vehicles
                              totalSelected={selectedTripsDetails.length}
                              year={tripDetails.year}
                              make={tripDetails.make}
                              model={tripDetails.model}
                              vin={tripDetails.vin}
                              plateNo={tripDetails.tagNumber}
                              unitNo={tripDetails.clientVehicleId}
                              vehicleType={tripDetails.vehicleType}
                            />
                          )
                        })
                      } */}
                    </div>

                    
                    {sideDrawerData && sideDrawerData.inputs.map((field, index) => {
                            let options = field.options || [];
                            const FieldComponent = FieldManager[field.fieldType];
                            const fieldFunction = handleInfoChange;
                            const fieldLabel = getLabel(
                                field.name,
                                field.name,
                                prismicData
                            );
                            const fieldPlaceholder = getLabel(
                                field.placeholder_prismic,
                                field.placeholder,
                                prismicData
                            );
                            return (
                                <GridItem
                                    span={{ mobile: 12, tablet: field.span ? field.span : 12 }}
                                    key={field.id}
                                >
                                    <FieldComponent
                                        alias={field.alias}
                                        options={options}
                                        label={fieldLabel}
                                        value={field.value}
                                        func={(e) => fieldFunction(e, index)}
                                        defaultValue={field.defaultValue}
                                        textTypeIcon={field.textTypeIcon ? field.textTypeIcon : ""}
                                        isRequired={field.isRequired || false}
                                        validation={field.validation || ""}
                                        isDisabled={field.isDisabled || false}
                                        placeholder={fieldPlaceholder || ""}
                                        errorMessage={field.errorMessage}
                                        validationState={field.validationState}
                                        disableDefault={field.disableDefault}
                                    />
                                </GridItem>
                            );
                        }
                    )} 
                    
                    {!showSearchAreaGrey && (
                    <div className={styles.checkoutDriverDiv}>
                      <Text component="p" size="s" color="petrolBlue" gutter>Checkout Driver</Text>
                      <InputBase className={styles.inputfield}
                        // options={searchString} 
                        search 
                        // filterOptions={handleFilter}
                        value={searchString}
                        name="select-driver" 
                        placeholder="Search Driver"
                        onChange={onDriverSelected} />
                    </div>)
                    
                    }
                  
                    
                  {potentialDriversSearch.data != null && showSearchArea === true && (
                    <div className={styles.dataResult}>
              {
              potentialDriversSearch.data.length > 0 && potentialDriversSearch.data.map((row, index) => {
                return (
                  <div className={styles.dataItem}>
                  <SearchRow key={index} id={index} rowData={row} />
                  <Spacing/>
                  </div>
                )
              })
            }

             
          </div>
          )}         
                </Grid>
            </LPDrawer>
        </Spacing>
    )
}

UpdateTrips.propTypes = {

}

export default React.memo(UpdateTrips)
