import { Grid, GridItem, Text, Spacing, Button } from '@leaseplan/ui';
import { Person, Phone, Mail, Label, DataUsage, DriveEta, CalendarToday, Sort } from '@material-ui/icons';
import { useMap, FeatureGroup } from 'react-leaflet';
import CircularProgressWithLabel from './CircularProgressWithLabel'
import React from 'react';

const VehicleTooltip = ({ deviceName, clientVehicleId, Iscluster, NoOfFleet }) => {
    return (
        <>
            {
                (!Iscluster) && (
                    <div className="GridStyle__VehicleTooltip">
                        <Grid gutter={false} justifyContent="center">
                            <GridItem span={12}>
                                <Spacing pb={0.2}>
                                    <Text fontWeight="bold" size="xs" color="bloodOrange">Vehicle Summary</Text>
                                </Spacing>
                            </GridItem>
                            <GridItem>
                                <Grid gutter={false}>
                                    <GridItem span={12}>
                                        <Text size="xs" color="petrolBlue">ePlan Unit #</Text>
                                        <Text size="xs" fontWeight="bold" color="petrolBlue">{clientVehicleId}</Text>
                                    </GridItem>
                                    <GridItem span={12}>
                                        <Text size="xs" color="petrolBlue">Device Name{` `}</Text>
                                        <Text size="xs" fontWeight="bold" color="petrolBlue">{deviceName}</Text>
                                    </GridItem>
                                </Grid>
                            </GridItem>
                        </Grid>
                    </div>
                )
            }

            {
                (Iscluster) && (
                    <div className="GridStyle__VehicleTooltip">
                        <Grid gutter={false} justifyContent="center">
                            <GridItem span={12}>
                                <Spacing pb={0.2}>
                                    <Text fontWeight="bold" size="xs" color="bloodOrange">Cluster Summary</Text>
                                </Spacing>
                            </GridItem>
                            <GridItem>
                                <Grid gutter={false} justifyContent="center">
                                    <GridItem span={12}>
                                        <Text size="xs" color="petrolBlue">Total Number of Vehicles = </Text>
                                        <Text size="xs" fontWeight="bold" color="petrolBlue">{NoOfFleet}</Text>
                                    </GridItem>
                                </Grid>
                            </GridItem>
                        </Grid>
                    </div>
                )
            }

        </>
    )
}


export default React.memo(VehicleTooltip)