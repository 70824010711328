import {
    Text,
    Heading,
  } from "@leaseplan/ui";

import styles from './VehicleInfo.module.scss';

const VehicleInfo = (props) => {
    const {vehicleIcon, vehicleName, plateNo, unitNo, vin, address} = props;
    return (
        <div className={styles.vehicleInfo}>
            <img src={vehicleIcon} className={styles.vehicleIcon} alt={'Vehicle icon'}/>
            {vehicleName && <Heading component="p" size="xxs" color="petrolBlue" gutter>{vehicleName}</Heading>}
            <div className={styles.carNo}>
                <Text component="p" size="xxs" color="petrolBlue" gutter>Plate</Text>
                <Text component="span" size="xxs" color="petrolBlue" gutter>{plateNo}</Text>
                <Text component="p" size="xxs" color="petrolBlue" gutter>Unit</Text>
                <Text component="span" size="xxs" color="petrolBlue" gutter>{unitNo}</Text>
            </div>

            <Text component="span" size="xxs" color="petrolBlue" gutter>VIN {vin}</Text>
            <Text component="span" size="xxs" color="petrolBlue" gutter>{address}</Text>
        </div>
    )
}

export default VehicleInfo;