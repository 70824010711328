import React, { useState, useEffect } from 'react';
import { getLabel, checkFormValidation } from '../../../utils/index';
import { Grid, GridItem, Spacing, Text } from '@leaseplan/ui';
import FieldManager from '../../../Common/FieldManager/FieldManager';
import LPDrawer from '../../../Common/LPDrawer/LPDrawer';
import { SidebarConstant } from './SidebarConstant';
import { checkDownloadReportStatus, fetchReportsWithDateRangesValue } from '../../../Actions/reporting.action';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, SvgIcon } from '@material-ui/core';
import styles from './ReportSidebar.module.scss';
import { ReactComponent as Download } from '../../../Asset/InsightIcons/Download.svg';

import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { fetchDeviceLastLocation, fetchLogRecordTrips } from '../../../Actions/driver.action';



const extractValueAndPopulate = (sideDrawerData, data) => {
    const newSideDrawerInputData = { ...sideDrawerData };
    newSideDrawerInputData.inputs = sideDrawerData.inputs.map((drawerData) => {
        if (drawerData.alias === "graph_type") {
            const options = data.map(d => ({
                name: d.reportName,
                val: d.reportCatalogueUid,
            }));
            return {
                ...drawerData,
                options: options,
            }
        } else {
            return drawerData
        }
    });
    return newSideDrawerInputData;
}

const ReportSideBar = ({ prismicData, shouldOpenDrawer, setIsSideDrawerOpen, addSelectedReport }) => {

    const dispatch = useDispatch();
    const [sideDrawerData, setSideDrawerData] = useState({
        ...SidebarConstant
    });
    const [isSideBarFormInvalid, setIsSideBarFormInvalid] = useState(true);
    const reportingSideBarSelector = useSelector((state) => state.reporting.reportingSideBarData);

    const checkDownloadReportStatusSelector = useSelector((state) => state.reporting.checkDownloadReportStatus);
    useEffect(() => {
        if (reportingSideBarSelector.data.length == 0) {
            dispatch(fetchReportsWithDateRangesValue());
            //set the url for hardrefreshing functionality #444
            sessionStorage.setItem("urlEndPoint", window.location.pathname)
            dispatch(fetchDeviceLastLocation([false]));
            dispatch(fetchLogRecordTrips(false));
        }
    }, [])

    useEffect(() => {
        if (!reportingSideBarSelector.isLoading && reportingSideBarSelector.data.length > 0) {
            let populatedData = extractValueAndPopulate(sideDrawerData, reportingSideBarSelector.data);
            setSideDrawerData(populatedData);
        }
    }, [reportingSideBarSelector.isLoading])

    const renderTime = ({ remainingTime }) => {
        if (remainingTime <= 0) {
            dispatch(checkDownloadReportStatus())
        }
        return (
            <div className={styles.value1}>{children({ remainingTime })}</div>
        );
    };

    const children = ({ remainingTime }) => {
        const minutes = Math.floor(remainingTime / 60)
        const seconds = (remainingTime % 60)
        return `${minutes}:${seconds}`
    }



    const handleInfoChange = (event, index) => {
        let data = reportingSideBarSelector.data;
        let sideDrawerInputData = { ...sideDrawerData };

        if (event.alias === "graph_type") {
            let selectedData = data.filter((d) => d.reportCatalogueUid === parseInt(event.value))[0]

            sideDrawerInputData.inputs[index] = {
                ...sideDrawerInputData.inputs[index],
                ...event,
                value: event.value,
            };


            let findObjectWithDefault = selectedData.reportDateRanges.filter((d) => d.isDefaultDateRange === 1)[0];
            let findDefaultUid = findObjectWithDefault?.dateRangeUid;
            let dateRangeIndex = sideDrawerInputData.inputs.findIndex((input) => input.alias === "date_range");

            sideDrawerInputData.inputs[dateRangeIndex] = {
                ...sideDrawerInputData.inputs[dateRangeIndex],
                defaultValue: findDefaultUid ? '' + findDefaultUid : '',
                value: findDefaultUid ? '' + findDefaultUid : '',
                options: selectedData.reportDateRanges.map((c) => {
                    return {
                        name: c.dateRangeName,
                        val: c.dateRangeUid,
                    };
                }),
            };

        } else if (event.alias === "date_range") {
            sideDrawerInputData.inputs[index] = {
                ...sideDrawerInputData.inputs[index],
                ...event,
                value: event.value,
            };
        }

        setSideDrawerData(sideDrawerInputData);
        setIsSideBarFormInvalid(checkFormValidation(sideDrawerInputData));
    }
    const getCounterDuration = (row) => {
        var now = new Date();
        var utc_now = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds());
        var result2
        var s3DateTime = new Date(new Date(row.reportS3LinkTimestamp)).getTime() + 3600000
        if (s3DateTime - utc_now.getTime() > 0) {
            var result = ((s3DateTime - utc_now.getTime()))
            result2 = (result / 1000).toFixed(0)
        } else {
            result2 = 0
        }
        return result2
    }

    const addSelectedReportHandler = () => {
        let requestBody = sideDrawerData.inputs.reduce((originalData, updatedData) => {
            originalData[updatedData.propertyName] = parseInt(updatedData.value);
            return originalData;
        }, {});
        if (requestBody.graphType != 12 || requestBody.graphType != 11)
            addSelectedReport(requestBody);
    }

    return (
        <Spacing>
            <LPDrawer
                title={getLabel('add_report', 'Add Report', prismicData)}
                shouldOpenDrawer={shouldOpenDrawer}
                shouldDrawerOpened={setIsSideDrawerOpen}
                isDisabled={isSideBarFormInvalid}
                saveActionBtn={addSelectedReportHandler}
                primaryBtnText={getLabel("save", "Save", prismicData)}
                secondaryBtnText={getLabel("cancel", "Cancel", prismicData)}
            >
                <Grid direction="row">
                    {sideDrawerData && sideDrawerData.inputs.map((field, index) => {
                        let options = field.options || [];
                        const FieldComponent = FieldManager[field.fieldType];
                        const fieldFunction = handleInfoChange;
                        const fieldLabel = getLabel(
                            field.name,
                            field.name,
                            prismicData
                        );
                        const fieldPlaceholder = getLabel(
                            field.placeholder_prismic,
                            field.placeholder,
                            prismicData
                        );
                        return (
                            <GridItem
                                span={{ mobile: 12, tablet: field.span ? field.span : 12 }}
                                key={field.id}
                            >
                                <FieldComponent
                                    alias={field.alias}
                                    options={options}
                                    label={fieldLabel}
                                    value={field.value}
                                    func={(e) => fieldFunction(e, index)}
                                    defaultValue={field.defaultValue}
                                    textTypeIcon={field.textTypeIcon ? field.textTypeIcon : ""}
                                    isRequired={field.isRequired || false}
                                    validation={field.validation || ""}
                                    isDisabled={field.isDisabled || false}
                                    placeholder={fieldPlaceholder || ""}
                                    errorMessage={field.errorMessage}
                                    validationState={field.validationState}
                                    disableDefault={field.disableDefault}
                                />
                            </GridItem>
                        );
                    }
                    )}
                </Grid>

                <Spacing pv={2}></Spacing>
                <Text component="p" size="l" fontWeight="bold" color="petrolBlue" gutter>Download Library</Text>
                <Text component="p" size="xs" color="petrolBlue" gutter>List of files ready to download (files will be available  for 60 mins only)</Text>
                <Spacing pv={1}></Spacing>

                {checkDownloadReportStatusSelector?.data && checkDownloadReportStatusSelector?.data[0]?.map((row,) => {
                    return (
                        <>
                            <Spacing pv={.5}></Spacing>
                            <div style={{ backgroundColor: '#e9f0f5' }}>
                                <Grid justifyContent='left'>
                                    <GridItem>
                                        <div style={{ marginLeft: '.5em' }}>
                                            <Text component="p" size="s" color="petrolBlue" gutter>{row.reportName}</Text>
                                            <Text component="p" size="xs" color="petrolBlue" gutter>{row.dateRangeDescription ? row.dateRangeDescription : ''}</Text>
                                        </div>
                                    </GridItem>
                                </Grid>
                                <div style={{ marginTop: '-2.5em', marginRight: '2em' }}>
                                    <Grid justifyContent='right'>
                                        {row.reportS3Link ? (
                                            <>
                                                <GridItem>
                                                    <Spacing pv={.5}></Spacing>
                                                    <a href={row.reportS3Link}>
                                                        {<SvgIcon style={{ width: '1.4em', height: '1.4em' }}  >
                                                            <Download />
                                                        </SvgIcon>}
                                                    </a>
                                                </GridItem>
                                                <div style={{ marginTop: '1em' }}>
                                                    <CountdownCircleTimer
                                                        key={1}
                                                        isPlaying
                                                        duration={getCounterDuration(row)}
                                                        size={46}
                                                        strokeWidth={3}
                                                        colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                                                    >{renderTime}
                                                    </CountdownCircleTimer>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <GridItem>
                                                    <Spacing pv={.5}></Spacing>
                                                    <a href={row.reportS3Link}>
                                                        {<SvgIcon style={{ width: '1.4em', height: '1.4em' }}>
                                                            <Download />
                                                        </SvgIcon>}
                                                    </a>
                                                </GridItem>
                                                <div style={{ marginTop: '1em' }}>
                                                    <CircularProgress />
                                                </div>
                                            </>
                                        )}

                                    </Grid>
                                </div>
                            </div>
                            <Spacing />
                            <hr />

                        </>
                    )
                }
                )}



            </LPDrawer>
        </Spacing>
    )
}

ReportSideBar.propTypes = {

}

export default React.memo(ReportSideBar)
