import React, { useState, useEffect, useRef } from 'react';
import { baseURL } from "../../Store/base-url";
import { useSelector } from 'react-redux';
import { fetchDataUsingCancelToken } from '../../AxiosInterceptor/fetchDataUsingCacelToken';

const useFetchPOIDataUsingZoom = (clientNumber, method, url, map, delay = 0) => {
    const [isLoading, setIsLoading] = useState(false);
    const [apiData, setApiData] = useState([]);
    const [serverError, setServerError] = useState(null);
    const [returnResult, setReturnResult] = useState(40);
    const [poiType, setPoiType] = useState([]);
    const fetchPOIDataTimerRef = useRef(null);
    // const mapFilterPOISelector = useSelector(state => state.mapFilters.poiFilter.filter)
    const poiFiltersSessionSelector = useSelector(state => state.filters.putPoiFilterSession)

    useEffect(() => {
        fetchData(returnResult, JSON.parse(sessionStorage.getItem("POI")))
    }, [poiFiltersSessionSelector])

    useEffect(() => {
        map.on('moveend', () => {
            setIsLoading(true);

            let returnResult;
            let zoom = map._zoom;

            // Display the number of markers at each Zoom level
            switch (zoom) {
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                    returnResult = 20
                    break;
                case 7:
                case 8:
                    returnResult = 30
                    break
                case 9:
                case 10:
                case 11:
                    returnResult = 40
                    break
                case 12:
                case 13:
                    returnResult = 50
                    break
                default:
                    returnResult = 200
            }
            if ((JSON.parse(sessionStorage.getItem("POI"))).length > 0) { fetchData(returnResult, JSON.parse(sessionStorage.getItem("POI"))) }
            // runDeferredFetchData(returnResult, poiType);
            setReturnResult(returnResult);
        })
        return () => {
            map.off('moveend')
        }
    }, [url, method, poiType, poiFiltersSessionSelector])

    const runDeferredFetchData = (returnResult, poiType) => {
        clearTimeout(fetchPOIDataTimerRef.current);
        fetchPOIDataTimerRef.current = setTimeout(() => fetchData(returnResult, poiType), delay);
    }

    // used when we select default poi checkbox to hit /paged api

    // useEffect(() => {
    //     fetchData(returnResult, JSON.parse(sessionStorage.getItem("POI")))
    //     setPoiType(JSON.parse(sessionStorage.getItem("POI")))
    // }, [JSON.stringify(mapFilterPOISelector)])


    const fetchData = async (returnResult, poi = []) => {
        let bbox = map.getBounds();

        let { lat: f_x, lng: f_y } = bbox.getSouthWest();
        let { lat: s_x, lng: s_y } = bbox.getNorthEast();

        let query = {
            first: {
                x: f_y,
                y: f_x,
            },
            second: {
                x: s_y,
                y: s_x,
            }
        }

        const { cancelPrevQuery, result, error } = await fetchDataUsingCancelToken(
            baseURL,
            "json",
            url,
            method,
            {
                box: {
                    ...query
                },
                pageNumber: 0,
                pageSize: returnResult,
                poiTypes: poi,
            },
        );

        if (cancelPrevQuery) return;

        if (result) {
            setApiData(result);
            setIsLoading(false);
        } else if (error) {
            setServerError(error);
            setIsLoading(false);
        }
    };



    return { isLoading, apiData, serverError };
}



export default useFetchPOIDataUsingZoom
