import axios from "../AxiosInterceptor";
import { put, takeEvery } from "redux-saga/effects";
import { MAP_FILTERS } from "../Constants/mapFilters";
import { F, S } from "../utils/actions";


function* insertPOIFiltersWorker(action) {
    yield put({ type: S(action.type), payload: action.payload })
}

function* insertMapStyleWorker(action) {
    yield put({ type: S(action.type), payload: action.payload })
}

function* openSettingDrawerWorker(action) {
    yield put({ type: S(action.type), payload: action.payload })
}

function* mapFiltersSaga() {
    yield takeEvery(MAP_FILTERS.POI_FILTER_INSERT, insertPOIFiltersWorker);
    yield takeEvery(MAP_FILTERS.MAP_STYLE_INSERT, insertMapStyleWorker);
    yield takeEvery(MAP_FILTERS.OPEN_SETTING_DRAWER, openSettingDrawerWorker);
}


export default mapFiltersSaga;