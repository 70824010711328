import React from 'react';
import {
    Text,
    Heading,
  } from "@leaseplan/ui";
import styles from './Row.module.scss';

import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import ArrowRightAltRoundedIcon from '@material-ui/icons/ArrowRightAltRounded';

const Row = (props) => {
    const { id, courseName, assignmentMethod, enrolledDate, completionDate } = props.data;
    return (
        <div className={styles.wrapper}>
            <Heading component="p" size={'xs'} color={'petrolBlue'}>{courseName}</Heading>
            <div className={styles.tripDetails}>
                <div className={styles.left}>
                    <div className={styles.element}>
                        <WarningRoundedIcon htmlColor={'#ee4c14'} fontSize={'small'}/>
                        <Text component="p" size="xs" color={'petrolBlue'} gutter>{assignmentMethod}</Text>
                    </div>
                </div>
            </div>
            <div className={styles.tripDuration}>
                <Text component="p" size="xs" color={'petrolBlue'} gutter>{enrolledDate}</Text>
                <ArrowRightAltRoundedIcon className={styles.arrowIcon}/>
                <Text component="p" size="xs" color={'petrolBlue'} gutter>{completionDate ?? 'InComplete'}</Text>
            </div>
        </div>
    )
}

export default Row;