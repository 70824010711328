import React from 'react';
import { XAxis, YAxis, CartesianGrid, ResponsiveContainer, BarChart, Bar, LabelList, Tooltip } from 'recharts';
import { CustomizedXAxisLabel, CustomizedYAxisLabel, AxisLabel, findStyleSetting, CustomLineReportTooltip } from './GraphComponents';
import { BAR_GRAPH_SPEC } from '../SpecificationSettings';
import { GRAPH_TYPES } from '../../../Constants/reporting';
import styles from './Graph.module.scss';
import { COLORS } from '../GeneralSettings';

const colorConfig = {
  '0-5 Miles': '#E2C2A5',
  '6-10 Miles': '#E47B66',
  '11-25 Miles': '#EBD34A',
  '>25 Miles': '#DFAA4F',

};


const BarGraph = ({ height, width, isModal, graphData, styleType, graphSpecification }) => {


  const settings = findStyleSetting(styleType, "bar");


  const barSpecSettings = BAR_GRAPH_SPEC[graphSpecification];

  //const data = graphSpecification === GRAPH_TYPES.DAILY_TRIPS.VALUE ? (graphData[0].values[0].tripCountList) 
  const data = (graphData[0]?.values)?(graphData[0]?.values[0]?.tripCountList)
  : (
    [
      {
        "date": "1-Nov",
        "trips": 22.7,
      },
      {
        "date": "2-Nov",
        "trips": 25.6,
      },
      {
        "date": "3-Nov",
        "trips": 19.8,
      },
      {
        "date": "4-Nov",
        "trips": 23.2,
      },
      {
        "date": "5-Nov",
        "trips": 27.7,
      },
      {
        "date": "6-Nov",
        "trips": 21.8,
      },
      {
        "date": "7-Nov",
        "trips": 23.4,
      },
    ]
  );




  return (
    <div className={styles.container}>
      <ResponsiveContainer width={width} height={height} className={styles.graphArea}>
        <BarChart
          width={width} height={height} data={data}
          margin={settings.margin}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis label={<AxisLabel axisType='xAxis'></AxisLabel>} dataKey="Date" tick={<CustomizedXAxisLabel {...settings.xAxisValue} />} />
          <YAxis label={<AxisLabel axisType='yAxis' {...settings.yAxisLabel}>{barSpecSettings?.y_axis_label}</AxisLabel>} tick={<CustomizedYAxisLabel {...settings.yAxisValue} />} />
          <Tooltip content={<CustomLineReportTooltip />} />
          {Object.keys(colorConfig).map((level) => {
            return (

              <Bar key={level}
                dataKey={level}
                stackId="a"
                fill={colorConfig[level]}>
              </Bar>
            );
          })}
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}


export default React.memo(BarGraph)