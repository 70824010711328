import React, { useEffect } from 'react';
import L from "leaflet";
import { useDispatch, useSelector } from 'react-redux';
import SingleDrivermarker from './SingleDriverMarker/SingleDrivermarker';
import useFetchSingleVehicleData from '../../Common/CustomHook/useFetchSingleVehicleData';
import { Marker, Popup, Tooltip, useMap } from 'react-leaflet';
import VehicleTooltip from './VehiclesPopupAndTooltip/VehicleTooltip';
import { fetchSingleLogRecordTrips } from '../../Actions/driver.action';
import moment from 'moment';
import RotatingMovingMarker from './RotatingMovingMarker/RotatingMovingMarker';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SelectedVehiclePopup from './VehiclesPopupAndTooltip/SelectedVehiclePopup';
import { render } from 'react-dom';
import { fuelingEventIcon, fuelingEventIconBig } from './MarkerIcon/MarkerIcon';
import VehicleMaintenanceTooltip from './VehiclesPopupAndTooltip/VehicleMaintenanceTooltip';

const SingleDynamicMarker = props => {


    const initalSelectedDriverData = useSelector((state) => state.driver.initialDriverDetails);
    const lastKnownLocation = useSelector((state) => state.driver.fetchSingleDeviceLastLocation);
    const setFuelMaintenanceHistoryData = useSelector(state => state.vehicle.setFuelMaintenanceHistory);
    const maintenanceEventSelectedIdSelector = useSelector(state => state.vehicle.setSelectedEvents);

    const [lastKnownlocationArray, setLastKnownlocationArray] = useState([]);
    const [fuelEventLocation, setFuelEventLocation] = useState(false);

    const dispatch = useDispatch()
    const map = useMap()
    map.closePopup()
    const history = useNavigate()

    let popup = L.popup()
    let container = L.DomUtil.create('div');

    const todaysDate = moment().tz(sessionStorage.getItem("Timezone") ? sessionStorage.getItem("Timezone") : "").format('YYYY-MM-DD');

    /**
     * Client Number and user id is not required in the payload anymore after integrating the api with authentication
     */
    let { isLoading, apiData, eventData, serviceEventData } = useFetchSingleVehicleData(
        "post",
        "/log-record-zoned",
        "/events/selected-driver-events",
        "/service-events/getServiceEvents",
        {
            // clientNumber: clientNumber,
            // userId: userId,
        },
        initalSelectedDriverData.deviceId,
        initalSelectedDriverData.selectedDate,
    );
    if (serviceEventData && eventData) { eventData = [...eventData, ...serviceEventData]; }

    useEffect(() => {
        if (!isLoading) {
            dispatch(fetchSingleLogRecordTrips(apiData));
            // if there is no log-record data then clear the arrowhead from map 
            if (apiData.length == 0) {
                let id = sessionStorage.getItem("getArrowsMarker._leaflet_id")
                map.eachLayer(function (getArrowsMarker) {
                    let id2 = getArrowsMarker._leaflet_id
                    if (id == id2) {
                        map.removeLayer(getArrowsMarker);
                    }
                });
            }
        }
    }, [isLoading])

    useEffect(() => {
        if (setFuelMaintenanceHistoryData?.data && initalSelectedDriverData.selectedDate === setFuelMaintenanceHistoryData?.data[0]?.eventDate) {
            setFuelMaintenanceHistoryData.data.forEach(element => {
                if (element.recordId === maintenanceEventSelectedIdSelector.data) {
                    setFuelEventLocation([element.latitude, (parseFloat(element.longitude) - 0.030)])
                    map.setView([element.latitude, (parseFloat(element.longitude) - 0.030)], 14)
                }
            })


        }
    }, [setFuelMaintenanceHistoryData, isLoading])

    // useEffect for lastknown location and conditions when there is no data from log-record api / when there is no data at all
    useEffect(() => {
        let knownlocationArray = []
        if (lastKnownLocation && lastKnownLocation?.data && map && apiData) {
            const deviceId = lastKnownLocation.data.deviceId;
            const deviceName = lastKnownLocation.data?.data.deviceName;
            const clientVehicleId = lastKnownLocation.data?.data.clientVehicleId;
            const timezoneShortName = lastKnownLocation.data?.data.timezoneShortName;
            const logRecordData = lastKnownLocation.data?.data.locations;
            knownlocationArray.push({ deviceId, logRecordData, deviceName, clientVehicleId, timezoneShortName });
            if (knownlocationArray[0].logRecordData) {
                let firstLat = knownlocationArray[0].logRecordData[0].latitude;
                let firstLon = knownlocationArray[0].logRecordData[0].longitude;
                knownlocationArray[0].logRecordData.splice(0, 0, { "latitude": firstLat, "longitude": firstLon, "dateTime": `${todaysDate} 00:00:00`, "device": knownlocationArray.deviceId, "client": knownlocationArray.clientVehicleId }, { "latitude": firstLat, "longitude": firstLon, "dateTime": `${todaysDate} 00:00:00`, "device": knownlocationArray.deviceId, "client": knownlocationArray.clientVehicleId, "timezoneShortName": knownlocationArray.timezoneShortName });
                map.setView([firstLat, (parseFloat(firstLon) - 0.050)], 13)
            }
            setLastKnownlocationArray(knownlocationArray)
        } else if (lastKnownLocation && lastKnownLocation.data == null && !lastKnownLocation.isLoading && apiData.length == 0) {
            let polylineId = sessionStorage.getItem("polylineDecorator._leaflet_id")
            map.eachLayer(function (getArrowsMarker) {
                let id = getArrowsMarker._leaflet_id
                if (polylineId == id) {
                    map.removeLayer(getArrowsMarker);
                }
            })
            setLastKnownlocationArray([])
        }
    }, [apiData])

    return (
        <>
            {
                apiData[0] ?
                    (<SingleDrivermarker key={0} clientVehicleId={apiData[0].clientVehicleId} deviceId={apiData[0].deviceId} events={eventData} serviceEvents={serviceEventData} vehicleData={apiData[0].logRecordData} apiData={apiData} deviceName={apiData[0].deviceName} isLoading={isLoading} date={initalSelectedDriverData.selectedDate} lastKnownLocation={lastKnownLocation} fuelEventLocation={fuelEventLocation}>
                        <>
                            <Tooltip>
                                <VehicleTooltip deviceName={apiData[0].deviceName} clientVehicleId={apiData[0].clientVehicleId} />
                            </Tooltip>
                            <Popup>
                                <SelectedVehiclePopup deviceId={apiData[0].deviceId} date={todaysDate} history={history} />
                            </Popup>
                        </>
                    </SingleDrivermarker>) :
                    lastKnownlocationArray.map((c, i) => (
                        <RotatingMovingMarker key={c.deviceId} deviceId={c.deviceId} vehicleData={c.logRecordData} deviceName={c.deviceName} clientVehicleId={c.clientVehicleId}>
                        </RotatingMovingMarker>
                    )
                    )
            }
            {
                setFuelMaintenanceHistoryData?.data && initalSelectedDriverData.selectedDate === setFuelMaintenanceHistoryData?.data[0]?.eventDate && setFuelMaintenanceHistoryData.data.map((data, index) => (
                    <Marker
                        icon={data.recordId === maintenanceEventSelectedIdSelector.data ? fuelingEventIconBig : fuelingEventIcon} position={[data.latitude, data.longitude]} zIndexOffset={data.recordId === maintenanceEventSelectedIdSelector.data ? 20 : 0} key={index}
                        eventHandlers={{
                            click: (e) => {
                                popup.setLatLng(e.latlng).openOn(map);
                                render(
                                    <VehicleMaintenanceTooltip leafletLatLng={e.latlng} eventData={data} />,
                                    container,
                                );
                                popup.setContent(container).setLatLng(e.latlng).update();
                            }
                        }}
                    >
                    </Marker>

                ))

            }
        </>
    )

}
SingleDynamicMarker.propTypes = {

}

export default React.memo(SingleDynamicMarker);
