import React from 'react';
import { Grid, Spacing } from "@leaseplan/ui";
import CircularProgressWithLabel from '../../OneConnectCommon/CircularProgressWithLabel/CircularProgressWithLabel';
import { SvgIcon } from '@material-ui/core';
import { ReactComponent as Driver } from '../../../Asset/SearchIcons/Driver.svg';
import { ReactComponent as Vehicle } from '../../../Asset/SearchIcons/Vehicle.svg';
import { ReactComponent as Navigation } from '../../../Asset/SearchIcons/Navigation.svg';
import styles from '../LiveSearch.module.scss';
import { useNavigate, generatePath } from "react-router-dom";
import { RoutePath } from '../../../Routers/Routes';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { isGlobalSearchField, isTripFilterSearchField } from '../../../Actions/searchPage.action';
import { putSelectedDriver } from '../../../Actions/level.action';
import { getTime, getValueByTime, timezoneOffSetAdd } from '../../../utils';
import { fetchDeviceLastLocation, fetchLogRecordTrips, insertInitalDriverDetails, insertInitialFromTime } from '../../../Actions/driver.action';
import { insertDate, insertTimelineClockData } from '../../../Actions/timeline.actions';


const LiveSearchRow = ({ rowData, onChange }) => {

    const dispatch = useDispatch();
    const timelineSelector = useSelector(state => state.timeline)
    const history = useNavigate();

    const timelineSelectorDate = useSelector(state => state.timeline.date);
    const todaysDate = moment().tz(sessionStorage.getItem("Timezone") ? sessionStorage.getItem("Timezone") : "").format('YYYY-MM-DD');
    const clearSearch = (event) => {
        // Here, we invoke the callback with the new value
        onChange(false);
    }
    // need to make a generic function for this for the system (later)
    const getCurrentTimeValue = () => {
        dispatch(insertDate(new Date()));
        let date = new Date();
        // let tzid = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let tzid = sessionStorage.getItem("Timezone");
        if (tzid == "") { tzid = 'America/New_York' }

        let dateAndTime = date.toLocaleString('en-US', { hour12: false, timeZone: tzid });
        dateAndTime = dateAndTime.split(',')
        let time = dateAndTime[1].substring(1);
        date = dateAndTime[0];

        let value = getValueByTime(time);
        let dayDate = `${moment(date).format('DD')}`;
        let fromTime = `${moment(date).format('YYYY-MM-DD')} ${getTime(0)}:00`;
        let toTime = `${moment(date).format('YYYY-MM-DD')} ${getTime(value)}:00`;
        dispatch(insertTimelineClockData({ dayDate, fromTime, toTime, event: 'mouseup', speed: 1 }));
    }

    const generateRequestPayload = (deviceId) => {
        const DriverDateFromUTC = timezoneOffSetAdd(todaysDate, '00:00:00', sessionStorage.getItem("driverTimezoneOffset"))
        const DriverDateToUTC = timezoneOffSetAdd(todaysDate, '23:59:59', sessionStorage.getItem("driverTimezoneOffset"))
        return {
            isHistoric: false,
            fromDateTime: DriverDateFromUTC,
            toDateTime: DriverDateToUTC,
            deviceId: deviceId ? deviceId : false,
        };
    };


    const moveToDriver = () => {
        sessionStorage.setItem("driverTimezoneOffset", rowData.utcOffset)
        sessionStorage.removeItem("tabUrl")
        sessionStorage.removeItem("selectedDate")
        dispatch(insertInitialFromTime())
        history({
            pathname: generatePath(RoutePath.DriverDetails, { driverId: rowData.deviceId, date: timelineSelectorDate ? timelineSelectorDate : todaysDate })
        });
        dispatch(insertInitalDriverDetails({ deviceId: rowData.deviceId, getTrips: true }));
        clearSearch()
    }

    const moveToVehicle = () => {
        sessionStorage.setItem("driverTimezoneOffset", rowData.utcOffset)
        sessionStorage.removeItem("tabUrl")
        sessionStorage.removeItem("selectedDate")
        dispatch(insertInitialFromTime())
        history({
            pathname: generatePath(RoutePath.VehicleDetails, { vin: rowData.vin, driverId: rowData.deviceId, date: timelineSelectorDate ? timelineSelectorDate : todaysDate })
        });
        dispatch(insertInitalDriverDetails({ deviceId: rowData.deviceId, selectedDate: todaysDate, getTrips: true }));
        clearSearch()
    }

    const moveToNavigation = () => {
        sessionStorage.setItem("driverTimezoneOffset", rowData.utcOffset)
        history({
            pathname: generatePath(RoutePath.Dashboard)
        });
        getCurrentTimeValue()
        dispatch(isGlobalSearchField(false));
        dispatch(isTripFilterSearchField(true));
        dispatch(fetchDeviceLastLocation([rowData.deviceId, todaysDate]));
        dispatch(fetchLogRecordTrips(generateRequestPayload([rowData.deviceId])));
        dispatch(putSelectedDriver([rowData.deviceId]));
        clearSearch()
    }



    return (
        <>

            <div className={styles.dataItem}>
                <Spacing p={.5} />
                {/* <Grid justifyContent='left'>        */}
                <CircularProgressWithLabel style={{ color: rowData.scoreColor ? (rowData.scoreColor.toLowerCase() === 'yellow' ? '#F9C74B' : rowData.scoreColor.toLowerCase()) : 'red' }} size='2rem' value={rowData.driverScore ? rowData.driverScore : 0} />
                {/* </Grid> */}
                <Spacing p={1} />
                <Grid justifyContent='left'>
                    <div className={styles.fontstyle}>{rowData.deviceName}</div>
                </Grid>
                <Spacing p={1} />
                <SvgIcon className={styles.button} onClick={moveToDriver}>
                    <Driver />
                </SvgIcon>

                <Spacing p={.5} />
                <SvgIcon className={styles.button} onClick={moveToVehicle}>
                    <Vehicle />
                </SvgIcon>
                <Spacing p={.5} />

                {/* {rowData.lastKnownLocationLatitude != null && ( */}
                <>
                    <SvgIcon className={styles.button} onClick={moveToNavigation}>
                        <Navigation />
                    </SvgIcon>
                    <Spacing p={.5} />
                </>
            </div>
        </>
    )
}

LiveSearchRow.propTypes = {

}

export default React.memo(LiveSearchRow)
