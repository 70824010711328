import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import RotatingMovingMarker from './RotatingMovingMarker/RotatingMovingMarker';
import useFetchVehicleDataViewPort from '../../Common/CustomHook/useFetchVehicleDataViewPort';
import { moveToTheLocation } from '../../utils';
import { driverLocationInfo } from '../../Actions/driverLocation.action';
import { simplifyPath } from './SimplifyPolyline';
import { isTripFilterSearchField } from '../../Actions/searchPage.action';
import { Popup, Tooltip, useMap } from 'react-leaflet';
import moment from 'moment-timezone';
import MarkerCluster from './RotatingMovingMarker/MarkerCluster';
import RotatingTripMarker from './RotatingMovingMarker/RotatingTripMarker';
import VehicleTooltip from './VehiclesPopupAndTooltip/VehicleTooltip';
import { useNavigate } from 'react-router-dom';
import SelectedVehiclePopup from './VehiclesPopupAndTooltip/SelectedVehiclePopup';

// const todaysDate = moment().tz('America/New_York').format('YYYY-MM-DD');


const DynamicMarker = (props) => {
    let getgeoloc = [];
    const map = useMap();
    const history = useNavigate()
    let resultdiff = [];
    const dispatch = useDispatch();
    const [hideCar, setHideCar] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [newApi, setNewApi] = useState([]);
    const timelineSelector = useSelector(state => state.timeline)
    const levelFilteredDrivers = useSelector(state => state.levels);
    const clientNumber1 = useSelector(state => state.auth.user.clientNumber);
    const getIsTripFilterSearchField = useSelector(state => state.searchPage.isTripFilterSearchField);
    const apiData = useSelector((state) => state.driver.fetchLogRecordTrips);
    const lastKnownLocation = useSelector((state) => state.driver.fetchDeviceLastLocation);
    const todaysDate = moment().tz(sessionStorage.getItem("Timezone") ? sessionStorage.getItem("Timezone") : "America/New_York").format('YYYY-MM-DD');

    const { isLoadingVp, resp } = useFetchVehicleDataViewPort(
        "post",
        "/log-device-viewport",
        {
            clientNumber: clientNumber1
        },
        0.00001,
        [timelineSelector.clockData.fromTime], [timelineSelector.clockData.toTime],
        2000
    );

    useEffect(() => {
        if (apiData) {
            setIsLoading(apiData.isLoading)
        }
    }, [apiData])


    {/*
// Date change functionality has been changed.
    const generateRequestPayload = () => {
        const DriverDateFromUTC = timezoneOffSetAdd(todaysDate, '00:00:00', sessionStorage.getItem("driverTimezoneOffset"))
        const DriverDateToUTC = timezoneOffSetAdd(todaysDate, '23:59:59', sessionStorage.getItem("driverTimezoneOffset"))
        return {
            isHistoric: timelineSelector.date === todaysDate ? false : true,
            fromDateTime: DriverDateFromUTC,
            toDateTime: DriverDateToUTC,
            deviceId: levelFilteredDrivers.selectedDrivers ? levelFilteredDrivers.selectedDrivers : false,
        };
    };



    useEffect(() => {
        if (apiData && apiData.data && apiData.data.length > 0 && apiData != null) {
            dispatch(fetchLogRecordTrips(generateRequestPayload()));
        }
    }, [timelineSelector.date])
*/}



    useEffect(() => {
        setHideCar(true)
    }, [apiData])

    useEffect(() => {
        setNewApi([])
        let newData = [];
        if (apiData && apiData.data && apiData.data.length > 0) {
            for (let i = 0; i < apiData.data.length; i++) {
                // convertLogRecordToLocalTime(apiData.data[i].data.locations);
                const logRecordData = simplifyPath(apiData.data[i].data.locations, '.001');
                const deviceId = apiData.data[i].deviceId;
                const deviceName = apiData.data[i].data.deviceName;
                const clientVehicleId = apiData.data[i].data.clientVehicleId;
                // comment down the 476 story
                // const vehicleUtilizationStatus = apiData.data[i].vehicleUtilizationStatus;
                // const driverScore = apiData.data[i].driverScore
                // const scoreColor = apiData.data[i].scoreColor;
                const timezoneShortName = apiData.data[i].data.timezoneShortName;
                if (logRecordData.length > 0) {
                    newData.push({ deviceId, logRecordData, deviceName, clientVehicleId, timezoneShortName});
                    if (newData[i].logRecordData.length) {
                        let firstLat = newData[i].logRecordData[0].latitude;
                        let firstLon = newData[i].logRecordData[0].longitude;
                        newData[i].logRecordData.splice(0, 0, { "latitude": firstLat, "longitude": firstLon, "dateTime": `${todaysDate}T00:00:00`, "device": newData.deviceId, "client": newData.clientVehicleId }, { "latitude": firstLat, "longitude": firstLon, "dateTime": `${todaysDate}T00:00:00`, "device": newData.deviceId, "client": newData.clientVehicleId, "timezoneShortName": newData.timezoneShortName });
                    }
                }
            }
            setNewApi(newData)
            //   console.log(newApi)
            if (newData.length < 2 && newData.length > 0) {
                getgeoloc = moveToTheLocation(timelineSelector.clockData.toTime, newData)
                map.setView([getgeoloc[0].logRecordData[0].latitude, getgeoloc[0].logRecordData[0].longitude], 13)
                map.flyTo([getgeoloc[0].logRecordData[0].latitude, getgeoloc[0].logRecordData[0].longitude], 13.0001)
                // dispatch(driverLastLocationInfo([getgeoloc[0].logRecordData[0].latitude, getgeoloc[0].logRecordData[0].longitude]));
            }

            //this condition is used when there is no logrecord data for last knownlocation

            // }else if(levelFilteredDrivers.driverLastLocationInfo != null  && apiData != 'undefined' && apiData.data && apiData.data.length == 0 && levelFilteredDrivers.driverLastLocationInfo[0] != false) {
            //     map.setView([levelFilteredDrivers.driverLastLocationInfo[0], levelFilteredDrivers.driverLastLocationInfo[1]], 13)
            //     map.flyTo([levelFilteredDrivers.driverLastLocationInfo[0], levelFilteredDrivers.driverLastLocationInfo[1]], 13.0001)
            //     // setTimeout(() => {
            //     //     // map.flyTo([levelFilteredDrivers.driverLastLocationInfo[0], levelFilteredDrivers.driverLastLocationInfo[1]], 13.0001)
            //     // }, 2000);
        }

        // this if case is used to show vehicle on map if there is no log-record i.e showing vehicle on last-known-location.

        else if (lastKnownLocation && lastKnownLocation.data != null && map) {
            const deviceId = lastKnownLocation.data.deviceId;
            const deviceName = lastKnownLocation.data.data.deviceName;
            const clientVehicleId = lastKnownLocation.data.data.clientVehicleId;
            const timezoneShortName = lastKnownLocation.data.data.timezoneShortName;
            const logRecordData = lastKnownLocation.data.data.locations;
            newData.push({ deviceId, logRecordData, deviceName, clientVehicleId, timezoneShortName });
            if (newData[0].logRecordData) {
                let firstLat = newData[0].logRecordData[0].latitude;
                let firstLon = newData[0].logRecordData[0].longitude;
                newData[0].logRecordData.splice(0, 0, { "latitude": firstLat, "longitude": firstLon, "dateTime": `${todaysDate} 00:00:00`, "device": newData.deviceId, "client": newData.clientVehicleId }, { "latitude": firstLat, "longitude": firstLon, "dateTime": `${todaysDate} 00:00:00`, "device": newData.deviceId, "client": newData.clientVehicleId, "timezoneShortName": newData.timezoneShortName });
            }
            setNewApi(newData)
        }
    }, [apiData, lastKnownLocation])

    useEffect(() => {
        if (getIsTripFilterSearchField && apiData.length > 0) {
            // console.log('coming')
            let getgeoloc = moveToTheLocation(timelineSelector.clockData.toTime, apiData)
            map.setView([getgeoloc[0].logRecordData[0].latitude, getgeoloc[0].logRecordData[0].longitude], 13)
            dispatch(driverLocationInfo([getgeoloc[0].logRecordData[0].latitude, getgeoloc[0].logRecordData[0].longitude]));
            dispatch(isTripFilterSearchField(false));
            resultdiff = resp.filter(o1 => !apiData.some(o2 => o1.deviceId === o2.deviceId));
        }
    }, [apiData])

    useEffect(() => {
        if (timelineSelector.clockData.event === "mouseup") {
            setHideCar(true);
        }
    }, [timelineSelector.clockData.fromTime, timelineSelector.clockData.toTime])

    useEffect(() => {
        resultdiff = resp
    }, [timelineSelector.date])


    useEffect(() => {
        if (hideCar) {
            setHideCar(false)
        }
    }, [hideCar])

    resultdiff = resp.filter(o1 => !newApi.some(o2 => o1.deviceId === o2.deviceId));

    return (
        <>
            {(resultdiff && resultdiff.length > 0) && (
                <MarkerCluster viewportResult={resultdiff} />
            )}

            {(!hideCar) && (!isLoading) && (newApi) && (
                newApi.map((c, i) => (
                    <RotatingTripMarker key={c.deviceId} deviceId={c.deviceId} vehicleData={c.logRecordData} deviceName={c.deviceName} clientVehicleId={c.clientVehicleId}>
                        <Tooltip>
                            <VehicleTooltip deviceName={c.deviceName} clientVehicleId={c.clientVehicleId} hideOneScore={true} hideUtilization={true} />
                        </Tooltip>
                        <Popup>
                            <SelectedVehiclePopup deviceId={c.deviceId} date={timelineSelector.date} history={history} />
                        </Popup>
                    </RotatingTripMarker>
                ))
            )}

            {/* {(!hideCar) && (!isLoading) && (newApi) && (
                newApi.map((c, i) => (
                    <RotatingMovingMarker key={c.deviceId} deviceId={c.deviceId} vehicleData={c.logRecordData} deviceName={c.deviceName} clientVehicleId={c.clientVehicleId}>
                    </RotatingMovingMarker>
                ))
            )} */}


        </>
    )
}

export default React.memo(DynamicMarker)
