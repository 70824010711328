// import * as codeListActions
import { S, F } from "../utils/actions";
import CodeList from "../Constants/code-list";

const INITIAL_STATE = {};

const codeList = (state = INITIAL_STATE, action) => {
  // console.log('REDUCER', action);

  switch (action.type) {
    case CodeList.FETCH_CODE_LIST:
      // if already fetch and has error property then delete
      const newState = { ...state };
      if (
        newState.hasOwnProperty(
          action.payload.entityName + "_" + action.payload.entityItem
        )
      ) {
        delete newState[
          action.payload.entityName + "_" + action.payload.entityItem
        ];
      }
      // addToast({ title: "Fetching Code List data...", text: "L" });
      return { ...newState };
    case S(CodeList.FETCH_CODE_LIST):
      action.payload.payload.sort(function (a, b) {
        return a.sortOrder - b.sortOrder;
      });
      return {
        ...state,
        [action.entityName + "_" + action.entityItem]: action.payload.payload,
      };
    case F(CodeList.FETCH_CODE_LIST):
      // toasterFail('codeList!', action.error.response.data)
      return {
        ...state,
        payload: null,
        [action.entityName + "_" + action.entityItem]: {
          error: { ...action.error },
        },
      };
    default:
      return state;
  }
}

export default codeList;