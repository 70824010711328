export const COLORS = [ '#ea9c8c', '#f4e891', '#38caa2','#ebcb91','#e9d1bb']

export const PIE_SETTINGS = {
    Placeholder: {
        innerRadius: 0,
        outerRadius: 80,
        legend: {
            wrapperStyle: {
                 position: 'absolute', 
                 fontSize: 7, 
                 fontWeight: 'bold'
            }
        },
        cellLabel: {
            fontSize: 8,
            color: '#000000'
        },
    },
    Modal: {
        innerRadius: 0,
        outerRadius: 270,
        legend: {
            wrapperStyle: {
                 position: 'absolute', 
                 fontSize: 18, 
                 fontWeight: 'bold'
            }
        },
        cellLabel: {
            fontSize: 14,
            color: '#000000'
        },
    },
    Dashboard: {
        innerRadius: 0,
        outerRadius: 80,
        legend: {
            wrapperStyle: {
                 position: 'absolute', 
                 fontSize: 7, 
                 fontWeight: 'bold'
            }
        },
        cellLabel: {
            fontSize: 8,
            color: '#000000'
        },
    },
}

export const DONUT_SETTINGS = {
    Placeholder: {
        innerRadius: 50,
        outerRadius: 80,
        paddingAngle: 2,
        legend: {
            wrapperStyle: {
                 position: 'absolute', 
                 fontSize: 7, 
                 fontWeight: 'bold'
            }
        },
        label: {
            fontSize: 10,
            width: 70
        },
        cellLabel: {
            fontSize: 8,
            color: '#000000'
        },
    },
    Modal: {
        innerRadius: 180,
        outerRadius: 270,
        legend: {
            wrapperStyle: {
                 position: 'absolute', 
                 fontSize: 18, 
                 fontWeight: 'bold'
            }
        },
        label: {
            fontSize: 20,
            width: 200,
            fontWeight: 'bold'
        },
        cellLabel: {
            fontSize: 14,
            color: '#000000'
        },
    },
    Dashboard: {
        innerRadius: 50,
        outerRadius: 80,
        paddingAngle: 2,
        legend: {
            wrapperStyle: {
                 position: 'absolute', 
                 fontSize: 7, 
                 fontWeight: 'bold',
                 left: '-10px',
            }
        },
        label: {
            fontSize: 9,
            width: 40
        },
        cellLabel: {
            fontSize: 8,
            color: '#000000'
        },

    }
}

export const LINE_SETTINGS = {
    Placeholder: {
        margin: {
            top: 10, 
            right: 10, 
            left: -20, 
            bottom: 0
        },
        legend: {
            wrapperStyle: {
                 position: 'absolute', 
                 fontSize: 7, 
                 fontWeight: 'bold',
                 left: '10px'
            }
        },
        xAxisValue: {
            fontSize: 6,
            dy: 5
        },
        yAxisLabel: {
            fontSize: 8,
        },
        yAxisValue: {
            fontSize: 6,
            dx: -5
        }
    },
    Modal: {
        margin: {
            top: 10, 
            right: 10, 
            left: 0, 
            bottom: 0
        },
        legend: {
            wrapperStyle: {
                 position: 'absolute', 
                 fontSize: 16, 
                 fontWeight: 'bold',
                 left: '10px'
            }
        },
        xAxisValue: {
            fontSize: 14,
            dy: 12,
            dx: 0,
            fontWeight: 'bold'
        },
        yAxisValue: {
            fontSize: 12,
            dy: 2,
            dx: -14,
            fontWeight: 'bold'
        },
        yAxisLabel: {
            fontSize: 18,
            fontWeight: '800',
            labelOffset: 15
        },
    },
    Dashboard: {
        margin: {
            top: 10, 
            right: 10, 
            left: -20, 
            bottom: 0
        },
        legend: {
            wrapperStyle: {
                 position: 'absolute', 
                 fontSize: 7, 
                 fontWeight: 'bold',
                 left: '10px'
            }
        },
        xAxisValue: {
            fontSize: 6,
            dy: 5
        },
        yAxisLabel: {
            fontSize: 8,
        },
        yAxisValue: {
            fontSize: 6,
            dx: -5
        }
    }
}

export const BAR_SETTINGS = {
    Placeholder: {
        margin: {
            top: 10, 
            right: 10, 
            left: -20, 
            bottom: 0
        },
        xAxisValue: {
            fontSize: 6,
            dy: 5
        },
        yAxisValue: {
            fontSize: 6,
            dx: -5
        },
        yAxisLabel: {
            fontSize: 8,
        },
        overheadLabel:{
            position: "top",
            fontSize: "9px"
        }
    },
    Modal: {
        xAxisValue: {
            fontSize: 14,
            dy: 12,
            dx: 0,
            fontWeight: 'bold'
        },
        yAxisValue: {
            fontSize: 14,
            dy: 2,
            dx: -14,
            fontWeight: 'bold'
        },
        yAxisLabel: {
            fontSize: 18,
            fontWeight: '800',
            labelOffset: 10,
        },
        overheadLabel:{
            fontSize: "18px",
            fontWeight: "700",
        }
    },
    Dashboard: {
        margin: {
            top: 10, 
            right: 10, 
            left: -20, 
            bottom: 0
        },
        xAxisValue: {
            fontSize: 6,
            dy: 5
        },
        yAxisValue: {
            fontSize: 6,
            dx: -5
        },
        yAxisLabel: {
            fontSize: 8,
        },
        overheadLabel:{
            position: "top",
            fontSize: "9px"
        }
    },
}