import React, {useEffect} from 'react'
import Error from "../../Common/ErrorPage/ErrorPage";
import styles from "./PageNotFound.module.scss";

export const PageNotFound = () => {
    return (
        <div className={styles.loginPage}>
               <div className={styles.error}>
                   <Error
                       title={"No Page Found"}
                       errorCodeText={
                           "Error Code 404"
                       }
                       icon={"search"}
                       iconSize={"s"}
                       description={
                           "Sorry this page doesn't exist"
                       }
                   />
               </div>
        </div>
    )
}

export default PageNotFound;