import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Heading, Text, Spacing } from "@leaseplan/ui";
import { MenuItem, Select, InputBase} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search';
import { TableConstants } from './Table/TableConstants';
import TableWrapperOneConnect from '../../Common/TableWrapperOneConnect/TableWrapperOneConnect';
import SearchRow from './Table/SearchRow';
import FilterDisplayer from './FilterDisplayer/FilterDisplayer';
import Filters from '../OneConnectCommon/Filters/Filters';
import {fetchSeachPageInfo} from '../../Actions/searchPage.action';
import { fetchFilters } from '../../Actions/filters.action';
import { removeSpaceFromString, capitalizeFirstLetter, getLabel } from '../../utils';
import styles from './Search.module.scss';
import _ from "lodash";
import { LPLoader } from '../../Common/LPLoader/LPLoader';

const generateRequestPayload = (
    filteredList = {},
    start = 1,
    limit,
    fieldOrder = [],
    mainSearch={}
  ) => {
    return {
        driverVehicleMainSearchInput:{
        name: mainSearch.name ? mainSearch.name: '',
        type: mainSearch.type ? mainSearch.type: '',
      },
      driverVehicleFilterCriteriaInput:{
        usage: filteredList.usage ? filteredList.usage : [],
        status: filteredList.status ? filteredList.status : [],
        make: filteredList.make ? filteredList.make : [],
        model: filteredList.model ? filteredList.model : [],
        year: filteredList.year ? filteredList.year: [],
        level1: filteredList["Level 1"] ? filteredList["Level 1"] : [],
        level2: filteredList["Level 2"] ? filteredList["Level 2"] : [],
        level3: filteredList["Level 3"] ? filteredList["Level 3"] : [],
        level4: filteredList["Level 4"] ? filteredList["Level 4"] : [],
        level5: filteredList["Level 5"] ? filteredList["Level 5"] : [],
        level6: filteredList["Level 6"] ? filteredList["Level 6"] : [],
        level7: filteredList["Level 7"] ? filteredList["Level 7"] : [],
        level8: filteredList["Level 8"] ? filteredList["Level 8"] : [],
        level9: filteredList["Level 9"] ? filteredList["Level 9"] : [],
        level10: filteredList["Level 10"] ? filteredList["Level 10"] : []
      },
      sortCriteriaInput: fieldOrder,
      pagingCriteriaInput:{
        pageNumber: start,
        pageSize: limit
      }
    };
  };


const Search = (props) => {
    const {prismicData} = props;
    const dispatch = useDispatch();
    const [searchItem, setSearchItem] = useState('');
    const [searchString, setSearchString] = useState('');
    const [filtersData, setFiltersData] = useState({});
    const [formattedFiltersData, setFormattedFiltersData] = useState({});
    const [selectedFilters, setSelectedFilters] = useState({});
    const [clearAll, setClearAll] = useState(false);

    const filtersSelector = useSelector(state => state.filters.search);
    const searchPageSelector = useSelector(state => state.searchPage);
    
    //For Table Data
    const [tableData, setTableData] = useState({
        ...TableConstants
    })


    const [fieldOrder, setFieldOrder] = useState([]);
    const [globalSearch, setGlobalSearch] = useState({});

    //ForPagination
    const PAGE_LIMIT = 20
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(PAGE_LIMIT);

    const [debouncedValue, setDebouncedValue] = useState('');


    useEffect(() => {
        const { isLoading, data } = filtersSelector;

        if(!isLoading && data) {
            setFiltersData(data);
        }
    }, [filtersSelector]);

    useEffect(() => {
        dispatch(fetchFilters());
    }, [dispatch]);

    useEffect(() => {
        if(filtersData.usage) {
            const getFilters = (key) => {
                const filters = []
                const filterItem = filtersData[key];
                filterItem.forEach(row => {
                    let obj = {}
                    obj['label'] = row;
                    obj['value'] = row;
                    obj['checked'] = false;
                    filters.push(obj);
                });
                
                return filters;
            }
            const finalData = {};
            /* This is because we want the filters in this order only (usage, status, year, make, model) 
                and after that levels
            */
            finalData['usage'] = getFilters('usage');
            finalData['status'] = getFilters('status');
            finalData['year'] = getFilters('year');
            finalData['make'] = getFilters('make');
            finalData['model'] = getFilters('model');
    
            let filters = [];
            Object.keys(filtersData['levelDescription']).forEach(row => {
                if(filtersData['levelDescription'][row].length){
                    filters = filtersData['levelDescription'][row].map(subItem => {
                        let obj = {}
                        obj['label'] = subItem;
                        obj['value'] = subItem;
                        obj['checked'] = false;
                        return obj;
                    });

                    finalData[`Level ${row}`] = filters;
                }
                
            });
            setFormattedFiltersData(finalData);
        }
    }, [filtersData])

    /**
     * To generate initail table values with no filters
     */
    useEffect(() => {
        let newTableData = { ...tableData };
        const field_order = [];
        for(let i=0; i< newTableData.columns.length; i++){
            if(newTableData.columns[i].sortEnabled){
                if(newTableData.columns[i].initialSort){
                    field_order.unshift({field: newTableData.columns[i].field, order: newTableData.columns[i].sort})
                    continue;
                }
                field_order.push({field: newTableData.columns[i].field, order: newTableData.columns[i].sort})
            }
        }

        dispatch(fetchSeachPageInfo(
            generateRequestPayload(
                selectedFilters,
                1,
                rowsPerPage,
                field_order
            )
        ))

        setFieldOrder(field_order)
    }, [])

    // To make it re usable making it false again
    useEffect(() => {
        setClearAll(false);
    }, [clearAll]);


    const handleChange = (event) => {
        dispatch(fetchSeachPageInfo(
            generateRequestPayload(
                selectedFilters,
                1,
                rowsPerPage,
                fieldOrder,
                {
                    name: searchString,
                    type: event.target.value,
                }
            )
        ))
        setSearchItem(event.target.value);
    }


    const onFiltersChanged = (name, heading, checked) => {
        let globalFilters = {...formattedFiltersData};
        globalFilters[heading] = globalFilters[heading].map(el => (el.value === name ? {...el, checked: checked} : el));
        let value = []
        for (let item of globalFilters[heading]) {
            if(item.checked){
                value.push(item.value);
            }
        }
        const filters = {...selectedFilters};
        if(!heading.startsWith('level')){
            filters[heading] = value;
        } 
        else {
            filters[removeSpaceFromString(heading)] = name;
        }
        dispatch(fetchSeachPageInfo(
            generateRequestPayload(
                filters,
                1,
                rowsPerPage,
                fieldOrder,
                globalSearch
            )
        ))
        setCurrentPage(1);
        setSelectedFilters(filters);
        setFormattedFiltersData(globalFilters);
    }

    const clearAllFilters = () => {
        let globalFilters = {...formattedFiltersData};

        let newGlobalFilter = {}

        Object.keys(globalFilters).forEach((key) => {
            let newKeyValue = globalFilters[key].map((data) => {
                return {
                    ...data,
                    checked: false,
                }
            })
            Object.assign(newGlobalFilter, {[key]: newKeyValue}); 
        });
        
        
        setFormattedFiltersData(newGlobalFilter);
        setSelectedFilters({})
        dispatch(fetchSeachPageInfo(
            generateRequestPayload(
                {},
                currentPage,
                rowsPerPage,
                fieldOrder,
                globalSearch
            )
        ))
        setClearAll(true);
    }

    const onPageOrPageLimitChange = (pageNumber = 1, rowsPerPage = PAGE_LIMIT) => {
        dispatch(fetchSeachPageInfo(
            generateRequestPayload(
                selectedFilters,
                pageNumber,
                rowsPerPage,
                fieldOrder,
                globalSearch
            )
        ))
        
        setCurrentPage(pageNumber);
        setRowsPerPage(rowsPerPage)
    }


    const onSortColumn = (
        field_name,
        direction = "asc",
        rowsPerPage = PAGE_LIMIT
      ) => {

        let newTableData = { ...tableData };
        newTableData.columns = newTableData.columns.map((col, index) => {
          if (col.sortEnabled && field_name === col.field)
            return {
              ...col,
              sort: direction,
            };
          return col;
        });

        let field_order = [...fieldOrder];

        field_order.splice(field_order.findIndex(a => a.field === field_name) , 1)
        field_order.unshift({field: field_name, order: direction});

        setFieldOrder(field_order);
        setTableData(newTableData);

        dispatch(fetchSeachPageInfo(
            generateRequestPayload(
                selectedFilters,
                1,
                rowsPerPage,
                field_order,
                globalSearch,
            )
        ))
        
        setCurrentPage(1);
        setRowsPerPage(rowsPerPage)
      };

    const handleSearchStringChange = (event) => {
        setSearchString(event.target.value);
    };

    useEffect(() => {
        dispatch(fetchSeachPageInfo(
            generateRequestPayload(
                selectedFilters,
                1,
                rowsPerPage,
                fieldOrder,
                {
                    name: searchString,
                    type: searchItem,
                }
            )
        ))
        setGlobalSearch({
            name: searchString,
            type: searchItem,
        })
        setCurrentPage(1);
    }, [debouncedValue]);

    const searchData = _.debounce((event)=> {
        setDebouncedValue(event.target.value);
    }, 2000);

    const handleOnPageChange = (e, newPage) => {
        onPageOrPageLimitChange(newPage+1, rowsPerPage);
    }

    const handleOnRowPerPageChange = (e) => {
        onPageOrPageLimitChange(1, parseInt(e.target.value, 10))
    }



    return (
        <div className={styles.mainWrapper}>
            <div className={styles.header}>
                <div className={styles.heading}>
                    <Spacing p={2}>
                        <Heading component="p" size="s" color="petrolBlue" gutter>{getLabel("search","Search", prismicData)}</Heading>
                    </Spacing>
                </div>
                <div className={styles.searchDiv}>
                    <div className={styles.left}>
                        <SearchIcon htmlColor={'#767878'}/>
                        <InputBase  
                            className={styles.searchTextInput} 
                            value={searchString} 
                            onChange={handleSearchStringChange} 
                            style={{width: '100%'}}
                            inputProps={{ maxLength: 100 }}
                            onKeyDown={searchData}
                        />
                    </div>
                </div>
            </div>

            
            <div className={styles.filters}>
                <div className={styles.filtersHeader}>
                    <Heading component="p" size="xs" color="petrolBlue" gutter>{getLabel("filter","Filter", prismicData)}</Heading>
                    <Text component="p" size="xs" color="petrolBlue" gutter onClick={clearAllFilters}>{getLabel("clear_all","Clear All", prismicData)}</Text>
                </div>

                { !filtersSelector.isLoading ? (
                        <div className={styles.filtersBody}>
                            {
                                Object.keys(formattedFiltersData).map(item => {
                                    return (
                                        <Filters
                                            key={item}
                                            heading={item}
                                            data={formattedFiltersData[item] || []}
                                            onFilterChange={onFiltersChanged}
                                            clearAll={clearAll}
                                        />
                                    ) 
                                })
                            }
                        </div>
                     ) : (
                         <div className={styles.filterLoader}>
                            <LPLoader
                                loading={filtersSelector.isLoading}
                                message={"Loading Search Filters"}
                            />
                         </div>
                     )
                    }

            </div>

            <div className={styles.searchArea}>
                <div className={styles.searchAreaMain}>
                    <div className={styles.mainTableHeader}>
                        <FilterDisplayer searchString={searchString} rowLength={searchPageSelector.resultSetCount} filters={selectedFilters} onTagCancelClick={onFiltersChanged}/>
                    </div>
                    <div className={styles.mainTableBody}>
                        <TableWrapperOneConnect
                                currentPage={currentPage}
                                rowsPerPage={rowsPerPage}
                                columns={tableData.columns}
                                currentRowLength={searchPageSelector.data.length}
                                resultSetCount={searchPageSelector.resultSetCount ? searchPageSelector.resultSetCount: 0}
                                isLoading={searchPageSelector.isLoading}
                                onSortColumn={onSortColumn}
                                handleOnPageChange={handleOnPageChange}
                                handleOnRowPerPageChange={handleOnRowPerPageChange}
                                maxHeight={'calc(100vh - 420px)'}
                                minHeight={'calc(100vh - 420px)'}
                            >
                                {
                                    searchPageSelector.data.length > 0 && searchPageSelector.data.map((row, index) => {
                                        return (
                                            <SearchRow key={index} id={index} rowData={row}/>
                                        )
                                    })
                                }
                            </TableWrapperOneConnect>
                    </div>
                </div>
            </div>
        </div>
        
    )
};

export default Search;