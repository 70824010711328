import React from "react";
import PropTypes from "prop-types";
import { Paper } from "@material-ui/core";
import { Text, CloseIcon, DownloadIcon, Grid, GridItem } from "@leaseplan/ui";

import LineGraph from "./Graphs/LineGraph";
import PieGraph from "./Graphs/PieGraph";
import DonutGraph from "./Graphs/DonutGraph";
import BarGraph from "./Graphs/BarGraph";
import TableGraph from "./Graphs/TableGraph";
import styles from "./GraphPlaceholder.module.scss";
import { GRAPH } from "../../Constants/reporting";
import { StarBorder, Star } from "@material-ui/icons";
import NumberGraph from "./Graphs/NumberGraph";

import FilterIcon from "../../Asset/Images/filter.png";
import NoDataGraph from "./Graphs/NoDataGraph";

const GraphPlaceholder = ({
  graphId,
  graphType,
  graphTitle,
  graphDescription,
  graphSpecification,
  graphDateRange,
  width,
  graphData,
  graphHideCalendar,
  height,
  isModal,
  styleType,
  isFavourite,
  reportData,
  hasWrapperElements,
  toggleModalHandler,
  removeReportHandler,
  addToFavouriteHandler,
  downloadReportHandler,
}) => {

  const modalDataHandler = (e, data) => {
    if (isModal || data.graphType === "NUMBER" || data.graphData === "NA") return;
    Object.assign(e, { graphData: data });
    toggleModalHandler(e);
  };

  const findGraph = (data, reportData) => {
    switch (graphType) {
      case GRAPH.LINE:
        return (
          <LineGraph
            key={graphId}
            id={graphId}
            height={height}
            width={width}
            isModal={isModal}
            graphData={data}
            styleType={styleType}
            graphSpecification={graphSpecification}
          />
        );
      case GRAPH.DONUT:
        return (
          <DonutGraph
            key={graphId}
            id={graphId}
            height={height}
            width={width}
            graphData={data}
            isModal={isModal}
            styleType={styleType}
            graphSpecification={graphSpecification}
          />
        );
      case GRAPH.PIE:
        return (
          <PieGraph
            key={graphId}
            id={graphId}
            height={height}
            graphData={data}
            width={width}
            isModal={isModal}
            styleType={styleType}
            graphSpecification={graphSpecification}
          />
        );
      case GRAPH.BAR:
        return (
          <BarGraph
            key={graphId}
            id={graphId}
            height={height}
            width={width}
            graphData={data}
            isModal={isModal}
            styleType={styleType}
            graphSpecification={graphSpecification}
          />
        );
      case GRAPH.NUMBER:
        return (
          <NumberGraph
            key={graphId}
            id={graphId}
            height={height}
            width={width}
            graphData={data}
            mergeData={reportData}
          />
        );

      case GRAPH.TABLE:
        return (
          <TableGraph
            key={graphId}
            id={graphId}
            height={height}
            width={width}
            graphData={data}
            isModal={isModal}
            styleType={styleType}
            graphSpecification={graphSpecification}
          />
        );
      default:
        return (
          <NoDataGraph
            key={graphId}
            id={graphId}
            height={height}
            width={width}
          />
        );
    }
  };

  return (
    <div>
      {hasWrapperElements ? (
        <Paper className={styles.graphPaper}>
          <div
            onClick={(event) => addToFavouriteHandler(graphId)}
            className={styles.favouriteButton}
          >
            {isFavourite ? (
              <Star className={styles.filledStar} />
            ) : (
              <StarBorder className={styles.borderedStar} />
            )}
          </div>
          <div
            onClick={(event) => downloadReportHandler(graphId)}
            className={styles.downloadButton}
          >
            <DownloadIcon className={styles.downloadIcon} />
          </div>
          {graphSpecification === "CLIENT_VEHICLE_UTILIZATION" && (
            <img
              src={FilterIcon}
              alt="Filter Icon"
              onClick={(e) =>
                modalDataHandler(e, {
                  graphType,
                  graphId,
                  graphDescription,
                  graphTitle,
                  graphSpecification,
                  graphData,
                  graphDateRange,
                  graphHideCalendar,
                })
              }
              className={styles.iconFilter}
            />
          )}
          <Grid gutter={false} justifyContent="center" alignItems="center">
            <GridItem>
              <Text
                component="p"
                size={"xs"}
                fontWeight="bold"
                color={"petrolBlue"}
                className={styles.heading}
                gutter={false}
              >
                {graphTitle}
              </Text>
            </GridItem>
          </Grid>

          <div
            onClick={(event) => removeReportHandler(event, graphId)}
            className={styles.cancelButton}
          >
            <CloseIcon className={styles.cancelIcon} />
          </div>
          <div
            onClick={(e) =>
              modalDataHandler(e, {
                graphType,
                graphId,
                graphDescription,
                graphTitle,
                graphSpecification,
                graphData,
                graphDateRange,
                graphHideCalendar,
              })
            }
          >
            {findGraph(graphData, reportData)}
          </div>
        </Paper>
      ) : (
        findGraph(graphData, reportData)
      )}
    </div>
  );
};

GraphPlaceholder.propTypes = {
  graphId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  graphType: PropTypes.oneOf([
    "LINE",
    "PIE",
    "DONUT",
    "BAR",
    "TABLE",
    "NUMBER",
  ]),
  graphTitle: PropTypes.string,
  graphDescription: PropTypes.string,
  graphSpecification: PropTypes.string,
  graphDateRange: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  graphData: PropTypes.array,
  reportData: PropTypes.object,
  isModal: PropTypes.bool,
  styleType: PropTypes.oneOf(["placeholder", "modal", "dashboard"]),
  toggleModalHandler: PropTypes.func,
  removeReportHandler: PropTypes.func,
  addToFavouriteHandler: PropTypes.func,
  downloadReportHandler: PropTypes.func,
  hasWrapperElements: PropTypes.bool,
};

GraphPlaceholder.defaultProps = {
  graphType: "line",
  graphTitle: "",
  graphDescription: "",
  graphDateRange: "",
  graphData: [],
  graphSpecification: "Max Speed",
  isModal: false,
  styleType: "placeholder",
  reportData: {},
  toggleModalHandler: () => {},
  removeReportHandler: () => {},
  addToFavouriteHandler: () => {},
  downloadReportHandler: () => {},
  hasWrapperElements: false,
};

export default GraphPlaceholder;
