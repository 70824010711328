import { IDENTITY_CONFIG, METADATA_OIDC } from "../../utils/authConst";
import { UserManager, WebStorageStateStore, Log } from "oidc-client";
import { customValidateIdTokenFromTokenRefreshToken, customProcessSigninParams, customValidateIdTokenAttributes, customValidateIdToken } from "./customFunctions";

export class AuthService {
    UserManager;

    constructor() {
        
    }

    signinRedirectCallback = () => {
        this.UserManager.signinRedirectCallback()
        .then(() => {
            "";
        })
        .catch((e) => {
            // window.location.replace("/");
        });
    };

    getUser = async () => {
        const user = await this.UserManager.getUser();
        if (!user) {
            return await this.UserManager.signinRedirectCallback();
        }
        return user;
    };

    parseJwt = (token) => {
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace("-", "+").replace("_", "/");
        return JSON.parse(window.atob(base64));
    };


    signinRedirect = () => {
        var redirectUri = window.localStorage.getItem("redirectUri");
        if (redirectUri == null) 
            window.localStorage.setItem("redirectUri", window.location.pathname);
            
        this.UserManager.signinRedirect({});
    };


    navigateToScreen = () => {
        var redirectUri = window.localStorage.getItem("redirectUri");
        if (redirectUri == null) {
            redirectUri = "/"
        } else {
            window.localStorage.removeItem("redirectUri");
        }
        window.location.replace(redirectUri);
    };


    isAuthenticated = () => {
        const oidcStorage = JSON.parse(window.localStorage.getItem(`oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`));
        if (!!oidcStorage && !!oidcStorage.id_token) {
            const expiresAt = new Date(oidcStorage['expires_at'] * 1000);
            const currentTime = new Date();
            return currentTime < expiresAt;
        } else {
            return false;
        }
    };

    signinSilent = () => {
        this.UserManager.signinSilent()
            .then((user) => {
                console.log("signed in", user);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    signinSilentCallback = () => {
        this.UserManager.signinSilentCallback();
    };

    createSigninRequest = () => {
        return this.UserManager.createSigninRequest();
    };

    logout = () => {
        localStorage.clear();
        // window.location.replace(process.env.REACT_APP_PUBLIC_URL);
    };
}

const getAuthorizedToken = () => {
    const oidcStorage = JSON.parse(window.localStorage.getItem(`oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`));
    return (!!oidcStorage && !!oidcStorage.id_token && oidcStorage.id_token) || null
};

const getUserId = () => {
    return '7';
}

const getClientNumber = () => {
    return '1015';
}

export default {
    AuthService,
    getAuthorizedToken,
    getUserId,
    getClientNumber
};