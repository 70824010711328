import { Marker} from "react-leaflet";
import { useEffect, useRef } from "react";
import "leaflet-rotatedmarker";

const RotatingMarker = ({ ...props }) => {
    const markerRef = useRef();
    const { rotationAngle, rotationOrigin } = props;

    

    useEffect(() => {
      const marker = markerRef.current;
      if (marker) {
        marker.setRotationAngle(rotationAngle);
        marker.setRotationOrigin(rotationOrigin);
      }
    }, [rotationAngle, rotationOrigin]);
  
    return (
        <Marker
            ref={(ref) => {
              markerRef.current = ref;
              }}
            {...props}
          >
          {props.children}
        </Marker>
    );
};


export default RotatingMarker;