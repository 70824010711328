import React, { useEffect } from "react";
import L, { Icon } from "leaflet";
import car_blue from '../../../Asset/MapIcons/car-blue.png';
import car_red from '../../../Asset/MapIcons/car-red.png';
import "leaflet.markercluster/dist/leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import { useMap } from "react-leaflet";
import VehiclePopup from "../VehiclesPopupAndTooltip/VehiclePopup";
import { render } from "react-dom";
import VehicleTooltip from "../VehiclesPopupAndTooltip/VehicleTooltip";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const markerClusters = L.markerClusterGroup();
const carIcon = new Icon({
  iconUrl: car_blue,
  iconSize: [15, 22],
  tooltipAnchor: [20, 0],
})
// commented down 476 story 
// const carIconRed = new Icon({
//   iconUrl: car_red,
//   iconSize: [15, 22],
//   tooltipAnchor: [20, 0],
// })

const MarkerCluster = ({ viewportResult, clearMarker }) => {
  const map = useMap()
  let m;
  let getDeviceId
  var popup2 = L.popup()
  var container = L.DomUtil.create('div');
  const history = useNavigate();

  const timelineSelectorDate = useSelector(state => state.timeline.date);


  useEffect(() => {
    if (clearMarker) {
      markerClusters.clearLayers();
    }
  }, [clearMarker])

  //mouseovering to the cluster
  useEffect(() => {
    if (viewportResult && viewportResult.length > 0) {
      markerClusters.on('clustermouseover', function (e) {
        popup2.setLatLng(e.latlng).openOn(map);
        render(
          <VehicleTooltip deviceName={''} clientVehicleId={''} Iscluster={true} NoOfFleet={e.layer.getAllChildMarkers().length} />,
          container,
        );
        popup2.setContent(container).setLatLng(e.latlng).update();
        markerClusters.unbindPopup().bindPopup(popup2, { autoPan: false });
      });
      markerClusters.on('clustermouseout', function (e) {
        // console.log(e)
        map.closePopup();
      })

      markerClusters.clearLayers();
      for (var i = 0; i < viewportResult.length; ++i) {
        if (viewportResult) {

          //using popup to get the data from a cluster.
          var popup = viewportResult[i].deviceId + '</br>' + viewportResult[i].deviceName + '</br>' + viewportResult[i].clientVehicleId + '</br>' 

          m = new L.marker([viewportResult[i].logRecordData[0].latitude, viewportResult[i].logRecordData[0].longitude], { icon: carIcon }).bindPopup(popup, { maxWidth: 560 })

          //mouseovering to the marker/vehicles
          m.on('mouseover', function (e) {
            popup2.setLatLng(e.latlng).openOn(map);
            let getDeviceId = e.target._popup._content
            if (getDeviceId.length) {
              render(
                <VehicleTooltip deviceName={getDeviceId.split('</br>')[1]} clientVehicleId={getDeviceId.split('</br>')[2]} />,
                container,
              );
            } else {
              render(
                <VehicleTooltip deviceName={popup.split('</br>')[1]} clientVehicleId={popup.split('</br>')[2]}/>,
                container,
              );
            }
            popup2.setContent(container).setLatLng(e.latlng).update();
            m.unbindPopup().bindPopup(popup2, { autoPan: false });
          })
          // m.on('mouseout', function (e) {
          //   m.closePopup()
          // })

          ////clicking event to the marker/vehicles
          m.on('click', (e) => {
            markerClusters.unbindPopup()
            popup2.setLatLng(e.latlng).openOn(map);
            getDeviceId = e.target._popup._content
            if (!getDeviceId.length) {
              getDeviceId = popup
            }
            render(
              <VehiclePopup deviceId={getDeviceId.split('</br>')[0]} date={timelineSelectorDate} history={history} />,
              container,
            );
            popup2.setContent(container).setLatLng(e.latlng).update();
            m.unbindPopup().bindPopup(popup2, { maxWidth: 560 });
          })
        }
        markerClusters.addLayer(m);
      }
      map.addLayer(markerClusters);
    }
  }, [viewportResult])

  return null;
};

export default React.memo(MarkerCluster);
