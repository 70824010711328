import React, { useState } from 'react';
import styles from './PopupFilter.module.scss';
import { Heading, CheckboxList, Checkbox, CheckboxGroup, CheckboxGroupItem, Text } from "@leaseplan/ui";
import { Timezonedata_Api } from '../../utils';

const PopupFilterComponent = (props) => {
    const { heading, data, onFilterChange, defaultValue, values } = props;
    const [value, setValue] = useState(values);

    const onChange = (values) => {
        setValue(values.value);
        onFilterChange(values)
    }

    const optionHandler = (e) => {
        let targetValue = e.target.value;
        if (e.target.value == ''){
            const targetValue = Intl.DateTimeFormat().resolvedOptions().timeZone;
            //  targetValue = 'America/New_York'
        }

        sessionStorage.setItem("Timezone", targetValue);
        let Timezonedata = Timezonedata_Api(targetValue);
    }

    return (
        <div className={styles.popupContent}>
            <CheckboxGroup name={'Layer Filter'} value={value} onChange={(values) => onChange(values)} defaultValue={defaultValue || []}>
                <CheckboxList >
                    {
                        data.map((row, index) => {
                            return (
                                <CheckboxGroupItem key={index.toString()}>
                                    <Checkbox name="default" checked={true} label={row.label} value={row.value} />
                                </CheckboxGroupItem>
                            )
                        })
                    }
                </CheckboxList>
            </CheckboxGroup>
            

        </div>
    )
}

export default PopupFilterComponent;