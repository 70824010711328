import CONSTANTS from "../Constants/vehicle";

export const fetchVehicleDetails = (params) => ({
    type: CONSTANTS.FETCH_VEHICLE_INFO,
    payload: params
});

export const fetchPoolVehicleDrivers = (params) => ({
    type: CONSTANTS.FETCH_POOL_VEHICLE_DRIVERS,
    payload: params
});

export const fetchVoltageEvents = (params) => ({
    type: CONSTANTS.FETCH_VOLTAGE_EVENTS,
    payload: params
});

export const fetchLocationMaskingEvents = (params) => ({
    type: CONSTANTS.FETCH_LOCATION_MASKING_EVENTS,
    payload: params
});

export const setSelectedEventsUId = (params) => ({
    type: CONSTANTS.SET_SELECTED_EVENTS_ID,
    payload: params
});

export const setFuelMaintenanceHistory = (params) => ({
    type: CONSTANTS.SET_FUEL_MAINTENANCE_HISTORY,
    payload: params
});

export const fetchVehicleHealth = (params) => ({
    type: CONSTANTS.FETCH_VEHICLE_HEALTH,
    payload: params
});

export const fetchVehicleMaintenance = (params) => ({
    type: CONSTANTS.FETCH_VEHICLE_MAINTENANCE,
    payload: params
});

export const fetchVehicleMaintenanceHistory = (params) => ({
    type: CONSTANTS.FETCH_VEHICLE_MAINTENANCE_HISTORY,
    payload: params
});

export const fetchVehicleUtilization = (params) => ({
    type: CONSTANTS.FETCH_VEHICLE_UTILIZATION,
    payload: params
});

export const resetVehicleInfo = () => ({
    type: CONSTANTS.RESET_VEHICLE_INFO
})