export const TableConstants = {
    columns: [
        {
            id: 0,
            label: "Driver Score",
            field: "score",
            align: 'left',
            disablePadding: false,
            width: '5%',
            prismicKey: "one_score",
            requestName: "driverScore",
            customComponent: true,
            sortEnabled: true,
            sort: 'desc',
            initialSort: true,
            tooltipTitle: "Driver Behaviour Score"
        },
        {
            id: 1,
            label: "Device Name",
            field: "deviceName",
            // styles: {
            //     minWidth: 90
            // },
            prismicKey: "deviceName",
            width: '10%',
            requestName: "deviceName",
            // sortEnabled: true,
            // sort: 'asc',
        },
        {
            id: 2,
            label: "Vehicle",
            field: "vehicle",
            prismicKey: "vehicle",
            requestName: "vehicle",
            width: '15%',
        },
        {
            id: 3,
            label: "Event Per 100mi",
            field: "eventSum",
            prismicKey: "eventNumber",
            requestName: "eventNumber",
            width: '10%',
            sortEnabled: true,
            sort: 'desc',
            initialSort: true,
        },
        {
            id: 4,
            label: "Event Details Per 100mi",
            field: "eventWeight",
            minWidth: 90,
            prismicKey: "event",
            requestName: "event",
            width: '30%',
            sortEnabled: true,
            sort: 'desc',
            initialSort: true,
        },
        {
            id: 5,
            label: "Driver Profile",
            field: "vehicle_profile",
            align: 'center',
            // styles: {
            //     minWidth: 90
            // },
            prismicKey: "vehicle_profile",
            requestName: "vehicle_profile",
            width: '5%',
        },
        {
            id: 6,
            label: "Vehicle Profile",
            field: "vehicle_profile",
            align: 'center',
            // styles: {
            //     minWidth: 90
            // },
            prismicKey: "vehicle_profile",
            requestName: "vehicle_profile",
            width: '5%',
        },
    ],
    rows: [
        // {id:1, score: 98, firstName: "John" ,lastName:"Smith", vehicle: "21 Chevy Colorado", event: [{type:'seat', value: 42, label:'Seat Belt', popupEnable: true}], action:[{type:'notification', value: 12, label: 'Notification'}]},
        // {id:2, score: 98, firstName: "John" ,lastName:"Smith", vehicle: "21 Chevy Colorado", event: [{type:'seat', value: 42, label:'Seat Belt', popupEnable: true}, {type:'seat', value: 42, label:'Seat Belt', popupEnable: true}, {type:'seat', value: 42, label:'Seat Belt', popupEnable: true}, {type:'seat', value: 42, label:'Seat Belt', popupEnable: true}],},
        // {id:3, score: 98, firstName: "John" ,lastName:"Smith", vehicle: "21 Chevy Colorado", event: [{type:'seat', value: 42, label:'Seat Belt', popupEnable: true}], action:[{type:'notification', value: 12, label: 'Notification'}]},
        // {id:4, score: 98, firstName: "John" ,lastName:"Smith", vehicle: "21 Chevy Colorado", event: [{type:'seat', value: 42, label:'Seat Belt', popupEnable: true}], action:[{type:'notification', value: 12, label: 'Notification'}, {type:'message', value: 1, label: 'Message'}, {type:'course', value: 16, label: 'Course'}]},
        // {id:5, score: 98, firstName: "John" ,lastName:"Smith", vehicle: "21 Chevy Colorado", event: [{type:'seat', value: 42, label:'Seat Belt', popupEnable: true}], action:[{type:'notification', value: 12, label: 'Notification'}]},
        // {id:6, score: 98, firstName: "John" ,lastName:"Smith", vehicle: "21 Chevy Colorado", event: [{type:'seat', value: 42, label:'Seat Belt', popupEnable: true}], action:[{type:'notification', value: 12, label: 'Notification'}]},
        // {id:7, score: 98, firstName: "John" ,lastName:"Smith", vehicle: "21 Chevy Colorado", event: [{type:'seat', value: 42, label:'Seat Belt', popupEnable: true}], action:[{type:'notification', value: 12, label: 'Notification'}]},
        // {id:8, score: 98, firstName: "John" ,lastName:"Smith", vehicle: "21 Chevy Colorado", event: [{type:'seat', value: 42, label:'Seat Belt', popupEnable: true}], action:[{type:'notification', value: 12, label: 'Notification'}]},
        // {id:9, score: 98, firstName: "John" ,lastName:"Smith", vehicle: "21 Chevy Colorado", event: [{type:'seat', value: 42, label:'Seat Belt', popupEnable: true}], action:[{type:'notification', value: 12, label: 'Notification'}]},

    ]
}