import { combineReducers } from "redux";
import codeList from "./codeList";
import global from "./global";
import toasts from "./toasts";
import socket from "./sockets.reducer";
import timeline from "./timeline.reducer";
import user from "./user"
import driverEvents from './driverEvents.reducer';
import clientConfig from "./clientConfig.reducer";
import filters from "./filters.reducer";
import searchPage from "./searchPage.reducer";
import fleetOverview from "./fleetOverview.reducer";
import mapFilters from './mapFilters.reducer';
import driver from './driver.reducer';
import driverLocation from './driverLocation.reducer';
import driverInsightsPage from "./driverInsightsPage.reducer";
import vehicleInsightsPage from "./vehicleInsightsPage.reducer";
import auth from "./auth.reducer";
import levels from './levels.reducer';
import vehicle from "./vehicle.reducer";
import tripAdmin from './tripAdmin.reducer';
import reporting from "./reporting.reducer";

export default combineReducers({
  codeList,
  toasts,
  user,
  global,
  socket,
  clientConfig,
  driverLocation,
  timeline,
  driverEvents,
  filters,
  searchPage,
  fleetOverview,
  mapFilters,
  driver,
  driverInsightsPage,
  vehicleInsightsPage,
  auth,
  levels,
  vehicle,
  tripAdmin,
  reporting
});
