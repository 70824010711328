import axios from "../AxiosInterceptor";
import { call, put, takeEvery, delay } from "redux-saga/effects";
import {CLIENT_CONFIG} from "../Constants/clientConfig";
import { F, S } from "../utils/actions";
import { baseURL } from "../Store/base-url";
import authService from '../Services/Auth/authService';

import {CANCEL} from 'redux-saga';
import { addToast } from "../Actions/toasts";


function getClientConfig() {
  return axios.request({
    baseURL: baseURL,
    responseType: "json",
    url: `/client-config/retrieve-location`,
    method: "get",
  });
}

export function* clientConfigWorker(action) {
    try{
        let { data } = yield call(getClientConfig);
        if (data.latitude && data.longitude) {
            yield put({type: S(action.type), payload: {
                position: [data.latitude, data.longitude],
                zoom: 14,
                zip: data.zip
            }})
        } else {
            //Fallback to default location
            yield put({type: S(action.type), payload: {
                message:"No location Found",
                position: [40.64597734, -111.7309570],
                zip: data.zip,
                zoom: 5
            }})
        }
    } catch (e) {
        yield put({ type: F(action.type), error: e });
    }
}

function clientSaveConfig(payload) {
    const tokenSource = axios.CancelToken.source();
    const request =  axios.request({
      baseURL: baseURL,
      responseType: "json",
      url: `/client-config/save-user-default`,
      method: "put",
      data: {
        ...payload
      },
      cancelToken: tokenSource.token,
    });
    request[CANCEL] = () => tokenSource.cancel();
    return request;
  }
  
  export function* clientSaveConfigWorker(action) {
    try {
      const { data } = yield call(clientSaveConfig, action.payload);     
      yield put({ type: S(action.type), payload: data.content ? data.content : data });
    } catch (e) {
      yield put({ type: F(action.type), error: e });
      yield put(addToast({
        title: "Please Enter valid ZipCode",
        type: "error",
      }))
    }
  }

function* clientConfigSaga() {
    yield takeEvery(CLIENT_CONFIG.CLIENTHQ_FETCH , clientConfigWorker);
    yield takeEvery(CLIENT_CONFIG.CLIENTHQ_SAVE , clientSaveConfigWorker);
}


export default clientConfigSaga;