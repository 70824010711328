import React, { useState, useEffect } from 'react';
import {TableCell, TableRow} from '@material-ui/core';
import {
    Text,
    Grid,
    GridItem,
    Checkbox,
    ChevronDownIcon, 
    ChevronUpIcon
  } from "@leaseplan/ui";
import { withStyles } from '@material-ui/core';
import styles from './TripAdminRow.module.scss';
import CircularProgressWithLabel from '../../OneConnectCommon/CircularProgressWithLabel/CircularProgressWithLabel';
import { convertSecondsInHoursAndMinutes, getOffsetTime, getOffsetTimeZone } from '../../../utils';

const timeZoneId = 'America/New_York';

const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: "white",
      },
      '&:nth-of-type(even)': {
        backgroundColor: "#f6f8fb",
      },
    },
  }))(TableRow);

  const ShowMoreButton = ({handleOnClick, showMore}) => {
    return (
      <Grid onClick={handleOnClick} gutter={false} className={styles.showMore}>
        <GridItem gutter={false}>
          {
            showMore ? (
              <ChevronUpIcon color="midOrange" style={{marginRight: '5px', marginTop: '1px'}}/>
            ): (
              <ChevronDownIcon color="midOrange" style={{marginRight: '5px', marginTop: '1px'}}/>
            )
          }
        </GridItem>
        <GridItem >
          <Text component="p" size="xxs" color="midOrange" gutter={false}>{showMore? 'Show Less': 'Show More'}</Text>
        </GridItem>
      </Grid>
    )
  }

const TripAdminRow = ({id, rowData, onSelected, selectedTrips}) => {
    const [showMore, setShowMore] = useState(false);

    const handleOnClick = (event) => {
        setShowMore(!showMore);
    }
  
    const isMore = rowData.poi.length > 2;
    const numberOfItems = showMore ? rowData.poi.length : 2;

    return (
        <StyledTableRow>
            <TableCell
                className={styles.tableCell}
            >
                <div className={styles.poi}>
                    <Checkbox
                        name="default"  
                        value={id}
                        checked={selectedTrips[id]} 
                        onChange={(event) => onSelected(id, event)} />

                    <div className={styles.list}>
                        {
                            rowData.poi.slice(0, numberOfItems).map((item) => {
                                return (
                                    <Text size="s"
                                        styles={{color: '#4a4a4a'}}
                                        fontWeight="bold">{item}
                                    </Text>
                                )
                            })
                        }

                        {
                            isMore && <ShowMoreButton showMore={showMore} handleOnClick={handleOnClick}/>
                        } 
                    </div>
                </div>
                
            </TableCell>
            <TableCell
                className={styles.tableCell}
            >   
                <div className={styles.tripTimeCell}>
                    <div className={styles.timeline}>
                        <div className={styles.greyCircle}></div>
                        <div className={styles.vl}></div>
                        <div className={styles.orangeCircle}></div>
                    </div>
                    <div className={styles.tripTime}>
                        <Text size="xs">{rowData.startTime ? getOffsetTimeZone(rowData.startTime, rowData.timezoneShortName,rowData.utcOffset) : '--'}</Text>
                        <Text size="xs">{rowData.endTime ? getOffsetTimeZone(rowData.endTime, rowData.timezoneShortName,rowData.utcOffset) : '--'}</Text>
                    </div>
                </div>
            </TableCell>
            <TableCell
                className={styles.tableCell}
            >
                <Text
                    size="xs"
                    styles={{color: '#4a4a4a'}}
                    fontWeight="bold"
                >
                    {rowData.driveDistance ? `${parseFloat(rowData.driveDistance).toFixed(2)} Miles` : '--'}
                </Text>        
            </TableCell>
            <TableCell
                className={styles.tableCell}
            >
                <Text
                    size="xs"
                    styles={{color: '#4a4a4a'}}
                    fontWeight="bold"
                >
                    {rowData.driveTime ? `${convertSecondsInHoursAndMinutes(rowData.driveTime)}` : '--'}
                </Text>        
            </TableCell>
            <TableCell
                className={styles.tableCell}
            >
                <Text
                    size="xs"
                    styles={{color: '#4a4a4a'}}
                    fontWeight="bold"
                >
                    {rowData.projectCode ? rowData.projectCode : '--'}
                </Text>        
            </TableCell>
            <TableCell
                className={styles.tableCell}
            >   
                <div className={styles.row}>
                    <CircularProgressWithLabel
                        style={{color: rowData.scoreColor ? (rowData.scoreColor.toLowerCase() === 'yellow' ? '#F9C74B' : rowData.scoreColor.toLowerCase()) : 'red'}}
                        size='2rem'
                        value={rowData.driverScore ? rowData.driverScore : 0}/>

                    <Text
                        component={"p"}
                        size="xs"
                        styles={{color: '#4a4a4a'}}
                        fontWeight="bold"
                    >
                        {`${rowData.firstName ? rowData.firstName : ''} ${rowData.lastName ? rowData.lastName : ''}`}
                    </Text> 
                </div>
                       
            </TableCell>
            <TableCell
                className={styles.tableCell}
            >
                <Text
                size="xs"
                styles={{color: '#4a4a4a'}}
                >
                    {`${rowData.year ? rowData.year: ''} ${rowData.make ? rowData.make: ''} ${rowData.model ? rowData.model: ''}`}
                </Text>         
            </TableCell>
            <TableCell
                colSpan={2} align="center"
                className={styles.tableCell}
            >
                <Text
                        component={"p"}
                        size="xs"
                        styles={{color: '#4a4a4a'}}
                        fontWeight="bold"
                    >
                        {rowData.deviceName ? rowData.deviceName : '--'}
                    </Text>        
            </TableCell>
        </StyledTableRow>
    )
}

TripAdminRow.propTypes = {

}

export default TripAdminRow
