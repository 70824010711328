import React from 'react';
// import PropTypes from 'prop-types';
import styles from './Toast.module.scss';
import { Icon } from '@velocity/ui.Icon';
import { Text } from '@leaseplan/ui';
import { Color } from "@velocity/core.Color";

const Toast = (props) => {
	// console.log('Toast COlor', props);
	let toastrColor = props.color;
	let toastrIcon = props.icon;
	let size = "s";
	if (props.type === "error") {
		toastrColor = "error";
		toastrIcon = "error";
	} else if (props.type === "success") {
		toastrColor = "grassGreen";
		toastrIcon = "correct";
	} else if (props.type === "warning") {
		toastrColor = "lightOrange";
		toastrIcon = "newService";
		size = "xl"
	}


	return (
		<div className={styles.Toast} style={{ borderColor: Color[toastrColor], backgroundColor: Color['black'] }}>
			<Icon color={toastrColor} name={toastrIcon} size={size} />
			<div className={styles.Toast__content}>
				<Text color="white" component="p" size="s">
					<strong>{props.title}</strong>
				</Text>
				<Text color="white" component="p" size="xs">
					{props.text}
				</Text>
			</div>
			<button className={styles.Toast__dismiss} onClick={props.onDismissClick}>
				<Icon name="close" size="xs" />
			</button>
		</div>
	)
};

Toast.propTypes = {};

Toast.defaultProps = {};

export default Toast;
