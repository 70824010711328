import axios from "../AxiosInterceptor";
import { call, put, takeEvery, select, takeLatest } from "redux-saga/effects";
import { REPORTING_PAGE, GRAPH, GRAPH_TYPES } from "../Constants/reporting";
import { baseURL } from "../Store/base-url";
import { F, S } from "../utils/actions";
import { addToast } from "../Actions/toasts";
import { CANCEL } from "redux-saga";

function FavouriteLimitExceededError(message) {
  this.message = message;
  this.name = "UserException";
}

function InstanceAlreadyExists(message) {
  this.message = message;
  this.name = "UserException";
}

function UnableDownloadReport(message) {
  this.message = message;
  this.name = "UserException";
}

function getReportsWithDateRangesApi() {
  return axios.request({
    baseURL: baseURL,
    responseType: "json",
    url: `/reports/get-reports-with-date-ranges`,
    method: "get",
  });
}

function getAllUserReportsApi() {
  return axios.request({
    baseURL: baseURL,
    responseType: "json",
    url: `/reports/user-report`,
    method: "get",
  });
}

function getAllUserReportsIdsApi() {
  return axios.request({
    baseURL: baseURL,
    responseType: "json",
    url: `/reports/user-report-ids`,
    method: "get",
  });
}

function deleteUserReportApi(reportId) {
  return axios.request({
    baseURL: baseURL,
    responseType: "json",
    url: `/reports/user-report/${reportId}`,
    method: "delete",
  });
}

function sortUserReportApi(params) {
  const tokenSource = axios.CancelToken.source();
  const request = axios.request({
    baseURL: baseURL,
    responseType: "json",
    url: `/reports/reindex-report`,
    method: "post",
    data: params,
    cancelToken: tokenSource.token,
  });
  request[CANCEL] = () => tokenSource.cancel();
  return request;
}

function saveUserReportApi(params, clientNumber) {
  return axios.request({
    baseURL: baseURL,
    responseType: "json",
    url: `/reports/user-report`,
    method: "post",
    data: {
      clientNumber: clientNumber,
      reportCatalogueUid: params.graphType,
      reportDateRangeUid: params.dateRange,
      reportOrder: 1,
    },
  });
}

const fetchSingleUserReportsIdsApi = async (params) => {
  const requests = params.map(async (id) => {
    try {
      const response = await axios.request({
        baseURL: baseURL,
        responseType: "json",
        url: `/reports/user-report/${id.reportSelectionUid}`,
        method: "get",
      });
      return response.data;
    } catch (error) {
      // Check if the error is a 504 Gateway Timeout
      if (error) {
        console.warn(`API call for ID ${id} timed out (504), skipping.`);
        return id; // Return null for this ID
      }
    }
  });

  const results = await Promise.all(requests);
  const filteredResults = results.filter((result) => result !== null);
  return filteredResults;
};

function addReportToFavoriteApi(reportId) {
  return axios.request({
    baseURL: baseURL,
    responseType: "json",
    url: `/reports/favourite-report/${reportId}`,
    method: "put",
  });
}

function downloadReportApi(reportId) {
  return axios.request({
    baseURL: baseURL,
    responseType: "json",
    url: `/reports/download-report/${reportId}`,
    method: "get",
  });
}

function checkDownloadReportStatusApi(reportId) {
  return axios.request({
    baseURL: baseURL,
    responseType: "json",
    url: `/reports/check-report-download-status`,
    method: "get",
  });
}

export function* getReportsWithDateRangeWorker(action) {
  try {
    const { data } = yield call(getReportsWithDateRangesApi);
    yield put({ type: S(action.type), payload: data });
  } catch (e) {
    yield put({ type: F(action.type), payload: e });
  }
}

export function* getAllUserReportsWorker(action) {
  try {
    const { data } = yield call(getAllUserReportsApi);
    let array = Object.keys(GRAPH_TYPES).map((k) => GRAPH_TYPES[k]);
    let newData = data.map((eachReport) => {
      const GRAPHTYPE = array.filter(
        (d) => d.ID === eachReport.reportCatalogueUid
      )[0];
      return {
        reportOrder: eachReport.reportOrder,
        graphId: eachReport.reportSelectionUid,
        graphType: eachReport.presentationStyle.split(" ")[0].toUpperCase(),
        graphTitle: eachReport.reportName
          ? eachReport.reportName
          : GRAPHTYPE.NAME,
        graphDescription: eachReport.reportDescription,
        graphSpecification: GRAPHTYPE ? GRAPHTYPE.VALUE : "NA",
        graphCatalogueUid: eachReport.reportCatalogueUid,
        graphDateRangeUid: eachReport.reportDateRangeUid,
        graphDateRange: eachReport.dateRangeName,
        graphHideCalendar: data.hideCalendar,
        isFavourite: eachReport.homePage,
        data: eachReport.data,
      };
    });
    yield put({ type: S(action.type), payload: newData });
  } catch (e) {
    yield put({ type: F(action.type), payload: e });
  }
}

export function* getAllUserReportsIdsWorker(action) {
  try {
    const { data } = yield call(getAllUserReportsIdsApi);
    yield put({ type: S(action.type), payload: data });
  } catch (e) {
    yield put({ type: F(action.type), payload: e });
  }
}

export function* fetchSingleUserReportsIdsWorker(action) {
  try {
    const filteredResults = yield call(
      fetchSingleUserReportsIdsApi,
      action.payload
    );
    let array = Object.keys(GRAPH_TYPES).map((k) => GRAPH_TYPES[k]);
    let newData = filteredResults.map((eachReport) => {
      const GRAPHTYPE = array.filter(
        (d) => d.ID === eachReport.reportCatalogueUid
      )[0];
      return {
        reportOrder: eachReport?.reportOrder,
        graphId: eachReport?.reportSelectionUid,
        graphType: eachReport?.presentationStyle ? eachReport?.presentationStyle.split(" ")[0].toUpperCase() : "NA",
        graphTitle: eachReport?.reportName
          ? eachReport?.reportName
          : GRAPHTYPE.NAME,
        graphDescription: eachReport?.reportDescription ? eachReport?.reportDescription : "NA",
        graphSpecification: GRAPHTYPE ? GRAPHTYPE.VALUE : "NA",
        graphCatalogueUid: eachReport?.reportCatalogueUid,
        graphDateRangeUid: eachReport?.reportDateRangeUid,
        graphDateRange: eachReport?.dateRangeName ? eachReport?.dateRangeName : "NA",
        graphHideCalendar: eachReport?.hideCalendar ? eachReport?.hideCalendar : 0,
        isFavourite: eachReport?.homePage,
        data: eachReport?.data ? eachReport?.data : "NA",
      };
    });
    yield put({ type: S(action.type), payload: newData });
  } catch (e) {
    yield put({ type: F(action.type), payload: e });
  }
}

export function* deleteUserReportWorker(action) {
  try {
    yield put({ type: S(action.type), payload: action.payload });
    yield call(deleteUserReportApi, action.payload);
    yield put(
      addToast({
        title: "Successfully deleted report",
        type: "success",
      })
    );
  } catch (e) {
    yield put({ type: F(action.type), payload: e });
    yield put(
      addToast({
        title: "Failed to delete report from database",
        type: "error",
      })
    );
  }
}

export function* saveUserReportWorker(action) {
  try {
    const reportData = yield select(
      (state) => state.reporting.reportsData.data
    );
    const doesReportDataAlreadyExist = reportData.filter(
      (report) =>
        report.graphDateRangeUid === action.payload.dateRange &&
        report.graphCatalogueUid === action.payload.graphType
    );

    // commented down this check to fix issue but may need when 504 error comes
    // const reportDataIds = yield select(state => state.reporting.reportsDataIds.data);
    // const doesReportAlreadyExist = reportDataIds.filter((report) => report.reportDateRangeUid === action.payload.dateRange && report.reportCatalogueUid === action.payload.graphType);

    if (doesReportDataAlreadyExist.length > 0) {
      throw new InstanceAlreadyExists(
        "Report with selected date range already exists"
      );
    }

    const clientNumber = yield select((state) => state.auth.user.clientNumber);
    const { data } = yield call(
      saveUserReportApi,
      action.payload,
      clientNumber
    );
    let array = Object.keys(GRAPH_TYPES).map(function (k) {
      return GRAPH_TYPES[k];
    });
    const GRAPHTYPE = array.filter((d) => d.ID === data.reportCatalogueUid)[0];

    yield put({
      type: S(action.type),
      payload: {
        reportOrder: data.reportOrder,
        graphId: data.reportSelectionUid,
        graphType: data.presentationStyle.split(" ")[0].toUpperCase(),
        graphTitle: data.reportName ? data.reportName : GRAPHTYPE.NAME,
        graphDescription: data.reportDescription,
        graphSpecification: GRAPHTYPE ? GRAPHTYPE.VALUE : "NA",
        graphCatalogueUid: data.reportCatalogueUid,
        graphDateRangeUid: data.reportDateRangeUid,
        graphDateRange: data.dateRangeName,
        graphHideCalendar: data.hideCalendar,
        isFavourite: data.homePage,
        data: data.data,
      },
    });

    yield put(
      addToast({
        title: "Report Saved",
        type: "success",
      })
    );
  } catch (e) {
    yield put({ type: F(action.type), payload: e });
    if (e instanceof InstanceAlreadyExists) {
      yield put(
        addToast({
          title: e.message,
          type: "error",
        })
      );
    } else {
      yield put(
        addToast({
          title: "Failed saving to the database",
          type: "error",
        })
      );
    }
  }
}

export function* addReportToFavoriteWorker(action) {
  try {
    {
      /*
       *check the report data also the no of report data less then the reportId date.
       */
    }
    const apiData = yield select((state) => state.reporting.reportsData.data);
    let data = apiData.filter(
      (ele, ind) =>
        ind === apiData.findIndex((elem) => elem.graphId === ele.graphId)
    );
    const currentFavouriteReports = data.filter(
      (data) => data.isFavourite === 1
    );
    const isTheCurrentReportIncluded = currentFavouriteReports.filter(
      (data) => data.graphId === action.payload
    );
    if (
      (currentFavouriteReports.length >= 2 &&
        isTheCurrentReportIncluded.length === 0)
    ) {
      throw new FavouriteLimitExceededError(
        "You can only select 2 favourite reports"
      );
    }
    yield put({ type: S(action.type), payload: action.payload });
    yield call(addReportToFavoriteApi, action.payload);
  } catch (e) {
    yield put(
      addToast({
        title: e.message,
        type: "error",
      })
    );
  }
}
export function* downloadReportWorker(action) {
  try {
    yield put(
      addToast({
        title: "File is getting ready",
        type: "success",
      })
    );
    const { data } = yield call(downloadReportApi, action.payload);
    yield put({ type: S(action.type), payload: "Queuing Successfull" });
    yield put(
      addToast({
        title: "please check Download Library",
        type: "success",
      })
    );
  } catch (e) {
    yield put({ type: F(action.type), payload: e });
    yield put(
      addToast({
        title: e.message,
        type: "error",
      })
    );
  }
}

export function* checkDownloadReportStatusWorker(action) {
  try {
    const { data } = yield call(checkDownloadReportStatusApi, action.payload);
    yield put({ type: S(action.type), payload: data });
  } catch (e) {
    yield put({ type: F(action.type), payload: e });
    yield put(
      addToast({
        title: e.message,
        type: "error",
      })
    );
  }
}

export function* sortUserReportWorker(action) {
  try {
    yield put({ type: S(action.type), payload: action.payload });
    yield call(sortUserReportApi, action.payload);
  } catch (e) {
    yield put({ type: F(action.type), payload: e });
    yield put(
      addToast({
        title: "Failed to sort report",
        type: "error",
      })
    );
  }
}

function* reportingSaga() {
  yield takeEvery(
    REPORTING_PAGE.FETCH_REPORTING_SIDE_BAR_INFO,
    getReportsWithDateRangeWorker
  );
  yield takeEvery(REPORTING_PAGE.FETCH_REPORTS, getAllUserReportsWorker);
  yield takeEvery(REPORTING_PAGE.FETCH_REPORTS_IDS, getAllUserReportsIdsWorker);
  yield takeEvery(REPORTING_PAGE.DELETE_REPORT, deleteUserReportWorker);
  yield takeEvery(REPORTING_PAGE.SAVE_REPORT, saveUserReportWorker);
  yield takeEvery(REPORTING_PAGE.ADD_FAVOURITE, addReportToFavoriteWorker);
  yield takeEvery(REPORTING_PAGE.DOWNLOAD_REPORT, downloadReportWorker);
  yield takeEvery(
    REPORTING_PAGE.FETCH_SINGLE_REPORTS_IDS,
    fetchSingleUserReportsIdsWorker
  );
  yield takeEvery(
    REPORTING_PAGE.CHECK_DOWNLOAD_REPORT_STATUS,
    checkDownloadReportStatusWorker
  );
  yield takeLatest(REPORTING_PAGE.SORT_REPORT, sortUserReportWorker);
}

export default reportingSaga;
