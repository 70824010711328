import React, { useEffect, useState } from 'react';
import styles from './Dashboard.module.scss';
import DashboardGraph from './DashboardGraph/DashboardGraph';
import DriverEvents from './DriverEvents/DriverEvents';
import FleetOverview from './FleetOverview/FleetOverview';
import BottomSlider from './BottomSlider/BottomSlider';
import { useSelector } from 'react-redux';
import { Timezonedata_Api_nowindowload } from '../../utils';
import directionCardinalIcon from '../../Asset/MapIcons/compassRoseIcon.svg';
import { Text } from '@leaseplan/ui';
import moment from 'moment';

const Dashboard = props => {
    const [hideLeftSection, setHideLeftSection] = useState(false);
    const [hideRightSection, setHideRightSection] = useState(false);
    let pannelArray = []

    const filtersSelectordefaultValue = useSelector(state => state.filters.defaultConfig);

    useEffect(() => {

        // if case to check default setting i.e save-user-default api
        if (filtersSelectordefaultValue && filtersSelectordefaultValue.data) {
            if (filtersSelectordefaultValue.data.showFleetOverview == 1 || filtersSelectordefaultValue.data.showFleetOverview == null) {
                sessionStorage.setItem("setHideLeftSection", 1)
                sessionStorage.setItem("fleet_overview", "true")
                pannelArray.push('fleet_overview')
                setHideLeftSection(false)
            } else {
                sessionStorage.setItem("setHideLeftSection", 0)
                sessionStorage.setItem("fleet_overview", "false")
                setHideLeftSection(true)
            }
            if (filtersSelectordefaultValue.data.showLatestEvents == 1 || filtersSelectordefaultValue.data.showLatestEvents == null) {
                sessionStorage.setItem("setHideRightSection", 1)
                sessionStorage.setItem("latest_events", "true")
                pannelArray.push('latest_events')
                setHideRightSection(false)
            } else {
                sessionStorage.setItem("setHideRightSection", 0)
                sessionStorage.setItem("latest_events", "false")
                setHideRightSection(true)
            }

            sessionStorage.setItem("pannelArray", JSON.stringify(pannelArray))

            //set default timezone using default setting.
            if (filtersSelectordefaultValue.data.timezoneId) {
                let timezoneId = filtersSelectordefaultValue.data.timezoneId ? filtersSelectordefaultValue.data.timezoneId : "America/Detroit"
                sessionStorage.setItem("Timezone", timezoneId)
                let Timezonedata = Timezonedata_Api_nowindowload(timezoneId);
            }

            //set the url for hardrefreshing functionality #444
            sessionStorage.setItem("urlEndPoint", window.location.pathname)
        }
    }, [filtersSelectordefaultValue])
    return (
        <div className={!hideLeftSection ? styles.graphGrid : styles.graphGridHidingLeft}>
            {
                !hideLeftSection && (
                    <div className={styles.leftDrawer}>
                        <FleetOverview />
                        {/* <FleetActions/> */}
                        <DashboardGraph />
                    </div>
                )
            }

            {
                !hideRightSection && (
                    <div className={styles.rightDrawer}>
                        <DriverEvents />
                    </div>
                )
            }
            <div className={styles.middleDrawer}>
            </div>
            <div className={styles.bottomDrawer}>
                <BottomSlider hideLeftSection={setHideLeftSection} hideRightSection={setHideRightSection} />
            </div>
            <div className={styles.dateDetail}>
            <Text component="span" size="s" color="petrolBlue" gutter>{moment(new Date()).format('MMM DD, YYYY')}</Text>
            </div>
            <div className={styles.middleDrawer}>
                {<img src={directionCardinalIcon} alt="cardinal Icon" className={styles.cardinalIcon} />}
            </div>
        </div>
    )
}


export default React.memo(Dashboard)
