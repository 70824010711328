import axios from "../AxiosInterceptor/index";
import { call, put, takeEvery } from "redux-saga/effects";
import types from '../Constants/action-types';
import { F, S } from "../utils/actions";


export function* globalWorker(action) {
    try {
        // dispatch action to change redux state
        yield put({ type: S(action.type), isLoading: action.payload });
    } catch (e) {
        // console.log(e);
        // dispatch action to change redux state
        yield put({ type: F(action.type), error: e });
    }
}


export function* proposalLoaderWorker(action) {
    try {
        // dispatch action to change redux state
        yield put({ type: S(action.type), isLoading: action.payload });
    } catch (e) {
        // console.log(e);
        // dispatch action to change redux state
        yield put({ type: F(action.type), error: e });
    }
}


export function* masterInputLoaderWorker(action) {
    try {
        // dispatch action to change redux state
        yield put({ type: S(action.type), isLoading: action.payload });
    } catch (e) {
        // console.log(e);
        // dispatch action to change redux state
        yield put({ type: F(action.type), error: e });
    }
}


function fetchUserInfoApi() {
    return axios.request({
      baseURL:  process.env.REACT_APP_PPA_BASEURL,
      responseType: 'json',
      url: `/ppa/user-details`,
      method: 'get'
    })
  }

export function* fetchUserInfoWorker() {
     try {
        let { data } = yield call(fetchUserInfoApi);
        yield put({ type: S(types.FETCH_USER_INFO), payload: data && data.payload ? data.payload : null });
      } catch (e) {
        yield put({ type: F(types.FETCH_USER_INFO), error: e });
      }
}


function* globalSaga() {
    yield takeEvery(types.FETCH_USER_INFO, fetchUserInfoWorker);
    yield takeEvery(types.LOADER_MASTER_INPUT, masterInputLoaderWorker);
    yield takeEvery(types.LOADER_PROPOSAL_BUILDER, proposalLoaderWorker);
    yield takeEvery(types.GLOBAL_LOADER, globalWorker);
}

export default globalSaga;