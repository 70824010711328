import { S, F } from "../utils/actions";
import User from "../Constants/user";

const INITIAL_STATE = {
  userObj: {
    error: null,
    isLoading: false,
    data: {
      username: "abhishek.jha@quantiphi.com",
      password: "testPass",
    },
  },
  validateUserObj: {
    error: null,
    isLoading: false,
    data: false,
  },
};

const user = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case User.POST_USER:
      return { ...state, userObj: { ...state.userObj, isLoading: true } };
    case S(User.POST_USER):
      return {
        ...state,
        userObj: { data: action.payload, isLoading: false, error: null },
      };
    case F(User.POST_USER):
      /* return {
        ...state,
        userObj: {
          ...state.userObj,
          isLoading: false,
          error: action.error,
        },
	  }; */
      return {
        ...state,
        userObj: {
          ...state.userObj,
          isLoading: false,
          error: null,
        },
      };

    case User.VALIDATE_USER:
      return {
        ...state,
        validateUserObj: { ...state.validateUserObj, isLoading: true },
      };
    case S(User.VALIDATE_USER):
      return {
        ...state,
        validateUserObj: {
          data: action.payload,
          isLoading: false,
          error: null,
        },
      };
    case F(User.VALIDATE_USER):
      /* return {
        ...state,
        userObj: {
          ...state.userObj,
          isLoading: false,
          error: action.error,
        },
	  }; */
      return {
        ...state,
        validateUserObj: {
          ...state.validateUserObj,
          data: true,
          isLoading: false,
          error: null,
        },
      };
    default:
      return state;
  }
}

export default user;