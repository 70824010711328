export const TableConstants = {
    columns:[
        {
            id: 0,
            label: "Score",
            field: "one_score",
            align: 'left',
            disablePadding: false,
            width: '5%',
            prismicKey:"one_score",
            requestName:"oneScore",
            customComponent: true,
            tooltipTitle: "One Score"
        },
        {
            id: 1,
            label:"First Name",
            field: "firstName",
            // styles: {
            //     minWidth: 90
            // },
            prismicKey:"firstName",
            width: '12%',
            requestName:"firstName",
            sortEnabled: true,
            sort: 'desc',
            initialSort: true,
        },
        {
            id: 2,
            label:"Last Name",
            field: "lastName",
            // styles: {
            //     minWidth: 90
            // },
            prismicKey:"lastName",
            width: '12%',
            requestName:"lastName",
            sortEnabled: true,
            sort: 'asc',
        },
        {
            id: 3,
            label: "Vehicle",
            field: "vehicle",
            prismicKey: "vehicle",            
            requestName:"vehicle",
            width: '15%',
        },
        {
            id: 4,
            label: "Usage",
            field: "usage",
            minWidth: 90,
            prismicKey: "usage",            
            requestName:"usage",
            width: '7%',
        },
        {
            id: 5,
            label:"Device Name",
            field: "deviceName",
            // styles: {
            //     minWidth: 90
            // },
            prismicKey: "deviceName",            
            requestName:"deviceName",
            width: '13%',
            sortEnabled: true,
            sort: 'asc',
        },
        {
            id: 6,
            label:"Serial Number",
            field: "serialNumber",
            // styles: {
            //     minWidth: 90
            // },
            prismicKey: "serialNumber",            
            requestName:"serialNumber", 
            width: '14%',
            sortEnabled: true,
            sort: 'asc',
        },
        {
            id: 7,
            label:"VIN",
            field: "vin",
            // styles: {
            //     minWidth: 90
            // },
            prismicKey: "vin",            
            requestName:"vin", 
            width: '10%',
            sortEnabled: true,
            sort: 'asc',
        },
        {
            id: 8,
            label:"Driver Profile",
            field: "vehicle_profile",
            align: 'center',
            // styles: {
            //     minWidth: 90
            // },
            prismicKey: "vehicle_profile",            
            requestName:"vehicle_profile",
            width: '5%',
        },
        {
            id: 9,
            label:"Vehicle Profile",
            field: "vehicle_profile",
            align: 'center',
            // styles: {
            //     minWidth: 90
            // },
            prismicKey: "vehicle_profile",            
            requestName:"vehicle_profile",
            width: '5%',
        },
    ],
    rows:[
        // {score: 98, firstName: "John Smith", vehicle: "21 Chevy Colorado", type: "Pool Vehicles", deviceLabel: "GSHEASW", deviceSerial: "HGB3213", vin: "12ASD38450983H3D8"},
        // {score: 98, firstName: "John Smith", vehicle: "21 Chevy Colorado", type: "Pool Vehicles", deviceLabel: "GSHEASW", deviceSerial: "HGB3213", vin: "12ASD38450983H3D8"},
        // {score: 98, firstName: "John Smith", vehicle: "21 Chevy Colorado", type: "Pool Vehicles", deviceLabel: "GSHEASW", deviceSerial: "HGB3213", vin: "12ASD38450983H3D8"},
        // {score: 98, firstName: "John Smith", vehicle: "21 Chevy Colorado", type: "Pool Vehicles", deviceLabel: "GSHEASW", deviceSerial: "HGB3213", vin: "12ASD38450983H3D8"},
        // {score: 98, firstName: "John Smith", vehicle: "21 Chevy Colorado", type: "Pool Vehicles", deviceLabel: "GSHEASW", deviceSerial: "HGB3213", vin: "12ASD38450983H3D8"},
        // {score: 98, firstName: "John Smith", vehicle: "21 Chevy Colorado", type: "Pool Vehicles", deviceLabel: "GSHEASW", deviceSerial: "HGB3213", vin: "12ASD38450983H3D8"},
        // {score: 98, firstName: "John Smith", vehicle: "21 Chevy Colorado", type: "Pool Vehicles", deviceLabel: "GSHEASW", deviceSerial: "HGB3213", vin: "12ASD38450983H3D8"},
        // {score: 98, firstName: "John Smith", vehicle: "21 Chevy Colorado", type: "Pool Vehicles", deviceLabel: "GSHEASW", deviceSerial: "HGB3213", vin: "12ASD38450983H3D8"},
        // {score: 98, firstName: "John Smith", vehicle: "21 Chevy Colorado", type: "Pool Vehicles", deviceLabel: "GSHEASW", deviceSerial: "HGB3213", vin: "12ASD38450983H3D8"},
        // {score: 98, firstName: "John Smith", vehicle: "21 Chevy Colorado", type: "Pool Vehicles", deviceLabel: "GSHEASW", deviceSerial: "HGB3213", vin: "12ASD38450983H3D8"},
    ]
}