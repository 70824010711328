import React from 'react';
import {
    Text,
    Heading,
} from "@leaseplan/ui";
import styles from './Row.module.scss';
import { convertSecondsInHoursAndMinutes, getOffsetTimeZone } from '../../../utils';
import { useDispatch } from 'react-redux';
import Images from '../../../Asset/Images';
import NavigationRoundedIcon from '@material-ui/icons/NavigationRounded';
import ArrowRightAltRoundedIcon from '@material-ui/icons/ArrowRightAltRounded';
import { IconButton } from '@material-ui/core';
import { insertSelectedEvent } from '../../../Actions/driver.action';

const Row = (props) => {
    const { eventUid, ruleId, ruleLabel, averageEventDataValue, durationSeconds, activeFrom, activeTo, startAddress, utcOffset, timezoneShortName, color, ticketNumber } = props.data;
    const { selectActiveEvent, activeEvent } = props;
    const dispatch = useDispatch();


    const onHandleClick = (eventUid) => {
        selectActiveEvent(eventUid);
        dispatch(insertSelectedEvent(eventUid));
    }

    //function to check and show the img/icon in the events list
    const getIconsViaEvents = () => {
        switch (color) {
            case 'Green':
                return (
                    <img src={Images.eventIcons['ticket_Green'].default} className={styles.vehicleIcon} alt={ruleLabel} />
                )
            case 'Red':
                return (
                    <img src={Images.eventIcons['ticket_Red'].default} className={styles.vehicleIcon} alt={ruleLabel} />
                )
            case 'Yellow':
                return (
                    <img src={Images.eventIcons['ticket_Yellow'].default} className={styles.vehicleIcon} alt={ruleLabel} />
                )
            default:
                return (
                    <img src={Images.eventIcons[ruleId].default} className={styles.vehicleIcon} alt={ruleLabel} />
                );
        }
    }

    //function to check events and show the different UI as per the same
    const getDetailsViaEvents = () => {
        switch (ruleId) {
            case 'posted_speed':
                return (
                    <div className={styles.row}>
                        <Text component="p" size={'xs'} color={'petrolBlue'}>{`${averageEventDataValue.toFixed(2)} mph`}</Text>
                        <div className={styles.divider}></div>
                        <Text component="p" size={'xs'} color={'petrolBlue'}>{convertSecondsInHoursAndMinutes(durationSeconds)}</Text>
                        <div className={styles.divider}></div>
                        <Text component="p" size="xs" color={'petrolBlue'} gutter>{getOffsetTimeZone(activeFrom, timezoneShortName, utcOffset)}</Text>
                        <ArrowRightAltRoundedIcon className={styles.arrowIcon} />
                        <Text component="p" size="xs" color={'petrolBlue'} gutter>{getOffsetTimeZone(activeTo, timezoneShortName, utcOffset)}</Text>
                    </div>
                )
            case 'max_speed':
                return (
                    <div className={styles.row}>
                        <Text component="p" size={'xs'} color={'petrolBlue'}>{`${averageEventDataValue.toFixed(2)} mph`}</Text>
                        <div className={styles.divider}></div>
                        <Text component="p" size={'xs'} color={'petrolBlue'}>{convertSecondsInHoursAndMinutes(durationSeconds)}</Text>
                        <div className={styles.divider}></div>
                        <Text component="p" size="xs" color={'petrolBlue'} gutter>{getOffsetTimeZone(activeFrom, timezoneShortName, utcOffset)}</Text>
                        <ArrowRightAltRoundedIcon className={styles.arrowIcon} />
                        <Text component="p" size="xs" color={'petrolBlue'} gutter>{getOffsetTimeZone(activeTo, timezoneShortName, utcOffset)}</Text>
                    </div>
                )

            case 'idling':
                return (
                    <div className={styles.row}>
                        <Text component="p" size={'xs'} color={'petrolBlue'}>{convertSecondsInHoursAndMinutes(durationSeconds)}</Text>
                        <div className={styles.divider}></div>
                        <Text component="p" size="xs" color={'petrolBlue'} gutter>{getOffsetTimeZone(activeFrom, timezoneShortName, utcOffset)}</Text>
                        <ArrowRightAltRoundedIcon className={styles.arrowIcon} />
                        <Text component="p" size="xs" color={'petrolBlue'} gutter>{getOffsetTimeZone(activeTo, timezoneShortName, utcOffset)}</Text>
                    </div>
                )

            case 'seatbelt':
                return (
                    <div className={styles.row}>
                        <Text component="p" size={'xs'} color={'petrolBlue'}>{convertSecondsInHoursAndMinutes(durationSeconds)}</Text>
                        <div className={styles.divider}></div>
                        <Text component="p" size="xs" color={'petrolBlue'} gutter>{getOffsetTimeZone(activeFrom, timezoneShortName, utcOffset)}</Text>
                        <ArrowRightAltRoundedIcon className={styles.arrowIcon} />
                        <Text component="p" size="xs" color={'petrolBlue'} gutter>{getOffsetTimeZone(activeTo, timezoneShortName, utcOffset)}</Text>
                    </div>
                )

            case 'harsh_acceleration':
                return (
                    <div className={styles.row}>
                        <Text component="p" size={'xs'} color={'petrolBlue'}>{`${((averageEventDataValue * 2.237).toFixed(2))} mph/s`}</Text>
                        <div className={styles.divider}></div>
                        <Text component="p" size="xs" color={'petrolBlue'} gutter>{getOffsetTimeZone(activeFrom, timezoneShortName, utcOffset)}</Text>
                    </div>
                )
            case 'hard_braking':
                return (
                    <div className={styles.row}>
                        <Text component="p" size={'xs'} color={'petrolBlue'}>{`${((averageEventDataValue * 2.237).toFixed(2))} mph/s`}</Text>
                        <div className={styles.divider}></div>
                        <Text component="p" size="xs" color={'petrolBlue'} gutter>{getOffsetTimeZone(activeFrom, timezoneShortName, utcOffset)}</Text>
                    </div>
                )
            case 'low_voltage':
                return (
                    <div className={styles.row}>
                        <Text component="p" size={'xs'} color={'petrolBlue'}>{`${averageEventDataValue.toFixed(2)} volts`}</Text>
                        <div className={styles.divider}></div>
                        <Text component="p" size={'xs'} color={'petrolBlue'}>{durationSeconds ? convertSecondsInHoursAndMinutes(durationSeconds) : " "}</Text>
                        <div className={styles.divider}></div>
                        <Text component="p" size="xs" color={'petrolBlue'} gutter>{getOffsetTimeZone(activeFrom, timezoneShortName, utcOffset)}</Text>
                        <ArrowRightAltRoundedIcon className={styles.arrowIcon} />
                        <Text component="p" size="xs" color={'petrolBlue'} gutter>{activeTo ? getOffsetTimeZone(activeTo, timezoneShortName, utcOffset) : '--'}</Text>
                    </div>
                )
            case 'location_masking':
                return (
                    <div className={styles.row}>
                        <Text component="p" size={'xs'} color={'petrolBlue'}>{convertSecondsInHoursAndMinutes(durationSeconds)}</Text>
                        <div className={styles.divider}></div>
                        <Text component="p" size="xs" color={'petrolBlue'} gutter>{getOffsetTimeZone(activeFrom, timezoneShortName, utcOffset)}</Text>
                        <ArrowRightAltRoundedIcon className={styles.arrowIcon} />
                        <Text component="p" size="xs" color={'petrolBlue'} gutter>{activeTo ? getOffsetTimeZone(activeTo, timezoneShortName, utcOffset) : '--'}</Text>
                    </div>
                )
            default:
                return (
                    <div className={styles.row}>
                        {/* <Text component="p" size={'xs'} color={'petrolBlue'}>{'2.3 g'}</Text>
                        <div className={styles.divider}></div> */}
                        <Text component="p" size={'xs'} color={'petrolBlue'}>{getOffsetTimeZone(activeFrom, timezoneShortName, utcOffset)}</Text>
                    </div>
                );
        }
    }
    return (
        <div className={styles.wrapper} style={{ borderLeft: color ? (color == 'Red' ? `4px solid #ff1a1a` : `4px solid #00cc00`) : '4px solid #F1AC4C' }}>
            <div className={styles.details}>
                <div className={styles.left}>
                    {
                        getIconsViaEvents()
                    }
                </div>
                <div className={styles.right}>
                    <Heading component="p" size={'xxs'} color={'petrolBlue'}>{ruleLabel} {ticketNumber ? '#' + ticketNumber : ''}</Heading>
                    {
                        getDetailsViaEvents()
                    }
                    <Text component="p" size={'xs'} color={'petrolBlue'}>{startAddress}</Text>
                </div>

            </div>
            <div className={styles.right}>
                <IconButton onClick={(e) => onHandleClick(eventUid)}>
                    <NavigationRoundedIcon htmlColor={'#ee4c14'} style={{ transform: 'rotate(45deg)', color: (activeEvent != null && activeEvent === eventUid) ? '#ee4c14' : '#004a5d' }} />
                </IconButton>
            </div>
        </div>
    )
}

export default Row;