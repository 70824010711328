import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import App from "./Components/App/App";
import "./index.scss";
import { AuthProvider } from "./Providers/authProvider";
import * as serviceWorker from "./serviceWorker";
import store from "./Store/store";
import ErrorBoundary from "./ErrorBoundary/ErrorBoundary";

createRoot(document.getElementById("app")).render(
	<Provider store={store}>
		<ErrorBoundary>
			<AuthProvider>
				<App />
			</AuthProvider>
		</ErrorBoundary>
	</Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
