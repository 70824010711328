import React, { useState, useEffect, useCallback, useRef } from 'react';
import styles from './TripFilters.module.scss';
import {
    Spacing,
    Grid,
    Text,
    Checkbox,
    Button,
    MessageBar
} from "@leaseplan/ui";
import { getLabel, timezoneOffSetAdd } from '../../../utils/index';
import { default as ReactSelect } from "react-select";
import { useDispatch, useSelector } from 'react-redux';
import LPTripsDrawer from '../../../Common/LPDrawer/LPTripsDrawer';
import { driverLastLocationInfo, fetchDriversViaLevels, putSelectedDriver } from '../../../Actions/level.action';
import { InputBase } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import FilterIcon from "../../../Asset/Images/filter.png";
import trip_select_icon from "../../../Asset/Images/trip_select_icon.png";
import { driverLocationInfo } from '../../../Actions/driverLocation.action';
import { addToast } from '../../../Actions/toasts';
import { LPLoader } from '../../../Common/LPLoader/LPLoader';
import { isGlobalSearchField, isTripFilterSearchField } from '../../../Actions/searchPage.action';
import { fetchLogRecordTrips } from '../../../Actions/driver.action';
import moment from 'moment-timezone';

const todaysDate = moment().tz(sessionStorage.getItem("Timezone") ? sessionStorage.getItem("Timezone") : "America/New_York").format('YYYY-MM-DD');

const TripFilters = ({
    data,
    prismicData,
    isTripSideDrawerOpen,
    setIsTripSideDrawerOpen,
    onLayerFilterChange,
    poiFilters,
    onLevelFilterChange1,
    levelFilter,
    ...props
}) => {
    const [selectedDeviceId, setSelectedDeviceId] = useState([]);
    const levelFilteredDrivers = useSelector(state => state.levels);
    const [searchString, setSearchString] = useState('');
    const [showSearchArea, setshowSearchArea] = useState(false);
    const [searchItem, setSearchItem] = useState([false]);
    const authSelector = useSelector(state => state.auth);
    const timelineSelector = useSelector(state => state.timeline)
    const [isLoading, setIsLoading] = useState([false])
    const apiData = useSelector((state) => state.driver.fetchLogRecordTrips);
    const levelDescription = useSelector(state => state.levels.driverLevels);

    useEffect(() => {
        if (apiData) {
            setIsLoading(apiData.isLoading)
        }
    }, [apiData])

    const dispatch = useDispatch();
    const ref = useRef()

    const TripDeviceRow = ({ id, rowData, onSelected, selectedDeviceId }) => {

        return (
            <>
                <Spacing pv={1} />
                <Grid justifyContent='left'>
                    <Spacing ph={1} pv={1} />

                    <Checkbox
                        name="default"
                        value={id}
                        checked={selectedDeviceId[id]}
                        onChange={(event) => onSelected(id, event)} />
                    <Spacing ph={.5} pv={1} />
                    <div className={styles.fontstyle}>{rowData.deviceName}</div>
                </Grid>

            </>



        );
    };

    const generateRequestPayload = (deviceId) => {
        const DriverDateFromUTC = timezoneOffSetAdd(todaysDate, '00:00:00', sessionStorage.getItem("TimezoneOffset"))
        const DriverDateToUTC = timezoneOffSetAdd(todaysDate, '23:59:59', sessionStorage.getItem("TimezoneOffset"))
        return {
            isHistoric: false,
            fromDateTime: DriverDateFromUTC,
            toDateTime: DriverDateToUTC,
            deviceId: deviceId ? deviceId : false,
        };
    };

    const deviceIdFromViewport = (rowData) => {
        let newData = [];
        if (rowData)
            rowData.map((row, index) => {

                newData.push(row.deviceId);
                // console.log(newData)

            })
    };

    const SearchRow = ({ id, columns, onSelected, rowData, selectedDeviceId }) => {
        const showTrip = () => {
            // dispatch(driverLastLocationInfo([getCurrentLoc.latitude, getCurrentLoc.longitude]));
            dispatch(isGlobalSearchField(false));
            dispatch(isTripFilterSearchField(true));
            dispatch(fetchLogRecordTrips(generateRequestPayload([rowData.deviceId])));
            dispatch(putSelectedDriver([rowData.deviceId]));

        }
        return (

            <div className={styles.dataItem}>

                <Spacing pv={.5} ph={1} />
                <div className={styles.fontstyle}>{rowData.deviceName}</div>
                <Spacing pv={.5} ph={1} />
                <img src={trip_select_icon} onClick={() => { showTrip(rowData) }} className={styles.tripSelectIcon} />

            </div>

        )
    }

    useEffect(() => {
        if (levelFilteredDrivers.viewportApi)
            deviceIdFromViewport(levelFilteredDrivers.viewportApi)
    }, [levelFilteredDrivers])

    useEffect(() => {
        if (levelFilteredDrivers.data.deviceSearchDtoList == 0) {
            setshowSearchArea(false);
        }
        setSearchItem(levelFilteredDrivers.isLoading)
    }, [levelFilteredDrivers])

    useEffect(() => {
        const checkIfClickedOutside = e => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (showSearchArea && ref.current && !ref.current.contains(e.target)) {
                setshowSearchArea(false);
            }
        }

        document.addEventListener("mousedown", checkIfClickedOutside)

        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [showSearchArea])

    const updateTrips = (e) => {
        // dispatch(isGlobalSearchField(false));
        // dispatch(isTripFilterSearchField(false));
        if (Object.keys(selectedDeviceId).length > 10) {
            <MessageBar variant="alert">Please select up to 10 trips to view on map</MessageBar>
        } else {
            dispatch(fetchLogRecordTrips(generateRequestPayload(Object.keys(selectedDeviceId))));
            dispatch(putSelectedDriver(Object.keys(selectedDeviceId)));
        }
    }

    const clearTrips = (e) => {
        // console.log(Object.keys(selectedDeviceId))
        dispatch(fetchLogRecordTrips(generateRequestPayload(["clear Trips"])));
        dispatch(putSelectedDriver([]));
        dispatch(driverLastLocationInfo([false]));
        // dispatch(isTripFilterSearchField(false));
    }




    const shouldOpenDrawer = (isOpened) => {
        setIsTripSideDrawerOpen(isOpened);
    };

    const handleChange = (event) => {
        {
            setshowSearchArea(true);
            dispatch(fetchDriversViaLevels({
                // deviceIds: levelFilteredDrivers.viewportLocation,
                fromDateTime: timelineSelector.clockData.fromTime,
                toDateTime: timelineSelector.clockData.toTime,
                clientNumber: authSelector.user.authUser.clientNumber,
                topLeftLat: levelFilteredDrivers.viewportLocation.topLeftLat,
                topLeftLng: levelFilteredDrivers.viewportLocation.topLeftLng,
                bottomRightLat: levelFilteredDrivers.viewportLocation.bottomRightLat,
                bottomRightLng: levelFilteredDrivers.viewportLocation.bottomRightLng,
                search: event.target.value,
                level1: levelDescription.level1,
                level2: levelDescription.level2,
                level3: levelDescription.level3,
                level4: levelDescription.level4,
                level5: levelDescription.level5,
                level6: levelDescription.level6,
                level7: levelDescription.level7,
                level8: levelDescription.level8,
                level9: levelDescription.level9,
                level10: levelDescription.level10,
            }
            )
            )

        }
    }

    const applyFilterButton = () => {
        setIsTripSideDrawerOpen(false);
    }

    const handleSearchStringChange = (event) => {


        setSearchItem(true)
        setSearchString(event.target.value);
        handleChange(event);
    }




    const onDeviceIdSelected = (id, event) => {
        // console.log(selectedDeviceId)
        const selectedDeviceIdTemp = { ...selectedDeviceId };
        if (event.checked) {

            selectedDeviceIdTemp[id] = event.checked;

        } else {
            delete selectedDeviceIdTemp[id];
        }
        // console.log(selectedDeviceIdTemp[id])
        setSelectedDeviceId(selectedDeviceIdTemp);
        // console.log(selectedDeviceIdTemp)
    }



    return (

        <Spacing top='86'>
            <div>
                <LPTripsDrawer
                    title={getLabel('display_trips', 'Display Trips', prismicData)}
                    shouldOpenDrawer={shouldOpenDrawer}
                    shouldDrawerOpened={setIsTripSideDrawerOpen}
                    isDisabled={false}
                    dynamicWidth={true}
                    saveActionBtn={applyFilterButton}
                    primaryBtnText={"Save"}

                >
                    {<img src={FilterIcon} alt="Filter Icon" className={styles.iconFilter} />}
                    <Text position="fixed" key={'Select up to 10 vehicles currently showing on the map to show trips'} component="p" size="s" color="petrolBlue" gutter>{'Select up to 10 vehicles currently showing on the map to show trips'}</Text>
                    <hr size="2"></hr>
                    <div className={styles.mainWrapper} >
                        <SearchIcon htmlColor={'#767878'} className={styles.SearchIcon} />
                        <InputBase
                            className={styles.searchTextInput}
                            value={searchString}
                            onChange={handleSearchStringChange}
                            placeholder='Enter driver/vehicle info here'
                            style={{ overflowWrap: "break-word", fontWeight: '700', fontSize: '14px', fontFamily: 'LeasePlan, Arial, Helvetica, sansSerif' }}
                        // inputProps={{ maxLength: 100 }}
                        />
                    </div>
                    {levelFilteredDrivers.isLoading == true && (
                        <LPLoader
                            loading={searchItem == true}
                            message={"Searching Info... please wait"}
                        />
                    )}

                    {levelFilteredDrivers.data.deviceSearchDtoList != null && showSearchArea === true && (
                        <>
                            <div className={styles.title}>Top 10 Search Result </div>
                            <div className={styles.dataResult} ref={ref}>

                                {
                                    levelFilteredDrivers.data.deviceSearchDtoList != null && levelFilteredDrivers.data.deviceSearchDtoList.length > 0 && levelFilteredDrivers.data.deviceSearchDtoList.map((row, index) => {
                                        return (

                                            <div className={styles.dataItem}>
                                                <SearchRow key={index} id={row.deviceId} rowData={row} onSelected={onDeviceIdSelected} selectedDeviceId={selectedDeviceId} />
                                                <Spacing />

                                            </div>

                                        )
                                    })
                                }


                            </div>
                            <div className={styles.title2}> Total Search Result : {levelFilteredDrivers.data.totalCount}</div>
                        </>
                    )}

                    {/* {levelFilteredDrivers.viewportApi != null && levelFilteredDrivers.viewportApi.length >25 && (
                            <MessageBar variant="alert">IF number of vehicles is large, please zoom in to reduce the number and for a better user experience</MessageBar>
                     )} */}

                    {/* {isLoading == true && (
                        <LPLoader
                            loading={isLoading}
                            message={"Fetching Data... please wait"}
                        />
                    )} */}


                    <div className={styles.test}>
                        {(levelFilteredDrivers.viewportLoadingStatus) && (
                            <LPLoader
                                loading={levelFilteredDrivers.viewportLoadingStatus}
                                message={"Loading Data ..."}
                            />
                        )}
                        {
                            levelFilteredDrivers.viewportApi != null && levelFilteredDrivers.viewportLoadingStatus != true && levelFilteredDrivers.viewportApi.map((row, index) => {
                                return (

                                    <TripDeviceRow key={index} id={row.deviceId} rowData={row} onSelected={onDeviceIdSelected} selectedDeviceId={selectedDeviceId} />

                                )
                            })
                        }
                    </div>

                    {Object.keys(selectedDeviceId).length != null && Object.keys(selectedDeviceId).length > 10 && (

                        <MessageBar variant="alert">Please select up to 10 trips to view on map</MessageBar>

                    )}

                    <div style={{ marginTop: '2rem' }} className={styles.rightSide}>
                        <Grid justifyContent='center'>
                            {Object.keys(selectedDeviceId).length ? <Button
                                size="s"
                                onClick={(event) => {
                                    updateTrips();
                                }}
                            >Show Trips</Button> : null
                            }<Spacing ph={2} pv={2} />
                            <Button
                                label='find trip'
                                size="s"
                                onClick={(event) => {
                                    clearTrips();
                                }}
                            >Clear Trips</Button>
                        </Grid>
                    </div>


                </LPTripsDrawer>
            </div>
        </Spacing>
    )
}

TripFilters.propTypes = {

}

export default React.memo(TripFilters) 
