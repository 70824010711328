import { SEARCH_PAGE } from '../Constants/searchPage'

export const fetchSeachPageInfo = (params) => ({
    type: SEARCH_PAGE.SEARCH_PAGE_FETCH,
    payload: params
});

export const fetchLiveSeachInfo = (params) => ({
    type: SEARCH_PAGE.SEARCH_TYPE_FETCH,
    payload: params
});

export const fetchLiveFleetSeachInfo = (params) => ({
    type: SEARCH_PAGE.SEARCH_TYPE_FLEET_FETCH,
    payload: params
});

export const isGlobalSearchField = (params) => ({
    type: SEARCH_PAGE.IS_GLOBAL_SEARCH_FIELD,
    payload: params
});

export const isTripFilterSearchField = (params) => ({
    type: SEARCH_PAGE.IS_TRIP_FILTER_SEARCH_FIELD,
    payload: params
});
