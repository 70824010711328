export const TableConstants = {
    columns: [
        {
            id: 0,
            label: "Starting POI",
            field: "startingPoi",
            align: 'left',
            disablePadding: false,
            width: '5%',
            prismicKey: "starting_poi",
            requestName: "startingPoi",
            customComponent: true,
            sortEnabled: true,
            sort: 'desc',
            initialSort: true,
            checkbox: true
        },
        {
            id: 1,
            label: "Trip Start/End",
            field: "tripStartTime",
            // styles: {
            //     minWidth: 90
            // },
            prismicKey: "trip_start_end",
            width: '25%',
            requestName: "tripStartTime",
            sortEnabled: true,
            sort: 'asc',
        },
        {
            id: 2,
            label: "Distance",
            field: "distance",
            // styles: {
            //     minWidth: 90
            // },
            prismicKey: "distance",
            width: '10%',
            requestName: "distance",
            sortEnabled: true,
            sort: 'asc',
        },
        {
            id: 3,
            label: "Drive Time",
            field: "driveTime",
            prismicKey: "drive_time",
            requestName: "driveTime",
            width: '10%',
        },
        {
            id: 4,
            label: "Project Code",
            field: "projectCode",
            minWidth: 90,
            prismicKey: "project_code",
            requestName: "projectCode",
            width: '15%',
            sortEnabled: true,
            sort: 'asc',
        },
        {
            id: 5,
            label: "Driver",
            field: "driverName",
            prismicKey: "driver",
            requestName: "driverName",
            width: '10%',
            sortEnabled: true,
            sort: 'asc',
        },
        {
            id: 6,
            label: "Vehicle",
            field: "year",
            prismicKey: "vehicle",
            requestName: "year",
            width: '10%',
        },
        // {
        //     id: 7,
        //     label: "",
        //     field: "",
        //     width: '15%',
        // },
        {
            id: 7,
            label: "Device Name",
            field: "deviceName",
            prismicKey: "deviceName",
            requestName: "deviceName",
            align: 'center',
            width: '30%',
            // sortEnabled: true,
            // sort: 'asc',
        },
    ],
    rows: [
        { id: 1, startingPoi: ["Fire stone - Alpharetta"], tripStartTime: "2021-10-13T13:58:36", tripEndTime: "2021-10-13T13:58:36", distance: "211", driveTime: 1234, projectCode: "1234567890", firstName: "John", lastName: "Smith", driverScore: 98, scoreColor: "yellow", year: "2021", make: "Jeep", model: "GrandCherokee", startingPoiSelected: false, tripStartAddress: '2544 Road Name Circle, Alpharetta', eventCount: 2, vin: 'VNYTBH098765JJGJ', tagNumber: 'HGB-2312', clientVehicleId: 'E3321', vehicleType: 'Pool' },
        { id: 2, startingPoi: ["Fire stone - Alpharetta", "Fire stone - Alpharetta"], tripStartTime: "2021-10-13T13:58:36", tripEndTime: "2021-10-13T13:58:36", distance: "211", driveTime: 1234, projectCode: "1234567890", firstName: "John", lastName: "Smith", driverScore: 98, scoreColor: "yellow", year: "2021", make: "Jeep", model: "GrandCherokee", startingPoiSelected: false, tripStartAddress: '2544 Road Name Circle, Alpharetta', eventCount: 2, vin: 'VNYTBH098765JJGJ', tagNumber: 'HGB-2312', clientVehicleId: 'E3321', vehicleType: 'Pool' },
        { id: 3, startingPoi: ["Fire stone - Alpharetta", "Fire stone - Alpharetta", "Fire stone - Alpharetta"], tripStartTime: "2021-10-13T13:58:36", tripEndTime: "2021-10-13T13:58:36", distance: "211", driveTime: 1234, projectCode: "1234567890", firstName: "John", lastName: "Smith", driverScore: 98, scoreColor: "yellow", year: "2021", make: "Jeep", model: "GrandCherokee", startingPoiSelected: false, tripStartAddress: '2544 Road Name Circle, Alpharetta', eventCount: 2, vin: 'VNYTBH098765JJGJ', tagNumber: 'HGB-2312', clientVehicleId: 'E3321', vehicleType: 'Pool' },
        { id: 4, startingPoi: ["Fire stone - Alpharetta", "Fire stone - Alpharetta", "Fire stone - Alpharetta"], tripStartTime: "2021-10-13T13:58:36", tripEndTime: "2021-10-13T13:58:36", distance: "211", driveTime: 1234, projectCode: "1234567890", firstName: "John", lastName: "Smith", driverScore: 98, scoreColor: "yellow", year: "2021", make: "Jeep", model: "GrandCherokee", startingPoiSelected: false, tripStartAddress: '2544 Road Name Circle, Alpharetta', eventCount: 2, vin: 'VNYTBH098765JJGJ', tagNumber: 'HGB-2312', clientVehicleId: 'E3321', vehicleType: 'Pool' }
    ]
}