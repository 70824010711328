import React, { useState, useEffect } from 'react';
import styles from './Courses.module.scss';
import {
    Text,
    Heading,
  } from "@leaseplan/ui";
import Row from './Row';
import { getLabel } from '../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCourses } from '../../../Actions/driver.action';
import InformationTab from '../InformationTab/InformationTab';
import { LPLoader } from '../../../Common/LPLoader/LPLoader';


const Courses = (props) => {
    const dispatch = useDispatch();
    const {prismicData, driverDetails} = props;
    const [driverCourses, setDriverCourses] = useState([]);

    const driverCoursesSelector = useSelector(state => state.driver.courses);

    useEffect(() => {
        dispatch(fetchCourses({driverRecordNumber: driverDetails.driverRecordNumber}));
    }, [dispatch, driverDetails])
    
    useEffect(() => {
        const { data, isLoading } = driverCoursesSelector;
        if(!isLoading && data) {    
            const inComplete = data.filter(row => row.courseStatus === 'Incomplete');
            const complete = data.filter(row => row.courseStatus === 'Complete');
            setDriverCourses([...inComplete, ...complete]);
        }
    }, [dispatch, driverCoursesSelector]);

    return (
        <div>
            <Heading component="p" size={'xs'} color={'petrolBlue'} className={styles.heading} gutter>{getLabel('courses', 'Courses', prismicData)}</Heading>
            {
                driverCoursesSelector.isLoading ? (
                    <div className={styles.lpLoader}>
                        <LPLoader
                            loading={driverCoursesSelector.isLoading}
                            message={"Loading Data..."}
                        />
                    </div>
                ): (
                    <>
                        {
                            driverCourses?.length ? driverCourses.map((row, index) => {
                                return (
                                    <Row
                                        key={index.toString()}
                                        data={row}
                                    />
                                )
                            }): (
                                <InformationTab icon={'info'} iconSize={'l'} headerText={getLabel('no_information_available', 'No Information Available', prismicData)} text={getLabel('courses_data_not_available', 'Courses not available', prismicData)}/>
                            )
                        }
                    </>
                )
            }
        </div>
    )
}

export default Courses;