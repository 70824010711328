
import axios from "../AxiosInterceptor";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import CONSTANT from "../Constants/levels";
import { baseURL } from "../Store/base-url";
import { F, S } from "../utils/actions";
import { CANCEL } from 'redux-saga';
import authService from '../Services/Auth/authService';

function getDriversViaLevelsApi(params) {
  const tokenSource = axios.CancelToken.source();
  const request = axios.request({
    baseURL: baseURL,
    responseType: "json",
    url: `device/retrieve-driver-records`,
    method: "post",
    data: {
        ...params
    },
    cancelToken: tokenSource.token
  });
  request[CANCEL] = () => {tokenSource.cancel();}
  return request;
}

export function* getDriversViaLevelsWorker(action) {
  try {
    const { data } = yield call(getDriversViaLevelsApi, action.payload);
    yield put({ type: S(action.type), payload: data });
  } catch (e) {
    yield put({ type: F(action.type), error: e });
  }
}

 function* putSelectedDriverWorker(action) {
  yield put({type: S(action.type), payload: action.payload? action.payload : []})
}

function* putViewportLocationWorker(action) {
  yield put({type: S(action.type), payload: action.payload? action.payload : []})
}

function* viewportLoadingStatusWorker(action) {
  yield put({type: S(action.type), payload: action.payload? action.payload : []})
}

function* logRecordLoadingStatusWorker(action) {
  yield put({type: S(action.type), payload: action.payload? action.payload : []})
}

function* driverLastLocationInfoWorker(action) {
  yield put({type: S(action.type), payload: action.payload? action.payload : []})
}

function* putViewportDriverWorker(action) {
  yield put({type: S(action.type), payload: action.payload? action.payload : []})
}

function* hitViewportApiWorker(action) {
  yield put({type: S(action.type), payload: action.payload? action.payload : []})
}

function* isPanningMapWorker(action) {
  yield put({type: S(action.type), payload: action.payload? action.payload : []})
}

function* removeEventsFromDisplayWorker(action) {
  yield put({type: S(action.type), payload: action.payload? action.payload : []})
}


function* filtersSaga() {
    yield takeLatest(CONSTANT.FETCH_DRIVERS_VIA_LEVEL, getDriversViaLevelsWorker);
    yield takeEvery(CONSTANT.PUT_SELECTED_DRIVER, putSelectedDriverWorker);
    yield takeEvery(CONSTANT.PUT_VIEWPORT_DRIVER, putViewportDriverWorker);
    yield takeEvery(CONSTANT.HIT_VIEWPORT_API, hitViewportApiWorker);
    yield takeEvery(CONSTANT.REMOVE_EVENTS_FROM_DISPLAY, removeEventsFromDisplayWorker);
    yield takeEvery(CONSTANT.IS_PANNING_MAP, isPanningMapWorker);
    yield takeEvery(CONSTANT.PUT_VIEWPORT_LOCATION, putViewportLocationWorker);
    yield takeEvery(CONSTANT.VIEWPORT_LOADING_STATUS, viewportLoadingStatusWorker);
    yield takeEvery(CONSTANT.LOG_RECORD_LOADING_STATUS, logRecordLoadingStatusWorker);
    yield takeEvery(CONSTANT.DRIVER_LAST_LOCATION_INFO, driverLastLocationInfoWorker);
}


export default filtersSaga;