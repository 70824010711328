import { S, F } from "../utils/actions";
import CONSTANTS from '../Constants/fleetOverview'


//Status can be LIVE or OLD representing whether the data is real time or not
const INITIAL_STATE = {
    oneScore: {
        isLoading: false,
        data: {},
        error: null
    }
};

const fleetOverview = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CONSTANTS.FETCH_ONE_SCORE:
            return { 
                ...state,
                oneScore: {
                    ...state.oneScore,
                    isLoading: true,
                    error: null
                }
            };
        case S(CONSTANTS.FETCH_ONE_SCORE):
            return { 
                ...state,
                oneScore: {
                    isLoading: false,
                    error: null,
                    data: action.payload
                }
            };
        
        case CONSTANTS.RESET_ONE_SCORE:
            return {
                ...state,
                oneScore: {
                    isLoading: false,
                    data: {},
                    error: null
                }
            }
        default:
          return state;
      }
}

export default fleetOverview;