import axios from "../AxiosInterceptor";
import { call, put, takeLatest } from "redux-saga/effects";
import { DRIVER_INSIGHTS_PAGE } from "../Constants/driverInsightsPage";
import { baseURL } from "../Store/base-url";
import { F, S } from "../utils/actions";
import {CANCEL} from 'redux-saga';

function getDriverInsightsPageInfoAPI(payload) {
  const tokenSource = axios.CancelToken.source();
  const request =  axios.request({
    baseURL: baseURL,
    responseType: "json",
    url: `/driver-insights/retrieve-all-driver-insights`,
    method: "post",
    data:{
        ...payload
    },
    cancelToken: tokenSource.token,
  });
  request[CANCEL] = () => tokenSource.cancel();
  return request;
}

export function* getDriverInsightsPageInfoAPIWorker(action) {
  try {
    const {data} = yield call(getDriverInsightsPageInfoAPI, action.payload);
    yield put({ type: S(action.type), 
        data: [...data.content],
        resultSetCount: data.totalElements,
        currentPage: action.payload.pageNumber,
        pageLimit: action.payload.pageSize  
    });
  } catch (e) {
    yield put({ type: F(action.type), payload: e });
  }
}


function* driverInsightsPageSaga() {
    yield takeLatest(DRIVER_INSIGHTS_PAGE.DRIVER_INSIGHTS_PAGE_FETCH, getDriverInsightsPageInfoAPIWorker);
}


export default driverInsightsPageSaga;