import { Grid, GridItem, Text, Spacing } from '@leaseplan/ui';
import React from 'react';
import { getOffsetTimeZone } from '../../../utils';
import road_img from "../../../Asset/Images/streetViewIcon.svg";
import styles from "./Popup.module.scss";
import LPTooltip from "../../../Common/LPTooltip/LPTooltip";

const LocationMaskingTooltip = ({ eventData }) => {

    let Time
    let Time2

    if (eventData.activeFrom) { Time = getOffsetTimeZone(eventData.activeFrom, eventData.timezoneShortName, eventData.utcOffset) }
    if (eventData.activeTo) { Time2 = getOffsetTimeZone(eventData.activeTo, eventData.timezoneShortName, eventData.utcOffset) }


    return (
        <>

            <div className={styles.GridStyle__PolyLineTooltip}>
                <Grid gutter={false} justifyContent="center">
                    <GridItem span={12}>
                        <Spacing pb={0.2}>
                            <Text fontWeight="bold" size="xs" color="bloodOrange">{eventData.ruleLabel}</Text>
                        </Spacing>
                        <Text size="xs" color="petrolBlue">Activation Data</Text>
                        <Spacing pb={0.2} />
                    </GridItem >
                </Grid>
                <Grid gutter={false} justifyContent="left">
                    <GridItem span={3}>
                        <Text size="xs" color="petrolBlue">Speed</Text>
                        <GridItem>
                            <Text size="xs" fontWeight="bold" color="petrolBlue">{eventData.startSpeed || eventData.startSpeed == 0 ? eventData.startSpeed + ' mph' : '--'}</Text>
                            <Spacing pb={0.5} />
                        </GridItem>
                    </GridItem>
                    <div className={(eventData.endLat ? styles.locationVertical : styles.vertical)}></div>
                    <GridItem span={4}>
                        <Text size="xs" color="petrolBlue">Time</Text>
                        <GridItem>
                            <Text size="xs" fontWeight="bold" color="petrolBlue">{Time ? Time.split(" ")[0] + ' ' + Time.split(" ")[1] + ' ' + Time.split(" ")[2] : '--'}</Text>
                        </GridItem>
                    </GridItem>
                    <div className={(eventData.endLat ? styles.locationVerticalLine : styles.verticalLine)}></div>
                    <Spacing ph={.3} />
                    <GridItem span={4}>
                        <Text size="xs" color="petrolBlue">Date</Text>
                        <GridItem>
                            <Text size="xs" fontWeight="bold" color="petrolBlue">{Time?.split(" ")[3] ? Time?.split(" ")[3] : '--'}</Text>
                        </GridItem>
                    </GridItem>
                </Grid>
                <Grid gutter={false} justifyContent="left">
                    <Spacing pb={0.5} />
                    <GridItem span={9}>
                        <Text size="xs" color="petrolBlue">Activation Location</Text>
                        <GridItem>
                            <Text size="xs" fontWeight="bold" color="petrolBlue">{`${parseFloat(eventData.startLat).toFixed(7)}, ${parseFloat(eventData.startLon).toFixed(7)}`}</Text>

                            <LPTooltip style={{ paddingLeft: "10px" }} title={'Show Street View'} placement="bottom" arrow>
                                <img src={road_img} className={styles.img} onClick={() => { window.open(`https://www.google.com/maps?layer=c&cbll=${eventData.startLat},${eventData.startLon}`, "_blank", 'noopener') }} />
                            </LPTooltip>
                        </GridItem>
                    </GridItem>
                </Grid>
            </div>
            {eventData?.endLat && eventData?.endLon && (
                <>
                    <hr style={{ borderLeft: '2px solid rgb(141, 135, 135)', height: 1 }} />
                    <div className={styles.GridStyle__PolyLineTooltip}>
                        <Grid gutter={false} justifyContent="center">
                            <GridItem span={12}>
                                <Text size="xs" color="petrolBlue">Deactivation Data</Text>
                                <Spacing pb={0.2} />
                            </GridItem >
                        </Grid>
                        <Grid gutter={false} justifyContent="left">
                            <GridItem span={3}>
                                <Text size="xs" color="petrolBlue">Speed</Text>
                                <GridItem>
                                    <Text size="xs" fontWeight="bold" color="petrolBlue">{eventData.endSpeed || eventData.endSpeed == 0 ? eventData.endSpeed + ' mph' : '--'}</Text>
                                    <Spacing pb={0.5} />
                                </GridItem>
                            </GridItem>
                            <div className={styles.locationVertical}></div>
                            <GridItem span={4}>
                                <Text size="xs" color="petrolBlue">Time</Text>
                                <GridItem>
                                    <Text size="xs" fontWeight="bold" color="petrolBlue">{Time2 ? Time2.split(" ")[0] + ' ' + Time2.split(" ")[1] + ' ' + Time2.split(" ")[2] : '--'}</Text>
                                </GridItem>
                            </GridItem>
                            <Spacing ph={.3} />
                            <div className={styles.locationVerticalLine}></div>
                            <GridItem span={4}>
                                <Text size="xs" color="petrolBlue">Date</Text>
                                <GridItem>
                                    <Text size="xs" fontWeight="bold" color="petrolBlue">{Time2?.split(" ")[3] ? Time2?.split(" ")[3] : '--'}</Text>
                                </GridItem>
                            </GridItem>
                        </Grid>
                        <Grid gutter={false} justifyContent="left">
                            <Spacing pb={0.5}></Spacing>
                            <GridItem span={9}>
                                <Text size="xs" color="petrolBlue">Deactivation Location</Text>
                                <GridItem>
                                    <Text size="xs" fontWeight="bold" color="petrolBlue">{eventData.endLat + ',  ' + eventData.endLon}</Text>

                                    <LPTooltip style={{ paddingLeft: "10px" }} title={'Show Street View'} placement="bottom" arrow>
                                        <img src={road_img} className={styles.img} onClick={() => { window.open(`https://www.google.com/maps?layer=c&cbll=${eventData.endLat},${eventData.endLon}`, "_blank", 'noopener') }} />
                                    </LPTooltip>
                                </GridItem>
                            </GridItem>
                        </Grid>
                    </div>
                </>
            )}
        </>
    )
}


export default React.memo(LocationMaskingTooltip)