import React, { useState, useEffect, useRef } from 'react'
import { MapContainer, Popup, ZoomControl, TileLayer, ScaleControl } from 'react-leaflet';
import VectorTileLayer from 'react-leaflet-vector-tile-layer';
import { fetchClientInfo } from '../../Actions/clientConfig.action';
import { useSelector, useDispatch } from 'react-redux';
import "./MapLeaflet.css";
import StaticMarker from '../MapComponents/StaticMarker';
import DynamicMarker from '../MapComponents/DynamicMarker';
import SingleDynamicMarker from '../MapComponents/SingleDynamicMarker';
import { useLocation } from 'react-router-dom';
import { fetchFilters, fetchPoiFilters, fetchUserDefaultConfig } from '../../Actions/filters.action';
import { addToast } from '../../Actions/toasts';
import MarkerCluster from '../MapComponents/RotatingMovingMarker/MarkerCluster';

const position = [52.6376, -1.135171]

const dynamicMarkerPosition = [40.735362, -73.994235]

/**In VectorTileLayer attribution needs to be added during production
 * attribution='© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>'
 */

const MapLeaflet = props => {
    const location = useLocation();
    const dispatch = useDispatch();
    const [map, setMap] = useState(null);
    const [mapStyle, setMapStyle] = useState("street");

    const satellite = `https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`
    const street = `https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`

    const ref = useRef(null);

    const clientHQInfoSelector = useSelector(state => state.clientConfig);

    const mapStyleSelector = useSelector(state => state.mapFilters.mapStyle)

    const searchPageSelector = useSelector(state => state.searchPage);

    const initalSelectedDriverData = useSelector((state) => state.driver.initialDriverDetails);

    const lastKnownLocation = useSelector((state) => state.driver.fetchDeviceLastLocation);

    const filtersSelectordefaultValue = useSelector(state => state.filters.defaultConfig);

    const authSelector = useSelector(state => state.auth);

    useEffect(() => {
        dispatch(fetchClientInfo())
        dispatch(fetchPoiFilters());
        dispatch(fetchFilters());
        dispatch(fetchUserDefaultConfig())
    }, []);

    useEffect(() => {
        if (clientHQInfoSelector.clientSaveInfo && clientHQInfoSelector.clientSaveInfo.data) {
            if (clientHQInfoSelector.clientSaveInfo.data.zip === null || clientHQInfoSelector.clientSaveInfo.data.zip) {
                // dispatch(fetchClientInfo())
                dispatch(addToast({
                    title: "Default config Saved",
                    type: "success",
                }));
                map.setView([clientHQInfoSelector.clientSaveInfo.data.latitude, clientHQInfoSelector.clientSaveInfo.data.longitude, 13])

                setTimeout(() => {
                    map.flyTo([clientHQInfoSelector.clientSaveInfo.data.latitude, clientHQInfoSelector.clientSaveInfo.data.longitude, 13.0001])
                }, 2000);
                dispatch(fetchUserDefaultConfig())
                setMapStyle(clientHQInfoSelector.clientSaveInfo.data.mapStyle)
            } else if (clientHQInfoSelector.clientSaveInfo.data === null) {
                dispatch(addToast({
                    title: "Please Enter valid ZipCode",
                    type: "error",
                }));
            }
        }
    }, [clientHQInfoSelector.clientSaveInfo])

    // used when select the map style within a session

    useEffect(() => {
        if (mapStyleSelector) {
            setMapStyle(mapStyleSelector.filter)
        }
    }, [mapStyleSelector])


    useEffect(() => {
        if (searchPageSelector.data.driverVehicleFilterResponseDataDto === null && map) {
            let lat = searchPageSelector.data.latitude
            let long = searchPageSelector.data.longitude
            if (lat != 0 && long != 0) {
                map.setView([lat, long], 13)
                setTimeout(() => {
                    map.flyTo([lat, long], 13.0001)
                }, 2000);
            }
        }
    }, [searchPageSelector, map])

    useEffect(() => {
        if (lastKnownLocation && lastKnownLocation.data != null && map) {
            if (lastKnownLocation.data.data.locations[0].latitude != null && lastKnownLocation.data.data.locations[0].longitude != null) {

                map.setView([lastKnownLocation.data.data.locations[0].latitude, lastKnownLocation.data.data.locations[0].longitude], 13)

                setTimeout(() => {
                    map.flyTo([lastKnownLocation.data.data.locations[0].latitude, lastKnownLocation.data.data.locations[0].longitude], 13.0001)
                }, 2000);
            }
        }
    }, [lastKnownLocation, map])

    useEffect(() => {
        if (authSelector.user.clientUserDefaultDto && map) {
            if (authSelector.user.clientUserDefaultDto.latitude != null) {
                map.setView([authSelector.user.clientUserDefaultDto.latitude, authSelector.user.clientUserDefaultDto.longitude], 13)
            } else {
                map.setView([40.64597734, -111.7309570], 5)
            }

        }
    }, [authSelector.user.clientUserDefaultDto, map])

    useEffect(() => {
        if (location.pathname === '/' && filtersSelectordefaultValue && filtersSelectordefaultValue.data.latitude && map) {
            // case if user switch from driver Insight or vehcile Insight to dashboard clear the arrows and breadcrumbs.
            if (sessionStorage.getItem("getArrowsMarker._leaflet_id") || sessionStorage.getItem("polylineDecorator._leaflet_id")) {
                let id = sessionStorage.getItem("getArrowsMarker._leaflet_id")
                let polylineId = sessionStorage.getItem("polylineDecorator._leaflet_id")
                map.eachLayer(function (getArrowsMarker) {
                    let id2 = getArrowsMarker._leaflet_id
                    if (id == id2 || polylineId == id2) {
                        map.removeLayer(getArrowsMarker);
                    }
                });
            }
            map.setView([filtersSelectordefaultValue.data.latitude, filtersSelectordefaultValue.data.longitude], 13)
        }
    }, [filtersSelectordefaultValue, map, location.pathname])



    useEffect(() => {
        if (authSelector.user.clientUserDefaultDto && authSelector.user.clientUserDefaultDto.mapStyle == "satellite") {
            setMapStyle(authSelector.user.clientUserDefaultDto.mapStyle)
        }
    }, [authSelector.user.clientUserDefaultDto])

    useEffect(() => {
        if (ref.current) {
            ref.current.setUrl(mapStyle === "satellite" ? satellite : street);
        }
    }, [mapStyle]);

    return (
        <MapContainer
            zoomControl={false}
            center={[40.64597734, -111.7309570]}
            zoom={5}
            ref={setMap}
            minZoom={1}
            maxZoom={18}
        >
            <ScaleControl
                position="bottomright"
                metric="" />
            <TileLayer ref={ref} url={mapStyle === "street" ? street : satellite}
                attribution={'© <a href="https://www.mapbox.com/feedback/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'} />
            {/* <VectorTileLayer
                 styleUrl="mapbox://styles/mapbox/light-v10"
                 accessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
            /> */}

            <ZoomControl position="bottomright" />
            {(JSON.parse(sessionStorage.getItem("POI"))).length > 0 && (
                <StaticMarker />)}
            {
                initalSelectedDriverData.deviceId ? (
                    <>
                        <SingleDynamicMarker />
                        <MarkerCluster clearMarker={true} />
                    </>
                ) : (
                    <DynamicMarker />
                )
            }
        </MapContainer>
    )
}

MapLeaflet.propTypes = {

}

export default MapLeaflet
