import React from 'react'
import styles from './RowEvents.module.scss';
import { Grid, Heading } from '@leaseplan/ui';

const RowEvents = ({
    type = "",
    Eventdata = "",
    number = "",
    color = 'rgb(250, 217, 210)'
}) => {

    //opacity: 0.308;

    let filter = "";

    if (type != "") {
        filter = type.split("_").map(data => {
            return data.charAt(0).toUpperCase() + data.substring(1);
        }).join(' ');
    }

    return (
        <Grid>
            {Eventdata?.length > 0 == true ? (
                <Heading component="p" size={'xs'} color="petrolBlue" gutter>{filter}</Heading>
            ) :
                <span className={styles.filterValue}>{filter}</span>
            }


            <div className={`${styles.tagBody}`} style={{
                backgroundColor: `rgba(${color.substring(color.lastIndexOf('(') + 1, color.lastIndexOf(')'))})`,
                border: `1px solid ${color}`
            }}>
                <span className={styles.filterNumber}>{number}</span>

            </div>
        </Grid>
        /* {
            enablePopup && (
                <Popup
                    trigger={<span className={styles.filterDownArrow}>&#8964;</span>}
                    position="bottom"
                    on="click"
                    closeOnDocumentClick
                    contentStyle={{ padding: '0px', width: '118px' }}
                >
                    <div className={styles.menu}>
                        <div className={styles.menuItem}>View Details</div>
                        <div className={styles.menuItem}>Communication Log</div>
                        <div className={styles.menuItem}>Assign Course</div>
                    </div>
                </Popup>
            )
        } */
        // </div>
    )
}

RowEvents.propTypes = {

}

export default RowEvents
