export default {
    FETCH_DRIVERS_VIA_LEVEL: "FETCH_DRIVERS_VIA_LEVEL",
    PUT_DRIVER_LEVELS: "PUT_DRIVER_LEVELS",
    PUT_SELECTED_DRIVER:"PUT_SELECTED_DRIVER",
    PUT_VIEWPORT_LOCATION:"PUT_VIEWPORT_LOCATION",
    VIEWPORT_LOADING_STATUS:"VIEWPORT_LOADING_STATUS",
    LOG_RECORD_LOADING_STATUS:"LOG_RECORD_LOADING_STATUS",
    DRIVER_LAST_LOCATION_INFO:"DRIVER_LAST_LOCATION_INFO", 
    PUT_VIEWPORT_DRIVER:"PUT_VIEWPORT_DRIVER",
    HIT_VIEWPORT_API:"HIT_VIEWPORT_API",
    REMOVE_EVENTS_FROM_DISPLAY:"REMOVE_EVENTS_FROM_DISPLAY",
    IS_PANNING_MAP:"IS_PANNING_MAP",
    SET_DRIVERS: "SET_DRIVERS",
    RESET_DRIVERS: "RESET_DRIVERS"
}