import React from 'react';
import PropTypes from 'prop-types';
import { Text, Grid, GridItem, Spacing, CloseIcon } from '@leaseplan/ui';
import { StylesProvider } from '@material-ui/core';
import styles from './FilterDisplayer.module.scss';

const FilterTag = ({type, value, onCancel}) => {

    const onCancelClick = () => {
        onCancel(value, type, false)
    }
    

    return (
        <div className={styles.tagBody}>
            <span>
                {/* <span className={styles.filterType}>{type}</span> */}
                <span className={styles.filterValue}>{value}</span>
                <span><CloseIcon className={styles.cancelButton} onClick={onCancelClick}/></span>
            </span>
        </div>
    )
}


const FilterDisplayer = ({filters, rowLength, searchString, onTagCancelClick}) => {
    return (
        <Grid direction="column" gutter={false}>
            <GridItem>
                <Text size="l" fontWeight="bold" color="petrolBlue">
                    Search Results { searchString !== '' ? `for "${searchString}"`: ''}
                </Text>
            </GridItem>
            <GridItem>
                <div className={styles.groupTag}>
                    <Grid gutter={false}>
                        {
                            rowLength !== null && (
                            <GridItem span={{mobile:12, tablet:12, desktop:1}}>
                                        <Text fontWeight="bold" size="s" color="petrolBlue">
                                            {`${rowLength} Results`}
                                        </Text>
                            </GridItem>
                        )}
                        <GridItem span={{mobile:12, tablet:12, desktop:11}}>
                            <Spacing ml={2}>
                                <Grid direction="row">
                                    {
                                        Object.keys(filters).map(item => {
                                            let allItems = filters[item]
                                            allItems = allItems.map(i => {
                                                return (
                                                <GridItem>
                                                    <FilterTag type={item} value={i} onCancel={onTagCancelClick}/>
                                                </GridItem> 
                                                )
                                            }) 
                                            return allItems
                                        })
                                    }
                                </Grid>
                            </Spacing>
                        </GridItem>
                    </Grid>
                </div>
            </GridItem>
        </Grid>
    )
}

FilterDisplayer.propTypes = {

}

export default FilterDisplayer
