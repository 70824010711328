import React, { useEffect, useState } from 'react';
import { getLabel, hoursToDaysAndHours, titleCase } from '../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import styles from './VehicleUtilization.module.scss';
import { fetchVehicleUtilization } from '../../../Actions/vehicle.action';

import {
    Grid,
    GridItem,
    Heading,
    Timeline,
    TimelineItem,
    Text,
    Spacing,
    CardCollapse,
    CardCollapseContent,
    CardCollapseHeader,
    CardCollapseHeaderContentSmall
} from '@leaseplan/ui';
import {
    ResponsiveContainer,
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    LabelList,
    Legend,
    Label
} from "recharts";
import { CustomBarReportTooltip, CustomNoDataTooltip } from '../../GraphPlaceholder/Graphs/GraphComponents';



const renderLegend = (props) => {
    const { payload } = props;
    return (
        <div>
            {
                payload.map((entry, index) => (
                    <span key={`item-${index}`}><div style={{ display: 'inline-block', width: '12px', height: '12px', marginLeft: '20px', backgroundColor: entry.color }}></div>{" " + (entry.dataKey).replace(/([A-Z])/g, ' $1').trim()}</span>
                ))
            }
        </div>
    );
}

let tooltip;
//need to optimize all fun.
const CustomTooltip = ({ active, payload, tooltipData, reportData }) => {
    if (!active || !tooltip) return null
    if (payload) {
        for (const bar of payload) {
            if (bar.dataKey != tooltip) {
                if (tooltip == 'NotUtilized') {
                    return <CustomBarReportTooltip tooltipData={tooltipData} reportData={reportData.NotUtilized > 1.5} />
                }
                else {
                    return <CustomNoDataTooltip reportData={reportData.NotData > 1.5} />
                }
            }

            else
                return null
        }
    }
    return null
}

const generateReportData = (data1, daysInHours) => {
    if (data1) {
        let data
        const totalHours = data1.utilizedHours + data1.unutilizedHours
        if (daysInHours - totalHours > 0) {
            data = [
                /* Add the gap key to each data line; could also be done in a forloop */
                {},
                { name: `${(data1.utilizedHours / 24).toFixed(2)} days`, name2: `${(data1.unutilizedHours / 24).toFixed(2)} days`, name3: 'No Data', Utilized: data1.utilizedHours / 24, NotUtilized: data1.unutilizedHours / 24, NoData: (daysInHours - totalHours) / 24 },
            ];
        }
        else {
            data = [
                /* Add the gap key to each data line; could also be done in a forloop */
                {},
                { name: `${(data1.utilizedHours / 24).toFixed(2)} days`, name2: `${(data1.unutilizedHours / 24).toFixed(2)} days`, Utilized: data1.utilizedHours / 24, NotUtilized: data1.unutilizedHours / 24 },
            ];
        }

        return data
    }
}

const getDowntimeData = (obj, daysInMs) => {
    let today = new Date().getTime()
    let date = new Date(today - daysInMs)
    // to fetch the object data between the startdate timeline
    let newArray = obj.filter(function (el) {
        return el.useDescription &&
            new Date(el.startDate).getTime() >= date
    });
    //to get the timelinedata filtered
    let timelinedata = gettimelinedata(newArray)
    let newArray22 = newArray
    const result = {};
    newArray22.forEach(curr => {
        if (result[curr.useDescription]) {
            result[curr.useDescription].durationHours += curr.durationHours;
        } else {
            result[curr.useDescription] = { ...curr };
        }
    });

    const finalResult = Object.values(result);

    let newArray2 = finalResult.filter(function (el) {
        return el.useDescription != 'Driver Assigned' &&
            new Date(el.startDate).getTime() >= date
    });
    let sum = 0
    newArray2.forEach(object => {
        sum += object.durationHours;
    });
    let newArray3 = finalResult.filter(function (el) {
        return el.useDescription == 'Driver Assigned' &&
            new Date(el.startDate).getTime() >= date
    });
    let sum2 = 0
    newArray3.forEach(object => {
        sum2 += object.durationHours;
    });
    const finaledata = {
        data: {
            unutilizedHours: sum,
            utilizedHours: sum2

        },
        result: newArray2,
        timelinedata: timelinedata
    }
    return finaledata
}

const gettimelinedata = (array) => {

    // Create an object to store the output data
    const output = {};

    // Loop through the array data and aggregate information based on 'useDescription'
    array.forEach(item => {
        if (!output[item.useDescription]) {
            output[item.useDescription] = {
                useDescription: item.useDescription,
                startDate: item.startDate,
                durationHours: item.durationHours,
            };
        } else {
            // Update 'startDate' to the earliest date
            output[item.useDescription].startDate = output[item.useDescription].startDate < item.startDate
                ? output[item.useDescription].startDate
                : item.startDate;

            // Add 'durationHours' to the existing value
            output[item.useDescription].durationHours += item.durationHours;
        }
    });

    // Convert the object into an array
    const timelineData = Object.values(output);
    return timelineData
}

const getLast30DaysTitle = (dateInTime) => {
    let currentDate = new Date()
    currentDate.setDate(currentDate.getDate() - 1)
    let startdate = currentDate.toString().split(' ')
    let endDateTime = new Date().getTime() - dateInTime
    let enddate = new Date(endDateTime).toString().split(' ')
    return enddate[1] + ' ' + enddate[2] + ' - ' + startdate[1] + ' ' + startdate[2]
}

const ColoredLine = ({ color }) => (
    <hr
        style={{
            color: color,
            height: 1
        }}
    />
);

const getCustomTooltip = (obj) => {
    if (obj) {
        let newArray = obj.filter(function (el) {
            return el.useDescription != 'Driver Assigned'
        });

        const result = {};
        newArray.forEach(curr => {
            if (result[curr.useDescription]) {
                result[curr.useDescription].durationHours += curr.durationHours;
            } else {
                result[curr.useDescription] = { ...curr };
            }
        });
        const finalResult = Object.values(result);
        return finalResult
    }
}




{/* 
Note : Working on the new vehcile Insight tab this is just a hardcode value base
 report still working on the bckend */}

const VehicleUtilization = ({ prismicData, vin }) => {
    const dispatch = useDispatch();
    const [vehicleUtilizationDataHistory, setVehicleUtilizationDataHistory] = useState(null);
    const vehicleDetailsSelector = useSelector(state => state.vehicle.details);
    const vehicleUtilizationSelector = useSelector(state => state.vehicle.vehicleUtilization);
    const [dataState14, setDataState14] = useState(false);
    const [dataState7, setDataState7] = useState(false);
    const [dataState30, setDataState30] = useState(false);
    const [calculateDate, setCalculateDate] = useState(false);
    const [utilizationInfo, setUtilizationInfo] = useState(false);
    const [utilizationData, setUtilizationData] = useState(false);
    const [reportData, setReportData] = useState(false);
    const [tooltipData, setTooltipData] = useState(false);
    const [timelineData, setTimelineData] = useState(false);
    const [setTicks, setSetTicks] = useState([[0, 5, 10, 15, 20, 25, 30], [0, 30]]);

    let data

    useEffect(() => {
        if (utilizationInfo) {
            dispatch(fetchVehicleUtilization({
                "vehicleId": vehicleDetailsSelector.data.vehicleId,

                "reportCatalogueUid": vehicleDetailsSelector.data.utilizationReportInfo[0].reportCatalogueUid,

                "reportDateRangeUid": vehicleDetailsSelector.data.utilizationReportInfo[0].reportDateRanges[0].dateRangeUid
            }))
        }
    }, [utilizationInfo]);

    useEffect(() => {
        const { data, isLoading } = vehicleDetailsSelector;
        if (!isLoading && data.utilizationReportInfo) {
            setUtilizationInfo(data.utilizationReportInfo);
        }
    }, [dispatch, vehicleDetailsSelector]);

    useEffect(() => {
        if (vehicleUtilizationSelector) {
            const { data, isLoading } = vehicleUtilizationSelector;
            if (!isLoading && data.data) {
                setUtilizationData(data);
            }
        }
    }, [dispatch, vehicleUtilizationSelector]);

    const updateReportOnClick = (e) => {
        switch (e.target.value) {
            case '14':
                setSetTicks([[0, 2, 4, 6, 8, 10, 12, 14], [0, 14]])
                let data14 = dataState14 ? dataState14 : getDowntimeData(utilizationData.timelineData, 1209600000, 312)
                setDataState14(data14)
                let calculateDate14 = getLast30DaysTitle(1209600000)
                setTooltipData(data14.result)
                setTimelineData(data14.timelinedata)
                let data142 = generateReportData(data14.data, 336)
                setReportData(data142);
                setCalculateDate(calculateDate14)
                break;

            case '7':
                setSetTicks([[0, 1, 2, 3, 4, 5, 6, 7], [0, 7]])
                let data7 = dataState7 ? dataState7 : getDowntimeData(utilizationData.timelineData, 604800000, 144)
                setDataState7(data7)
                let calculateDate7 = getLast30DaysTitle(604800000)
                setTooltipData(data7.result)
                setTimelineData(data7.timelinedata)
                let data72 = generateReportData(data7.data, 168)
                setReportData(data72);
                setCalculateDate(calculateDate7)
                break;

            default:
                let data30 = dataState30 ? dataState30 : getDowntimeData(utilizationData.timelineData, 2592000000, 720)
                setDataState30(data30)
                let calculateDate30 = getLast30DaysTitle(2592000000)
                setCalculateDate(calculateDate30)
                setTooltipData(data30.result)
                setTimelineData(data30.timelinedata)
                let data302 = generateReportData(data30.data, 720)
                setReportData(data302);
                let getTooltipData = getCustomTooltip(utilizationData?.timelineData)
                setTooltipData(getTooltipData)
                setSetTicks([[0, 5, 10, 15, 20, 25, 30], [0, 30]])
                break
        }
    }

    useEffect(() => {
        if (utilizationData) {
            let data30 = dataState30 ? dataState30 : getDowntimeData(utilizationData.timelineData, 2592000000, 720)
            setDataState30(data30)
            let calculateDate30 = getLast30DaysTitle(2592000000)
            setCalculateDate(calculateDate30)
            setTooltipData(data30.result)
            setTimelineData(data30.timelinedata)
            let data302 = generateReportData(data30.data, 720)
            setReportData(data302);
            let getTooltipData = getCustomTooltip(utilizationData?.timelineData)
            setTooltipData(getTooltipData)
        }
    }, [dispatch, vehicleUtilizationSelector, utilizationData])

    return (
        <div className={styles.container}>
            <Spacing mb={1}>
                <Heading component="p" size={'xs'} color={'petrolBlue'} className={styles.heading} gutter>{getLabel('vehicle_utilization', 'Vehicle Utilization Status', prismicData)}</Heading>
            </Spacing>

            <Grid direction='column' gutter={false}>

                <GridItem>
                    <Spacing p={1}>
                        <Grid direction='row' justifyContent={'space-between'} gutter={false}>
                            <GridItem>
                                <Text component="p" size={'xs'} fontWeight={'bold'} color={'steel'} className={styles.heading} gutter={false}>Last Movement</Text>
                            </GridItem>
                            <GridItem>
                                <Text component="p" size={'xs'} fontWeight={'bold'} color={'petrolBlue'} className={styles.heading} gutter={false}>{"--"}</Text>
                            </GridItem>
                        </Grid>
                    </Spacing>
                </GridItem>
                <GridItem>
                    <Spacing p={1}>
                        <Grid direction='row' justifyContent={'space-between'} gutter={false}>
                            <GridItem>
                                <Text component="p" size={'xs'} fontWeight={'bold'} color={'steel'} className={styles.heading} gutter={false}>Last Fueling</Text>
                            </GridItem>
                            <GridItem>
                                <Text component="p" size={'xs'} fontWeight={'bold'} color={'petrolBlue'} className={styles.heading} gutter={false}>{"--"}</Text>
                            </GridItem>
                        </Grid>
                    </Spacing>
                </GridItem>
                <GridItem>
                    <Spacing p={1}>
                        <Grid direction='row' justifyContent={'space-between'} gutter={false}>
                            <GridItem>
                                <Text component="p" size={'xs'} fontWeight={'bold'} color={'steel'} className={styles.heading} gutter={false}>Last Maintenance</Text>
                            </GridItem>
                            <GridItem>
                                <Text component="p" size={'xs'} fontWeight={'bold'} color={'petrolBlue'} className={styles.heading} gutter={false}>{"--"}</Text>
                            </GridItem>
                        </Grid>
                    </Spacing>
                </GridItem>

            </Grid>

            {/* comment down this section because of backend facing some performance issue */}

            <ColoredLine color="#dddddd" />

            <Spacing mt={1} mb={1} mr={1}>
                <Heading component="p" size={'xxs'} color={'petrolBlue'} className={styles.heading} gutter>{getLabel('history', 'History', prismicData)}</Heading>
                <Grid justifyContent='right'>
                    <div className={styles.calendarText}>
                        <Text component="p" size={'xs'} color={'petrolBlue'} gutter={false}> {calculateDate}</Text>
                    </div>
                    <Spacing mh={.5} />
                    <select className={styles.selectDiv}
                        onChange={updateReportOnClick}
                    >
                        <option value="30">Last 30 Days</option>
                        <option value="14">Last 14 Days</option>
                        <option value="7">Last 7 Days</option>
                    </select>
                </Grid>
            </Spacing>




            <Grid justifyContent="space-between">
                <GridItem span={{ tablet: 12 }}>
                    {/* waiting for backend data */}
                    <Timeline>
                        {
                            vehicleUtilizationDataHistory && vehicleUtilizationDataHistory.slice(0, '').map((data, index) =>
                                <TimelineItem status="normal" label={data.date} key={index}>
                                    <CardCollapse status="success" className={styles.cardCollapse}>
                                        <CardCollapseHeader>
                                            <CardCollapseHeaderContentSmall
                                                title={titleCase(data?.merchantName)}
                                                label={`${titleCase(data?.merchantCity)}, ${data?.merchantState}`}
                                            />
                                        </CardCollapseHeader>
                                        <span className={styles.costDisplay}>
                                            {`$${data.totalCost}`}
                                        </span>
                                        <CardCollapseContent>
                                            {
                                                data.productName &&
                                                <Text component="p" size={'xs'} fontWeight='600' color={'midOrange'} className={styles.productName} gutter={false}>
                                                    {titleCase(data.productName)}
                                                </Text>
                                            }
                                            {
                                                data.odometer &&
                                                <Text component="p" size={'xs'} color={'petrolBlue'} className={styles.heading} gutter={false}>
                                                    {`Odometer: ${data.odometer}`}
                                                </Text>
                                            }
                                        </CardCollapseContent>
                                    </CardCollapse>
                                </TimelineItem>
                            )
                        }
                    </Timeline>


                </GridItem>
                {reportData && (
                    <ResponsiveContainer width="90%" height={205}>
                        <BarChart width={200} height={200} data={reportData} layout="vertical" margin={{ left: 20, bottom: 30 }} >
                            <XAxis type="number" ticks={setTicks[0]} domain={setTicks[1]} >
                                {reportData[1].Utilized > 0 ? <Label value="Days" offset={-10} position="insideBottom" /> : <></>}
                            </XAxis>
                            <YAxis hide type="category"
                                ticks={[0]} />
                            <Bar dataKey="Utilized" stackId="a" fill="#3c8ae4" barSize={200} onMouseOver={() => tooltip = "Utilized"}>
                                {reportData[1].Utilized > 1.5 ? <LabelList dataKey="name" position="insideMiddle" /> : <></>}

                            </Bar>
                            <Tooltip content={<CustomTooltip tooltipData={tooltipData} reportData={reportData[1]} />} cursor={{ fill: 'transparent' }} />
                            <Bar dataKey="NotUtilized" stackId="a" fill="#f06400" barSize={200} onMouseOver={() => tooltip = "NotUtilized"}>
                                {reportData[1].NotUtilized > 1.5 ? <LabelList dataKey="name2" position="insideMiddle" /> : <></>}
                            </Bar>
                            <Bar dataKey="NoData" stackId="a" fill="#989a9a" barSize={200} onMouseOver={() => tooltip = "NoData"}>
                                {reportData[1].NoData > 1.5 ? <LabelList dataKey="name3" position="insideMiddle" /> : <></>}

                            </Bar>
                            <Spacing mv={1} />
                            <Legend verticalAlign="top" content={renderLegend} />
                            <Legend content={renderLegend} />
                        </BarChart>
                    </ResponsiveContainer>)
                }
            </Grid>
            <Spacing pv={1.5} />
            {
                timelineData && timelineData.map((data, index) =>

                    <div className={styles.tripTimeCell}>
                        <div className={styles.timeline}>
                            <div className={data.useDescription == 'Driver Assigned' ? styles.blueCircle : styles.orangeCircle}></div>
                            <div className={data.useDescription == 'Driver Assigned' ? styles.vl2 : styles.vl}></div>

                        </div>

                        <Spacing ph={.5} />
                        <Grid direction='row' justifyContent={'space-between'} gutter={false}>
                            <GridItem>
                                <Text component="p" size={'xs'} fontWeight={'bold'} color={'steel'} className={styles.heading} gutter={false}>{data.startDate}</Text>
                            </GridItem>
                            <GridItem>
                                <Text component="p" size={'xs'} fontWeight={'bold'} color={'steel'} className={styles.heading} gutter={false}>{data.useDescription}</Text>
                            </GridItem>
                            <GridItem>
                                <Text component="p" size={'xs'} fontWeight={'bold'} color={'petrolBlue'} className={styles.heading} gutter={false}>{hoursToDaysAndHours(data.durationHours)}</Text>
                            </GridItem>
                        </Grid>
                    </div>
                )
            }
        </div>
    )
}

VehicleUtilization.propTypes = {

}

export default VehicleUtilization