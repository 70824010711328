import React, {useState, useEffect} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import { Text, Checkbox } from '@leaseplan/ui';
import { ArrowDropDown } from '@material-ui/icons';
import styles from './TableWrapperOneConnect.module.scss';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {Tooltip} from '@material-ui/core';


const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#ffffff',
    color: '#4a4a4a',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTooltip = withStyles({
  tooltipPlacementTop: {
    margin: "0",
  },
})(Tooltip);

const NoRowFound = ({length, dense, emptyRows, style}) => (
  <>
  <TableRow>
        <TableCell
          colSpan={length}
          align="center"
        >
          <Text color="steel60">
              {/* {getLabel(
                "no_records_default_message",
                "No Records Found",
                prismicData
              )} */}
              No Record Found
          </Text>
        </TableCell>
  </TableRow>
  <TableRow style={{...style}}>
      {/* <TableCell
          colSpan={length}
          align="center"
        >
        </TableCell> */}
  </TableRow>
  </>
)

const RowIsLoading = ({rowsPerPage, length, dense}) => {

  const dummyRows = []

  for(let i =0; i<rowsPerPage; i++){
    dummyRows.push(i);
  }

  return (
    <>
    {dummyRows.map((row, index) => {
      return(
        <TableRow style={{height:53}} key={index}>
              <TableCell 
                  colSpan={length}
                  align="center" 
                  key={index}
                >
                <Text
                  size="xs"
                  color="petrolBlue"
                  id={index}
                >
                  <div className={styles.shine}></div>
                </Text>
              </TableCell>
        </TableRow>
      )
    })}
  </>
  )
}


function EnhancedTableHead({ onRequestSort, header }) {

  const createSortHandler = (field_name, direction) => {
    onRequestSort(field_name, direction)
  };

  return (
    <TableHead>
      <TableRow>
        {header.map((eachHeader) => (
          <StyledTooltip key={eachHeader.id}  title={eachHeader.tooltipTitle ? eachHeader.tooltipTitle : ""} placement="top" disableHoverListener={!eachHeader.tooltipTitle}>
              <StyledTableCell
                key={eachHeader.id}
                align={eachHeader.align ? eachHeader.align : 'left'}
                // padding={eachHeader.disablePadding ? 'none' : 'normal'}
                sortDirection={eachHeader.sort ? eachHeader.sort : 'asc'}
                style={{position: 'sticky', top: 0, paddingTop: '20px', paddingBottom: '20px', ...eachHeader.styles}}
                width={eachHeader.width ? eachHeader.width: ''}
              >
                {eachHeader.sortEnabled ? (
                  <div className={styles.row}>
                  {eachHeader.checkbox && <Checkbox 
                    name="default" 
                    value={"all"} 
                    onChange={(event) => eachHeader.onSelected(event)} />}

                  <TableSortLabel
                    active={eachHeader.sortEnabled}
                    direction={eachHeader.sort ? eachHeader.sort : 'asc'}
                    onClick={() => createSortHandler(eachHeader.field, eachHeader.sort)}
                    IconComponent={ArrowDropDown}
                  >
                    <Text style={{margin: 0}} size="m" fontWeight="bold" color="petrolBlue" gutter>{eachHeader.label}</Text>
                  </TableSortLabel>
                  </div>
                ) : (
                  <Text style={{margin: 0}} size="m" fontWeight="bold" color="petrolBlue" gutter>{eachHeader.label}</Text>
                )}
              </StyledTableCell>
          </StyledTooltip>
        ))}
      </TableRow>
    </TableHead>
  );
}


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '@media (max-width: 1340px)' : {
      width: '109%'
    },
    '@media (max-width: 1240px)' : {
      width: '115%'
    },
    '@media (min-width: 1230px) and (max-width:1670px)' : {
      width: '115%'
    }
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    borderCollapse: 'separate',
    width: '100%'
  },
  tableCell: {
    paddingRight: 4,
    paddingLeft: 5
  }
}));

const TableWrapperOneConnect = ({
  currentPage,
  rowsPerPage,
  handleOnPageChange,
  handleOnRowPerPageChange,
  columns = [],
  onSortColumn,
  onPageOrPageLimitChange,
  children,
  isLoading,
  resultSetCount,
  currentRowLength,
  maxHeight='100%',
  minHeight='100%',
}) => {
  const classes = useStyles();
  const [dense, setDense] = useState(true)


  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { columns });
    }
    return child;
  });

  const handleRequestSort = (field_name, direction) => {
    let dir = direction == "asc" ? "desc" : "asc";
    onSortColumn(field_name, dir, rowsPerPage);
  };


  const handleChangePage = (event, newPage) => {
    handleOnPageChange(event, newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    handleOnRowPerPageChange(event)
    // setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(1);
  };

  const resultSetCountTemp = currentRowLength <= rowsPerPage ? currentRowLength : resultSetCount - currentPage * rowsPerPage;
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, resultSetCountTemp);
  

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={3}>
        <TableContainer className={styles.tableContainer} style={{ maxHeight: maxHeight, minHeight: minHeight }}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
            stickyHeader
          >
            <EnhancedTableHead
              header={columns}
              onRequestSort={handleRequestSort}
              rowCount={resultSetCount}
            />
            <TableBody>
              {(!isLoading && resultSetCount === 0) && (
                  <NoRowFound length={columns.length} style={{ height: (dense ? 23 : 53) * emptyRows }}/>
              )}
              {(isLoading) && (
                <RowIsLoading dense={dense} length={columns.length} style={{ height: (dense ? 23 : 53) * emptyRows }} rowsPerPage={rowsPerPage}/>
              )}
              {(!isLoading && resultSetCount > 0) && childrenWithProps}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[20, 30, 40]}
          component="div"
          count={resultSetCount}
          rowsPerPage={rowsPerPage}
          page={currentPage-1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

export default React.memo(TableWrapperOneConnect);