import { createStore, applyMiddleware,compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from '../Reducers';
import rootSaga from '../Sagas/rootSaga'

const sagaMiddleware = createSagaMiddleware();
let buildStore=createStore(
    rootReducer,
 applyMiddleware(sagaMiddleware)
);

//enabling redux dev tool on dev and qa environment
// if(process.env.REACT_APP_ENV==="DEV" || process.env.REACT_APP_ENV==="QA"){
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 }) || compose;
    buildStore=createStore(
        rootReducer,
        composeEnhancers(applyMiddleware(sagaMiddleware))
    );
// }
const store = buildStore;

sagaMiddleware.run(rootSaga);

export default store;