let id = 0;

export const defaultOptions = {
    icon: "info",
    type: "info",
    timeout: 3000,
    color: "aquaBlue",
    // color: "#6796e6",
};

export default function createToast(options) {
    // console.log('createToast', options);
    
    return {
        ...defaultOptions,
        ...options,
        id: id++
    }
}