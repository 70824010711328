import axios from "../AxiosInterceptor";
import { call, put, takeEvery, delay } from "redux-saga/effects";
import {TIMELINE} from "../Constants/timeline";
import { F, S } from "../utils/actions";


function* timelineClockDataWorker(action) {
    yield put({type: S(action.type), payload: {
        dayDate: action.payload.dayDate,
        fromTime: action.payload.fromTime,
        toTime: action.payload.toTime,
        event: action.payload.event,
        speed: action.payload.speed
    }})
}

function* playTimelineWorker(action) {
    yield put({type: S(action.type)})
}

function* pauseTimelineWorker(action) {
    yield put({type: S(action.type)})
}

function* speedTimelineWorker(action) {
    yield put({type: S(action.type), payload: action.payload})
}

function* insertDateWorker(action) {
    yield put({type: S(action.type), payload: action.payload})
}

function* timelineSaga() {
    yield takeEvery(TIMELINE.TIMELINE_CLOCKDATA_INSERT, timelineClockDataWorker);
    yield takeEvery(TIMELINE.TIMELINE_PLAY_INSERT, playTimelineWorker);
    yield takeEvery(TIMELINE.TIMELINE_PAUSE_INSERT, pauseTimelineWorker);
    yield takeEvery(TIMELINE.TIMELINE_SPEED_INSERT, speedTimelineWorker);
    yield takeEvery(TIMELINE.TIMELINE_DATE_INSERT, insertDateWorker);
}


export default timelineSaga;