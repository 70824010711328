import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';

import { Heading, Spacing } from "@leaseplan/ui";
import styles from './LPTripsDrawer.module.scss';



const useStyles = makeStyles((theme) => ({
	grid: {

		// marginTop: '-2rem',
		width: 320,
		// backgroundColor: '#DADADA'
	},
	customGridWidth: {

		width: '23em',
		marginTop: '-1em',
		// paddingTop: 15,
		overflowX: 'hidden',
		overflowY: 'auto',
		// backgroundColor: '#DADADA'
	},
	drawerHeader: {
		height: '7em',
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing(0.2, 3),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
		justifyContent: 'space-between',
		// backgroundColor: '#DADADA'
	},
	drawer: {
		// color: '#DADADA',
		// Opacity: '-1'

	},
	paper: {
		top: '70px',
		// background: "#DADADA",
		height: '92%'
	}

}));

export default function LPTripsDrawer(props) {
	const {
		title,
		shouldOpenDrawer,
		shouldDrawerOpened,
		drawerAlignment = "right",
		dynamicWidth = false,
	} = props

	const classes = useStyles();
	const [drawerOpen, setDrawerOpen] = React.useState({ right: false });

	useEffect(() => {
		// console.log("open drawer2: ", !!shouldDrawerOpened)
		setDrawerOpen({ ...drawerOpen, right: !!shouldDrawerOpened });
	}, [shouldDrawerOpened]);

	const toggleDrawer = (open) => (event) => {
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}
		// console.log("open drawer4: ", open);
		shouldOpenDrawer(open);
		setDrawerOpen({ ...drawerOpen, right: open });
	};

	return (
		<Drawer
			BackdropProps={{ invisible: true }}
			classes={{ paper: classes.paper }}
			open={drawerOpen.right}
			anchor={drawerAlignment}
			className={classes.drawer}
			onClose={toggleDrawer(false)}
		>
			<div className={classes.drawerHeader}>
				<Heading size="xs">{title}</Heading>


			</div>
			<Spacing
				p={2}
				pt={0}
				className={props.width
					? styles.customWidth
					: clsx({
						[classes.grid]: true,
						[classes.MuiBackdropRoot]: true,
						[classes.customGridWidth]: dynamicWidth
					})
				}
			>
				{props.children}
			</Spacing>

		</Drawer>
	);
}
