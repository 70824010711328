export const LINE_GRAPH_SPEC = {
    FLEET_MILEAGE: {
        y_axis_label: "Total Miles / Vehicles in Use",
        avg_milage: "Average Daily Miles",
    },
    MAX_SPEEDING_EVENTS: {
        max_speed: "Max Speed Events",
        worst_event: "Events by 5 Worst Drivers"
    },
    SEAT_BELT_EVENTS: {
        client_avg: "Client avg per day",
        worst_event: "Events by 5 Worst Drivers"
    },
    SPEEDING_EVENTS: {
        client_avg: "Client avg per day",
        worst_event: "Events by 5 Worst Drivers"
    },
    LOW_VOLTAGE: {
        client_avg: "Client avg per day",
        worst_event: "Events by 5 Worst Drivers"
    }
}

export const BAR_GRAPH_SPEC = {
    DAILY_TRIPS: {
        y_axis_label: "Daily Trips By Fleet",
    },
    AVERAGE_MPG: {
        y_axis_label: "Average Miles Per Gallon",
    }
} 