import React, {useEffect, useState} from 'react';
import ReactDOM from "react-dom";
import PropTypes from 'prop-types';
import {Marker, useMap, Tooltip, Popup} from 'react-leaflet';
import ReactDOMServer from "react-dom/server";
import NationalShopPopup from '../NationShopPopup/NationalShopPopup';
import * as L from 'leaflet';
import "leaflet-responsive-popup";
import "leaflet-responsive-popup/leaflet.responsive.popup.css";
import {render} from 'react-dom';

const ResponsivePopup = ({popupData,children, onClose}) => {
    const map = useMap();



    useEffect(() => {
        // const link = L.DomUtil.create('button');
        // const container = L.DomUtil.create('div');
        // const content = children;


        // link.addEventListener('click', () =>
        //     console.log("Hello")
        // );

        // container.innerHTML = ReactDOMServer.renderToString(content);
        // container.appendChild(link);

        var latlng = new L.latLng(popupData.poiLatitude, popupData.poiLongitude);
        const container = L.DomUtil.create('div');
        render(
            children,
            container,
          );
        const popup = new L.popup({
            hasTip: true,
            offset: [0, -10]
        });
        popup.setContent(container).setLatLng(latlng).openOn(map);
    }, [])

    useEffect(() => {
        map.on('popupclose',function(e) {
            onClose()
        });
    }, [])

    return null;
}

export default ResponsivePopup
