import React, { useEffect, useState } from 'react';
import { Legend, ResponsiveContainer, Label, PieChart, Pie, Cell, Tooltip } from 'recharts';
import { RenderCustomizedLabelForDonut, findStyleSetting, capitalizeAndConvert, CustomPieReportTooltip } from './GraphComponents';
import styles from './Graph.module.scss';
import { GRAPH_TYPES } from '../../../Constants/reporting';


const modifyGraphData = (data = []) => {
    let result = [];
    if (data.length > 0) {
        result = data[0]?.values?.map(value => (
            {
                name: value.name,
                value: value.count,
            }
        )
        )
    }
    return result;
}

/**
 * @format
 * const data = graphSpecification === GRAPH_TYPES.DEVICE_COMMUNICATION_STATUS.VALUE ? 
        (
            [
                {name: '0 to 2 days', value: 67}, 
                {name: '3 to 5 days', value: 7},
                {name: 'More than 5 days', value: 4}
            ]
        ): (
            [
                {name: 'Active', value: 62}, 
                {name: 'Terminated', value: 3},
                {name: 'Suspended', value: 4},
                {name: 'Never Activated', value: 9}
            ]
        )
 */

export const DonutGraph = ({ height, width, graphData, styleType, graphSpecification }) => {

    const [totalCount, setTotalCount] = useState(0);
    const [data, setData] = useState([]);
    const settings = findStyleSetting(styleType, "donut");
    const COLORS = graphData && graphData[0].eventName == "active_event-low_voltage" || graphData[0].eventName == "active_event-location_masking" ?
        ['#E7401E', '#ED8B00', '#E7401E', '#BBBBBB']
        : graphData && graphData[0].eventName == "device_communication_status" ? (
            ['#66C274', '#ED8B00', '#E7401E', '#BBBBBB']
        ) : (
            ['#66C274', '#ED8B00', '#FAD9D2', '#BBBBBB']
        )

    useEffect(() => {
        const data = modifyGraphData(graphData);
        if (data) {
            const total = Object.values(data).reduce((t, { value }) => t + value, 0)
            setData(data);
            setTotalCount(total);
        }
    }, [])

    return (
        <div className={styles.container}>
            <ResponsiveContainer width={width} height={height} className={styles.graphArea}>
                <PieChart
                    width={width}
                    height={height}
                >
                    <Legend
                        payload={
                            data.length > 0 && data.map(
                                (item, index) => ({
                                    id: item.name,
                                    type: "circle",
                                    value: item.name ? `${capitalizeAndConvert(item.name)}` : ``,
                                    color: COLORS[index % COLORS.length]
                                })
                            )
                        }
                        iconSize="6"
                        layout="vetical"
                        verticalAlign="bottom"
                        wrapperStyle={settings?.legend?.wrapperStyle}
                    />
                    <Pie
                        data={data}
                        innerRadius={settings?.innerRadius || 20}
                        outerRadius={settings?.outerRadius || 30}
                        fill="#8884d8"
                        paddingAngle={settings?.paddingAngle || 0}
                        label={<RenderCustomizedLabelForDonut {...settings?.cellLabel} />}
                        labelLine={false}
                    // isAnimationActive={false}
                    >
                        {
                            (graphData && graphData[0].eventName == "active_event-low_voltage" || graphData[0].eventName == "active_event-location_masking") ? (
                                <Label
                                    text
                                    width={settings.label.width || 20}
                                    wordSpacing={1}
                                    value={totalCount}
                                    position="center"
                                    fontSize={35}
                                    fontWeight={settings.label.fontWeight || 'normal'}
                                />
                            ) :
                                <Label
                                    text
                                    width={settings.label.width || 20}
                                    wordSpacing={1}
                                    value={`Total number of device ${totalCount}`}
                                    position="center"
                                    fontSize={settings.label.fontSize || 20}
                                    fontWeight={settings.label.fontWeight || 'normal'}
                                />
                        }

                        {
                            data.map((entry, index) => <Cell key={index} fill={COLORS[index % COLORS.length]} />)
                        }
                    </Pie>
                    <Tooltip content={<CustomPieReportTooltip />} />
                </PieChart>
            </ResponsiveContainer>
        </div>
    )
}


export default React.memo(DonutGraph);