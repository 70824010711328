import React, { useState, useEffect } from "react";
import { Card, CardContent, Button } from "@leaseplan/ui";
import styles from "./DashboardGraph.module.scss";
import useFetch from "../../../Common/CustomHook/useFetch";
import { GRAPH_TYPES } from "../../../Constants/reporting";
import GraphPlaceholder from "../../GraphPlaceholder/GraphPlaceholder";
import { LPLoader } from "../../../Common/LPLoader/LPLoader";
import { useNavigate, generatePath } from "react-router-dom";
import { RoutePath } from "../../../Routers/Routes";
import GraphModal from "../../Reporting/GraphModal/GraphModal";
import { useDispatch } from "react-redux";
import { downloadReport } from "../../../Actions/reporting.action";

const EmptyReports = ({ length, onClick }) => {
  const dummyReports = [];

  for (let i = 0; i < length; i++) {
    dummyReports.push(i);
  }

  return (
    <>
      {dummyReports.map((value) => {
        return (
          <Card key={value} className={styles.emptyCards} elevation={6}>
            <CardContent className={styles.addReports}>
              <Button
                className={styles.addReportsButton}
                variant="secondary"
                size="s"
                onClick={onClick}
              >
                Add Favorite
              </Button>
            </CardContent>
          </Card>
        );
      })}
    </>
  );
};

const DashboardGraph = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const TOTAL_GRAPH = 2;
  const [data, setData] = useState([]);
  const history = useNavigate();
  const dispatch = useDispatch();

  const { isLoading, apiData, serverError } = useFetch(
    "get",
    "/reports/favourite-report/",
    null,
    []
  );

  const toggleModalHandler = (e) => {
    setModalData(e.graphData);
    setOpenModal((openModal) => !openModal);
  };

  const downloadReportHandler = (graphId) => {
    dispatch(downloadReport(graphId));
  };

  const length = apiData?.length;
  const emptyGrid = length >= 2 ? 0 : TOTAL_GRAPH - length;

  useEffect(() => {
    if (!isLoading && apiData) {
      let array = Object.keys(GRAPH_TYPES).map((k) => GRAPH_TYPES[k]);
      let newData = apiData.map((eachReport) => {
        const GRAPHTYPE = array.filter(
          (d) => d.ID === eachReport.reportCatalogueUid
        )[0];
        return {
          graphId: eachReport.reportSelectionUid,
          graphType: eachReport.presentationStyle.split(" ")[0].toUpperCase(),
          graphTitle: eachReport.reportName,
          graphDescription: "MaxLine",
          graphSpecification: GRAPHTYPE ? GRAPHTYPE.VALUE : "NA",
          isFavourite: eachReport.homePage,
          data: eachReport.data,
        };
      });
      setData(newData);
    }
  }, [isLoading]);

  const moveToReportingPage = () => {
    history({
      pathname: generatePath(RoutePath.Reporting),
    });
  };

  return (
    <>
      {openModal && (
        <GraphModal
          openModal={openModal}
          toggleModalHandler={toggleModalHandler}
          modalData={modalData}
        />
      )}
      {data.length > 0 &&
        data.map((data) => (
          <Card key={data.graphId} className={styles.cardContainer} elevation={6}>
            <CardContent>
              <div className={styles.graphContainer}>
                <GraphPlaceholder
                  key={data.graphId}
                  graphId={data.graphId}
                  graphType={data.graphType}
                  graphTitle={data.graphTitle}
                  graphDescription={data.graphDescription}
                  graphSpecification={data.graphSpecification}
                  graphData={data.data}
                  graphHideCalendar={data.graphHideCalendar}
                  // isModal={false}
                  styleType="dashboard"
                  isFavourite={data.isFavourite}
                  hasWrapperElements={true}
                  height={200}
                  width={"99%"}
                  toggleModalHandler={toggleModalHandler}
                  downloadReportHandler={downloadReportHandler}
                />
              </div>
            </CardContent>
          </Card>
        ))}
      {isLoading ? (
        <>
          <Card className={styles.cardContainer} elevation={6}>
            <CardContent>
              <LPLoader loading={true} message={"Loading Data..."} />
            </CardContent>
          </Card>
          <Card className={styles.cardContainer} elevation={6}>
            <CardContent>
              <LPLoader loading={true} message={"Loading Data..."} />
            </CardContent>
          </Card>
        </>
      ) : (
        <EmptyReports length={emptyGrid} onClick={moveToReportingPage} />
      )}
    </>
  );
};

export default DashboardGraph;
