import axios from "../AxiosInterceptor";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import CONSTANTS from "../Constants/tripAdmin";
import { baseURL } from "../Store/base-url";
import { F, S } from "../utils/actions";
import { CANCEL } from 'redux-saga';

function fetchTripApi(params) {
  const tokenSource = axios.CancelToken.source();
  // const { queryString } = params;
  // if(queryString=='undefined'){queryString='page=0&size=20'}
  // delete params.queryString;
  
  const request = axios.request({
    baseURL: baseURL,
    responseType: "json",
    url: `/trip-admin`,
    data: params,
    method: "post",
    cancelToken: tokenSource.token
  });
  request[CANCEL] = () => {tokenSource.cancel();}
  return request;
}

function getLiveSearchTripDrivers(payload) {
  const tokenSource = axios.CancelToken.source();
  const request = axios.request({
    baseURL: baseURL,
    responseType: "json",
    url: `/potential-trip-drivers`,
    method: "post",
    data: {
      ...payload
    },
    cancelToken: tokenSource.token
  });
  request[CANCEL] = () => tokenSource.cancel();
  return request;

}

function fetchEligibleDriversApi(params) {
  return axios.request({
    baseURL: baseURL,
    responseType: "json",
    url: '/potential-trip-drivers',
    data: params,
    method: "post",
  });
}

function updateProjectCodeApi(params) {
  return axios.request({
    baseURL: baseURL,
    responseType: "json",
    url: '/update-trip',
    data: params,
    method: "put",
  });
}

export function* fetchTripsWorker(action) {
  try {
    const { data } = yield call(fetchTripApi, action.payload);
    yield put({ type: S(action.type), 
        data: [...data.content],
        resultSetCount: data.totalElements,
        currentPage: Number(data.pageable.pageNumber) - 1,
        pageLimit: data.pageable.pageSize  
    });
  } catch (e) {
    yield put({ type: F(action.type), payload: e });
  }
}

export function* fetchLiveSearchInfoWorker(action) {
  try {
    const { data } = yield call(getLiveSearchTripDrivers, action.payload);
    yield put({
      type: S(action.type),
      data: data
    });
  } catch (e) {
    yield put({ type: F(action.type), payload: e });
  }
}

export function* fetchEligibleDriversWorker(action) {
  try {
    const { data } = yield call(fetchEligibleDriversApi, action.payload);
    yield put({ type: S(action.type), payload: data });
  } catch (e) {
    yield put({ type: F(action.type), payload: e });
  }
}

export function* updateProjectTripWorker(action) {
  try {
    const { data } = yield call(updateProjectCodeApi, action.payload);
    yield put({ type: S(action.type), payload: data });
  } catch (e) {
    yield put({ type: F(action.type), payload: e });
  }
}


function* userSaga() {
    yield takeLatest(CONSTANTS.FETCH_TRIP_ADMIN, fetchTripsWorker);
    yield takeLatest(CONSTANTS.FETCH_DRIVER_SEARCH, fetchLiveSearchInfoWorker);
    yield takeEvery(CONSTANTS.FETCH_ELIGIBLE_DRIVERS, fetchEligibleDriversWorker);
    yield takeEvery(CONSTANTS.UPDATE_PROJECT_CODE, updateProjectTripWorker);
}


export default userSaga;