import React, {useState, useEffect} from 'react';
import styles from './OneScore.module.scss';
import {
    Text,
    Heading,
    Card,
    Grid,
    GridItem
  } from "@leaseplan/ui";

import { useDispatch, useSelector } from 'react-redux';
import { getLabel } from '../../../utils';
import CircularStatic from '../../../Common/CircularProgressWithLabel/CircularProgressWithLabel';
import { fetchOneScore } from '../../../Actions/driver.action';
import Images from '../../../Asset/Images';
import CarIcon from '../../../Asset/Images/car.png';

import Badge from '@material-ui/core/Badge';
import { withStyles } from "@material-ui/core/styles";

import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';

import InformationTab from '../InformationTab/InformationTab';
import { LPLoader } from '../../../Common/LPLoader/LPLoader';

const styles1 = theme => ({
    margin: {
      margin: theme.spacing.unit * 2
    },
    customBadge: {
      backgroundColor: "#ffbf00",
      color: "white"
    }
});

const OneScoreCardTrends = ({changeType}) => {
    return(
      <div>
        {
            changeType === 'increased' && <TrendingUpIcon htmlColor={'green'} className={styles.trendIcon}/>
        }
        {
            changeType !== 'increased' && <TrendingDownIcon htmlColor={'red'} className={styles.trendIcon}/>
        }
      </div>
    )
  }

  
const OneScoreCard = (props) => {
    const { classes, eventId, eventName, eventIcon, eventCount, scoreColor, scoreValue } = props;
    return (
        <Badge badgeContent={eventCount} color={'primary'} style={{width: '100%'}} classes={{ badge: classes.customBadge }}>
            <div className={styles.oneScoreCard}>
                <div className={styles.oneScoreCardsGrid}>
                    
                    
                    <div className={styles.eventImage}>
                        <CircularStatic progress={scoreValue} color={scoreColor ? (scoreColor.toLowerCase() === 'yellow' ? '#F9C74B' : scoreColor.toLowerCase()) : 'red'}/>
                    </div>

                    <div className={styles.eventName}>
                        <Text className={styles.textFont} color='steel' size='xxs' fontWeight='bold' fontStyle="italic">{eventName}</Text>
                    </div>
                </div>
            </div>
        </Badge>
    )
}

const OneScore = (props) => {
    const dispatch = useDispatch();
    const {prismicData, driverDetails} = props;
    const [oneScoreData, setOneScoreData] = useState([]);

    const oneScoreSelector = useSelector(state => state.driver.oneScore);

    useEffect(() => {
        const { isLoading, data } = oneScoreSelector;
        if(!isLoading && data && data.Score) {
            setOneScoreData(data.Score);
        }
    }, [oneScoreSelector]);

    useEffect(() => {
        dispatch(fetchOneScore({driverRecordNumber: driverDetails.driverRecordNumber}))
    }, [dispatch, driverDetails]);

    const getTrendValue = (score) => {
        let string = '';
        if(score.changeType === 'increased') {
            string += '+';
        } else {
            string += '-';
        }
        string += ' ' + score.change  + ' points';
        return string;
    }

    return (
        <div className={styles.oneScoreContainer}>
            <Heading component="p" size={'xs'} color={'petrolBlue'} className={styles.heading} gutter>{getLabel('one_score', 'One Score', prismicData)}</Heading>
            {
                oneScoreSelector.isLoading ? (
                    <div className={styles.lpLoader}>
                        <LPLoader
                            loading={oneScoreSelector.isLoading}
                            message={"Loading Data..."}
                        />
                    </div>
                ): (
                    <>
                        {
                            oneScoreData?.length > 0 ? oneScoreData.map(score => {
                                return (
                                    <div className={styles.score}>
                                        {Array.isArray(score.data) ? <><div className={styles.row}>
                                            <Heading component="p" size={'xs'} color={'petrolBlue'} className={styles.heading} gutter>{score.scoreName}</Heading>

                                            <CircularStatic progress={score.scoreValue} color={ score?.scoreColor?.toLowerCase() === 'yellow' ? '#F9C74B' : score?.scoreColor?.toLowerCase() || 'green'}/>

                                            {score.changeType !== 'NA' && <div className={styles.column}>
                                                <div className={styles.oneScoreTrend}>
                                                    <OneScoreCardTrends changeType={score.changeType}/>
                                                </div>
                                                <div className={styles.oneScoreTrendPercentage}>
                                                    <Text className={styles.textFont} color='steel' size='xxs' fontStyle="italic">{getTrendValue(score)}</Text>
                                                </div>
                                            </div>}
                                        </div>

                                        <Grid direction='row' justifyContent='center'>
                                            {
                                                score.data.map(row => {
                                                    return (
                                                        <GridItem span={6}>
                                                            <OneScoreCard 
                                                                {...props}
                                                                eventId={row.eventId}
                                                                eventName={row.eventName}
                                                                eventCount={row.eventCount}
                                                                scoreValue={row.scoreValue}
                                                                scoreColor={row.scoreColor}
                                                                eventIcon={Images.oneScoreEvents[row.eventId]?.default || CarIcon}
                                                            />
                                                        </GridItem>
                                                    )
                                                })
                                            }
                                        </Grid> </> : null}
                                        
                                    </div>
                                )
                            }):(
                                <InformationTab icon={'info'} iconSize={'l'} headerText={getLabel('no_information_available', 'No Information Available', prismicData)} text={getLabel('onescore_data_not_available', 'One Score data is not available for this driver', prismicData)}/>
                            )
                        }
                    </>
                )
            }
        </div>
    )
}

export default withStyles(styles1)(OneScore);