import React from 'react';
import { ErrorPage } from '@leaseplan/ui';
import styles from './ErrorPage.module.scss';
import { Icon } from '@velocity/ui.Icon';

const Error = ({
    title,
    errorCodeText,
    icon,
    iconSize,
    description
}) => {

    return (
        <div className={styles.ErrorPage} data-testid="ErrorPage">
            <ErrorPage
                title={title}
                errorCodeText={errorCodeText}
                icon={() => <Icon name={icon} size={iconSize} color="petrolBlue" className={styles.ErrorIcon} />}
                description={description}
            />
        </div>
    )

}

Error.propTypes = {};

Error.defaultProps = {};

export default Error;