import React, { useState, useEffect, useMemo } from 'react';
import styles from './Events.module.scss';
import {
    Heading,
} from "@leaseplan/ui";
import Row from './Row';
import { useDispatch, useSelector } from 'react-redux';
import { getLabel, timezoneOffSetAdd } from '../../../utils';
import { fetchEvents, fetchServiceEvents, insertInitialFromTime } from '../../../Actions/driver.action';
import moment from 'moment';
import FilterIcon from "../../../Asset/Images/filter.png";
import RefreshIcon from '@material-ui/icons/Refresh';
import Popup from 'reactjs-popup';

import InformationTab from '../InformationTab/InformationTab';
import { LPLoader } from '../../../Common/LPLoader/LPLoader';
import PopupFilterComponent from '../../../Common/PopupFilter/PopupFilter';
import { removeEventsFromDisplay } from '../../../Actions/level.action';

import CalendarTodayIcon from "@material-ui/icons/CalendarToday";

const contentStyle = { background: '#FFF' };
const overlayStyle = { background: 'rgba(0,0,0,0.5)' };
const arrowStyle = { color: '#FFF' }; // style for an svg element

//changes for valid and non valid ticket events for USIC 9939 client
const TICKET_CONSTANT = {
    TICKET_GREEN: {
        ID: "ticket_green",
        PRIMARY_ID: "ticket",
        LABEL: "Valid Ticket",
        COLOR: "Green"
    },
    TICKET_RED: {
        ID: "ticket",
        PRIMARY_ID: "ticket",
        LABEL: "Ticket",
        COLOR: "Red"
    },
    TICKET_YELLOW: {
        ID: "ticket",
        PRIMARY_ID: "ticket",
        LABEL: "Ticket",
        COLOR: "Yellow"
    }
}

const Events = (props) => {
    const dispatch = useDispatch();
    const { prismicData, deviceId, selectedTripDate, selectActiveEvent, activeEvent, openCalendar } = props;
    const [events, setEvents] = useState([]);
    const [eventFilters, setEventFilters] = useState([]);
    const [selectedEvents, setSelectedEvents] = useState([]);
    const [initialSelectedEvents, setInitialSelectedEvents] = useState([]);
    const authSelector = useSelector(state => state.auth);
    let driverEventsSelector = useSelector(state => state.driver.events);
    let driverNewEventsSelector = useSelector(state => state.driver.serviceEvents);

    // checking and filtering for valid and non valid tickets from USIC events and normal events 
    const displayedEvents = events?.filter(event => {
        let { ruleId, color = null } = event;
        let modifiedRuleId = ruleId;
        if (
            ruleId === TICKET_CONSTANT.TICKET_GREEN.PRIMARY_ID &&
            color === TICKET_CONSTANT.TICKET_GREEN.COLOR
        ) {
            modifiedRuleId = TICKET_CONSTANT.TICKET_GREEN.ID;
        }
        return selectedEvents.includes(modifiedRuleId)
    });

    useEffect(() => {
        let date = moment(selectedTripDate).format('YYYY-MM-DD');
        const dateFromUTC = timezoneOffSetAdd(date, '00:00:00', sessionStorage.getItem("driverTimezoneOffset"));
        const dateToUTC = timezoneOffSetAdd(date, '23:59:59', sessionStorage.getItem("driverTimezoneOffset"));
        dispatch(fetchEvents({
            clientNumber: authSelector.user.authUser.clientNumber,
            userId: authSelector.user.authUser.username,
            fromDate: dateFromUTC,
            toDate: dateToUTC,
            deviceId,
        }))
        dispatch(fetchServiceEvents({
            clientNumber: authSelector.user.authUser.clientNumber,
            userId: authSelector.user.authUser.username,
            fromDate: dateFromUTC,
            toDate: dateToUTC,
            deviceId,
        }))
        dispatch(insertInitialFromTime());
    }, [dispatch, selectedTripDate, deviceId]);

    useEffect(() => {
        if (!driverEventsSelector.isLoading && !driverNewEventsSelector.isLoading) {
            let genericEvents = driverEventsSelector.data || []
            let ticketEvents = driverNewEventsSelector.data || []
            const ids = new Set(driverEventsSelector?.data?.map(d => d.eventUid));
            let updatedEvents = [...genericEvents, ...ticketEvents.filter(d => !ids.has(d.eventUid))]
            var sorted = updatedEvents.sort(function (date1, date2) {
                var formateDate1 = new Date(date1.activeFrom)
                var formateDate2 = new Date(date2.activeFrom)
                return formateDate2.getTime() - formateDate1.getTime();
            });
            const { filtersData, selectedEvents } = createEventsFilter(sorted);
            setEvents(sorted);
            setEventFilters(filtersData);
            setSelectedEvents(selectedEvents);
        }
    }, [driverNewEventsSelector.isLoading, driverEventsSelector.isLoading]);


    const createEventsFilter = (rawListData, ignoreEventList = []) => {
        let filtersData = []
        let selectedEvents = []
        rawListData.forEach(event => {
            let { ruleId, ruleLabel, color = null } = event;
            let modifiedRuleId = ruleId;
            let modifiedRuleLabel = ruleLabel;
            if (
                ruleId === TICKET_CONSTANT.TICKET_GREEN.PRIMARY_ID &&
                color === TICKET_CONSTANT.TICKET_GREEN.COLOR
            ) {
                modifiedRuleId = TICKET_CONSTANT.TICKET_GREEN.ID
                modifiedRuleLabel = TICKET_CONSTANT.TICKET_GREEN.LABEL
            }
            if (!selectedEvents.includes(modifiedRuleId)) {
                filtersData.push({
                    label: modifiedRuleLabel,
                    value: modifiedRuleId,
                })
                selectedEvents.push(modifiedRuleId)
            }
        })
        if (ignoreEventList.length && selectedEvents.length) {
            selectedEvents = selectedEvents.filter(data => !ignoreEventList.includes(data));
        }
        setInitialSelectedEvents(selectedEvents)
        selectedEvents = filterEventsFunc(selectedEvents, ['ticket_green']);
        filtersData.forEach(function (item, i) {
            if (item.value === "ticket_green") {
                filtersData.splice(i, 1);
                filtersData.unshift(item);
            }
        });
        dispatch(removeEventsFromDisplay(['ticket_green']))
        return { filtersData, selectedEvents }
    }

    const filterEventsFunc = (arr, value) => {
        var myArray = arr.filter((el) => !value.includes(el));
        return myArray

    }

    const onRefresh = () => {
        let date = moment(selectedTripDate).format('YYYY-MM-DD');
        const dateFromUTC = timezoneOffSetAdd(date, '00:00:00', sessionStorage.getItem("driverTimezoneOffset"));
        const dateToUTC = timezoneOffSetAdd(date, '23:59:59', sessionStorage.getItem("driverTimezoneOffset"));
        dispatch(fetchEvents({
            clientNumber: authSelector.user.authUser.clientNumber,
            userId: authSelector.user.authUser.username,
            fromDate: dateFromUTC,
            toDate: dateToUTC,
            deviceId,
        }))
        dispatch(fetchServiceEvents({
            clientNumber: authSelector.user.authUser.clientNumber,
            userId: authSelector.user.authUser.username,
            fromDate: dateFromUTC,
            toDate: dateToUTC,
            deviceId,
        }));
    }

    const onFilterChange = (values) => {
        setSelectedEvents(values.value);
        var filteredValue = filterEventsFunc(initialSelectedEvents, values.value);
        values.value ? dispatch(removeEventsFromDisplay(filteredValue)) : dispatch(removeEventsFromDisplay(false))
    }


    return (
        <div>
            <div className={styles.row}>
                <Heading component="p" size={'xs'} color={'petrolBlue'} className={styles.heading} gutter>{getLabel('events', 'Events', prismicData)}</Heading>
                <div className={styles.right}>
                    <div className={styles.calendarIcon} onClick={() => openCalendar()}>
                        {moment(selectedTripDate).format("DD")}
                    </div>
                    <CalendarTodayIcon
                        htmlColor={"#4a4a4a"}
                        style={{ cursor: "pointer" }}
                    />
                    <Popup
                        trigger={<img src={FilterIcon} alt="Filter Icon" className={styles.iconFilter} />}
                        position="left top"
                        {...{ contentStyle, overlayStyle, arrowStyle }}>
                        <PopupFilterComponent
                            heading={getLabel("event_types", "Event Types", prismicData)}
                            data={eventFilters}
                            onFilterChange={onFilterChange}
                            values={selectedEvents}
                        />
                    </Popup>

                    <RefreshIcon htmlColor={'#004a5d'} onClick={onRefresh} />
                </div>
            </div>
            {
                (driverEventsSelector.isLoading || (driverNewEventsSelector && driverNewEventsSelector.isLoading)) ? (
                    <div className={styles.lpLoader}>
                        <LPLoader
                            loading={true}
                            message={"Loading Data..."}
                        />
                    </div>
                ) : (
                    <>
                        {
                            displayedEvents?.length > 0 ? displayedEvents.map((row, index) => {
                                return (
                                    <Row
                                        key={index}
                                        data={row}
                                        id={index}
                                        selectActiveEvent={selectActiveEvent}
                                        activeEvent={activeEvent}
                                    />
                                )
                            }) : (
                                <InformationTab icon={'info'} iconSize={'l'} headerText={getLabel('no_information_available', 'No Information Available', prismicData)} text={getLabel('events_data_not_available', 'Events are not available for this date', prismicData)} />
                            )
                        }
                    </>
                )
            }
        </div>
    )
}

export default Events;