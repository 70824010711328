import React, {useState, useEffect, useRef, useCallback} from 'react';
import {Marker, useMap, Tooltip, Popup, FeatureGroup} from 'react-leaflet';
import { Icon} from 'leaflet';
import shop from '../../Asset/MapIcons/shop.png'
import oval from '../../Asset/MapIcons/Oval.png'
import NationalShopPopup from './NationShopPopup/NationalShopPopup';
import ResponsivePopup from './ResponsivePopup/ResponsivePopup';
import {Button } from '@leaseplan/ui';
import MarkersPOI from './MarkersPOI/MarkersPOI';
import authService from '../../Services/Auth/authService';


/**
 * Popup could have been included inside the Marker
 * and it would have worked a similar way but storing 
 * it in a state and rendering only one popup drastically 
 * increases the performance of the application
 */


const StaticMarker = (props) => {
    const [popupData, setPopupData] = useState(null);
    const map = useMap();
    const clientNumber = authService.getClientNumber();


    const onMarkerClick = useCallback((e, data) => {
      setPopupData(data);
    }, [])

    const onPopupClose= () => {
        setPopupData(null)
    }
    
    const sendToDriver = () => {
        console.log(map.closePopup());
    }

    return (
        <>
            <MarkersPOI onMarkerClick={onMarkerClick} clientNumber={clientNumber}/> 
            {
                popupData && (
                        <ResponsivePopup popupData={popupData} onClose={onPopupClose}>
                            <NationalShopPopup map={map} data={popupData} onClick={sendToDriver} clientNumber={clientNumber}/>
                        </ResponsivePopup>
                    
                )
            }
        </>
    )
}

StaticMarker.propTypes = {

}

export default StaticMarker
