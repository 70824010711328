import CONSTANTS from '../Constants/tripAdmin';
import { F, S } from "../utils/actions";

const initialState = {
    trips: {
        data: [],
        resultSetCount: null,
        pageLimit: null,
        currentPage: null,
        isLoading: false,
        error: null
    },
    driverSearch: {
        data: {},
        isLoading: false,
        error: null
    },
    drivers: {
        data: {},
        isLoading: false,
        error: null
    },
    updateProjectCode: {
        isUpdated: false,
        isLoading: false,
        error: null
    }
};

const tripAdmin = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case CONSTANTS.FETCH_TRIP_ADMIN:
        return {
            ...state,
            trips: {
                ...state.trips,
                data: [],
                isLoading: true,
                error: null
            }
            
        };
    case S(CONSTANTS.FETCH_TRIP_ADMIN):
        return {
            ...state,
            trips: {
                data: action.data,
                resultSetCount: action.resultSetCount,
                pageLimit: action.pageLimit,
                currentPage: action.currentPage,
                isLoading: false,
                error: null
            }
        };
    case F(CONSTANTS.FETCH_TRIP_ADMIN):
        return {
            ...state,
            trips: {
                data: [],
                resultSetCount: null,
                pageLimit: null,
                currentPage: null,
                isLoading: false,
                error: action.payload
            }
        };

        case CONSTANTS.FETCH_DRIVER_SEARCH:
            return {
                ...state,
                driverSearch: {
                    ...state.trips,
                    data: [],
                    isLoading: true,
                    error: null
                }
                
            };
        case S(CONSTANTS.FETCH_DRIVER_SEARCH):
            return {
                ...state,
                driverSearch: {
                    data: action.data,
                    // isLoading: false,
                    error: null
                }
            };
        case F(CONSTANTS.FETCH_DRIVER_SEARCH):
            return {
                ...state,
                driverSearch: {
                    data: [],
                    resultSetCount: null,
                    pageLimit: null,
                    currentPage: null,
                    isLoading: false,
                    error: action.payload
                }
            };


    case CONSTANTS.FETCH_ELIGIBLE_DRIVERS:
        return {
            ...state,
            drivers: {
                data: [],
                isLoading: true,
                error: null
            }
            
        };
    case S(CONSTANTS.FETCH_ELIGIBLE_DRIVERS):
        return {
            ...state,
            drivers: {
                data: action.payload,
                isLoading: false,
                error: null
            }
        };
    case F(CONSTANTS.FETCH_ELIGIBLE_DRIVERS):
        return {
            ...state,
            drivers: {
                data: [],
                isLoading: false,
                error: action.payload
            }
        };
    case CONSTANTS.UPDATE_PROJECT_CODE:
        return {
            ...state,
            updateProjectCode: {
                isUpdated: false,
                isLoading: true,
                error: null
            }
            
        };
    case S(CONSTANTS.UPDATE_PROJECT_CODE):
        return {
            ...state,
            updateProjectCode: {
                isUpdated: true,
                isLoading: false,
                error: null
            }
        };
    case F(CONSTANTS.UPDATE_PROJECT_CODE):
        return {
            ...state,
            updateProjectCode: {
                isUpdated: false,
                isLoading: false,
                error: action.payload
            }
        };
    case CONSTANTS.RESET_UPDATE_STATUS_PROJECT_CODE: 
    return {
        ...state,
        updateProjectCode: {
            ...state.updateProjectCode,
            isUpdated: false
        }
    };
    default:
        return state;
  }
};

export default tripAdmin;