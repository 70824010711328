import React, { useState, useEffect } from 'react';
import styles from './DriverDetails.module.scss';
import {
    Text,
    Heading,
    Grid,
    GridItem,
    ChevronLeftIcon,
    ChevronRightIcon
} from "@leaseplan/ui";
import CircularStatic from '../../../Common/CircularProgressWithLabel/CircularProgressWithLabel';
import List from '../InsightsLink/List';
import VehicleInfo from '../VehicleInfo/VehicleInfo';
import Trips from '../Trips/Trips';
import Courses from '../Courses/Courses';
import Events from '../Events/Events';
import OneScore from '../OneScore/OneScore';

import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import CarIconSmall from '../../../Asset/Images/car.png';
import CarIconBig from '../../../Asset/Images/vehicle.png';
import MissingCarIcon from '../../../Asset/Images/carMissing.svg';
import WifiIcon from '@material-ui/icons/Wifi';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { DRIVER_INSIGHTS_LINKS } from '../../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDeviceLastLocation, fetchDriverDetails, fetchLogRecordTrips, fetchSingleDeviceLastLocation, insertInitalDriverDetails, insertInitialFromTime, resetDriverDetails } from '../../../Actions/driver.action';
import { getLabel } from '../../../utils';
import InformationTab from '../InformationTab/InformationTab';
import moment from 'moment';

import { getTimeGapInDays } from '../../../utils';
import DatePicker from '../../../Common/DatePicker/DatePicker';
import { generatePath, useNavigate } from "react-router-dom";
import { RoutePath } from '../../../Routers/Routes';
import { addToast } from '../../../Actions/toasts';
import directionCardinalIcon from '../../../Asset/MapIcons/compassRoseIcon.svg';
import { useParams } from 'react-router-dom/dist';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    'MuiAccordionSummary-content': {
        marginTop: 5,
        marginBottom: 5
    }
}));

const DriverDetails = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {date, driverId} =useParams();
    const classes = useStyles();
    const { prismicData } = props;
    const [deviceId, setDeviceId] = useState();
    const [driverDetails, setDriverDetails] = useState({});
    const [selectedTripDate, setSelectedTripDate] = useState(new Date());
    const [isOpen, setIsOpen] = useState(false);

    const [isVehicleAssigned, setIsVehicleAssigned] = useState(true);
    const [selectedListItem, setSelectedListItem] = useState(sessionStorage.getItem("tabUrl") ? sessionStorage.getItem("tabUrl") : "trips");
    const [isCollapsed, setIsCollapsed] = useState(false);

    const driverDetailsSelector = useSelector(state => state.driver.details);

    const initalSelectedDriverData = useSelector((state) => state.driver.initialDriverDetails);
    // const isToggleRoutingSelector = useSelector((state) => state.driver.isToggleRouting);

    const [activeTrip, setActiveTrip] = useState(null);
    const [activeEvent, setActiveEvent] = useState(null);

    const calendarDate = sessionStorage.getItem("selectedDate") || date || new Date()
    useEffect(() => {
        setSelectedTripDate(calendarDate)
        const deviceId = driverId|| null;
        if (!deviceId) {
            props.history.goBack();
            return
        }
        setDeviceId(deviceId);
        dispatch(fetchDriverDetails({ deviceId: deviceId }));

        //set the url for hardrefreshing functionality #444
        sessionStorage.setItem("urlEndPoint", window.location.pathname)
        dispatch(fetchDeviceLastLocation([false]));
        let selectedDate = moment(calendarDate).format('YYYY-MM-DD')
        dispatch(fetchSingleDeviceLastLocation([deviceId, selectedDate ? selectedDate : calendarDate]));
        dispatch(fetchLogRecordTrips(false));
    }, [dispatch, driverId, props.history]);

    useEffect(() => {
        const { data, isLoading } = driverDetailsSelector;
        if (!isLoading && data) {
            setDriverDetails(data);
        }
    }, [dispatch, driverDetailsSelector]);


    useEffect(() => {
        const deviceId = driverId|| null;
        let date = moment(selectedTripDate).format('YYYY-MM-DD');
        setActiveTrip(null);
        setActiveEvent(null);
        dispatch(insertInitalDriverDetails({ deviceId: deviceId, selectedDate: date }));
        return () => {
            dispatch(insertInitalDriverDetails({ deviceId: null, selectedDate: null }))
        }
    }, [selectedTripDate, initalSelectedDriverData.getTrips])

    useEffect(() => {
        return () => {
            dispatch(resetDriverDetails())
        }
    }, []);



    const getGarageAddress = () => {
        const address = [];
        // if(driverDetails.garageAddress){
        //     address.push(driverDetails.garageAddress)
        // }
        if (driverDetails.garageCity) {
            address.push(driverDetails.garageCity)
        }
        if (driverDetails.garageState) {
            address.push(driverDetails.garageState)
        }
        if (driverDetails.garageZip) {
            address.push(driverDetails.garageZip)
        }

        return address.join(', ')
    }

    const selectActiveTrip = (selectedId) => {
        setActiveEvent(null);
        setActiveTrip(selectedId);
    }

    const selectActiveEvent = (selectedId) => {
        setActiveEvent(selectedId);
    }

    const openCalendar = () => {
        setIsOpen(!isOpen);
    }


    const vehicleInsightsRoute = () => {
        // dispatch(isToggleRouting(true))
        if (deviceId && driverDetails && driverDetails.vin) {
            sessionStorage.setItem("tabUrl", 'vehicle_maintenance')
            navigate({
                pathname: generatePath(RoutePath.VehicleDetails, { vin: driverDetails.vin, driverId: deviceId, date: sessionStorage.getItem("selectedDate") ? sessionStorage.getItem("selectedDate") : initalSelectedDriverData.selectedDate })
            })
        }
    }

    const getSelectedComponent = () => {
        switch (selectedListItem) {
            case 'trips':
                return <Trips prismicData={prismicData} driverDetails={driverDetails} selectedTripDate={selectedTripDate} selectActiveTrip={selectActiveTrip} activeTrip={activeTrip} openCalendar={openCalendar} />
            case 'courses':
                return <Courses prismicData={prismicData} driverDetails={driverDetails} />
            case 'vehicle_insights':
                return vehicleInsightsRoute();
            case 'communication_log':
                //TODO:Replace when API is available
                // return <CommunicationLog prismicData={prismicData} driverDetails={driverDetails}/>
                return <InformationTab componentName={'Message Log'} />
            case 'events':
                return <Events prismicData={prismicData} deviceId={deviceId} selectedTripDate={selectedTripDate} selectActiveEvent={selectActiveEvent} activeEvent={activeEvent} openCalendar={openCalendar}/>
            case 'onescore':
                return <OneScore prismicData={prismicData} driverDetails={driverDetails} />
            default:
                return <InformationTab componentName={''} />
        }
    }

    const getOBDIconColor = (lastCommunicationTime) => {
        let days = (Number(lastCommunicationTime) || 0) / 1440; //converting minutes in days
        if (days >= 0 && days <= 2) {
            return '#7ccc5a'; //green
        } else if (days > 2 && days <= 5) {
            return '#FDD835'; // yellow
        } else if (days > 5) {
            return 'red'
        } else {
            return '#7ccc5a'; // default green
        }

    }

    const handleDateChange = (date) => {
        let currentDateTimestamp = new Date()
        const offset = currentDateTimestamp.getTimezoneOffset()
        currentDateTimestamp = new Date(new Date().getTime() - (offset * 60 * 1000))
        let selectedDateTimestamp = new Date(new Date(date).getTime() - (offset * 60 * 1000))
        const selectedDate = selectedDateTimestamp.toISOString().split('T')[0];
        const currentDate = currentDateTimestamp.toISOString().split('T')[0];
        if (selectedDate > currentDate) {
            dispatch(addToast({
                title: "Future dates are not supported",
                type: "error",
            }));
            return;
        } else {
            setSelectedTripDate(selectedDate);
            sessionStorage.setItem("selectedDate", selectedDate)
            dispatch(fetchSingleDeviceLastLocation([deviceId, selectedDate]));
            dispatch(insertInitialFromTime(date));
        }
    };

    const onDateDialogClose = () => {
        setIsOpen(false);
    }

    const toggleSideBar = () => {
        setIsCollapsed(isCollapsed => !isCollapsed)
    }


    const onItemClick = (item) => {
        if (isCollapsed) {
            setIsCollapsed(isCollapsed => !isCollapsed)
        }
        sessionStorage.setItem("tabUrl", item)
        setSelectedListItem(item)
    }

    return (
        <div className={isCollapsed ? styles.mainWrapperCollapsed : styles.mainWrapper}>
            <div className={styles.driverDetails}>
                <Grid gutter={false} justifyContent="space-between">
                    <GridItem>
                        <Text component="p" size="xs" color="petrolBlue" gutter>{getLabel('driver_insight', 'Driver Insight', prismicData)} / {driverDetails.deviceName}</Text>
                    </GridItem>
                </Grid>
                <div className={styles.row}>
                    <Heading component="p" size="xs" color="petrolBlue" gutter>{driverDetails.deviceName}</Heading>
                    <CircularStatic color={driverDetails.scoreColor ? (driverDetails.scoreColor.toLowerCase() === 'yellow' ? '#F9C74B' : driverDetails.scoreColor.toLowerCase()) : 'red'} progress={parseInt(driverDetails.driverScore ? driverDetails.driverScore : "0")} />
                </div>

                {isVehicleAssigned && <div>
                    <VehicleInfo
                        vehicleIcon={driverDetails.vehicleImageName ? `https://imageonthefly.autodatadirect.com/images/?QUALITY=50&IMG=${driverDetails.vehicleImageName}&HEIGHT=800` : MissingCarIcon}
                        vehicleName={`${driverDetails.modelYear ?? ''} ${driverDetails.makeDescription ?? ''} ${driverDetails.baseModelName ?? ''}`}
                        plateNo={driverDetails.tagNumber ? driverDetails.tagNumber : ''}
                        unitNo={driverDetails.clientVehicleId ? driverDetails.clientVehicleId : ''}
                        vin={driverDetails.vin ?? '-'}
                        address={getGarageAddress()}
                    />
                    <div className={styles.obd}>
                        <div className={styles.left}>
                            <div className={styles.icon} style={{ backgroundColor: driverDetails.minutesSinceLastCommunicated === null ? 'grey' : getOBDIconColor(driverDetails.minutesSinceLastCommunicated) }}>
                                <WifiIcon htmlColor={'#FFF'} style={{ transform: 'rotate(90deg)', width: 15, height: 15 }} fontSize={"small"} />
                            </div>
                            <Text component="span" size="xxs" color="petrolBlue" gutter>{driverDetails.deviceType}</Text>
                        </div>
                        <Text fontWeight="bold" component="span" size="xxs" color="petrolBlue" gutter>{driverDetails.deviceStatus ? (driverDetails.deviceStatus).charAt(0).toUpperCase() + driverDetails.deviceStatus.slice(1) : '--'}</Text>
                        <Text component="span" size="xxs" color="petrolBlue" gutter>{driverDetails.minutesSinceLastCommunicated === null ? 'unknown' : getTimeGapInDays(driverDetails.minutesSinceLastCommunicated || 0)}</Text>
                    </div>
                </div>
                }

                {!isVehicleAssigned && <div className={styles.centeredDiv}>
                    <div className={styles.notAssignedVehicle}>
                        <img src={MissingCarIcon} className={styles.vehicleIcon} alt={'Vehicle icon'} />
                        <Heading component="p" size="xxs" color="petrolBlue" gutter>{getLabel('vehicle_not_assigned', 'Vehicle Not Assigned', prismicData)}</Heading>
                    </div>

                    <div className={styles.recentPoolVehicles}>
                        <Heading component="p" size="xxs" color="petrolBlue" gutter>{getLabel('recent_pool_vehicles', 'Recent Pool Vehicles', prismicData)}</Heading>
                        <div className={classes.root}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <div className={styles.accordianSummary}>
                                        <img src={CarIconSmall} className={styles.vehicleIcon} alt={'Vehicle icon'} />
                                        <Text component="p" size={'xs'} color={'petrolBlue'} gutter>2021 Ford Transit</Text>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <VehicleInfo
                                        vehicleIcon={driverDetails.vehicleImageName ? `https://imageonthefly.autodatadirect.com/images/?QUALITY=50&IMG=${driverDetails.vehicleImageName}&HEIGHT=800` : MissingCarIcon}
                                        vehicleName={'Jeep Grand Cherokee'}
                                        plateNo={'HGB-2321'}
                                        unitNo={'E3321'}
                                        vin={'123AS345JNSM13234'}
                                        address={'Alpharetta, GA 30022 - EST'}
                                    />
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <div className={styles.accordianSummary}>
                                        <img src={CarIconSmall} className={styles.vehicleIcon} alt={'Vehicle icon'} />
                                        <Text component="p" size={'xs'} color={'petrolBlue'} gutter>2021 Ford Transit</Text>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <VehicleInfo
                                        vehicleIcon={CarIconBig}
                                        vehicleName={'Jeep Grand Cherokee'}
                                        plateNo={'HGB-2321'}
                                        unitNo={'E3321'}
                                        vin={'123AS345JNSM13234'}
                                        address={'Alpharetta, GA 30022 - EST'}
                                    />
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <div className={styles.accordianSummary}>
                                        <img src={CarIconSmall} className={styles.vehicleIcon} alt={'Vehicle icon'} />
                                        <Text component="p" size={'xs'} color={'petrolBlue'} gutter>2021 Ford Transit</Text>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <VehicleInfo
                                        vehicleIcon={CarIconBig}
                                        vehicleName={'Jeep Grand Cherokee'}
                                        plateNo={'HGB-2321'}
                                        unitNo={'E3321'}
                                        vin={'123AS345JNSM13234'}
                                        address={'Alpharetta, GA 30022 - EST'}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </div>
                </div>}
                <div className={styles.list}>
                    <List data={DRIVER_INSIGHTS_LINKS} selectedListItem={selectedListItem} onClick={onItemClick} />
                </div>
            </div>
            {
                !isCollapsed && <div className={styles.tripDetails}>{getSelectedComponent()}</div>
            }
            <div className={styles.collapsedTrip} onClick={toggleSideBar}>
                {
                    isCollapsed ? (
                        <div className={styles.hiddenIcon}>
                            <ChevronRightIcon />
                        </div>
                    ) : (
                        <div className={styles.hiddenIcon}>
                            <ChevronLeftIcon />
                        </div>
                    )
                }
                {/* </Paper> */}
            </div>
            <div className={styles.emptySpace}></div>
            <div className={styles.hiddenDiv}>
                <DatePicker
                    isOpen={isOpen}
                    selectedDate={selectedTripDate}
                    handleDateChange={handleDateChange}
                    onClose={onDateDialogClose}
                />
            </div>
            <div className={styles.dateDetail}>
            <Text component="span" size="s" color="petrolBlue" gutter>{moment(selectedTripDate).format('MMM DD, YYYY')}</Text>
            </div>
            <div className={styles.cardinalIcon}>
                {<img src={directionCardinalIcon} alt="cardinal Icon"  />}
            </div>
        </div>
    );
}

export default React.memo(DriverDetails);