import "@leaseplan/ui/fonts/LeasePlan.css";
import { Predicates } from "prismic-javascript";
import React, { useContext, useEffect, useState } from "react";
import "react-perfect-scrollbar/dist/css/styles.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Error from "../../Common/ErrorPage/ErrorPage";
import Toasts from "../../Common/Toasts/Toasts";
import { client } from "../../prismic-configuration";
import { AuthContext } from "../../Providers/authProvider";
import { routes } from "../../Routers/Routes";
import HeaderWrapper1 from "../HeaderWrapper/HeaderWrapper1";
import { PrivateRoute } from "../PrivateRoute/privateRoute";
import "./App.css";
import { SocketContext, socket } from "../../Context/Sockets";
import MapLeaflet from "../Map/MapLeaflet";

const RootWrapper = ({ isLoggedIn, children }) => (
	<div className={isLoggedIn ? "marginTopLoggedIn" : "main"}>{children}</div>
);

const AccessDeniedError = () => (
	<Error
		title="Oops, no access…"
		errorCodeText="For the record, this is error code 403"
		icon="lock"
		iconSize="s"
		description="Looks like you dont have permission to view this page."
	/>
);

const App = (props) => {
  const [prismicObj, setPrismicObj] = useState({ homeDoc: null });
  const authContext = useContext(AuthContext);

  useEffect(() => {
    const fetchPrismicData = async () => {
      try {
        const homeDoc = await client.query(
          Predicates.at("document.type", "_cnv_oneconn")
        );
        if (homeDoc) {
          if (homeDoc.results.length > 0) {
            setPrismicObj({
              prismicResponse: homeDoc,
              prismicValues: homeDoc.results[0].data,
            });
          } else {
            console.warn(
              "Document was not found. Make sure it exists in your Prismic repository"
            );
          }
        } else {
          console.warn(
            "Document was not found. Make sure it exists in your Prismic repository"
          );
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchPrismicData();
  }, []);

  return (
    <div className={authContext.isAuthenticated ? "Container__Map" : ""}>
      <Router>

        <SocketContext.Provider value={socket}>
          {authContext.isAuthenticated && <MapLeaflet />}
          <div className="Container_Relative">

            <div className="Container_Content">

              {authContext.isAuthenticated && (
                <div className="Container__Navbar">

                  <HeaderWrapper1
                    routes={routes}
                    prismicData={prismicObj.prismicValues || null}
                  />

                </div>
              )}

              <div className="Container__Routes">

                <RootWrapper isLoggedIn={false}>

                  <Routes>

                    {routes.map(
                      ({
                        path,
                        component: NewComp,
                        isPrivate,
                        exact = true,
                      }) => (
                        <Route
                          key={path}
                          path={path}
                          exact={exact}
                          element={
                            <PrivateRoute
                              prismicData={prismicObj.prismicValues || null}
                              path={path}
                              component={NewComp}
                              isPrivate={isPrivate}
                              error={
                                AccessDeniedError
                              }
                            />
                          }
                        />
                      )
                    )}

                  </Routes>

                </RootWrapper>

              </div>
              <Toasts />
            </div>

          </div>

        </SocketContext.Provider>
      </Router>
    </div>
  );
};

export default App;
