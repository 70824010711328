import { S, F } from "../utils/actions";
import { REPORTING_PAGE } from "../Constants/reporting";

const INITIAL_STATE = {
  reportingSideBarData: {
    data: [],
    isLoading: false,
    error: null,
  },
  reportsData: {
    data: [],
    isLoading: false,
    isSaving: false,
    isDeleting: false,
    error: null,
  },

};

const reporting = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case REPORTING_PAGE.FETCH_REPORTING_SIDE_BAR_INFO:
      return {
        ...state,
        reportingSideBarData: {
          ...state.reportingSideBarData,
          isLoading: true,
          error: null,
        }
      };
    case S(REPORTING_PAGE.FETCH_REPORTING_SIDE_BAR_INFO):
      return {
        ...state,
        reportingSideBarData: {
          data: payload,
          isLoading: false,
          error: null,
        }
      };
    case F(REPORTING_PAGE.FETCH_REPORTING_SIDE_BAR_INFO):
      return {
        ...state,
        reportingSideBarData: {
          ...state.reportingSideBarData,
          isLoading: false,
          error: payload,
        }
      };
    case REPORTING_PAGE.FETCH_REPORTS:
      return {
        ...state,
        reportsData: {
          ...state.reportsData,
          isLoading: true,
          error: null,
        }
      }
    case S(REPORTING_PAGE.FETCH_REPORTS):
      return {
        ...state,
        reportsData: {
          ...state.reportsData,
          data: payload,
          isLoading: false,
        }
      }
    case F(REPORTING_PAGE.FETCH_REPORTS):
      return {
        ...state,
        reportsData: {
          ...state.reportsData,
          isLoading: false,
          error: payload
        }
      }
    case REPORTING_PAGE.FETCH_REPORTS_IDS:
      return {
        ...state,
        reportsDataIds: {
          isLoading: true,
          error: null,
        }
      }
    case S(REPORTING_PAGE.FETCH_REPORTS_IDS):
      return {
        ...state,
        reportsDataIds: {
          data: payload,
          isLoading: false,
        }
      }
    case F(REPORTING_PAGE.FETCH_REPORTS_IDS):
      return {
        ...state,
        reportsDataIds: {
          isLoading: false,
          error: payload
        }
      }
    case REPORTING_PAGE.DELETE_REPORT:
      return {
        ...state,
        reportsData: {
          ...state.reportsData,
          isDeleting: true,
          error: null,
        }
      }
    case S(REPORTING_PAGE.DELETE_REPORT):
      return {
        ...state,
        reportsData: {
          data: state.reportsData.data.filter((data) => data.graphId !== payload),
          isDeleting: false,
          error: null
        }
      }
    case F(REPORTING_PAGE.DELETE_REPORT):
      return {
        ...state,
        reportsData: {
          ...state.reportsData,
          isDeleting: false,
          error: payload
        }
      }
    case REPORTING_PAGE.SAVE_REPORT:
      return {
        ...state,
        reportsData: {
          ...state.reportsData,
          isSaving: true,
          savingError: null,
        }
      }
    case S(REPORTING_PAGE.SAVE_REPORT):
      return {
        ...state,
        reportsData: {
          data: [...state.reportsData.data, payload],
          isSaving: false,
          error: null,
        }
      }
    case F(REPORTING_PAGE.SAVE_REPORT):
      return {
        ...state,
        reportsData: {
          ...state.reportsData,
          isSaving: false,
          error: payload,
        }
      }
    case REPORTING_PAGE.DOWNLOAD_REPORT:
      return {
        ...state,
        downloadData: {
          isSaving: true,
          savingError: null,
        }
      }
    case S(REPORTING_PAGE.DOWNLOAD_REPORT):
      return {
        ...state,
        downloadData: {
          data: [payload],
          isSaving: false,
          error: null,
        }
      }
    case F(REPORTING_PAGE.DOWNLOAD_REPORT):
      return {
        ...state,
        downloadData: {
          data: "download call got failed",
          isSaving: false,
          error: payload,
        }
      }



    case REPORTING_PAGE.FETCH_SINGLE_REPORTS_IDS:
      return {
        ...state,
        reportsData: {
          ...state.reportsData,
          isSaving: true,
          isLoading:true,
          savingError: null,
        }
      }
    case S(REPORTING_PAGE.FETCH_SINGLE_REPORTS_IDS):
      return {
        ...state,
        reportsData: {
          data: payload,
          isLoading:false,
          isSaving: false,
          error: null,
        }
      }
    case F(REPORTING_PAGE.FETCH_SINGLE_REPORTS_IDS):
      return {
        ...state,
        reportsData: {
          ...state.reportsData,
          isLoading:false,
          isSaving: false,
          error: payload,
        }
      }


    case REPORTING_PAGE.CHECK_DOWNLOAD_REPORT_STATUS:
      return {
        ...state,
        checkDownloadReportStatus: {
          data: null,
          isSaving: true,
          savingError: null,
        }
      }
    case S(REPORTING_PAGE.CHECK_DOWNLOAD_REPORT_STATUS):
      return {
        ...state,
        checkDownloadReportStatus: {
          data: [payload],
          isSaving: false,
          error: null,
        }
      }
    case F(REPORTING_PAGE.CHECK_DOWNLOAD_REPORT_STATUS):
      return {
        ...state,
        checkDownloadReportStatus: {
          data: [],
          isSaving: false,
          error: payload,
        }
      }

    case REPORTING_PAGE.ADD_FAVOURITE:
      return {
        ...state,
      }
    case S(REPORTING_PAGE.ADD_FAVOURITE):
      return {
        ...state,
        reportsData: {
          ...state.reportsData,
          data: state.reportsData.data.map((data) =>
            data.graphId === payload ? { ...data, isFavourite: data.isFavourite ? 0 : 1 } : data
          ),
        }
      }
    case F(REPORTING_PAGE.ADD_FAVOURITE):
      return {
        ...state,
      }
    default:
      return state;
  }
}

export default reporting;