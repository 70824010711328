import _ from "lodash";

export const ObjectUtils = {
  // return deepclone of the object passed
  clone: (obj) => _.cloneDeep(obj),

  //check if object is null
  isNull: (obj) => _.isNull(obj),

  //check if object is not null or undefined (to check user.level1.level2 != null && user.level1.level2!=undefined)
  isObject: (obj) => _.isObject(obj),

  //Checks if value is an empty object, collection, map, or set.
  isEmpty: (obj) => _.isEmpty(obj),

  //Checks if two objects are equal or not.
  isEqual: (obj1,obj2) => _.isEqual(obj1,obj2),
  
   //Checks if value objects are not empty
   isNotEmpty: (obj) => !_.isNull(obj) && !_.isEmpty(obj),

   //check if the obj is an array and has size less than 0
   isArrayEmpty:(obj) => _.isArray(obj) && obj.length <= 0,
};

export const StringUtils = {
  //Converts string to camel case.
  camelCase: (str) => _.camelCase(str),

  // Converts the first character of string to upper case and the remaining to lower case.
  capitalize: (str) => _.capitalize(str),

  //Escapes the RegExp special characters "^", "$", "", ".", "*", "+", "?", "(", ")", "[", "]", "{", "}", and "|" in string
  escapeSpecialChar: (str) => _.escapeRegExp(str),
};
