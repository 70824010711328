import React, { useState, useEffect, useRef } from 'react';
import styles from './BottomSlider.module.scss';
// import HorizontalSlider from '../../../Common/Slider/Slider';
import Popup from 'reactjs-popup';
import { getLabel, getTime, getDateInFormat, getValueByTime, getCurrentTimeInEST, validateUsZipCode } from '../../../utils';
// import PopupFilterComponent from '../../../Common/PopupFilter/PopupFilter';
import useInterval from '../../../Common/CustomHook/useInterval';
import { useSelector, useDispatch } from 'react-redux';
// import MenuItem from '@material-ui/core/MenuItem';
// import Select from '@material-ui/core/Select';
import LevelFilters from '../LevelFilters/LevelFilters';

// import DatePicker from '../../../Common/DatePicker/DatePicker';

//Icons
// import { IconButton, InputBase, SvgIcon } from '@material-ui/core';
// import PlayArrowIcon from '@material-ui/icons/PlayArrow';
// import PauseIcon from '@material-ui/icons/Pause';
import LayersIcon from '@material-ui/icons/Layers';
// import SettingsIcon from '@material-ui/icons/Settings';
// import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { Tooltip } from '@material-ui/core';
import moment from 'moment-timezone';
import trip_select_icon from "../../../Asset/Images/trip_select_icon.png";
// import location_icon from "../../../Asset/Images/locationIcon.png";
// import AddLocationConfig from '../LocationConfig/AddLocationConfig';

//Actions
import { fetchFilters, putPoiFiltersSession } from '../../../Actions/filters.action';
import { insertTimelineClockData, playTimeLine, insertDate } from '../../../Actions/timeline.actions';
import { insertMapStyle } from '../../../Actions/mapFilters.action';
import { setDrivers, putDriverLevels } from '../../../Actions/level.action';
// import { SPEED_CONSTANTS } from '../../../utils/constants';
import TripFilters from '../LevelFilters/TripFilters';
import { Grid, Heading, Spacing, Text } from '@leaseplan/ui';
import { saveClientInfo } from '../../../Actions/clientConfig.action';
import ToggleFilterComponent from '../../../Common/PopupFilter/ToggleFilter';

{/*
  # Need to optimize the code and also have to remove the unwanted code.
*/}


const contentStyle = {
    background: '#FFF',
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
    borderRadius: '5px',
    marginBottom: 10,
    width: 300
};
const overlayStyle = {};
const arrowStyle = { color: '#FFF' }; // style for an svg element

const LAYOUTS = [
    {
        label: 'Fleet Overview',
        value: 'fleet_overview',
    },
    {
        label: 'Latest Events',
        value: 'latest_events',
    }
]

const todaysDate = moment().tz('America/New_York').format('YYYY-MM-DD');

const generateRequestPayload = (
    selectedLevels = {},
) => {
    return {
        level1: selectedLevels["1"] ? selectedLevels["1"].map(row => row.value) : [],
        level2: selectedLevels["2"] ? selectedLevels["2"].map(row => row.value) : [],
        level3: selectedLevels["3"] ? selectedLevels["3"].map(row => row.value) : [],
        level4: selectedLevels["4"] ? selectedLevels["4"].map(row => row.value) : [],
        level5: selectedLevels["5"] ? selectedLevels["5"].map(row => row.value) : [],
        level6: selectedLevels["6"] ? selectedLevels["6"].map(row => row.value) : [],
        level7: selectedLevels["7"] ? selectedLevels["7"].map(row => row.value) : [],
        level8: selectedLevels["8"] ? selectedLevels["8"].map(row => row.value) : [],
        level9: selectedLevels["9"] ? selectedLevels["9"].map(row => row.value) : [],
        level10: selectedLevels["10"] ? selectedLevels["10"].map(row => row.value) : []
    };
};

const BottomSlider = (props) => {
    const popupRef = useRef();
    const { prismicData, hideLeftSection, hideRightSection } = props;
    const [date, setDate] = useState(todaysDate);
    const [movePopUp, setMovePopUp] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date()); //Date Object
    const [value, setValue] = useState([0, 20]);
    const [levelFiltersData, setLevelFiltersData] = useState({});
    const [isOpen, setIsOpen] = useState(false);
    const [sectionValues, setSectionValues] = useState([LAYOUTS[0].value, LAYOUTS[1].value]);
    const [selectedSpeed, setSelectedSpeed] = useState(1);
    const [selectedLevelFilters, setSelectedLevelFilter] = useState({});
    const [isSideDrawerOpen, setIsSideDrawerOpen] = useState(false);
    const [isTripSideDrawerOpen, setIsTripSideDrawerOpen] = useState(false);
    const [clientPoiFilters, setClientPoiFilters] = useState([]);
    const [poiFilters, setPoiFilters] = useState([]);
    const [addLocSideDrawerOpen, setAddLocSideDrawerOpen] = useState(false);
    const [zipMsg, setZipMsg] = useState(true);
    const [zipCode, setZipCode] = useState('');
    const [initalZipcode, setInitalZipcode] = useState('');
    const [showSaveButton, setShowSaveButton] = useState(false);
    const [isOpenPopup, setIsOpenPopup] = useState(true)
    const [openModal, setOpenModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    const levelFilteredDrivers = useSelector(state => state.levels);
    const [buttonColor, setButtonColor] = useState("street");

    const clientHQInfoSelector = useSelector(state => state.clientConfig);
    const filtersSelector = useSelector(state => state.filters.search);
    const filtersSelectordefaultValue = useSelector(state => state.filters.defaultConfig);
    const poiFiltersSelector = useSelector(state => state.filters.poi);
    const timelineSelector = useSelector(state => state.timeline);
    const driverDataViaLevelSelectors = useSelector(state => state.levels.data);
    const authSelector = useSelector(state => state.auth.user.clientUserDefaultDto);

    const [pannelValue, setPannelValue] = useState(LAYOUTS);
    const [clientPoiValue, setClientPoiValue] = useState([]);
    let updatedArray = []
    const dispatch = useDispatch();
    const ref = useRef()

    let dayDate = timelineSelector.clockData.dayDate

    const changeColor = (btn) => {
        setButtonColor(btn)
        sessionStorage.setItem("mapStyleSession", btn)
        dispatch(insertMapStyle(btn))
    };




    useEffect(() => {
        const setPannelDefaultValue = (index) => {
            let objectFleet
            let objectEvents
            if (LAYOUTS[index].value == "fleet_overview") {
                if (sessionStorage.getItem("fleet_overview") == "true" || sessionStorage.getItem("fleet_overview") == "false") {
                    //Update object's name property.
                    objectFleet = sessionStorage.getItem("fleet_overview") == "true" ? { ...LAYOUTS[index], checked: "true" } : { ...LAYOUTS[index], checked: "" }
                } else {
                    objectFleet = filtersSelectordefaultValue.data.showFleetOverview == 1 ? { ...LAYOUTS[index], checked: "true" } : { ...LAYOUTS[index], checked: "" }
                }
                return objectFleet
            }
            if (LAYOUTS[index].value == "latest_events") {
                if (sessionStorage.getItem("latest_events") == "true" || sessionStorage.getItem("latest_events") == "false") {
                    //Update object's name property.
                    objectEvents = sessionStorage.getItem("latest_events") == "true" ? { ...LAYOUTS[index], checked: "true" } : { ...LAYOUTS[index], checked: "" }
                } else {
                    objectEvents = filtersSelectordefaultValue.data.showLatestEvents == 1 ? { ...LAYOUTS[index], checked: "true" } : { ...LAYOUTS[index], checked: "" }
                }
                return objectEvents

            }

            // console.log(updatedObjectFleet)
            // console.log(updatedObjectEvents)
        }

        const setPOIDefaultValue = (index) => {
            let objectNAS
            let objectEVCS
            let objectFS
            if (clientPoiFilters[index].filterType == "ALL_NAS") {
                // if (sessionStorage.getItem("ALL_NAS") == "false" || sessionStorage.getItem("ALL_NAS") == "true") {
                //     //Update object's name property.
                //     objectNAS = sessionStorage.getItem("ALL_NAS") == "true" ? { ...clientPoiFilters[index], checked: "true" } : { ...clientPoiFilters[index], checked: "" }
                // } else {
                objectNAS = filtersSelectordefaultValue.data.showNationalAccountShops == 1 ? { ...clientPoiFilters[index], checked: "true" } : { ...clientPoiFilters[index], checked: "" }
                // }
                return objectNAS
            }
            if (clientPoiFilters[index].filterType == "ALL_EVCS") {
                // if (sessionStorage.getItem("ALL_EVCS") == "false" || sessionStorage.getItem("ALL_EVCS") == "true") {
                //     //Update object's name property.
                //     objectEVCS = sessionStorage.getItem("ALL_EVCS") == "true" ? { ...clientPoiFilters[index], checked: "true" } : { ...clientPoiFilters[index], checked: "" }
                // } else {
                objectEVCS = filtersSelectordefaultValue.data.showEvChargingStations == 1 ? { ...clientPoiFilters[index], checked: "true" } : { ...clientPoiFilters[index], checked: "" }
                // }
                return objectEVCS

            }
            if (clientPoiFilters[index].filterType == "ALL_FS") {
                // if (sessionStorage.getItem("ALL_FS") == "false" || sessionStorage.getItem("ALL_FS") == "true") {
                //     //Update object's name property.
                //     objectFS = sessionStorage.getItem("ALL_FS") == "true" ? { ...clientPoiFilters[index], checked: "true" } : { ...clientPoiFilters[index], checked: "" }

                // } else {
                objectFS = filtersSelectordefaultValue.data.showFuelStations == 1 ? { ...clientPoiFilters[index], checked: "true" } : { ...clientPoiFilters[index], checked: "" }
                // }
                return objectFS

            }
        }
        let updatedObjectFleet1
        let updatedObjectEvents1
        if (filtersSelectordefaultValue && filtersSelectordefaultValue.data) {
            if (LAYOUTS[0].value == "fleet_overview" || LAYOUTS[1].value == "latest_events") {
                for (let index = 0; index < LAYOUTS.length; index++) {
                    if (LAYOUTS[index].value == "fleet_overview") {
                        updatedObjectFleet1 = setPannelDefaultValue(index)
                    } else {
                        updatedObjectEvents1 = setPannelDefaultValue(index)
                    }

                    updatedArray = [updatedObjectFleet1, updatedObjectEvents1]
                    setPannelValue(updatedArray)
                }
            }
            if (clientPoiFilters.length > 0) {

                let updatedObjectNAS
                let updatedObjectEVCS
                let updatedObjectFS
                for (let index = 0; index < clientPoiFilters.length; index++) {
                    if (clientPoiFilters[index].filterType == "ALL_NAS") {
                        updatedObjectNAS = setPOIDefaultValue(index)
                    } else if (clientPoiFilters[index].filterType == "ALL_EVCS") {
                        updatedObjectEVCS = setPOIDefaultValue(index)
                    } else if (clientPoiFilters[index].filterType == "ALL_FS") {
                        updatedObjectFS = setPOIDefaultValue(index)
                    }
                    updatedArray = [updatedObjectNAS, updatedObjectEVCS, updatedObjectFS]
                    setClientPoiValue(updatedArray)
                }
            }
        }
    }, [filtersSelectordefaultValue, clientPoiFilters])
    const getCurrentTimeValue = () => {
        let date = new Date();
        // let tzid = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let tzid = sessionStorage.getItem("Timezone");
        if (tzid == "") { tzid = 'America/New_York' }

        let dateAndTime = date.toLocaleString('en-US', { hour12: false, timeZone: tzid });
        dateAndTime = dateAndTime.split(',')
        let time = dateAndTime[1].substring(1);
        date = dateAndTime[0];

        let value = getValueByTime(time);
        let dayDate = `${moment(date).format('DD')}`;
        let fromTime = `${moment(date).format('YYYY-MM-DD')} ${getTime(0)}:00`;
        let toTime = `${moment(date).format('YYYY-MM-DD')} ${getTime(value)}:00`;
        dispatch(insertTimelineClockData({ dayDate, fromTime, toTime, event: 'mouseup', speed: selectedSpeed }));
        setValue([0, value]);
    }

    useEffect(() => {
        let pannelArray = []
        let poiDefaultValueArray = []
        let poiDefaultArray = []
        //clearing session when login for POIS
        sessionStorage.setItem("ALL_NAS", "")
        sessionStorage.setItem("ALL_EVCS", "")
        sessionStorage.setItem("ALL_FS", "")

        if (filtersSelectordefaultValue && filtersSelectordefaultValue.data) {
            setButtonColor(filtersSelectordefaultValue.data.mapStyle)
            if (filtersSelectordefaultValue.data.showFleetOverview == 0) {
                pannelArray.push('fleet_overview')
            }
            if (filtersSelectordefaultValue.data.showLatestEvents == 0) {
                pannelArray.push('latest_events')
            }
            sessionStorage.setItem("pannelArray", JSON.stringify(pannelArray))

            // if (sessionStorage.getItem("ALL_EVCS") == "true" || sessionStorage.getItem("ALL_FS") == "true" || sessionStorage.getItem("ALL_NAS") == "true" ||
            //     sessionStorage.getItem("ALL_EVCS") == "false" || sessionStorage.getItem("false") == "true" || sessionStorage.getItem("false") == "true") {
            //     var evcsSession = sessionStorage.getItem("ALL_EVCS") == "false" ? poiDefaultValueArray : poiDefaultValueArray.push('ALL_EVCS')
            //     var fsSession = sessionStorage.getItem("ALL_FS") == "false" ? poiDefaultValueArray : poiDefaultValueArray.push('ALL_FS')
            //     var nasSession = sessionStorage.getItem("ALL_NAS") == "false" ? poiDefaultValueArray : poiDefaultValueArray.push('ALL_NAS')
            // } else {
            if (filtersSelectordefaultValue.data.showEvChargingStations == 1) {
                poiDefaultValueArray.push('ALL_EVCS')
            } if (filtersSelectordefaultValue.data.showFuelStations == 1) {
                poiDefaultValueArray.push('ALL_FS')
            } if (filtersSelectordefaultValue.data.showNationalAccountShops == 1) {
                poiDefaultValueArray.push('ALL_NAS')
            }
            // }
            if (filtersSelectordefaultValue.data.showEvChargingStations == 1) {
                poiDefaultArray.push('ALL_EVCS')
            } if (filtersSelectordefaultValue.data.showFuelStations == 1) {
                poiDefaultArray.push('ALL_FS')
            } if (filtersSelectordefaultValue.data.showNationalAccountShops == 1) {
                poiDefaultArray.push('ALL_NAS')
            }
        } else if (authSelector) {
            if (authSelector.showFleetOverview == 0) {
                pannelArray.push('fleet_overview')
            }
            if (authSelector.showLatestEvents == 0) {
                pannelArray.push('latest_events')
            }
            sessionStorage.setItem("pannelArray", JSON.stringify(pannelArray))
            if (authSelector.showEvChargingStations == 1) {
                poiDefaultValueArray.push('ALL_EVCS')

            } if (authSelector.showFuelStations == 1) {
                poiDefaultValueArray.push('ALL_FS')
            } if (authSelector.showNationalAccountShops == 1) {
                poiDefaultValueArray.push('ALL_NAS')
            }
        }
        sessionStorage.setItem("POI", JSON.stringify(poiDefaultValueArray))
        sessionStorage.setItem("POIvalue", JSON.stringify(poiDefaultArray))
    }, [filtersSelectordefaultValue, authSelector])

    useEffect(() => {
        const drivers = [];

        driverDataViaLevelSelectors.deviceSearchDtoList != null && (driverDataViaLevelSelectors?.deviceSearchDtoList.forEach((item) => {
            drivers.push(item.deviceId);
        }));

        dispatch(setDrivers(drivers));
    }, [dispatch, driverDataViaLevelSelectors]);

    useEffect(() => {
        const { isLoading, data } = filtersSelector;

        if (!isLoading && data) {
            setLevelFiltersData(data['levelDescription']);
        }
    }, [filtersSelector]);

    // useEffect for default level filter tab
    useEffect(() => {
        let filtersSelectordefaultValueArrayLevel1 = []
        let filtersSelectordefaultValueArrayLevel2 = []
        let filtersSelectordefaultValueArrayLevel3 = []
        let filtersSelectordefaultValueArrayLevel4 = []
        let filtersSelectordefaultValueArrayLevel5 = []
        let filtersSelectordefaultValueArrayLevel6 = []
        let filtersSelectordefaultValueArrayLevel7 = []
        let filtersSelectordefaultValueArrayLevel8 = []
        let filtersSelectordefaultValueArrayLevel9 = []
        let filtersSelectordefaultValueArrayLevel10 = []
        if (filtersSelectordefaultValue && filtersSelectordefaultValue.data) {
            filtersSelectordefaultValueArrayLevel1 = filtersSelectordefaultValue.data.level1 ? filtersSelectordefaultValue.data.level1.split(',') : filtersSelectordefaultValue.data.level1
            filtersSelectordefaultValueArrayLevel2 = filtersSelectordefaultValue.data.level2 ? filtersSelectordefaultValue.data.level2.split(',') : filtersSelectordefaultValue.data.level2
            filtersSelectordefaultValueArrayLevel3 = filtersSelectordefaultValue.data.level3 ? filtersSelectordefaultValue.data.level3.split(',') : filtersSelectordefaultValue.data.level3
            filtersSelectordefaultValueArrayLevel4 = filtersSelectordefaultValue.data.level4 ? filtersSelectordefaultValue.data.level4.split(',') : filtersSelectordefaultValue.data.level4
            filtersSelectordefaultValueArrayLevel5 = filtersSelectordefaultValue.data.level5 ? filtersSelectordefaultValue.data.level5.split(',') : filtersSelectordefaultValue.data.level5
            filtersSelectordefaultValueArrayLevel6 = filtersSelectordefaultValue.data.level6 ? filtersSelectordefaultValue.data.level6.split(',') : filtersSelectordefaultValue.data.level6
            filtersSelectordefaultValueArrayLevel7 = filtersSelectordefaultValue.data.level7 ? filtersSelectordefaultValue.data.level7.split(',') : filtersSelectordefaultValue.data.level7
            filtersSelectordefaultValueArrayLevel8 = filtersSelectordefaultValue.data.level8 ? filtersSelectordefaultValue.data.level8.split(',') : filtersSelectordefaultValue.data.level8
            filtersSelectordefaultValueArrayLevel9 = filtersSelectordefaultValue.data.level9 ? filtersSelectordefaultValue.data.level9.split(',') : filtersSelectordefaultValue.data.level9
            filtersSelectordefaultValueArrayLevel10 = filtersSelectordefaultValue.data.level10 ? filtersSelectordefaultValue.data.level10.split(',') : filtersSelectordefaultValue.data.level10
        }
        var levelDescriptionDefaultvalue = [filtersSelectordefaultValueArrayLevel1, filtersSelectordefaultValueArrayLevel2, filtersSelectordefaultValueArrayLevel3, filtersSelectordefaultValueArrayLevel4, filtersSelectordefaultValueArrayLevel5,
            filtersSelectordefaultValueArrayLevel6, filtersSelectordefaultValueArrayLevel7, filtersSelectordefaultValueArrayLevel8, filtersSelectordefaultValueArrayLevel9, filtersSelectordefaultValueArrayLevel10]
        dispatch(putDriverLevels(levelDescriptionDefaultvalue));
        let tempData2 = {}
        if (levelFiltersData) {
            Object.keys(levelFiltersData).forEach((level) => {
                let filteredArray1 = [];
                levelFiltersData[level].forEach(filter => {
                    if (filter) {
                        switch (level) {
                            case "1":
                                if (filtersSelectordefaultValueArrayLevel1 && (filtersSelectordefaultValueArrayLevel1.includes(filter[0]) || filtersSelectordefaultValueArrayLevel1.includes(' ' + filter[0]))) {
                                    if (filter[0] != "") {
                                        filteredArray1.push({ value: filter[0], label: filter[1] });
                                    }
                                }
                                break;
                            case "2":
                                if (filtersSelectordefaultValueArrayLevel2 && (filtersSelectordefaultValueArrayLevel2.includes(filter[0]) || filtersSelectordefaultValueArrayLevel2.includes(' ' + filter[0]))) {
                                    if (filter[0] != "") {
                                        filteredArray1.push({ value: filter[0], label: filter[1] });
                                    }
                                }
                                break;
                            case "3":
                                if (filtersSelectordefaultValueArrayLevel3 && (filtersSelectordefaultValueArrayLevel3.includes(filter[0]) || filtersSelectordefaultValueArrayLevel3.includes(' ' + filter[0]))) {
                                    if (filter[0] != "") {
                                        filteredArray1.push({ value: filter[0], label: filter[1] });
                                    }
                                }
                                break;
                            case "4":
                                if (filtersSelectordefaultValueArrayLevel4 && (filtersSelectordefaultValueArrayLevel4.includes(filter[0]) || filtersSelectordefaultValueArrayLevel4.includes(' ' + filter[0]))) {
                                    if (filter[0] != "") {
                                        filteredArray1.push({ value: filter[0], label: filter[1] });
                                    }
                                }
                                break;

                            default:
                                break;
                        }
                    }
                })
                tempData2[level] = filteredArray1;
            });

            setSelectedLevelFilter(tempData2);
        }
    }, [levelFiltersData, filtersSelectordefaultValue])

    useEffect(() => {
        const { isLoading, data } = poiFiltersSelector;
        if (!isLoading && data) {
            setClientPoiFilters(data);
        }
    }, [poiFiltersSelector]);

    useEffect(() => {
        const filters = [];
        clientPoiFilters.forEach(item => {
            filters.push(item.filterType)
        });
        setPoiFilters(filters);
    }, [clientPoiFilters]);

    // useEffect(() => {
    //     poiFilters.length && dispatch(insertPOIFilters(poiFilters))
    // }, [dispatch, poiFilters]);

    useEffect(() => {
        getCurrentTimeValue();

        dispatch(insertDate(new Date()));
        // dispatch(putDriverLevels(generateRequestPayload(selectedLevelFilters)));
        setZipCode(clientHQInfoSelector.clientHQInfo.data ? clientHQInfoSelector.clientHQInfo.data.zip : '')
        setInitalZipcode(clientHQInfoSelector.clientHQInfo.data ? clientHQInfoSelector.clientHQInfo.data.zip : '')
    }, [dispatch])

    useEffect(() => {
        const checkIfClickedOutside = e => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (ref.current && !ref.current.contains(e.target)) {
                setZipCode(initalZipcode)
                setZipMsg(true)
                setIsOpenPopup(o => !o)
            }
        }

        document.addEventListener("mousedown", checkIfClickedOutside)

        return () => {
            // Cleanup the event listener

            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [initalZipcode])

    useEffect(() => {

        setZipCode(clientHQInfoSelector.clientHQInfo.data ? clientHQInfoSelector.clientHQInfo.data.zip : '')
        setInitalZipcode(clientHQInfoSelector.clientHQInfo.data ? clientHQInfoSelector.clientHQInfo.data.zip : '')
    }, [clientHQInfoSelector.clientHQInfo.data])

    const handleChange = (newValue) => {
        setValue(newValue);
    };

    const toggleModalHandler = (e) => {
        setModalData('null');
        setOpenModal(openModal => !openModal);
    }

    const handleChangeCommitted = (newValue) => {
        if (date === todaysDate && newValue[1] > value[1]) {
            let time = getCurrentTimeInEST();
            const newVaue1 = [newValue[0], getValueByTime(time)]
            setValue(newVaue1);
        } else {
            setValue(newValue);
        }
        let fromTime = `${date} ${getTime(newValue[0])}:00`;
        let toTime = `${date} ${getTime(newValue[1])}:00`;
        dispatch(insertTimelineClockData({ dayDate, fromTime, toTime, event: 'mouseup', speed: selectedSpeed }));
    }


    /**
     * Uncomment this so the timer starts to run again
     */
    useInterval(() => {
        let value2 = value[1] + 0.167;
        value2 = parseFloat(value2.toFixed(3))
        let fromTime = `${date} ${getTime(value[0])}:00`;
        let toTime = `${date} ${getTime(value2)}:00`;
        dispatch(insertTimelineClockData({ dayDate, fromTime, toTime, event: 'onchange', speed: selectedSpeed }));
        setValue(([value1, value2]) => {
            value2 = value2 + 0.167;
            return [value1, parseFloat(value2.toFixed(3))]
        })
    }, timelineSelector.isPlaying ? 60000 / selectedSpeed : null);


    const handleDateChange = (date) => {
        getCurrentTimeValue();
        let dayDate = moment(date).format('DD');
        let tempDate = moment(date).format('YYYY-MM-DD');
        let value2 = value[1] + 0.167;
        value2 = parseFloat(value2.toFixed(3))
        let fromTime = `${tempDate} ${getTime(value[0])}:00`;
        let toTime = `${tempDate} ${getTime(value2)}:00`;
        dispatch(insertTimelineClockData({ dayDate, fromTime, toTime, event: 'mouseup', speed: selectedSpeed }));
        dispatch(insertDate(tempDate));
        dispatch(playTimeLine())
        setSelectedDate(date);
        setDate(getDateInFormat(date))
    };

    const onDateDialogClose = () => {
        setIsOpen(false);
    }

    const onLayerFilterChange = (values) => {
        // console.log(values)
        // dispatch(insertPOIFilters(values.value))
        setPoiFilters(values.value);
    }

    const onLevelFilterChange = (selected, level) => {
        let options = { ...selectedLevelFilters };
        options[level] = selected;
        setSelectedLevelFilter(options);
    }

    const onSettingsChange = (values) => {
        if (values[0] === "fleet_overview" || values[0] === "latest_events") {
            if (values[0] === "fleet_overview" && values[1] == true) {
                sessionStorage.setItem("fleet_overview", true)
                hideLeftSection(false)
            } else if (values[0] === "fleet_overview" && values[1] == false) {
                sessionStorage.setItem("fleet_overview", false)
                hideLeftSection(true);
            } else if (values[0] === "latest_events" && values[1] == true) {
                sessionStorage.setItem("latest_events", true)
                hideRightSection(false)
            } else if (values[0] === "latest_events" && values[1] == false) {
                sessionStorage.setItem("latest_events", false)
                hideRightSection(true);
            }
        } else {
            var poiValue = []
            var newArray = JSON.parse(sessionStorage.getItem("POI"))
            dispatch(putPoiFiltersSession((values[0])))
            if (values[0] === "ALL_NAS") {
                poiValue = !values[1] ? newArray = newArray.filter(item => item !== values[0]) : !newArray.includes(values[0]) ? newArray.push(values[0]) : newArray
                sessionStorage.setItem("POI", JSON.stringify(newArray))
                sessionStorage.setItem("ALL_NAS", values[1])
                dispatch(putPoiFiltersSession((values[0])))
            } else if (values[0] === "ALL_EVCS") {
                poiValue = !values[1] ? newArray = newArray.filter(item => item !== values[0]) : !newArray.includes(values[0]) ? newArray.push(values[0]) : newArray
                sessionStorage.setItem("POI", JSON.stringify(newArray))
                sessionStorage.setItem("ALL_EVCS", values[1])
                dispatch(putPoiFiltersSession(values[0]))
            } else if (values[0] === "ALL_FS") {
                poiValue = !values[1] ? newArray = newArray.filter(item => item !== values[0]) : !newArray.includes(values[0]) ? newArray.push(values[0]) : newArray
                sessionStorage.setItem("POI", JSON.stringify(newArray))
                sessionStorage.setItem("ALL_FS", values[1])
                dispatch(putPoiFiltersSession(values[0]))
            }
        }
    }

    const handleSelectedSpeed = (event) => {
        let value2 = value[1] + 0.167;
        value2 = parseFloat(value2.toFixed(3))
        let fromTime = `${date} ${getTime(value[0])}:00`;
        let toTime = `${date} ${getTime(value2)}:00`;
        dispatch(insertTimelineClockData({ dayDate, fromTime, toTime, event: 'mouseup', speed: event.target.value }));
        setSelectedSpeed(event.target.value)
    }

    const onZipcodeChange = (e) => {
        setZipCode(e.target.value);
        var msg = validateUsZipCode(e.target.value)
        setZipMsg(msg)
        setShowSaveButton(msg)
    }

    const generateZipcodePayload = () => {
        return {
            zip: zipCode,
            showNationalAccountShops: '0',
            showEvChargingStations: '0',
            showFuelStations: '0',
        };
    };

    const saveZipcode = (event) => {
        var payloadRequest = generateZipcodePayload()
        dispatch(saveClientInfo(payloadRequest));
        setIsOpenPopup(o => !o)
    }

    return (
        <div className={styles.root}>
            {/* for now default location drawer not needed but can be used for further enhancement */}

            {
                isSideDrawerOpen && (
                    <LevelFilters
                        key={0}
                        defaultData={filtersSelectordefaultValue}
                        prismicData={prismicData}
                        data={levelFiltersData}
                        isSideDrawerOpen={isSideDrawerOpen}
                        setIsSideDrawerOpen={setIsSideDrawerOpen}
                        onLayerFilterChange={onLayerFilterChange}
                        poiFilters={poiFilters}
                        onLevelFilterChange={onLevelFilterChange}
                        levelFilter={selectedLevelFilters}
                    />
                )
            }
            {
                isTripSideDrawerOpen && (

                    <TripFilters
                        prismicData={prismicData}
                        // data={levelFiltersData}
                        isTripSideDrawerOpen={isTripSideDrawerOpen}
                        setIsTripSideDrawerOpen={setIsTripSideDrawerOpen}
                    />

                )

            }
            <div className={styles.filterDiv}><div className={styles.filterCard}>
                <div>
                    <Popup className={styles.popup} open={!isOpenPopup}

                        trigger={
                            <Tooltip title="Filters" placement="bottom" arrow>
                                <LayersIcon htmlColor={'#f06400'} style={{ cursor: "pointer" }} onClick={() => {
                                    if (!filtersSelector.isLoading) {
                                        setIsOpenPopup(o => !o)
                                    }
                                }} />
                            </Tooltip>
                        }
                        position={"bottom left"}
                        {...{ contentStyle, overlayStyle, arrowStyle }}
                    >

                        <div className={styles.PopupHeaderContainer}>
                            <Text component="p" size="xs" color="petrolBlue" style={{ fontWeight: "bold", cursor: "pointer" }} gutter> Reset to Defaults </Text>
                        </div>
                        <Spacing ph={1} >
                            <Spacing pv={.5} >
                                <Heading component="p" size="xs" color="petrolBlue" gutter={false}>Current layers</Heading>
                            </Spacing>
                            <Spacing ph={1} pv={.5}>
                                <Grid direction='row'>
                                    <Text component="p" size="xs" gutter> To change defaults, see</Text>
                                    <Text component="p" size="xs" style={{ marginLeft: "5px", textDecoration: "underline", cursor: "pointer" }} onClick={() => {
                                        if (!filtersSelector.isLoading) {
                                            setIsSideDrawerOpen(!isSideDrawerOpen)
                                        }
                                    }} gutter> Settings</Text>
                                </Grid>
                            </Spacing>
                            <Spacing pv={1} />
                            <Text component="p" size="s" color="petrolBlue" style={{ fontWeight: "bold" }} gutter>Map style</Text>
                            <Spacing pv={.5} />
                            <div className={styles.option}>
                                <div className={styles.buttons}>
                                    <button id="option1Btn" className={buttonColor == "street" ? styles.selected : styles.notSelected} onClick={() => changeColor("street")}>street</button>
                                    <Spacing ph={.5} />
                                    <button className={buttonColor == "satellite" ? styles.selected : styles.notSelected} onClick={() => changeColor("satellite")}> satellite</button>
                                </div>
                            </div>
                            <Spacing pv={1} />
                            <Text component="p" size="s" color="petrolBlue" style={{ fontWeight: "bold" }} gutter>Points-of-Interest (POIs)</Text>
                            <ToggleFilterComponent
                                key={"POIs"}
                                heading={getLabel("layouts", "Layouts", prismicData)}
                                data={clientPoiValue}
                                onFilterChange={onSettingsChange}
                                values={sectionValues}
                            />
                            <Spacing pv={.5} />
                            <Text component="p" size="s" color="petrolBlue" style={{ fontWeight: "bold" }} gutter>Panels</Text>
                            <ToggleFilterComponent
                                key={"Panels"}
                                heading={getLabel("Layouts", "Layouts", prismicData)}
                                data={pannelValue}
                                onFilterChange={onSettingsChange}
                                values={sectionValues}
                            />

                        </Spacing>
                    </Popup>
                </div>


                {/* <Tooltip title="Select Date" placement="bottom" arrow>
                    <>
                        <div className={styles.calendarIcon} onClick={() => setIsOpen(!isOpen)}>
                            {timelineSelector.clockData.dayDate}
                        </div>
                        <CalendarTodayIcon style={{ position: 'absolute', zIndex: '10', marginLeft: '-5vh' }} htmlColor={'#4a4a4a'} onClick={() => setIsOpen(!isOpen)}>

                        </CalendarTodayIcon>

                    </>
                </Tooltip> */}


                {/* <Spacing ph={2} /> */}


                {/* <img src={trip_select_icon} onClick={() => { setIsTripSideDrawerOpen(!isTripSideDrawerOpen) }} className={styles.tripSelectIcon} /> */}
                {/* <Tooltip title="Select trips" placement="bottom" arrow>
                
                <SvgIcon onClick={() => { setIsTripSideDrawerOpen(!isTripSideDrawerOpen) }}>

                        <Navigation />
                    </SvgIcon>
                </Tooltip> */}
                {/* <Tooltip title="Hide Layer" placement="bottom" arrow>
                    <SettingsIcon onClick={() => {
                        if (!filtersSelector.isLoading) {
                            setIsSideDrawerOpen(!isSideDrawerOpen)
                        }
                    }} htmlColor={'#363636'} />
                </Tooltip> */}


            </div>
            </div>
            {/* <div className={styles.transparentBackground}>
                <div className={styles.sliderDiv}>
                    <HorizontalSlider sliderColor={'#52af77'} thumbColor={'#fff'} value={value} onChange={handleChange} onChangeCommitted={handleChangeCommitted} />
                </div>

                <div className={styles.playbackControlDiv}>
                    {timelineSelector.isPlaying ? (
                        <IconButton color="secondary" aria-label="pause" onClick={() => dispatch(pauseTimeLine())}>
                            <PauseIcon htmlColor={'#545656'} />
                        </IconButton>
                    ) : (
                        <IconButton color="secondary" aria-label="play" onClick={() => dispatch(playTimeLine())}>
                            <PlayArrowIcon htmlColor={'#545656'} />
                        </IconButton>
                    )}

                    <Select
                        labelId="demo-customized-select-label"
                        id="demo-customized-select"
                        className={styles.speedDropdown}
                        value={selectedSpeed}
                        onChange={handleSelectedSpeed}
                        disableUnderline={true}
                    >
                        {
                            SPEED_CONSTANTS.map(row => {
                                return (
                                    <MenuItem key={row.label} value={row.value}>{row.label}</MenuItem>
                                )
                            })
                        }
                    </Select>
                </div>
            </div> */}

            {/* <select className={styles.selectDiv}
                onChange={""} value={"America/Detroit"}
            >
                <option value="">Select The Timezone</option>
                <option value="America/Detroit">Eastern Time Zone (ET)</option>
                <option value="America/Chicago">Central Time Zone (CT)</option>
                <option value="America/Boise">Mountain Time Zone (MT)</option>
                <option value="America/Los_Angeles">Pacific Time Zone (PT)</option>
                <option value="America/Anchorage">Alaska Time Zone (AT)</option>
                <option value="US/Hawaii">Hawaii Time Zone (HT)</option>
            </select> */}

            {/* <div className={styles.hiddenDiv}>
                <DatePicker
                    isOpen={isOpen}
                    selectedDate={selectedDate}
                    handleDateChange={handleDateChange}
                    onClose={onDateDialogClose}
                />
            </div> */}
        </div>
    )
};

export default React.memo(BottomSlider);