import React, { useState, useEffect } from 'react';
import styles from './Trips.module.scss';
import {
    Heading,
} from "@leaseplan/ui";
import Row from './Row';
import { useDispatch, useSelector } from 'react-redux';
import { getLabel, timezoneOffSetAdd } from '../../../utils';
import moment from 'moment';
import { baseURL } from '../../../Store/base-url';
import { fetchDataUsingCancelToken } from '../../../AxiosInterceptor/fetchDataUsingCacelToken';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SvgIcon } from '@material-ui/core';
import { ReactComponent as Download } from '../../../Asset/InsightIcons/Download.svg';
import InformationTab from '../../DriverInsights/InformationTab/InformationTab';
import { LPLoader } from '../../../Common/LPLoader/LPLoader';
import { fetchTrips } from '../../../Actions/driver.action';

import CalendarTodayIcon from "@material-ui/icons/CalendarToday";

const Trips = (props) => {
    const dispatch = useDispatch();
    const { prismicData, driverDetails, selectedTripDate, selectActiveTrip, activeTrip, openCalendar } = props;
    const [driverTrips, setDriverTrips] = useState([]);
    const [exportLoading, setExportLoading] = useState(false);
    const authSelector = useSelector(state => state.auth);

    const driverTripsSelector = useSelector(state => state.driver.trips);

    let date = moment(sessionStorage.getItem('selectedDate') ? sessionStorage.getItem('selectedDate') : selectedTripDate).format('YYYY-MM-DD');
    const dateFromUTC = timezoneOffSetAdd(date, '00:00:00', sessionStorage.getItem("driverTimezoneOffset"));
    const dateToUTC = timezoneOffSetAdd(date, '23:59:59', sessionStorage.getItem("driverTimezoneOffset"));

    useEffect(() => {
        dispatch(fetchTrips(
            {
                clientNumber: authSelector.user.authUser.clientNumber,
                userId: authSelector.user.authUser.username,
                driverRecordNumber: driverDetails.driverRecordNumber,
                vin: driverDetails.deviceVin,
                fromDate: dateFromUTC,
                toDate: dateToUTC,
                pagingCriteriaInput: {
                    pageNumber: 1,
                    pageSize: 1000
                }
            }
        )
        )
    }, [dateFromUTC, driverDetails]);

    useEffect(() => {
        const { isLoading, data } = driverTripsSelector;
        if (!isLoading && data) {
            setDriverTrips(data);
        }
    }, [driverTripsSelector]);

    const exportTrips = async () => {
        setExportLoading(true);

        /**
         * Client Number and user Id removed since no longer required after authentication
         */
        const { cancelPrevQuery, result, error } = await fetchDataUsingCancelToken(
            baseURL,
            "json",
            '/export-trips-driver-vin',
            'post',
            {
                clientNumber: authSelector.user.authUser.clientNumber,
                userId: authSelector.user.authUser.username,
                driverRecordNumber: driverDetails.driverRecordNumber,
                vin: driverDetails.vin,
                fromDate: dateFromUTC,
                toDate: dateToUTC,
                pagingCriteriaInput: {
                    pageNumber: 1,
                    pageSize: 1000
                }
            },
        );

        if (cancelPrevQuery) return;

        if (result) {
            setExportLoading(false);
            window.open(result.url);
        } else if (error) {
            setExportLoading(false);
        }
    }

    return (
        <div>
            <div className={styles.row}>
                <Heading component="p" size={'xs'} color={'petrolBlue'} className={styles.heading} gutter>{getLabel('trips', 'Trips', prismicData)}</Heading>
                <div className={styles.row}>
                            <div className={styles.calendarIcon} onClick={() => openCalendar()}>
                                {moment(sessionStorage.getItem("selectedDate") ? sessionStorage.getItem("selectedDate") : selectedTripDate).format('DD')}
                            </div>
                            <CalendarTodayIcon htmlColor={'#4a4a4a'} style={{ cursor: 'pointer' }} />
                    <div className={styles.downloadIcon}>
                        {exportLoading ? <CircularProgress /> : <SvgIcon className={styles.svgStyle} onClick={() => exportTrips()}>
                            <Download />
                        </SvgIcon>}
                    </div>
                </div>
            </div>
            {
                driverTripsSelector.isLoading ? (
                    <div className={styles.lpLoader}>
                        <LPLoader
                            loading={driverTripsSelector.isLoading}
                            message={"Loading Data..."}
                        />
                    </div>
                ) : (
                    <div>
                        {
                            driverTrips?.length > 0 ? driverTrips.map((row, index) =>
                                <Row
                                    key={index}
                                    data={row}
                                    id={index}
                                    selectActiveTrip={selectActiveTrip}
                                    activeTrip={activeTrip}
                                />
                            ) : (
                                <InformationTab icon={'info'} iconSize={'l'} headerText={getLabel('no_information_available', 'No Information Available', prismicData)} text={getLabel('trips_data_not_available', 'Trips are not available for this date', prismicData)} />
                            )
                        }
                    </div>
                )
            }
        </div>
    )
}

export default Trips;