import React, { useState, useEffect, useRef } from "react";
import { getLabel } from "../../utils";
import styles from "./Reporting.module.scss";
import GraphModal from "./GraphModal/GraphModal";
import { LPLoader } from "../../Common/LPLoader/LPLoader";
import ReportSidebar from "./ReportSidebar/ReportSidebar";
import { useDispatch, useSelector } from "react-redux";
import GraphPlaceholder from "../GraphPlaceholder/GraphPlaceholder";
import { Button, Grid, GridItem, Heading, Spacing, Text } from "@leaseplan/ui";
import {
  addReportToFavourite,
  deleteUserReport,
  saveUserReport,
  downloadReport,
  sortUserReport,
  fetchSingleUserReportsIds,
  fetchAllUserReportsIds,
  checkDownloadReportStatus,
} from "../../Actions/reporting.action";
import { GridContextProvider, GridDropZone, swap, move } from "react-grid-dnd";
import { GridItem as GridItemGroup } from "react-grid-dnd";
import useWindowDimensions from "./windowDimension";
import {
  isPanningMap,
  logRecordLoadingStatusWorker,
} from "../../Actions/level.action";
import { insertInitialFromTime } from "../../Actions/driver.action";
import { Icon } from "@velocity/ui.Icon";
import { generatePath, useNavigate } from "react-router-dom";
import { RoutePath } from "../../Routers/Routes";
import { Card, Skeleton, CardHeader } from "@mui/material";

const Reporting = ({ prismicData }) => {
  const [openModal, setOpenModal] = useState(false);
  const [openSideDrawer, setOpenSideDrawer] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [dnd, setDnd] = useState(false);
  const [dataNew, setDataNew] = useState();
  const [reportIdData, setReportIdData] = useState("");
  const authSelector = useSelector((state) => state.auth);

  const reportingReportsDataSelector = useSelector(
    (state) => state.reporting.reportsData
  );
  const reportingReportsIdsDataSelector = useSelector(
    (state) => state.reporting.reportsDataIds
  );
  const reportingDownloadDataSelector = useSelector(
    (state) => state.reporting.downloadData
  );
  const dispatch = useDispatch();
  const history = useNavigate();
  const ref = useRef();

  const { noOfRows } = useWindowDimensions();

  const LoadingSkeleton = () => (
    <Card sx={{ maxWidth: 300, maxHeight: 230, marginBottom: 2 }}>
      <CardHeader
        avatar={<Skeleton animation="wave" width={40} height={40} />}
        title={
          <Skeleton
            animation="wave"
            height={20}
            width="80%"
            style={{ marginBottom: 6 }}
          />
        }
      />
      <Skeleton sx={{ height: 200 }} animation="wave" variant="rectangular" />
    </Card>
  );

  const generateRequestPayload = (items) => {
    const rearrangeData = [];
    for (let index = 0; index < items.length; index++) {
      rearrangeData.push({
        reportSelectionUid: items[index].graphId,
        reportOrder: index + 1,
      });
    }
    return {
      clientNumber: authSelector.user.authUser.clientNumber,
      data: rearrangeData,
    };
  };

  //Note :- To merge the data for Number graphType to display in single card
  const mergeApiData = (array) => {
    let mergedArray;
    let finaleOutputArray;
    if (array.length > 0) {
      const filteredArray = array.filter((obj) => obj.graphType === "NUMBER");
      const outputyo = array.filter((obj) => obj.graphType !== "NUMBER");
      const outputArray = [];
      for (const obj of filteredArray) {
        if (obj.graphType === "NUMBER") {
          const matchingObject = filteredArray.find(
            (otherObj) =>
              otherObj.reportOrder !== obj.reportOrder &&
              otherObj.graphType === "NUMBER"
          );
          if (matchingObject) {
            const mergedObject = {
              ...obj,
              mergeData: matchingObject,
            };
            outputArray.push(mergedObject);
            finaleOutputArray = outputArray.length > 0 ? outputArray[0] : [];
          }
        } else {
          outputArray.push(filteredArray);
        }
      }
      if (finaleOutputArray) {
        mergedArray = [...outputyo, ...[finaleOutputArray]];
      } else {
        mergedArray = [...outputyo, ...filteredArray];
      }
      return mergedArray;
    } else {
      return [];
    }
  };

  const { data, isLoading, isDeleting, isSaving, error } =
    reportingReportsDataSelector;

  useEffect(() => {
    if (data && !isLoading) {
      let apiData = data.filter(
        (ele, ind) =>
          ind === data.findIndex((elem) => elem.graphId === ele.graphId)
      );
      let mergeApiArray = mergeApiData(apiData);
      if (mergeApiArray.length > 0) {
        setDataNew(mergeApiArray);
      }
    }
  }, [data]);

  useEffect(() => {
    setReportIdData(reportingReportsIdsDataSelector);
  }, [reportingReportsIdsDataSelector]);

  useEffect(() => {
    if (reportIdData?.data?.length > 0) {
      dispatch(fetchSingleUserReportsIds(reportIdData?.data));
    }
  }, [reportIdData]);

  useEffect(() => {
    if (reportingDownloadDataSelector) {
      dispatch(checkDownloadReportStatus());
    }
  }, [reportingDownloadDataSelector]);

  useEffect(() => {
    setReportIdData("");
    dispatch(fetchAllUserReportsIds());
    sessionStorage.setItem("urlEndPoint", window.location.pathname);
    // dispatch(fetchAllUserReports());
    dispatch(logRecordLoadingStatusWorker(false));
    dispatch(insertInitialFromTime());
    dispatch(isPanningMap(false));
    //  setReportIdData(reportingReportsIdsDataSelector)
  }, []);

  const toggleModalHandler = (e) => {
    setModalData(e.graphData);
    setOpenModal((openModal) => !openModal);
    setDnd(false);
  };

  const toggleSideDrawerHandler = (e) => {
    if (!isLoading) {
      setOpenSideDrawer((openSideDrawer) => !openSideDrawer);
      if (!openSideDrawer) {
        dispatch(checkDownloadReportStatus());
      }
    }
  };

  const removeReportHandler = (e, graphId) => {
    dispatch(deleteUserReport(graphId));
  };

  const addSelectedReport = (body) => {
    dispatch(
      saveUserReport({
        graphType: body.graphType,
        dateRange: body.dateRange ? body.dateRange : 15,
      })
    );
  };

  const addToFavouriteHandler = (graphId) => {
    dispatch(addReportToFavourite(graphId));
  };

  const downloadReportHandler = (graphId) => {
    dispatch(downloadReport(graphId));
  };

  const moveToDashboard = () => {
    history({
      pathname: generatePath(RoutePath.Dashboard),
    });
  };

  const onChangeDrag = (sourceId, sourceIndex, targetIndex, targetId) => {
    setDnd(true);
    setOpenModal(true);
    setModalData(null);
    if (targetId) {
      const result = move(
        dataNew[sourceId],
        dataNew[targetId],
        sourceIndex,
        targetIndex
      );
      return setDataNew({
        ...dataNew,
        [sourceId]: result[0],
        [targetId]: result[1],
      });
    }

    let result = swap(dataNew, sourceIndex, targetIndex);
    setDataNew(result);
    dispatch(sortUserReport(generateRequestPayload(result)));
  };
  return (
    <div className={styles.mainWrapper} ref={ref}>
      {openModal && !dnd && (
        <GraphModal
          openModal={openModal}
          toggleModalHandler={toggleModalHandler}
          modalData={modalData}
        />
      )}

      <ReportSidebar
        prismicData={prismicData}
        shouldOpenDrawer={toggleSideDrawerHandler}
        setIsSideDrawerOpen={openSideDrawer}
        addSelectedReport={addSelectedReport}
      />

      <Grid direction="column" gutter={false}>
        <GridItem>
          <Spacing p={1}>
            <Text
              component="p"
              size={"xs"}
              color={"petrolBlue"}
              className={styles.heading}
              gutter={false}
            >
              Home / Reporting
            </Text>
          </Spacing>
        </GridItem>

        <GridItem>
          <Grid direction="row" justifyContent="space-between">
            <GridItem>
              <Spacing pl={1}>
                <Heading
                  component="span"
                  size={"s"}
                  color={"petrolBlue"}
                  className={styles.heading}
                  gutter={false}
                >
                  {getLabel("reporting", "Reporting", prismicData)}
                </Heading>
              </Spacing>
            </GridItem>
            <GridItem>
              <Spacing pr={1}>
                <div className={styles.stickyButton}>
                  <Button
                    size="s"
                    iconposition="right"
                    onClick={toggleSideDrawerHandler}
                  >
                    Manage Report
                  </Button>
                </div>
              </Spacing>
            </GridItem>
          </Grid>
        </GridItem>
        <button
          type="button"
          id={"drawer_close"}
          onClick={moveToDashboard}
          className={styles.CloseButton}
        >
          <Icon name="close" size="s" />
        </button>

        <GridItem>
          <Spacing m={2}>
            <Grid
              justifyContent="flex-start"
              direction="row"
              span={{ mobile: 12, tablet: 6, desktop: 3 }}
            >
              {isLoading &&
                [...Array(16)].map((_, index) => (
                  <GridItem key={index} className={styles.emptyCards}>
                    <LoadingSkeleton key={index} />
                  </GridItem>
                ))}
            </Grid>

            <GridContextProvider onChange={onChangeDrag}>
              <GridDropZone
                className={styles.dropzone}
                boxesPerRow={noOfRows || 8}
                rowHeight={302}
              >
                {!isLoading &&
                  dataNew?.map((data, index) => (
                    <GridItemGroup key={data.reportOrder}>
                      <div className={styles.graphRepo}>
                        <GraphPlaceholder
                          key={index}
                          graphId={data.graphId}
                          graphType={data.graphType}
                          graphTitle={data.graphTitle}
                          graphDescription={data.graphDescription}
                          graphSpecification={data.graphSpecification}
                          graphDateRange={data.graphDateRange}
                          isFavourite={data.isFavourite}
                          graphData={data.data}
                          graphHideCalendar={data.graphHideCalendar}
                          styleType="placeholder"
                          hasWrapperElements={true}
                          height={200}
                          width={301}
                          reportData={data}
                          toggleModalHandler={toggleModalHandler}
                          removeReportHandler={removeReportHandler}
                          addToFavouriteHandler={addToFavouriteHandler}
                          downloadReportHandler={downloadReportHandler}
                        />
                      </div>
                    </GridItemGroup>
                  ))}
              </GridDropZone>
              {isSaving && (
                <div className={styles.lploaderSaving}>
                  <LPLoader loading={true} message={"Saving Report"} />
                </div>
              )}
            </GridContextProvider>
          </Spacing>
        </GridItem>
      </Grid>
    </div>
  );
};

Reporting.propTypes = {};

export default React.memo(Reporting);
