import axios from "../AxiosInterceptor";
import { call, put, takeLatest } from "redux-saga/effects";
import { VEHICLE_INSIGHTS_PAGE } from "../Constants/vehicleInsightsPage";
import { baseURL } from "../Store/base-url";
import { F, S } from "../utils/actions";
import authService from '../Services/Auth/authService';
import {CANCEL} from 'redux-saga';

function getVehicleInsightsPageInfoAPI(payload) {
  const userId = authService.getUserId();
  const clientNumber = authService.getClientNumber();
  const tokenSource = axios.CancelToken.source();
  const request =  axios.request({
    baseURL: baseURL,
    responseType: "json",
    url: `/vehicle-insights?page=${payload.pagingCriteriaInput.pageNumber - 1}&size=${payload.pagingCriteriaInput.pageSize}${payload.sortCriteriaInput.length !== 0 ? `&sort=${payload.sortCriteriaInput[0].field},${payload.sortCriteriaInput[0].order}` : []}`,
    method: "post",
    data:{
        clientNumber: clientNumber,
        ...payload
    },
    cancelToken: tokenSource.token,
  });
  request[CANCEL] = () => tokenSource.cancel();
  return request;
}

export function* getVehicleInsightsPageInfoAPIWorker(action) {
  try {
    const {data} = yield call(getVehicleInsightsPageInfoAPI, action.payload);
    yield put({ type: S(action.type), 
        data: [...data.content],
        resultSetCount: data.totalElements,
        currentPage: action.payload.pageNumber,
        pageLimit: action.payload.pageSize  
    });
  } catch (e) {
    yield put({ type: F(action.type), payload: e });
  }
}


function* vehicleInsightsPageSaga() {
    yield takeLatest(VEHICLE_INSIGHTS_PAGE.VEHICLE_INSIGHTS_PAGE_FETCH, getVehicleInsightsPageInfoAPIWorker);
}


export default vehicleInsightsPageSaga;