import { REPORTING_PAGE } from "../Constants/reporting";

export const fetchReportsWithDateRangesValue = () => ({
    type: REPORTING_PAGE.FETCH_REPORTING_SIDE_BAR_INFO,
});

export const fetchAllUserReports = () => ({
    type: REPORTING_PAGE.FETCH_REPORTS,
});

export const fetchAllUserReportsIds = () => ({
    type: REPORTING_PAGE.FETCH_REPORTS_IDS,
});

export const deleteUserReport = (id) => ({
    type: REPORTING_PAGE.DELETE_REPORT,
    payload: id
});

export const saveUserReport = (params) => ({
    type: REPORTING_PAGE.SAVE_REPORT,
    payload: params
});


export const addReportToFavourite = (graphId) => ({
    type: REPORTING_PAGE.ADD_FAVOURITE,
    payload: graphId
});

export const downloadReport = (graphId) => ({
    type: REPORTING_PAGE.DOWNLOAD_REPORT,
    payload: graphId
});

export const fetchSingleUserReportsIds = (graphId) => ({
    type: REPORTING_PAGE.FETCH_SINGLE_REPORTS_IDS,
    payload: graphId
});

export const checkDownloadReportStatus = (graphId) => ({
    type: REPORTING_PAGE.CHECK_DOWNLOAD_REPORT_STATUS,
    payload: graphId
});

export const sortUserReport = (params) => ({
    type: REPORTING_PAGE.SORT_REPORT,
    payload: params
});
