import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router';
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../Actions/auth';
import { Loader } from '@leaseplan/ui';
import Error from "../../Common/ErrorPage/ErrorPage";
import styles from "./Login.module.scss";
import Cookies from 'js-cookie';
import { Timezonedata_Api_nowindowload } from '../../utils';

const Login = () => {
    const search = useLocation().search;
    const [error, setError] = useState(false);
    const clientId = new URLSearchParams(search).get('clientId');
    const userId = new URLSearchParams(search).get('userId');
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const authSelector = useSelector(state => state.auth);

    const dispatch = useDispatch();

    useEffect(() => {
        sessionStorage.setItem("POI", "[]");
        sessionStorage.removeItem("fleet_overview")
        sessionStorage.removeItem("latest_events")
        sessionStorage.removeItem("ALL_EVCS")
        sessionStorage.removeItem("ALL_FS")
        sessionStorage.removeItem("ALL_NAS")
        if (clientId && userId && !sessionStorage.getItem("authorization-client")) {
            sessionStorage.setItem("TimezoneOffset", '');
            sessionStorage.setItem("TimezoneShortname", 'ET');
            sessionStorage.setItem("Timezone", "");
            sessionStorage.setItem("authorization-client", clientId);
            Cookies.remove('authorization-id-code');
            Cookies.remove('authorization-refresh-code');
            dispatch(login({ userId, clientId }));

        }
        else if (clientId && userId && sessionStorage.getItem("authorization-client")) {
            if (clientId != sessionStorage.getItem("authorization-client")) {
                sessionStorage.setItem("authorization-client", clientId);
            }
        }
        else if (!sessionStorage.getItem("authorization-client") && (!clientId || !userId)) {
            setError(true);
        }
        if (!authSelector.isLoading && authSelector.isAuthenticated) {
            if (sessionStorage.getItem("Timezone") == "") {
                let Timezonedata = Timezonedata_Api_nowindowload(timezone);
            }
        }


    }, [])

    useEffect(() => {
        if (authSelector.error) {
            setError(true)
        }
    }, [authSelector.error])


    if (!authSelector.isLoading && authSelector.isAuthenticated) {
        if (sessionStorage.getItem("Timezone") == "") {
            let Timezonedata = Timezonedata_Api_nowindowload(timezone);
        }
        //checking the url for hardrefreshing functionality #444
        return <Navigate to={sessionStorage.getItem("urlEndPoint") != "" ? sessionStorage.getItem("urlEndPoint") : '/'}/>;
    }

    return (
        <div className={styles.loginPage}>
            {
                error && (
                    <div className={styles.error}>
                        <Error
                            title={"Invalid Credentials"}
                            errorCodeText={
                                "For the record, this is error code 401"
                            }
                            icon={"lock"}
                            iconSize={"s"}
                            description={
                                "Looks like you don’t have permission to view this page."
                            }
                        />
                    </div>
                )
            }
            {
                authSelector.isLoading && (
                    <div className={styles.loader}>
                        <Loader />
                    </div>
                )
            }
        </div>
    )
}

Login.propTypes = {

}

export default Login
