import CONSTANTS from '../Constants/DriverEvents';
import { F, S } from "../utils/actions";

const initialState = {
    data: [],
    isLoading: false,
    error: null
};

const driverAccounts = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case CONSTANTS.FETCH_DRIVER_EVENTS:
        return {
            ...state,
            isLoading: true,
            error: null
        };
    case S(CONSTANTS.FETCH_DRIVER_EVENTS):
        return {
            data: payload,//[...payload, ...state.data], -> this will be implemented later
            isLoading: false,
            error: null
        };
    case F(CONSTANTS.FETCH_DRIVER_EVENTS):
        return {
            ...state,
            isLoading: false,
            error: payload
        };
    case CONSTANTS.RESET_DRIVER_EVENTS:
        return {
            ...state,
            isLoading: false,
            error: null,
            data: []
        };
    case CONSTANTS.REMOVE_DRIVER_EVENTS: 
        const updatedData = [...state.data];
        updatedData.splice(updatedData.findIndex((row) => row.eventUid === action.payload), 1)
        return {
            ...state,
            data: updatedData
        }
    default:
        return state;
  }
};

export default driverAccounts;