import React, { useState, useEffect } from 'react';
import GraphPlaceholder from '../../GraphPlaceholder/GraphPlaceholder';
import { baseURL } from '../../../Store/base-url';
import { LPLoader } from '../../../Common/LPLoader/LPLoader';
import { fetchDataUsingCancelToken } from '../../../AxiosInterceptor/fetchDataUsingCacelToken';
import { SvgIcon, CircularProgress } from '@material-ui/core';
import { ReactComponent as Download } from '../../../Asset/InsightIcons/Download.svg';
import { Modal, ModalContent, ModalTitle, Grid, GridItem, CalendarIcon } from '@leaseplan/ui';
import CustomDateRangePicker from '../../../Common/CustomDateRangePicker/CustomDateRangePicker';
import styles from './GraphModal.module.scss';
import useSkipInitialRenderEffect from '../../../Common/CustomHook/useSkipInitialRenderEffect';
import moment from "moment";
import { useDispatch } from 'react-redux';
import { downloadReport } from '../../../Actions/reporting.action';
import FilterIcon from "../../../Asset/Images/filter.png";
import Popup from 'reactjs-popup';
import ReportPopupFilter from '../../../Common/PopupFilter/ReportPopupFilter';


const contentStyle = { background: '#FFF', top: '-2rem', right: '12rem', zIndex: 10000, width: '15em' };
const overlayStyle = { background: 'rgba(0,0,0,0.5)', zIndex: 10000 };
const arrowStyle = { color: '#FFF', zIndex: 10000 }; // style for an svg element

const GraphModal = ({ openModal, toggleModalHandler, children, modalData }) => {

    const [openGraphModal, setOpenGraphModal] = useState(openModal);
    const [graphData, setGraphData] = useState(modalData.graphData);
    const [graphFilterData, setGraphFilterData] = useState(false);
    const [selectedDateRange, setSelectedDateRange] = useState({
        from: moment().toDate(),
        to: moment().toDate()
    });
    const [isExportLoading, setIsExportLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isCalendarComponentSelected, setIsCalendarComponentSelected] = useState(false);
    const [isOpenPopup, setIsOpenPopup] = useState(false);
    const [eventFilters, setEventFilters] = useState([]);
    const [selectedEvents, setSelectedEvents] = useState([]);
    const [defaultValue, setDefaultValue] = useState([]);
    const dispatch = useDispatch();

    const GRAPH_HEIGHT = 600;
    const GRAPH_WIDTH = 700;


    useSkipInitialRenderEffect(() => {
        setIsLoading(true);
        const fetchData = async () => {
            let startDate = moment(selectedDateRange.from).format('YYYY-MM-DD');
            let endDate = moment(selectedDateRange.to).format('YYYY-MM-DD');
            const { cancelPrevQuery, result, error } = await fetchDataUsingCancelToken(
                baseURL,
                'json',
                `/reports/user-report-with-date/${modalData.graphId}?startDate=${startDate}&endDate=${endDate}`,
                'get',
                null,
            );
            if (cancelPrevQuery) return;

            if (result) {
                setGraphData(result.data);
                setIsLoading(false);
            } else if (error) {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [selectedDateRange])


    const handleOpen = () => {
        setIsOpenPopup(true)
    }

    const handleClose = () => {
        setIsOpenPopup(false)
    }

    const toggleCalendarIsOpen = () => {
        setIsOpen(open => !open);
    }

    const selectedDateRangeHandler = (date) => {
        if (!isCalendarComponentSelected) {
            setIsCalendarComponentSelected(true);
        }
        setSelectedDateRange(date);
        setIsOpen(open => !open);
    }

    //exportReports function have been changed to newExportReport function

    const newExportReport = () => {

        let builtUrl = ``;
        if (isCalendarComponentSelected) {
            let startDate = moment(selectedDateRange.from).format('YYYY-MM-DD');
            let endDate = moment(selectedDateRange.to).format('YYYY-MM-DD');
            builtUrl = `/${startDate}/${endDate}`;
        }
        dispatch(downloadReport(`${modalData.graphId}${builtUrl}`));
        setOpenGraphModal(false)
    }

    //To create filter labels and value.
    useEffect(() => {
        if (graphData) {
            const data = graphData[0]?.values;
            const createFilters = () => {
                const events = {};
                const filtersData = [];
                const initialSelectedFilterData = []
                data[0]?.vehicleUtilizationData.map(row => {
                    events[row.useDescription] = row.useDescription
                    return row;
                });

                Object.keys(events).forEach(key => {
                    filtersData.push({
                        label: events[key],
                        value: key
                    })
                    initialSelectedFilterData.push(key)

                });
                setEventFilters(filtersData);
                setDefaultValue(initialSelectedFilterData)
                setSelectedEvents(initialSelectedFilterData)
            }
            if (data[0]?.vehicleUtilizationData) {
                createFilters();
            }
        }
    }, [graphData]);

    const filterDataFunction = (value) => {
        setIsLoading(true);
        // Function to filter data based on the given filter values
        const filterDataByValues = (filters) => {
            return graphData.map(item => ({
                values: item.values.map(subItem => ({
                    date: subItem.date,
                    vehicleUtilizationData: subItem.vehicleUtilizationData.filter(entry => filters.includes(entry.useDescription))
                }))
            }));
        };

        if (value) {
            const filteredData = filterDataByValues(value);
            const output = { filteredData };
            setGraphFilterData(output.filteredData)


        }
    }

    //To handle changes filters with the help of value
    const onFilterChange = (values) => {
        let data = graphData[0]?.values;
        if (data) {
            data = data[0]?.vehicleUtilizationData.filter((row) => {
                let index = values.value ? values.value.indexOf(row.maintenanceCategory) : values.indexOf(row.maintenanceCategory);
                if (index > -1) {
                    return row;
                }
            })
        }
        setIsOpenPopup(false);
        setSelectedEvents(values?.value ? values?.value : values);

        filterDataFunction(values?.value ? values?.value : values)
    }

    const onCancelFilter = () =>{
        setIsOpenPopup(false)
    }

    useEffect(() => {
        setIsLoading(false);
    }, [graphFilterData])

    {/* 
    const exportReport = async () => {
        setIsExportLoading(true);

        let builtUrl = ``;
        if(isCalendarComponentSelected){
            let startDate = moment(selectedDateRange.from).format('YYYY-MM-DD');
            let endDate = moment(selectedDateRange.to).format('YYYY-MM-DD');
            builtUrl = `/${startDate}/${endDate}`;
        }

        const { cancelPrevQuery, result, error } = await fetchDataUsingCancelToken(
            baseURL,
            'json',
            `/reports/download-report/${modalData.graphId}${builtUrl}`,
            'get',
            null,
        );

        // const { cancelPrevQuery, result, error } = await fetchDataUsingCancelToken(
        //     baseURL,
        //     "json",
        //    '/vehicle-insights-export',
        //    'post',
        //    {"globalSearch":"","usage":[],"status":[],"make":[],"model":[],"year":[],"level1Desc":[],"level2Desc":[],"level3Desc":[],"level4Desc":[],"level5Desc":[],"level6Desc":[],"level7Desc":[],"level8Desc":[],"level9Desc":[],"level10Desc":[],"sortCriteriaInput":[{"field":"firstName","order":"desc"}],"pagingCriteriaInput":{"pageNumber":1,"pageSize":20},"healthType":null,"clientNumber":"1015","pageNumber":1,"pageSize":16000}
        // );



        if (cancelPrevQuery) return;

        if (result) {
            setIsExportLoading(false);
            window.open(result.url);
        } else if(error) {
            setIsExportLoading(false);
        }
    }
*/}


    return (
        <Modal isBodyScrollPrevented={true} open={openGraphModal} onClose={toggleModalHandler} wide={true} className={styles.modalConfig}>
            <ModalContent className={styles.modalContent}>
                <ModalTitle className={styles.modalHeading} gutter={false}>{modalData?.graphTitle}</ModalTitle>
                {<div className={styles.downloadIcon}>
                    {isExportLoading ? <CircularProgress /> : <SvgIcon className={styles.svgStyle} onClick={newExportReport}>
                        <Download />
                    </SvgIcon>}
                    {/* filter functionality for client vehicle utilization */}
                    {modalData.graphTitle === "Client Vehicle Utilization" &&
                        (
                            <img src={FilterIcon} alt="Filter Icon" onClick={handleOpen} className={styles.iconFilter} />
                        )}
                    {defaultValue.length !== selectedEvents.length &&
                        (<span className={styles.colorDot}></span>)}

                    <Popup open={isOpenPopup} onClose={handleClose} closeOnDocumentClick
                        {...{ contentStyle, overlayStyle, arrowStyle }}
                    >
                        <ReportPopupFilter
                            heading={'Filters'}
                            data={eventFilters}
                            onFilterChange={onFilterChange}
                            onCancelFilter={onCancelFilter}
                            defaultValue={defaultValue}
                            values={selectedEvents}
                        />
                    </Popup>

                </div>}
                {
                   modalData.graphDateRange !== "N/A" && modalData.graphHideCalendar === 0 && (
                        <div onClick={toggleCalendarIsOpen} className={styles.calendarButton}>
                            <CalendarIcon />
                        </div>
                    )
                }
                {
                    isOpen && (
                        <CustomDateRangePicker
                            currentDateRange={selectedDateRange}
                            onSelectedDateRange={selectedDateRangeHandler}
                            onToggleCalendarIsOpen={toggleCalendarIsOpen}
                            isGraphType={modalData.graphSpecification}
                        />
                    )
                }
            </ModalContent>
            <ModalContent>
                <Grid direction="column" gutter={false}>
                    {!isLoading && modalData &&
                        <GridItem>
                            <GraphPlaceholder
                                graphId={modalData.graphId}
                                graphType={modalData.graphType}
                                graphTitle={modalData.graphTitle}
                                graphDescription={modalData.graphDescription}
                                graphSpecification={modalData.graphSpecification}
                                graphData={graphFilterData ? graphFilterData : graphData}
                                graphHideCalendar= {modalData.graphHideCalendar}
                                styleType="modal"
                                height={GRAPH_HEIGHT}
                                width={GRAPH_WIDTH}
                                isModal={true}
                            />
                        </GridItem>
                    }
                    {
                        isLoading &&
                        <GridItem>
                            <div style={{ height: GRAPH_HEIGHT, width: GRAPH_WIDTH, position: 'relative' }}>
                                <div className={styles.loader}>
                                    <LPLoader
                                        loading={true}
                                        message={"Loading Data..."}
                                    />
                                </div>
                            </div>
                        </GridItem>
                    }
                </Grid>
            </ModalContent>
        </Modal>
    )
}

GraphModal.propTypes = {

}

export default GraphModal
