import CONSTANTS from "../Constants/driver";

export const insertInitalDriverDetails = (params) => ({
  type: CONSTANTS.INSERT_INITIAL_DRIVER_DETAILS,
  payload: params,
})

export const insertInitialToTime = (params) => ({
  type: CONSTANTS.INSERT_TO_TIME,
  payload: params,
})

export const insertInitialFromTime = (params) => ({
  type: CONSTANTS.INSERT_FROM_TIME,
  payload: params,
})

export const insertSelectedEvent = (params) => ({
  type: CONSTANTS.INSERT_TO_EVENT,
  payload: params,
})

export const fetchDriverDetails = (params) => ({
  type: CONSTANTS.FETCH_DRIVER_DETAILS,
  payload: params,
});

export const resetDriverDetails = (params) => ({
  type: CONSTANTS.RESET_DRIVER_DETAILS
});

export const fetchTrips = (params) => ({
  type: CONSTANTS.FETCH_TRIPS,
  payload: params,
})

export const fetchLogRecordTrips = (params) => ({
  type: CONSTANTS.FETCH_LOG_RECORD_TRIPS,
  payload: params,
})

export const fetchDeviceLastLocation = (params) => ({
  type: CONSTANTS.FETCH_DEVICE_LAST_LOCATION,
  payload: params,
})

export const fetchSingleDeviceLastLocation = (params) => ({
  type: CONSTANTS.FETCH_SINGLE_DEVICE_LAST_LOCATION,
  payload: params,
})

export const fetchSingleLogRecordTrips = (params) => ({
  type: CONSTANTS.FETCH_SINGLE_LOG_RECORD_TRIPS,
  payload: params,
})

export const fetchCourses = (params) => ({
  type: CONSTANTS.FETCH_COURSES,
  payload: params,
})

export const fetchCommunication = (params) => ({
  type: CONSTANTS.FETCH_COMMUNICATION,
  payload: params,
})

export const fetchEvents = (params) => ({
  type: CONSTANTS.FETCH_EVENTS,
  payload: params,
})

export const fetchServiceEvents = (params) => ({
  type: CONSTANTS.FETCH_SERVICE_EVENTS,
  payload: params,
})

export const fetchOneScore = (params) => ({
  type: CONSTANTS.FETCH_ONE_SCORE,
  payload: params,
})


export default {
  fetchDriverDetails,
  resetDriverDetails,
  fetchCourses,
  fetchTrips,
  fetchCommunication,
  fetchEvents,
  fetchOneScore
};
