import React, { useEffect, useState } from 'react';
import { getLabel } from '../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import styles from './VehicleMaintenance.module.scss';
import { fetchVehicleMaintenance, fetchVehicleMaintenanceHistory, setFuelMaintenanceHistory, setSelectedEventsUId } from '../../../Actions/vehicle.action';
import NavigationRoundedIcon from '@material-ui/icons/NavigationRounded';
import FilterIcon from "../../../Asset/Images/filter.png";
import {
    Grid,
    GridItem,
    Heading,
    Text,
    Spacing,
} from '@leaseplan/ui';
import { IconButton } from '@material-ui/core';
import Popup from 'reactjs-popup';
import { insertInitalDriverDetails } from '../../../Actions/driver.action';
import PopupFilterComponent from '../../../Common/PopupFilter/PopupFilter';
import LPLoader from '../../../Common/LPLoader/LPLoader';


const contentStyle = { background: '#FFF' };
const overlayStyle = { background: 'rgba(0,0,0,0.5)' };
const arrowStyle = { color: '#FFF' }; // style for an svg element


const VehicleMaintenance = ({ prismicData, vin }) => {

    const dispatch = useDispatch();

    const MaintenanceEventSelectedIdSelector = useSelector(state => state.vehicle.setSelectedEvents);
    const vehicleMaintenanceSelector = useSelector(state => state.vehicle.vehicleMaintenance);
    const vehicleMaintenanceHistorySelector = useSelector(state => state.vehicle.vehicleMaintenanceHistory);

    const [vehicleMaintenanceData, setVehicleMaintenanceData] = useState(null);
    const [vehicleMaintenanceDataHistory, setVehicleMaintenanceDataHistory] = useState(null);
    const [calculateDate, setCalculateDate] = useState(null);
    const [historyTimeLineData, setHistoryTimeLineData] = useState(false);
    const [eventFilters, setEventFilters] = useState([]);
    const [selectedEvents, setSelectedEvents] = useState([]);
    const [isLoading, setIsLoading] = useState([false]);

    const onHandleClick = (data) => {
        // filter History data for that date
        const filteredData = historyTimeLineData.filter(item =>
            item.maintenanceCategory === "Fuel" &&
            item.eventDate === data.eventDate
        );
        dispatch(setFuelMaintenanceHistory(filteredData))
        sessionStorage.setItem("selectedDate", data.eventDate)
        dispatch(insertInitalDriverDetails({ deviceId: vin, selectedDate: data.eventDate }));
        dispatch(setSelectedEventsUId(data.recordId));
    }

    // to filter historial timeLine data using dateRange
    const fetchTimeLineData = (obj, daysInMs) => {
        let today = new Date().getTime()
        let date = new Date(today - daysInMs)
        // to fetch the object data between the startdate timeline
        let newArray = obj.filter(function (el) {
            return new Date(el.eventDate).getTime() >= date
        });
        setHistoryTimeLineData(newArray)
    }

    //To create filter labels and value.
    useEffect(() => {
        if (vehicleMaintenanceHistorySelector) {
            const { isLoading, data } = vehicleMaintenanceHistorySelector;
            const createFilters = () => {
                const events = {};
                const filtersData = [];
                const initialSelectedFilterData = []
                data.map(row => {
                    events[row.maintenanceCategory] = row.maintenanceCategory
                    return row;
                });

                Object.keys(events).forEach(key => {
                    filtersData.push({
                        label: events[key],
                        value: key
                    })
                    initialSelectedFilterData.push(key)

                });
                setEventFilters(filtersData);
                setSelectedEvents(initialSelectedFilterData)
            }

            if (!isLoading && data.length > 0) {
                createFilters();
                setIsLoading(false)
                setVehicleMaintenanceDataHistory(data);
                setHistoryTimeLineData(data)
            } else {
                setVehicleMaintenanceDataHistory([]);
                setHistoryTimeLineData([])
            }
        }
    }, [vehicleMaintenanceHistorySelector]);

    //To handle chanegs filters with the help of value
    const onFilterChange = (values) => {
        let { data } = vehicleMaintenanceHistorySelector;
        if (data) {
            data = data.filter((row) => {
                let index = values.value.indexOf(row.maintenanceCategory);
                if (index > -1) {
                    return row;
                }
            })
        }

        setSelectedEvents(values.value);
        setVehicleMaintenanceDataHistory(data);
        setHistoryTimeLineData(data)
    }


    useEffect(() => {
        dispatch(fetchVehicleMaintenance({
            vin: vin
        }))
    }, [dispatch, vin]);

    // To update the date range title and TimeLine Data using Date range
    const updateDateRange = (dateInTime) => {
        let endDateTime
        if (vehicleMaintenanceDataHistory && dateInTime) {
            let currentDate = new Date()
            currentDate.setDate(currentDate.getDate() - 1)
            let startdate = currentDate.toString().split(' ')
            if (dateInTime.target) {
                endDateTime = new Date().getTime() - dateInTime.target.value
                fetchTimeLineData(vehicleMaintenanceDataHistory, dateInTime.target.value)
            }
            else {
                endDateTime = new Date().getTime() - dateInTime
                fetchTimeLineData(vehicleMaintenanceDataHistory, dateInTime)
            }

            let enddate = new Date(endDateTime).toString().split(' ')
            setCalculateDate(enddate[1] + ' ' + enddate[2] + ' - ' + startdate[1] + ' ' + startdate[2])
        }
    }



    useEffect(() => {
        setIsLoading(true)
        const { data, isLoading } = vehicleMaintenanceSelector;
        if (!isLoading && data?.vehicleMaintenanceStatusDto) {
            dispatch(fetchVehicleMaintenanceHistory({
                vehicleId: data?.vehicleMaintenanceStatusDto?.vehicleId
            }))
            setVehicleMaintenanceData(data?.vehicleMaintenanceStatusDto);
        }

    }, [vehicleMaintenanceSelector]);


    // useEffect(() => {
    //     const { isLoading, data } = vehicleMaintenanceHistorySelector;
    //     if (!isLoading && data.length > 0) {
    //         setVehicleMaintenanceDataHistory(data);
    //         setHistoryTimeLineData(data)

    //     }
    // }, [vehicleMaintenanceHistorySelector]);

    return (
        <div className={styles.container}>
            <Spacing mb={1}>
                <Heading component="p" size={'xs'} color={'petrolBlue'} className={styles.heading} gutter>{getLabel('vehicle_maintenance', 'Vehicle Maintenance ', prismicData)}</Heading>
            </Spacing>

            <Grid direction='column' gutter={false}>

                <GridItem>
                    <Spacing p={1}>
                        <Grid direction='row' justifyContent={'space-between'} gutter={false}>
                            <GridItem>
                                <Text component="p" size={'xs'} fontWeight={'bold'} color={'steel'} className={styles.heading} gutter={false}>CURRENT ODOMETER</Text>
                            </GridItem>
                            <GridItem>
                                <Text component="p" size={'xs'} fontWeight={'bold'} color={'petrolBlue'} className={styles.heading} gutter={false}>{vehicleMaintenanceData?.odometer ? vehicleMaintenanceData.odometer : "--"}</Text>
                            </GridItem>
                        </Grid>
                    </Spacing>
                </GridItem>
                <GridItem>
                    <Spacing p={1}>
                        <Grid direction='row' justifyContent={'space-between'} gutter={false}>
                            <GridItem>
                                <Text component="p" size={'xs'} fontWeight={'bold'} color={'steel'} className={styles.heading} gutter={false}>LAST SERVICE ODOMETER</Text>
                            </GridItem>
                            <GridItem>
                                <Text component="p" size={'xs'} fontWeight={'bold'} color={'petrolBlue'} className={styles.heading} gutter={false}>{vehicleMaintenanceData?.lastServiceOdometer ? vehicleMaintenanceData.lastServiceOdometer : "--"}</Text>
                            </GridItem>
                        </Grid>
                    </Spacing>
                </GridItem>
                <GridItem>
                    <Spacing p={1}>
                        <Grid direction='row' justifyContent={'space-between'} gutter={false}>
                            <GridItem>
                                <Text component="p" size={'xs'} fontWeight={'bold'} color={'steel'} className={styles.heading} gutter={false}>NEXT SERVICE ODOMETER</Text>
                            </GridItem>
                            <GridItem>
                                <Text component="p" size={'xs'} fontWeight={'bold'} color={'petrolBlue'} className={styles.heading} gutter={false}>{vehicleMaintenanceData?.nextServiceOdometer ? vehicleMaintenanceData.nextServiceOdometer : "--"}</Text>
                            </GridItem>
                        </Grid>
                    </Spacing>
                </GridItem>
                <GridItem>
                    <Spacing p={1}>
                        <Grid direction='row' justifyContent={'space-between'} gutter={false}>
                            <GridItem>
                                <Text component="p" size={'xs'} fontWeight={'bold'} color={'steel'} className={styles.heading} gutter={false}>MILES TO NEXT SERVICE</Text>
                            </GridItem>
                            <GridItem>
                                <Text component="p" size={'xs'} fontWeight={'bold'} color={'petrolBlue'} className={styles.heading} gutter={false}>{vehicleMaintenanceData?.milesUntilNextService ? vehicleMaintenanceData.milesUntilNextService : "--"}</Text>
                            </GridItem>
                        </Grid>
                    </Spacing>
                </GridItem>
                <GridItem>
                    <Spacing p={1}>
                        <Grid direction='row' justifyContent={'space-between'} gutter={false}>
                            <GridItem>
                                <Text component="p" size={'xs'} fontWeight={'bold'} color={'steel'} className={styles.heading} gutter={false}>SERVICE STATUS</Text>
                            </GridItem>
                            <GridItem>
                                <Text component="p" size={'xs'} fontWeight={'bold'} color={'petrolBlue'} className={styles.heading} gutter={false}>{vehicleMaintenanceData?.serviceStatus ? vehicleMaintenanceData.serviceStatus : "--"}</Text>
                            </GridItem>
                        </Grid>
                    </Spacing>
                </GridItem>
            </Grid>


            <Spacing mt={1} mb={1} mr={1}>
                <Heading component="p" size={'xxs'} color={'petrolBlue'} className={styles.heading} gutter>{getLabel('history', 'History', prismicData)}</Heading>

                <Grid justifyContent='right'>
                    <div className={styles.calendarText}>
                        <Text component="p" size={'xs'} color={'petrolBlue'} gutter={false}> {calculateDate ? calculateDate : updateDateRange(5184000000)}</Text>
                    </div>
                    <Spacing mh={.5} />
                    <select className={styles.selectDiv}
                        onChange={updateDateRange}
                    >
                        <option value="5184000000">Last 60 Days</option>
                        <option value="2592000000">Last 30 Days</option>
                        <option value="1209600000">Last 14 Days</option>
                        <option value="604800000">Last 7 Days</option>
                    </select>
                    <Spacing ph={1} />
                    <Popup
                        trigger={<img src={FilterIcon} alt="Filter Icon" className={styles.iconFilter} />}
                        position="left top"
                        {...{ contentStyle, overlayStyle, arrowStyle }}
                    >
                        <PopupFilterComponent
                            data={eventFilters}
                            onFilterChange={onFilterChange}
                            values={selectedEvents}
                        />
                    </Popup>
                </Grid>
            </Spacing>

            <Grid justifyContent="space-between">
                <GridItem span={{ tablet: 11.5 }}>
                    {/* <Timeline> */}
                    {
                        historyTimeLineData && historyTimeLineData.map((data) =>

                            <div className={styles.tripTimeCell}>
                                <div className={styles.timeline}>
                                    <div className={styles.greyCircle}></div>
                                    <div className={styles.vl}></div>

                                </div>
                                <Spacing ph={.5} />
                                <Grid direction='row' justifyContent={'space-between'} gutter={false}>
                                    <GridItem span={3}>
                                        <Text component="p" size={'xs'} fontWeight={'bold'} color={'steel'} className={styles.heading} gutter={false}>{data.eventDate}</Text>
                                    </GridItem >
                                    <GridItem justifyContent='left' span={5}>
                                        <Text component="p" size={'xs'} fontWeight={'bold'} color={'steel'} className={styles.heading} gutter={false}>{data.merchantName ? data.merchantName : data.maintenanceCategory}</Text>
                                        {data.maintenanceCategory === 'Fuel' && (<>

                                            <Text component="p" size={'xs'} color={'steel'} className={styles.heading} gutter={false}>{`Gallons: ${data.quantity ? data.quantity : '--'}`}</Text>
                                            <Text component="p" size={'xs'} color={'steel'} className={styles.heading} gutter={false}>{`Fuel Type: ${data.maintenanceDescription ? data.maintenanceDescription : '--'}`}</Text>
                                            <Text component="p" size={'xs'} color={'steel'} className={styles.heading} gutter={false}>{`Unit Cost: ${data.unitCost ? data.unitCost : '--'}`}</Text>
                                            <Text component="p" size={'xs'} color={'steel'} className={styles.heading} gutter={false}>{`Odometer: ${data.odometer ? data.odometer : '--'}`}</Text>
                                            <Text component="p" size={'xs'} color={'steel'} className={styles.heading} gutter={false}>{data.merchantCity ? `Location: ${data.merchantCity} ${data.merchantState}` : 'Location: --'}</Text>
                                        </>)}

                                        {data.maintenanceCategory === 'Accident' && (<>
                                            <Text component="p" size={'xs'} color={'steel'} className={styles.heading} gutter={false}>{`Status: ${data.accidentCloseDate ? 'closed' : 'open'}`}</Text>
                                            <Text component="p" size={'xs'} color={'steel'} className={styles.heading} gutter={false}>{`Date: ${data.accidentCloseDate ? data.accidentCloseDate : '--'}`}</Text>
                                            <Text component="p" size={'xs'} color={'steel'} className={styles.heading} gutter={false}>{`Inct #: ${data.incidentNumber ? data.incidentNumber : '--'}`}</Text>
                                            <Text component="p" size={'xs'} color={'steel'} className={styles.heading} gutter={false}>{data.merchantCity ? `Location: ${data.merchantCity} ${data.merchantState}` : 'Location: --'}</Text>
                                        </>)}
                                        {data.maintenanceCategory === 'Maintenance' && (<>
                                            <Text component="p" size={'xs'} color={'steel'} className={styles.heading} gutter={false}>{`${data.maintenanceDescription ? data.maintenanceDescription : '--'}`}</Text>
                                            <Text component="p" size={'xs'} color={'steel'} className={styles.heading} gutter={false}>{`Odometer: ${data.odometer ? data.odometer : '--'}`}</Text>
                                        </>)}
                                    </GridItem>
                                    <GridItem span={1}>
                                        <Text component="p" size={'xs'} fontWeight={'bold'} color={'petrolBlue'} className={styles.heading} gutter={false}>{data.totalCost ? '$' + data.totalCost : 'N/A'}</Text>



                                    </GridItem>
                                    <GridItem span={1}>
                                        {data.latitude != null ? (
                                            <IconButton onClick={(e) => onHandleClick(data)}>
                                                <NavigationRoundedIcon htmlColor={'#ee4c14'} style={{ transform: 'rotate(45deg)', color: MaintenanceEventSelectedIdSelector?.data === data.recordId ? '#ee4c14' : '#004a5d' }} />
                                            </IconButton>
                                        ) : (
                                            <IconButton>
                                                <NavigationRoundedIcon htmlColor={'#545656'} style={{ transform: 'rotate(45deg)', color: '#545656' }} />
                                            </IconButton>
                                        )}

                                    </GridItem>
                                </Grid>
                            </div>
                        )
                    }
                    {
                        isLoading && (
                            <div className={styles.noDataAvailable}>
                                <Text component="p" size={'s'} color={'petrolBlue'} className={styles.heading} gutter={false}>
                                    No maintenance Data Available
                                </Text>
                                {/* <LPLoader
						loading={true}
						message={"Loading Data ..."}
					/> */}
                            </div>
                        )
                    }
                </GridItem>
            </Grid>
        </div>
    )
}

VehicleMaintenance.propTypes = {

}

export default React.memo(VehicleMaintenance)
