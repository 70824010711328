import React, {useState, useEffect } from 'react';
import axios from "../../AxiosInterceptor/index";
import { baseURL } from "../../Store/base-url";
import PropTypes from 'prop-types';



const useFetch = (method, url, body, dependency) => {
    const [isLoading, setIsLoading] = useState(false);
    const [apiData, setApiData] = useState([]);
    const [serverError, setServerError] = useState(null);

  
    useEffect(() => {
      setIsLoading(true);
      const fetchData = async () => {
        try {
          const resp = await axios({
            baseURL: baseURL,
            responseType: "json",
            url: url,
            method: method,
            data:{
                ...body,
            }
          });
          const data = await resp?.data;
          setApiData(data);
          setIsLoading(false);
        } catch (error) {
          setServerError(error);
          setIsLoading(false);
        }
      };
      fetchData();
    }, [...dependency]);
  
    return { isLoading, apiData, serverError };
};

useFetch.propTypes = {
    method: PropTypes.oneOf(['POST', 'post', 'GET', 'get']).isRequired,
    url: PropTypes.string.isRequired,
    body: PropTypes.object.isRequired
}

export default useFetch;

