import axios from "../AxiosInterceptor/index";
import { call, put, takeEvery } from "redux-saga/effects";
import CodeList from "../Constants/code-list";
import { baseURL } from "../Store/base-url";
import { F, S } from "../utils/actions";

function codeListGetHeaderIdApi(params) {
  return axios.request({
    baseURL: baseURL,
    responseType: "json",
    url: `/mm/code-master-headers/search`,
    data: {
      searchCriteria: {
        model: params,
      },
    },
    method: "post",
  });
}

function codeListGetDetailApi(params) {
  return axios.request({
    baseURL: baseURL,
    responseType: "json",
    url: `/mm/code-master-headers/${params.headerId}/code-master-details`,
    method: "get",
  });
}

export function* codeListWorker(action) {
  try {
    // data is obtained after axios call is resolved
    yield put({ type: S(CodeList.FETCHING_CODELIST_DATA), isLoading: true });
    let codeListHeader = yield call(codeListGetHeaderIdApi, action.payload);
    if (codeListHeader.data.message.code === "SUCCESS") {
      let { data } = yield call(codeListGetDetailApi, {
        headerId: codeListHeader.data.payload.pageEntries[0].mmCodeHeaderUid,
      });
      // dispatch action to change redux state
      if (data.payload) {
        yield put({
          type: S(CodeList.FETCHING_CODELIST_DATA),
          isLoading: false,
        });
        yield put({
          type: S(action.type),
          entityName: action.payload.entityName,
          entityItem: action.payload.entityItem,
          payload: data,
        });
      } else {
        yield put({
          type: S(CodeList.FETCHING_CODELIST_DATA),
          isLoading: false,
        });
        yield put({
          type: F(action.type),
          entityName: action.payload.entityName,
          entityItem: action.payload.entityItem,
          error: data,
        });
      }
    } else {
      yield put({ type: S(CodeList.FETCHING_CODELIST_DATA), isLoading: false });
      yield put({
        type: F(action.type),
        entityName: action.payload.entityName,
        entityItem: action.payload.entityItem,
        error: codeListHeader.data,
      });
    }
  } catch (e) {
    // dispatch action to change redux state
    yield put({ type: S(CodeList.FETCHING_CODELIST_DATA), isLoading: false });
    yield put({
      type: F(action.type),
      entityName: action.payload.entityName,
      entityItem: action.payload.entityItem,
      error: e,
    });
  }
}


function* codeListSaga() {
    yield takeEvery(CodeList.FETCH_CODE_LIST, codeListWorker);
}


export default codeListSaga;
