
export const SidebarConstant = {
    id:'personTrip',
    actionText: "Save",
    inputs: [
        {
            id: 1,
            propertyName: "personalTrip",
            alias: "personal_trip",
            name: "Personal Trip",
            fieldType: "select",
            valueType: "",
            value: "",
            isDisabled: false,
            isRequired: false,
            defaultValue: "",
            span: 12,
            errorMessage: "",
            validation: "text",
            validationState: "pristine",
            disableDefault: false,
            options: [
                {
                    name: 'Yes',
                    val: 'yes'
                },
                {
                    name: 'No',
                    val: 'no'
                }
            ]
        },
        {
            id: 2,
            propertyName: "projectCode",
            alias: "project_code",
            name: "Project Code",
            fieldType: "input",
            valueType: "",
            value: "",
            span: 12,
            defaultValue: "",
            validation: "text",
            placeholder: "123456",
            isRequired: false,
            disabled: false,
            errorMessage: "",
            validationState: "pristine",
            maxLength: 50
        }
    ],
};
