import { Grid, GridItem, Text, Spacing } from "@leaseplan/ui";
import React from "react";
import {
  convertSecondsInHoursAndMinutes,
  getOffsetTimeZone,
} from "../../../utils";
import road_img from "../../../Asset/Images/streetViewIcon.svg";
import styles from "./Popup.module.scss";
import LPTooltip from "../../../Common/LPTooltip/LPTooltip";
import LocationMaskingTooltip from "./LocationMaskingTooltip";

const EventsTooltip = ({ leafletLatLng, eventData }) => {
  let Time = getOffsetTimeZone(
    eventData.activeFrom,
    eventData.timezoneShortName,
    eventData.utcOffset
  );
  let lat = parseFloat(leafletLatLng.lat).toFixed(7);
  let lng = parseFloat(leafletLatLng.lng).toFixed(7);

  //As per events get the UI labels and value

  const getLabelAndValue = (type, eventData) => {
    switch (type) {
      case "clock_in": // pin
        return { label: "", value: "" };
      case "clock_out": // pin
        return { label: "", value: "" };
      case "ticket": // pin
        return {
          label: "Ticket No",
          value: eventData.ticketNumber,
          label2: "Duration",
          value2: eventData.threshold + " s",
        };
      case "posted_speed": // segment
        return {
          label: "Speed limit",
          value: eventData.averageEventRuleValue + " mph",
          label2: "Speed",
          value2: eventData.averageEventDataValue + " mph",
        };
      case "harsh_acceleration": // pin
        return {
          label: "Acceleration",
          value:
            (eventData.averageEventDataValue * 2.237).toFixed(2) + " mph/s",
        };
      case "idling": // pin
        return {
          label: "Duration",
          value: convertSecondsInHoursAndMinutes(eventData.durationSeconds),
        };
      case "max_speed": // segment
        return {
          label: "Speed limit",
          value: eventData.averageEventRuleValue + " mph",
          label2: "Speed",
          value2: eventData.averageEventDataValue.toFixed(2) + " mph",
        };
        
      case "seatbelt": // pin
        return {
          label: "Duration",
          value: convertSecondsInHoursAndMinutes(eventData.durationSeconds),
        };
      case "hard_braking": // pin
        return {
          label: "Deceleration",
          value:
            (eventData.averageEventDataValue * 2.237).toFixed(2) + " mph/s",
        };
      case "reverse": // based on distance
        return {
          label: "Distance",
          value: parseFloat(eventData.distanceTraveled).toFixed(2) + " ft",
          label2: "Duration",
          value2: convertSecondsInHoursAndMinutes(eventData.durationSeconds),
        };
      case "low_voltage": // pin
        return {
          label: "Low Voltage",
          value: eventData.averageEventDataValue
            ? eventData.averageEventDataValue.toFixed(2) + " volts"
            : "--",
        };
      case "location_masking": // pin
        return {
          label: "Activation Data",
          value: "",
          label2: "Speed",
          value2: eventData.startSpeed + " mph",
        };
      // default:
      //     return { label: '', value: '' }
    }
  };
  let { label, value, label2, value2 } = getLabelAndValue(
    eventData.ruleId,
    eventData
  );

  return eventData.ruleId != "location_masking" ? (
    <>
      <div className={styles.GridStyle__EventsTooltip}>
        <Grid gutter={false} justifyContent="center">
          <GridItem span={12}>
            <Spacing pb={0.2}>
              <Text fontWeight="bold" size="xs" color="bloodOrange">
                {eventData.ruleLabel}
              </Text>
            </Spacing>
          </GridItem>
          <GridItem></GridItem>
        </Grid>
        <Grid gutter={false} justifyContent="left">
          <GridItem span={6}>
            <Text size="xs" color="petrolBlue">
              {label}
            </Text>
            <GridItem>
              <Text size="xs" fontWeight="bold" color="petrolBlue">
                {value}
              </Text>
            </GridItem>
          </GridItem>
          {label2 && (
            <>
              <div className={styles.eventVerticalLine}></div>
              <GridItem span={6}>
                <Text size="xs" color="petrolBlue">
                  {label2}
                </Text>
                <GridItem>
                  <Text size="xs" fontWeight="bold" color="petrolBlue">
                    {value2}
                  </Text>
                </GridItem>
              </GridItem>
            </>
          )}
        </Grid>
        <Spacing pb={0.5}></Spacing>
        <Grid gutter={false} justifyContent="left">
          <div className={styles.vertical1}></div>
          <GridItem span={6}>
            <Text size="xs" color="petrolBlue">
              Time
            </Text>
            <GridItem>
              <Text size="xs" fontWeight="bold" color="petrolBlue">
                {Time.split(" ")[0] +
                  " " +
                  Time.split(" ")[1] +
                  " " +
                  Time.split(" ")[2]}
              </Text>
            </GridItem>
          </GridItem>
          <div className={styles.eventVerticalLine}></div>
          <GridItem span={6}>
            <Text size="xs" color="petrolBlue">
              Date
            </Text>
            <GridItem>
              <Text size="xs" fontWeight="bold" color="petrolBlue">
                {Time.split(" ")[3]}
              </Text>
            </GridItem>
          </GridItem>
        </Grid>
        <Grid gutter={false} justifyContent="left">
          <Spacing pb={0.5}></Spacing>
          <GridItem span={9}>
            <Text size="xs" color="petrolBlue">
              Location
            </Text>
            <GridItem>
              <Text size="xs" fontWeight="bold" color="petrolBlue">
                {`${lat}, ${lng}`}
              </Text>
              <LPTooltip
                style={{ paddingLeft: "10px" }}
                title={"Show Street View"}
                placement="bottom"
                arrow
              >
                <img
                  src={road_img}
                  className={styles.img}
                  onClick={() => {
                    window.open(
                      `https://www.google.com/maps?layer=c&cbll=${leafletLatLng.lat},${leafletLatLng.lng}`,
                      "_blank",
                      "noopener"
                    );
                  }}
                />
              </LPTooltip>
            </GridItem>
          </GridItem>
        </Grid>
      </div>
    </>
  ) : (
    <>
      <LocationMaskingTooltip eventData={eventData} />
    </>
  );
};

export default React.memo(EventsTooltip);
