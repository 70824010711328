import React from "react";
import styles from "./LPLoader.module.scss";
import { Loader, Text, ErrorIcon, ButtonLink } from "@leaseplan/ui";
import { getLabel } from "../../utils/index";

export const LPLoader = ({
  loading,
  functionName,
  functionParameter,
  prismicData = {},
  message = "Fetching data",
  color = "steel",
  groupId = "lp-loader",
}) => {
  if (loading) {
    return (
      <div className={styles.Loader}>
        <Loader />{" "}
        <Text size="s" color={color}>
          {getLabel(
            message.toLowerCase().split(" ").join("_"),
            message,
            prismicData
          )}
        </Text>
      </div>
    );
  }
  return (
    <div className={styles.Loader}>
      <ErrorIcon />{" "}
      <Text size="s" color={color}>
        {getLabel(
          message.toLowerCase().split(" ").join("_"),
          message,
          prismicData
        )}
      </Text>
      <ButtonLink
        component="button"
        color="bloodOrange"
        gutter
        size="xs"
        id={"try-again" + groupId}
        onClick={() => functionName && functionName(functionParameter)}
      >
        {getLabel("try_again", "Try Again", prismicData)}
      </ButtonLink>
    </div>
  );
};

export const NotDataFound = ({
  message = "No Data Found",
  color = "steel",
  prismicData,
}) => {
  return (
    <div className={styles.Loader}>
      <ErrorIcon />{" "}
      <Text size="s" color={color}>
        {getLabel(
          message.toLowerCase().split(" ").join("_"),
          message,
          prismicData
        )}
      </Text>
    </div>
  );
};

export default { LPLoader, NotDataFound };
