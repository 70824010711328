import React from 'react';
import {ResponsiveContainer} from 'recharts';
import styles from './Graph.module.scss';
import { GRAPH_TYPES } from '../../../Constants/reporting';

export const TableGraph = ({height, width, isModal, graphData, graphSpecification}) => {





  const data = graphSpecification === GRAPH_TYPES.PM_OVERDUE.VALUE ? 
  (
   
    
    [
        {
            "t1": "1015-123",
            "t2": "20501",
            "t3": "2100",
          },
          {
              "t1": "1015-123",
              "t2": "20501",
              "t3": "2100",
          },
          {
              "t1": "1015-123",
              "t2": "20501",
              "t3": "2100",
          },
      ]
  )
  : (
      [
        {
            "t1": "1015-123",
            "t2": "20501",
            "t3": "3100",
            "t4": "1015-123",
            "t5": "20501",
            "t6": "3100",
            "t7": "1015-123",
            "t8": "20501",
            "t9": "3100",
            "t10": "1015-123",
            "t11": "20501",
            "t12": "3100",
          },
          {
            "t1": "1015-123",
            "t2": "20501",
            "t3": "3100",
            "t4": "1015-123",
            "t5": "20501",
            "t6": "3100",
            "t7": "1015-123",
            "t8": "20501",
            "t9": "3100",
            "t10": "1015-123",
            "t11": "20501",
            "t12": "3100",
          },
          {
            "t1": "1015-123",
            "t2": "20501",
            "t3": "3100",
            "t4": "1015-123",
            "t5": "20501",
            "t6": "3100",
            "t7": "1015-123",
            "t8": "20501",
            "t9": "3100",
            "t10": "1015-123",
            "t11": "20501",
            "t12": "3100",
          },
      ]
  )

      if(graphSpecification === GRAPH_TYPES.PM_OVERDUE.VALUE) {

        return (
            <div className={styles.container}>
                 <ResponsiveContainer width={width} height={height} className={styles.graphArea}>
                  <table className={styles.table}>
                      <tbody>
                          <tr>
                          {!isModal ? (
                            <>
                              <th>Device Name</th>
                              <th>Odometer</th>
                              <th>Next PM</th>
                             </>
                           ) : (
                             <>
                              <th>Device Name</th>
                              <th>Odometer</th>
                              <th>Next PM</th>
                              <th>Service Status</th>
                              <th>Miles Next Service</th>
                              </>  
                          )}
                          </tr>
                          {
                            graphData[0]?.values?.map(ele => 
                                  <tr>
                                  {!isModal ? 
                                      (   
                                        <> 
                                            <td>{ele.pmOverdue.deviceName}</td>
                                            <td>{ele.pmOverdue.odometer}</td>
                                            <td>{ele.pmOverdue.nextServiceOdometer}</td>      
                                        </> 
                                       ) : (
                                        <>
                                            <td>{ele.pmOverdue.deviceName}</td>
                                            <td>{ele.pmOverdue.odometer}</td>
                                            <td>{ele.pmOverdue.nextServiceOdometer}</td>
                                            <td>{ele.pmOverdue.serviceStatus}</td>
                                            <td>{ele.pmOverdue.milesNextService}</td>
                                        </>  
                                   )} 
                                  </tr>
                              )
                          }
                      </tbody>
                  </table>
                  </ResponsiveContainer>
            </div>
          )
      } else {
        return (
            <div className={styles.container}>
                 <ResponsiveContainer width={isModal ? 1200 : width} height={height} className={styles.graphArea}>
                  <table className={styles.table}>
                      <tbody>
                          <tr>


                              {!isModal ? (
                                  <>
                                    <th>Driver ID</th>
                                    <th>VIN</th>
                                    <th>Device First Name</th>
                                  </>
                              ) : (
                                  <>
                                    <th>Driver ID</th>
                                    <th>VIN</th>
                                    <th>Device First Name</th>
                                    <th>Device Last Name</th>
                                    <th>Device Name</th>
                                    <th>Year</th>
                                    <th>Make</th>
                                    <th>Model</th>
                                    <th>Average Miles</th>
                                  </>
                              )}


                          </tr>
                          {
                              graphData[0]?.values?.map(ele => 
                                  <tr>
                                      {!isModal ? 
                                      (
                                          <>
                                            <td>{ele.maxMileageDriver.driverId}</td>
                                            <td>{ele.maxMileageDriver.vin}</td>
                                            <td>{ele.maxMileageDriver.firstName}</td>
                                          </>
                                      ) : (
                                        <>
                                            <td>{ele.maxMileageDriver.driverId}</td>
                                            <td>{ele.maxMileageDriver.vin}</td>
                                            <td>{ele.maxMileageDriver.firstName}</td>
                                            <td>{ele.maxMileageDriver.lastName}</td>
                                            <td>{ele.maxMileageDriver.deviceName}</td>
                                            <td>{ele.maxMileageDriver.modelYear}</td>
                                            <td>{ele.maxMileageDriver.makeDescription}</td>
                                            <td>{ele.maxMileageDriver.baseModelName}</td>
                                            <td>{ele.maxMileageDriver.totalMiles}</td>
                                        </>
                                      )}
                                  </tr>
                              )
                          }
                      </tbody>
                  </table>
                  </ResponsiveContainer>
            </div>
          )
      }

}


export default React.memo(TableGraph)