import { Heading, Text } from "@leaseplan/ui";
import { Icon } from "@velocity/ui.Icon";
import React from "react";
import styles from "./ComingSoon.module.scss";

const ComingSoon = ({
  headerText = "Coming Soon...",
  text = "We are currently working hard building this page."
}) => {

  return (
    <div className={styles.CommingSoon}>
      <Icon name="lock" size="xl" />
      <Heading size="xxs">{headerText}</Heading>
      <Text size="xs" color="steel">
        {text}
      </Text>
    </div>
  );
};
ComingSoon.propTypes = {};

ComingSoon.defaultProps = {};
export default ComingSoon;
