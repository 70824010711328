import { Grid, GridItem, Text, Spacing } from '@leaseplan/ui';
import React from 'react';
import road_img from "../../../Asset/Images/streetViewIcon.svg";
import styles from "./Popup.module.scss";
import LPTooltip from "../../../Common/LPTooltip/LPTooltip";

const VehicleMaintenanceTooltip = ({ leafletLatLng, eventData }) => {
    let lat = parseFloat(leafletLatLng.lat).toFixed(7)
    let lng = parseFloat(leafletLatLng.lng).toFixed(7)

    //As per events get the UI labels and value

    const getLabelAndValue = (type, eventData) => {
        switch (type) {
            case 'Fuel': // pin
                return { heading: "Fueling Event", label: 'Type', value: eventData.maintenanceDescription, label2: 'Gallons', value2: eventData.quantity, label3: "cost", value3: '$' + eventData.totalCost };
            // default:
            //     return { label: '', value: '' }
        }
    }
    let { heading, label, value, label2, value2, label3, value3 } = getLabelAndValue(eventData.maintenanceCategory, eventData)

    return (
        <div className={styles.GridStyle__EventsTooltip}>
            <Grid gutter={false} justifyContent="center">
                <GridItem span={12}>
                    <Spacing pb={0.3}>
                        <Text fontWeight="bold" size="xs" color="bloodOrange">{heading}</Text>
                    </Spacing>
                </GridItem>
                <GridItem>
                </GridItem>
                <GridItem span={12}>
                    <Spacing pb={0.3}>
                        <Text size="xs" color="petrolBlue">{eventData.merchantName}</Text>
                    </Spacing>
                </GridItem>
                <GridItem>
                </GridItem>
            </Grid>
            <Grid gutter={false} justifyContent="left">
                <GridItem span={4}>
                    <Text size="xs" color="petrolBlue">{label}</Text>
                    <GridItem>
                        <Text size="xs" fontWeight="bold" color="petrolBlue">{value ? value : '--'}</Text>
                    </GridItem>
                </GridItem>
                {label2 && (
                    <>
                        <div className={styles.vertical}></div>
                        <GridItem span={4}>
                            <Text size="xs" color="petrolBlue">{label2}</Text>
                            <GridItem>
                                <Text size="xs" fontWeight="bold" color="petrolBlue">{value2 ? value2 : '--'}</Text>
                            </GridItem>
                        </GridItem>
                    </>
                )}
                {label3 && (
                    <>
                        <div className={styles.verticalLine}></div>
                        <GridItem span={4}>
                            <Text size="xs" color="petrolBlue">{label3}</Text>
                            <GridItem>
                                <Text size="xs" fontWeight="bold" color="petrolBlue">{value3 ? value3 : '--'}</Text>
                            </GridItem>
                        </GridItem>
                    </>
                )}
                <Spacing pv={1} />
            </Grid>
            <Grid gutter={false} justifyContent="left">

                <GridItem span={9}>
                    <Text size="xs" color="petrolBlue">Location</Text>
                    <GridItem>
                        <Text size="xs" fontWeight="bold" color="petrolBlue">{`${lat}, ${lng}`}</Text>
                        <LPTooltip style={{ paddingLeft: "10px" }} title={'Show Street View'} placement="bottom" arrow>
                            <img src={road_img} className={styles.img} onClick={() => { window.open(`https://www.google.com/maps?layer=c&cbll=${leafletLatLng.lat},${leafletLatLng.lng}`, "_blank", 'noopener') }} />
                        </LPTooltip>
                    </GridItem>
                </GridItem>
            </Grid>
        </div>


    )
}


export default React.memo(VehicleMaintenanceTooltip)