import { S, F } from "../utils/actions";
import { SEARCH_PAGE } from "../Constants/searchPage";

const INITIAL_STATE = {
  data: [],
  resultSetCount: null,
  pageLimit: null,
  currentPage: null,
  isLoading: false,
  error: null,
};

const searchPage = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SEARCH_PAGE.SEARCH_PAGE_FETCH:
      return {
        ...state,
        data: [],
        isLoading: true,
        error: null,
      };
    case S(SEARCH_PAGE.SEARCH_PAGE_FETCH):
      return {
        data: action.data,
        resultSetCount: action.resultSetCount,
        pageLimit: action.pageLimit,
        currentPage: action.currentPage,
        isLoading: false,
      };
    case F(SEARCH_PAGE.SEARCH_PAGE_FETCH):
      return {
        data: [],
        resultSetCount: null,
        pageLimit: null,
        currentPage: null,
        isLoading: false,
        error: action.payload,
      };
    case SEARCH_PAGE.SEARCH_TYPE_FETCH:
      return {
        ...state,
        data: [],
        isLoading: true,
        error: null,
      };
    case S(SEARCH_PAGE.SEARCH_TYPE_FETCH):
      return {
        data: action.data,
        isLoading: false,
      };
    case F(SEARCH_PAGE.SEARCH_TYPE_FETCH):
      return {
        data: [],
        isLoading: false,
        error: action.payload,
      };

    case SEARCH_PAGE.SEARCH_TYPE_FLEET_FETCH:
      return {
        ...state,
        data: [],
        isLoading: true,
        error: null,
      };
    case S(SEARCH_PAGE.SEARCH_TYPE_FLEET_FETCH):
      return {
        data: action.data,
        isLoading: false,
      };
    case F(SEARCH_PAGE.SEARCH_TYPE_FLEET_FETCH):
      return {
        data: [],
        isLoading: false,
        error: action.payload,
      };
    case SEARCH_PAGE.IS_GLOBAL_SEARCH_FIELD:
      return { 
          ...state,
          isGlobalSearchField: action.payload
      };
    case SEARCH_PAGE.IS_TRIP_FILTER_SEARCH_FIELD:
        return { 
            ...state,
            isTripFilterSearchField: action.payload
        };
    default:
      return state;
  }
}

export default searchPage;