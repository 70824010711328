import React from "react";
import styles from './GraphComponents.module.scss';
import { BAR_SETTINGS, DONUT_SETTINGS, LINE_SETTINGS, PIE_SETTINGS } from "../GeneralSettings";
import { Spacing } from "@leaseplan/ui";
const RADIAN = Math.PI / 180;

export const CustomizedXAxisLabel = (props) => {
  const { x, y, payload, fontSize, dy, dx, fontWeight } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={dy}
        dx={dx}
        textAnchor="middle"
        fill="#666"
        fontSize={fontSize}
        fontWeight={fontWeight}
      >
        {payload.value}
      </text>
    </g>
  );
};

export const CustomizedYAxisLabel = (props) => {
  const { x, y, payload, fontSize, dy, dx, fontWeight } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={dy}
        dx={dx}
        textAnchor="middle"
        fill="#666"
        fontSize={fontSize}
        fontWeight={fontWeight}
      >
        {payload.value}
      </text>
    </g>
  );
};

export const AxisLabel = ({ axisType, viewBox: { x, y, width, height }, children, fontSize, fontWeight, labelOffset = 35 }) => {
  const isVert = axisType === 'yAxis';
  const cx = isVert ? x + labelOffset : x + (width / 2);
  const cy = isVert ? (height / 2) + y : y + height - 5;
  const rot = isVert ? `270 ${cx} ${cy}` : 0;
  return (
    <text
      x={cx}
      y={cy}
      transform={`rotate(${rot})`}
      textAnchor="middle"
      fontSize={fontSize}
      fontWeight={fontWeight}
    >
      {children}
    </text>
  );
};


export const RenderCustomizedLabelForDonut = ({
  cx, cy, midAngle, innerRadius, outerRadius, percent, index, payload, fontSize, color
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill={color} textAnchor="middle" dominantBaseline="central" fontSize={fontSize}>
      {`${payload.value}`}
    </text>
  );
}

export const RenderCustomizedLabelForPie = ({
  cx, cy, midAngle, innerRadius, outerRadius, percent, index, payload
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor="middle" dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
}

export const CustomLineReportTooltip = ({ active, payload, label, utilizationTooltipData }) => {

  //filter data as per label derive
  let desiredObject = []
  if (utilizationTooltipData?.length > 0) {
    desiredObject.push(utilizationTooltipData.find(item => item.date === label))
  }
  if (active && payload && payload.length) {
    return (
      <div className={styles.customTooltip}>
        <p className={styles.label}>{`${label}`}</p>
        <div className={styles.desc_head}>
          {
            payload.map((data) => (
              <div className={styles.desc}>
                <p style={{ color: data.color }} className={styles.name}>{data.name}</p>
                <p className={styles.value}>{data.value}</p>
              </div>
            ))
          }
          {utilizationTooltipData?.length > 0 &&
            desiredObject.map((data) => (
              Object.entries(data).map(([key, val]) =>
                key !== 'date' && (
                  <div className={styles.descUtil}>
                    <p  >{key}</p>
                    <p>{val}</p>
                  </div>
                )))
            )
          }
        </div>
      </div>
    );
  }

  return null;
};

export const CustomBarReportTooltip = ({ tooltipData, reportData }) => {
  return (
    <div className={styles.customTooltip}>
      <p className={styles.label}>{'Summary'}</p>
      <Spacing pv={.5} />
      <div className={styles.desc_head}>
        {
          tooltipData.map((data) => (
            <div className={styles.desc}>
              <p style={{ color: '#004a5d', fontWeight: 'bold' }} className={styles.name}>{(data.durationHours / 24).toFixed(2)}</p>
              <p style={{ color: '#004a5d', fontWeight: '10' }} className={styles.value}>{data.useDescription}</p>
            </div>
          ))
        }
        <ColoredLine color={'#dddddd'} />
        {!reportData && (
          <div className={styles.desc}>

            <p style={{ color: '#004a5d', fontWeight: 'bold' }} className={styles.name}>{'--'}</p>
            <p style={{ color: '#004a5d', fontWeight: 'bold' }} className={styles.value}>Total Days</p>
          </div>
        )}

      </div>
    </div>
  );
}

const ColoredLine = ({ color }) => (
  <hr
    style={{
      width: '100%',
      color: color,
      height: 1
    }}
  />
);

export const CustomNoDataTooltip = ({ tooltipData, reportData }) => {
  return (
    <div className={styles.customTooltip}>
      <p className={styles.label}>{'Summary'}</p>
      <Spacing pv={.5} />
      <div className={styles.desc_head}>

        <div className={styles.desc}>
          <p style={{ color: '#004a5d', fontWeight: '10' }} className={styles.value}>Vehicle has not reached the</p>
        </div>
        <div className={styles.desc}>
          <p style={{ color: '#004a5d', fontWeight: '10' }} className={styles.value}>specified date range of being active</p>
        </div>
        <ColoredLine color={'#dddddd'} />
        {!reportData && (
          <div className={styles.desc}>

            <p style={{ color: '#004a5d', fontWeight: 'bold' }} className={styles.name}>{'--'}</p>
            <p style={{ color: '#004a5d', fontWeight: 'bold' }} className={styles.value}>Total Days</p>
          </div>
        )}


      </div>
    </div>
  );
}



export const CustomPieReportTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className={styles.customTooltip}>
        <div className={styles.desc_head}>
          {
            payload.map((data) => (
              <div className={styles.desc}>
                <p style={{ color: data.payload.fill }} className={styles.name}>{data.name ? capitalizeAndConvert(data.name) : ''}</p>
                <p className={styles.value}>{data.value}</p>
              </div>
            ))
          }
        </div>
      </div>
    );
  }

  return null;
};

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const capitalizeAndConvert = (item) => {
  let words = item.split('_');
  return words.map(data => capitalizeFirstLetter(data)).join(' ');
}

export const findStyleSetting = (settingType, graphType) => {
  if (graphType === "line") {
    if (settingType === "dashboard") {
      return LINE_SETTINGS.Dashboard;
    } else if (settingType === "placeholder") {
      return LINE_SETTINGS.Placeholder;
    } else if (settingType === "modal") {
      return LINE_SETTINGS.Modal;
    }
  } else if (graphType === "pie") {
    if (settingType === "dashboard") {
      return PIE_SETTINGS.Dashboard;
    } else if (settingType === "placeholder") {
      return PIE_SETTINGS.Placeholder;
    } else if (settingType === "modal") {
      return PIE_SETTINGS.Modal;
    }
  } else if (graphType === "bar") {
    if (settingType === "dashboard") {
      return BAR_SETTINGS.Dashboard;
    } else if (settingType === "placeholder") {
      return BAR_SETTINGS.Placeholder;
    } else if (settingType === "modal") {
      return BAR_SETTINGS.Modal;
    }
  } else if (graphType === "donut") {
    if (settingType === "dashboard") {
      return DONUT_SETTINGS.Dashboard;
    } else if (settingType === "placeholder") {
      return DONUT_SETTINGS.Placeholder;
    } else if (settingType === "modal") {
      return DONUT_SETTINGS.Modal;
    }
  }

  return {};
}