import { all, fork } from "redux-saga/effects";
import codeListSaga from "./codeListSaga";
import globalSaga from "./globalSaga";
import userSaga from "./userSaga";
import timelineSaga from "./timelineSaga";
import driverEventSaga from './driverEvents.saga';
import clientConfigSaga from "./clientConfigSaga";
import filtersSaga from "./filtersSaga";
import searchPageSaga from "./searchPageSaga";
import fleetOverviewSaga from "./fleetOverview.saga";
import mapFiltersSaga from "./mapFiltersSaga";
import driverSaga from './driver.saga';
import driverInsightsPageSaga from "./driverInsightsPageSaga";
import VehicleInsightsPageSaga from "./VehicleInsightsPageSaga";
import authSaga from "./authSaga";
import levelsSage from './levels.saga';
import vehicleSaga from './vehicleSaga';
import tripAdminSaga from './tripAdmin.saga';
import reportingSaga from "./reportingSaga";
import driverLocationSaga from "./driverLocationSaga";

export default function* rootSaga() {
  yield all([
    fork(codeListSaga),
    fork(globalSaga),
    fork(userSaga),
    fork(timelineSaga),
    fork(driverLocationSaga),
    fork(driverEventSaga),
    fork(clientConfigSaga),
    fork(filtersSaga),
    fork(searchPageSaga),
    fork(fleetOverviewSaga),
    fork(mapFiltersSaga),
    fork(driverSaga),
    fork(driverInsightsPageSaga),
    fork(VehicleInsightsPageSaga),
    fork(authSaga),
    fork(levelsSage),
    fork(vehicleSaga),
    fork(tripAdminSaga),
    fork(reportingSaga)
  ]);
}
