import axios from "../AxiosInterceptor";
import { call, put, takeEvery, delay } from "redux-saga/effects";
import { DRIVERLOCATION } from "../Constants/driverLocation";
import { F, S } from "../utils/actions";

// debugger
function* driverLocationWorker(action) {
    yield put({
        type: S(action.type), payload: {
            Location: action.payload
        }
    })
}



function* driverLocationSaga() {
    yield takeEvery(DRIVERLOCATION.driver_Location, driverLocationWorker);
}


export default driverLocationSaga;