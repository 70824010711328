import React, { useState, useEffect } from 'react';
import styles from './PoolDrivers.module.scss';
import {
    Text,
} from "@leaseplan/ui";
import CircularProgressWithLabel from '../../OneConnectCommon/CircularProgressWithLabel/CircularProgressWithLabel';


const VehicleDetails = (props) => {
    const { driverScore, scoreColor, firstName, lastName } = props;
    return (
        <div className={styles.mainWrapper}>
            <CircularProgressWithLabel style={{color: scoreColor ? (scoreColor.toLowerCase() === 'yellow' ? '#F9C74B' : scoreColor.toLowerCase()) : 'red'}} size='2rem' value={driverScore ? driverScore : 0}/>

            <Text size="xs" className={styles.driverName}>{`${firstName} ${lastName}`}</Text>
        </div>
    );
}

export default VehicleDetails;