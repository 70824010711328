import {
    Text
  } from "@leaseplan/ui";
import React from 'react';
import styles from './EventRow.module.scss';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import { getOffsetTimeZone } from "../../../utils";

const EventsRows = (props) => {
    let {id, eventName, timeStamp, vehicleIcon, label, onEventRemoved, timeZoneId, timezoneShortName, utcOffset} = props;
    let date = moment(timeStamp);
    let dateTime = getOffsetTimeZone(date.format('YYYY-MM-DDTHH:mm:ss'), timezoneShortName,utcOffset);
   
    return (
        <div className={styles.eventRow}>
            <img src={vehicleIcon} className={styles.vehicleIcon} alt={eventName}/>
            <div className={styles.eventColumn}>
                <div className={styles.eventInfo}>
                    <Text component="p" size="xs" color="petrolBlue" gutter>{eventName}</Text>
                    <CloseIcon className={styles.closeIcon} onClick={() => onEventRemoved(id)}/>
                </div>
                <div className={styles.vehicleInfo}>
                    <Text component="p" size="xxs" color="petrolBlue" gutter>{dateTime}</Text>
                    <Text component="p" size="xxs" color="petrolBlue" className={styles.divider} gutter>{'|'}</Text>
                    <Text component="p" size="xxs" color="petrolBlue" gutter>{label}</Text>
                </div>
            </div>
        </div>
    )
}

export default EventsRows;