import { S, F } from "../utils/actions";
import {TIMELINE} from '../Constants/timeline'


//Status can be LIVE or OLD representing whether the data is real time or not
const INITIAL_STATE = {
  status: 'OLD',  
  clockData: {
      dayDate: null,
      fromTime: null,
      toTime: null,
      event: null
  },
  isPlaying: true,
  speed: 1,
  date:null,
};

const timeline = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TIMELINE.TIMELINE_CLOCKDATA_INSERT:
          return { 
              ...state,
            };
        case S(TIMELINE.TIMELINE_CLOCKDATA_INSERT):
            return { 
                ...state,
                clockData: {
                    dayDate: action.payload.dayDate,
                    fromTime: action.payload.fromTime,
                    toTime: action.payload.toTime,
                    event: action.payload.event
                },
                speed: action.payload.speed
        };
        case TIMELINE.TIMELINE_PLAY_INSERT:
            return {
                ...state,
            }
        case S(TIMELINE.TIMELINE_PLAY_INSERT):
            return {
                ...state,
                isPlaying: true,
            }
        case TIMELINE.TIMELINE_PAUSE_INSERT:
            return {
                ...state,
            }
        case S(TIMELINE.TIMELINE_PAUSE_INSERT):
            return {
                ...state,
                isPlaying: false,
            }
        case TIMELINE.TIMELINE_SPEED_INSERT:
            return {
                ...state,
                speed: action.payload.speed
            }
        case S(TIMELINE.TIMELINE_SPEED_INSERT):
            return {
                ...state,
                speed: action.payload
            }
        case TIMELINE.TIMELINE_DATE_INSERT:
            return {
                ...state,
            }
        case S(TIMELINE.TIMELINE_DATE_INSERT):
            return {
                ...state,
                date: action.payload
            }
        default:
          return state;
      }
}

export default timeline;