const images = {
    eventIcons: {
        posted_speed: require('../NewEventsIcons/posted-speed-teal.svg'),
        engine_light_on: require('./engine_light_on.png'),
        harsh_acceleration: require('../NewEventsIcons/harsh-acc-teal.svg'),
        clock_in: require('../NewEventsIcons/clock-in-teal.svg'),
        clock_out: require('../NewEventsIcons/clock-in-teal.svg'),
        ticket: require('../NewEventsIcons/ticket-yellow.svg'),
        //by default blue color ticket is not implemented yet
        // ticket: require('../NewEventsIcons/ticket-teal.svg'),
        ticket_Green: require('../NewEventsIcons/ticket-green.svg'),
        ticket_Red: require('../NewEventsIcons/ticket-red.svg'),
        ticket_Yellow: require('../NewEventsIcons/ticket-yellow.svg'),
        idling: require('../NewEventsIcons/idling-teal.svg'),
        max_speed: require('../NewEventsIcons/max-speed-teal.svg'),
        seatbelt: require('../NewEventsIcons/seatbelt-teal.svg'),
        hard_braking: require('../NewEventsIcons/braking-teal.svg'),
        reverse: require('../NewEventsIcons/reverse-teal.svg'),
        location_masking: require('../NewEventsIcons/location-Masking-Green.svg'),
        low_voltage: require('../NewEventsIcons/voltage-yellow.svg'),
        overdue_maintenance: require('./Overdue_Maintenance.png'),
        fuelingEventIcon: require('../NewEventsIcons/Fueling-Event-Icon.svg'),
    },
    oneScoreEvents: {
        data_behaviour: require('./os_useOfCar.png'),
        driver_training: require('./os_carTraining.png'),
        incidents: require('./os_accidentCar.png'),
        motor_vehicle_record: require('./os_files.png')
    }
};

export default images;