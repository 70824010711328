import React, { useEffect, useState } from 'react';
import styles from './PopupFilter.module.scss';
import { Heading, CheckboxList, Checkbox, CheckboxGroup, CheckboxGroupItem, Spacing, Text, Button } from "@leaseplan/ui";
import SettingsIcon from '@material-ui/icons/Settings';
import { MessageBar } from '@leaseplan/ui';


import location_icon from "../../Asset/Images/locationIcon.png";
import { InputBase } from '@material-ui/core';
import PopupFilterComponent from './PopupFilter';
import { getLabel, validateUsZipCode } from '../../utils';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const LAYOUTS = [
    {
        label: 'Fleet Overview',
        value: 'fleet_overview'
    },
    {
        label: 'Latest Events',
        value: 'latest_events'
    }
]

const MapPopUpFilter = (props) => {
    const { onFilterChange, values, prismicData, mapPopUPPlayload } = props;
    const [value, setValue] = useState(values);

    const filtersSelectordefaultValue = useSelector(state => state.filters.defaultConfig);
    const authSelector = useSelector(state => state.auth.user.clientUserDefaultDto);

    const [zipCode, setZipCode] = useState(filtersSelectordefaultValue.data.zip);
    const [showSaveButton, setShowSaveButton] = useState(false);
    const [sectionValues, setSectionValues] = useState(JSON.parse(sessionStorage.getItem("pannelArray")));

    const [hideLeftSection, setHideLeftSection] = useState(0);
    const [hideRightSection, setHideRightSection] = useState(0);
    const [timeZoneId, setTimeZoneId] = useState(sessionStorage.getItem("Timezone"));
    const [zipMsg, setZipMsg] = useState(true);
    const [buttonColor, setButtonColor] = useState("street");
    const ref = useRef()
    const dispatch = useDispatch();

    useEffect(() => {
        if (filtersSelectordefaultValue && filtersSelectordefaultValue.data) {
            setZipCode(filtersSelectordefaultValue.data.zip)
            setHideLeftSection(filtersSelectordefaultValue.data.showFleetOverview)
            setHideRightSection(filtersSelectordefaultValue.data.showLatestEvent)
            setTimeZoneId(filtersSelectordefaultValue.data.timezoneId)
            setButtonColor(filtersSelectordefaultValue.data.mapStyle)
        } else if (authSelector) {
            setZipCode(authSelector.zip)
            setHideLeftSection(authSelector.showFleetOverview)
            setHideRightSection(authSelector.showLatestEvent)
            setTimeZoneId(authSelector.timezoneId)
            setButtonColor(authSelector.mapStyle)
        }

    }, [filtersSelectordefaultValue, authSelector]);



    const onSettingsChange = (values) => {
        setSectionValues(values.value);
        if (values.value.indexOf(LAYOUTS[0].value) < 0) {
            sessionStorage.setItem("setHideLeftSection", 0)
            setHideLeftSection(0)
        } else {
            sessionStorage.setItem("setHideLeftSection", 1)
            setHideLeftSection(1);
        }

        if (values.value.indexOf(LAYOUTS[1].value) < 0) {
            sessionStorage.setItem("setHideRightSection", 0)
            setHideRightSection(0)
        } else {
            sessionStorage.setItem("setHideRightSection", 1)
            setHideRightSection(1);
        }
    }

    const onZipcodeChange = (e) => {
        setZipCode(e.target.value);
        var msg = validateUsZipCode(e.target.value)
        if (msg) {
            sessionStorage.setItem("zipPayload", e.target.value)
        }
        setZipMsg(msg)
        setShowSaveButton(msg)
    }

    // const saveZipcode = (event) => {
    //     var payloadRequest = generateZipcodePayload()
    //     console.log(payloadRequest)
    //     // dispatch(saveClientInfo(payloadRequest));
    //     // setIsOpenPopup(o => !o)
    // }

    // const generateZipcodePayload = () => {
    //     return {
    //         zip: zipCode,
    //         showLatestEvent: hideLeftSection,
    //         showFleetOverview: hideRightSection,
    //         timezoneId: timeZoneId
    //     };
    // };

    const optionHandler = (e) => {
        let targetValue = e.target.value;
        if (e.target.value == '') {
            const targetValue = Intl.DateTimeFormat().resolvedOptions().timeZone;
            // console.log(targetValue)
            //  targetValue = 'America/New_York'
        }
        sessionStorage.setItem("timeZonePayload", targetValue)
        setTimeZoneId(targetValue)

        // sessionStorage.setItem("Timezone", targetValue);
        // let Timezonedata = Timezonedata_Api_nowindowload(targetValue);

        // console.log(Timezonedata)
    }

    const changeColor = (btn) => {
        setButtonColor(btn)
        sessionStorage.setItem("mapStylePayload", btn)
    };


    return (
        <div className={styles.popupContent}>
            <SettingsIcon size='xs' className={styles.settingsIcon} htmlColor={'#989a9a'} />
            <Spacing ph={2} />
            <Text component="p" style={{ padding: '5px 5px', marginTop: '-2.5em', marginLeft: '2em' }} size='xs' color="#989a9a" >DEFAULT SETTINGS</Text>
            <br />
            <Spacing pv={.5} />
            <Heading component="p" size="xs" color="petrolBlue" gutter={false}>Map View</Heading>
            <Spacing pv={.5}>
                <Text className={styles.locationText} size='xs' color="petrolBlue" >When you open OneConnect these are the default settings that will be loaded</Text>
            </Spacing>
            <div ref={ref} >
                {/* //commenting for prod push */}
                {!zipMsg && (
                    <div className={styles.messageBar}>
                        <MessageBar variant="alert">Please enter valid zipcode format</MessageBar>
                    </div>
                )}
                {/* for now default location drawer not needed but can be used for further enhancement */}

                {/* <img src={location_icon} className={styles.locationIcon} /> */}
                {/* <div className={styles.stickyButton}> */}


                <div>
                    <Text component="p" bold='300' size="s" color="petrolBlue" style={{ fontWeight: "bold" }} gutter>ZIP code
                    </Text>
                    <Spacing pv={.2} />
                    <InputBase className={styles.inputfield}
                        value={zipCode}
                        name="select-zipCode"
                        placeholder="Enter Zip"
                        onChange={onZipcodeChange} />

                    {/* {showSaveButton && (
                                <Button style={{ marginLeft: "1em" }} label='zipCode' size="s" onClick={(ref = { ref }) => { saveZipcode() }}>Save</Button>

                            )} */}
                </div>
                <Spacing pv={.5} />
                <Text component="p" size="s" color="petrolBlue" style={{ fontWeight: "bold" }} gutter>Time Zone</Text>
                <select className={styles.selectDiv}
                    onChange={optionHandler} value={timeZoneId ? timeZoneId : "America/Detroit"}
                >
                    <option value="">Select The Timezone</option>
                    <option value="America/Detroit">Eastern Time Zone (ET)</option>
                    <option value="America/Chicago">Central Time Zone (CT)</option>
                    <option value="America/Boise">Mountain Time Zone (MT)</option>
                    <option value="America/Los_Angeles">Pacific Time Zone (PT)</option>
                    <option value="America/Anchorage">Alaska Time Zone (AT)</option>
                    <option value="US/Hawaii">Hawaii Time Zone (HT)</option>
                </select>
                <Spacing pv={.5} />
                <Text component="p" size="s" color="petrolBlue" style={{ fontWeight: "bold" }} gutter>Panels</Text>
                <PopupFilterComponent
                    heading={getLabel("layouts", "Layouts", prismicData)}
                    data={LAYOUTS}
                    onFilterChange={onSettingsChange}
                    values={sectionValues}
                    defaultValue={sectionValues}
                />
            </div>

            <Text component="p" size="s" color="petrolBlue" style={{ padding: "5px", fontWeight: "bold" }} gutter>Map style</Text>
            <Spacing pv={.5} />
            <div className={styles.option}>
                <div className={styles.buttons}>
                    <button id="option1Btn" className={buttonColor == "street" ? styles.selected : styles.notSelected} onClick={() => changeColor("street")}>street</button>
                    <Spacing ph={.5} />
                    <button className={buttonColor == "satellite" ? styles.selected : styles.notSelected} onClick={() => changeColor("satellite")}> satellite</button>
                </div>
            </div>
            {/* <Button size="s" color="secondary" onClick={""}>street</Button>
            <Button size="s" color="secondary" onClick={""}>satellite</Button> */}

        </div>
    )
}

export default MapPopUpFilter;