import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';

import { Heading, ButtonGroup, Button, Spacing, Text } from "@leaseplan/ui";
import { Icon } from '@velocity/ui.Icon';
import styles from './LPDrawer.module.scss';

const useStyles = makeStyles((theme) => ({
    grid: {
		width: 220,
		overflowY: 'auto',
		overflowX: 'hidden',
		marginBottom: '4em'
	},
	customGridWidth : {
		width: '45em',
		height: '100%',
		marginTop:"-1.7em",
		// paddingTop: 15
	},
    drawerHeader: {
        display: 'flex',
        alignItems: 'left',
        padding: theme.spacing(0.5, 2.5),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'space-between',
    },
	paper: {
		top: '70px',
		height: '92%'
	}


}));

export default function LPDrawer(props) {
    const {
		title,
        saveActionBtn,
        shouldOpenDrawer,
        shouldDrawerOpened,
		primaryBtnText = "Save",
		secondaryBtnText = "Cancel",
		drawerAlignment = "right",
		isDisabled = false,
		width = false,
		isSaveHidden = false,
		backButtonRequired = false,
		dynamicWidth = false,
    } = props
	
    const classes = useStyles();
    const [drawerOpen, setDrawerOpen] = React.useState({ right: false });

    useEffect(() => {
        // console.log("open drawer2: ", !!shouldDrawerOpened)
        setDrawerOpen({ ...drawerOpen, right: !!shouldDrawerOpened });
    }, [shouldDrawerOpened]);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        // console.log("open drawer4: ", open);
        shouldOpenDrawer(open);
        setDrawerOpen({ ...drawerOpen, right: open });
    };

    return (
		<Drawer
			open={drawerOpen.right}
			
			classes={{ paper: classes.paper }}
			anchor={drawerAlignment}
			className={classes.drawer}
			onClose={toggleDrawer(false)}
		>
			<div className={classes.drawerHeader}>
				<Heading size="xs">{title}</Heading>
				{!backButtonRequired 
					? <button
						type="button"
						id={"drawer_close"}
						onClick={toggleDrawer(false)}
						className={styles.CloseButton}
					    >
							<Icon name="close" size="s" />
						</button>
					: <button
						type="button"
						id={"drawer_back_button"}
						onClick={toggleDrawer(false)}
						className={styles.BackButton}
					>
						<Icon
							color="bloodOrange"
							name="arrow"
							size="s"
						/>
						<Text color="midOrange" size="m">{"Back To Menu"}</Text>
					</button>
				}
			</div>
			<Spacing
				p={2}
				pt={0}
				className={props.width
					? styles.customWidth
					: clsx({
						[classes.grid]: true,
						[classes.customGridWidth]: dynamicWidth
					})
				}
			>
				{props.children}
			</Spacing>
			<div className={styles.ActionBtnContainer}>
				<ButtonGroup direction="row" itemAlignment="right">
					<Button
						size="s"
						variant="secondary"
						onClick={toggleDrawer(false)}
					>
						{secondaryBtnText}
					</Button>
					{!isSaveHidden &&
						<Button
							size="s"
							disabled={isDisabled}
							onClick={(event) => {
								saveActionBtn();
								toggleDrawer(false)(event);
							}}
						>
							{primaryBtnText}
						</Button>
					}
				</ButtonGroup>
			</div>
		</Drawer>
    );
}
