export const simplifyPath = ( points, tolerance ) => {    
	let Line = function( p1, p2 ) {
		this.p1 = p1;
		this.p2 = p2;
		
		this.distanceToPoint = function( point ) {
			// slope
			let m = ( this.p2.latitude - this.p1.latitude ) / ( this.p2.longitude - this.p1.longitude );
				// latitude offset
			let b = this.p1.latitude - ( m * this.p1.longitude );
			let d = [];
			// distance to the linear equation
			// d.push( Math.abs( point.latitude - ( m * point.longitude ) - b ) / Math.sqrt( Math.pow( m, 2 ) + 1 ) );
			// distance to p1
			d.push( Math.sqrt( Math.pow( ( point.longitude - this.p1.longitude ), 2 ) + Math.pow( ( point.latitude - this.p1.latitude ), 2 ) ) );
			// distance to p2
			d.push( Math.sqrt( Math.pow( ( point.longitude - this.p2.longitude ), 2 ) + Math.pow( ( point.latitude - this.p2.latitude ), 2 ) ) );
			// return the smallest distance
			return d.sort( function( a, b ) {
				return ( a - b ); //causes an array to be sorted numerically and ascending
			} )[0];
		};
	};
	
	let douglasPeucker = function( points, tolerance ) {
		if ( points.length <= 2 ) {
			return [points[0]];
		}
		let returnPoints = [];
		// make line from start to end 
		let line = new Line(points[0], points[points.length - 1]);
		// find the largest distance from intermediate poitns to this line
		let maxDistance = 0;
		let maxDistanceIndex = 0;
		let p;
		for ( let i = 1; i <= points.length - 2; i++ ) {
			let distance = line.distanceToPoint(points[ i ]);
			if( distance > maxDistance ) {
				maxDistance = distance;
				maxDistanceIndex = i;
			}
		}
		// check if the max distance is greater than our tollerance allows 
		if ( maxDistance >= tolerance ) {
			p = points[maxDistanceIndex];
			line.distanceToPoint( p, true );
			// include this point in the output 
			returnPoints = returnPoints.concat( douglasPeucker( points.slice( 0, maxDistanceIndex + 1 ), tolerance ) );
			// returnPoints.push( points[maxDistanceIndex] );
			returnPoints = returnPoints.concat( douglasPeucker( points.slice( maxDistanceIndex, points.length ), tolerance ) );
		} else {
			// ditching this point
			p = points[maxDistanceIndex];
			line.distanceToPoint( p, true );
			returnPoints = [points[0]];
		}
		return returnPoints;
	};
	let arr = douglasPeucker( points, tolerance );
	// if (arr.length > 1) {
		arr.push( points[points.length - 1 ] );
	// }
	return arr;
};