import React, { useState, useEffect } from 'react';
import styles from './VehicleDetails.module.scss';
import {
    Text,
    Heading,
    Grid,
    GridItem,
    ChevronLeftIcon,
    ChevronRightIcon
} from "@leaseplan/ui";
import List from '../../DriverInsights/InsightsLink/List';
import VehicleInfo from '../../DriverInsights/VehicleInfo/VehicleInfo';
import VehicleMaintenance from '../VehicleMaintenance/VehicleMaintenance';
import VehicleHealth from '../VehicleHealth/VehicleHealth';
import VehicleUtilization from '../VehcileUtilization/VehicleUtilization';
import Trips from '../Trips/Trips';
import PoolDrivers from '../PoolDrivers/PoolDrivers';

import MissingCarIcon from '../../../Asset/Images/carMissing.svg';
import WifiIcon from '@material-ui/icons/Wifi';
import directionCardinalIcon from '../../../Asset/MapIcons/compassRoseIcon.svg';

import { VEHICLE_INSIGHTS_LINKS, VEHICLE_INSIGHTS_LINKS_9939 } from '../../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { fetchVehicleDetails, fetchPoolVehicleDrivers, resetVehicleInfo } from '../../../Actions/vehicle.action';
import { getLabel } from '../../../utils';
import InformationTab from '../../DriverInsights/InformationTab/InformationTab';
import { fetchDeviceLastLocation, fetchLogRecordTrips, fetchSingleDeviceLastLocation, insertInitalDriverDetails, insertInitialFromTime, isToggleRouting } from '../../../Actions/driver.action';
import moment from 'moment';

import { getTimeGapInDays } from '../../../utils';
import DatePicker from '../../../Common/DatePicker/DatePicker';
import { generatePath, useNavigate } from 'react-router-dom';
import { RoutePath } from '../../../Routers/Routes';
import { addToast } from '../../../Actions/toasts';
import { useParams } from 'react-router-dom/dist';


const VehicleDetails = (props) => {
    const dispatch = useDispatch();
    const navigate =useNavigate();
    const { vin, driverId, date } = useParams();
    const { prismicData } = props;
    const [deviceId, setDeviceId] = useState();
    const [vehicleDetails, setVehicleDetails] = useState({});
    const [selectedTripDate, setSelectedTripDate] = useState(new Date());
    const [poolDrivers, setPoolDrivers] = useState([]);
    const [driverDetails, setDriverDetails] = useState({});
    const [isOpen, setIsOpen] = useState(false);

    const [isVehicleAssigned, setIsVehicleAssigned] = useState(true);
    const [selectedListItem, setSelectedListItem] = useState(sessionStorage.getItem("tabUrl") ? sessionStorage.getItem("tabUrl") : 'vehicle_maintenance');
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [activeTrip, setActiveTrip] = useState(null);

    const vehicleDetailsSelector = useSelector(state => state.vehicle.details);
    const poolVehicleDriversSelector = useSelector(state => state.vehicle.poolDrivers);
    const authSelector = useSelector(state => state.auth);
    const initalSelectedDriverData = useSelector((state) => state.driver.initialDriverDetails);

    const deviceVin = vin || null;
    const calendarDate = sessionStorage.getItem("selectedDate") || date || new Date()

    useEffect(() => {
        setSelectedTripDate(calendarDate)
        setSelectedListItem(sessionStorage.getItem("tabUrl") ? sessionStorage.getItem("tabUrl") : 'vehicle_maintenance')
        const deviceId = driverId || null;
        if (!deviceId) {
            props.history.goBack();
            return
        }
        setDeviceId(deviceId);
        if (!deviceVin) {
            props.history.goBack();
            return
        }

        dispatch(fetchDeviceLastLocation([false]));

        //set the url for hardrefreshing functionality #444
        sessionStorage.setItem("urlEndPoint", window.location.pathname)

        dispatch(fetchLogRecordTrips(false));
        dispatch(fetchVehicleDetails({ vin }));
        dispatch(fetchPoolVehicleDrivers({ vin }));
        let selectedDate = moment(calendarDate).format('YYYY-MM-DD')
        dispatch(fetchSingleDeviceLastLocation([deviceId, selectedDate ? selectedDate : calendarDate]));
        return () => {
            dispatch(resetVehicleInfo());
        }
    }, [dispatch, vin]);

    useEffect(() => {
        const { data, isLoading } = vehicleDetailsSelector;
        if (!isLoading && data) {
            const driverDetails = {
                driverRecordNumber: data.driverRecordNumber, deviceVin: data.vin
            }
            setDriverDetails(driverDetails)
            setVehicleDetails(data);
        }
    }, [dispatch, vehicleDetailsSelector]);

    useEffect(() => {
        const { data, isLoading } = poolVehicleDriversSelector;
        if (!isLoading && data) {
            setPoolDrivers(data);
        }
    }, [dispatch, poolVehicleDriversSelector]);


    useEffect(() => {
        const deviceId = driverId || null;
        let date = moment(selectedTripDate).format('YYYY-MM-DD');
        setActiveTrip(null);
        dispatch(insertInitalDriverDetails({ deviceId: deviceId, selectedDate: date }));
        setSelectedListItem(sessionStorage.getItem("tabUrl") ? sessionStorage.getItem("tabUrl") : 'vehicle_maintenance')
        return () => {
            dispatch(insertInitalDriverDetails({ deviceId: null, selectedDate: null }))
        }
    }, [selectedTripDate])



    const getGarageAddress = () => {
        const address = [];
        if (vehicleDetails.garageCity) {
            address.push(vehicleDetails.garageCity)
        }
        if (vehicleDetails.garageState) {
            address.push(vehicleDetails.garageState)
        }
        if (vehicleDetails.garageZip) {
            address.push(vehicleDetails.garageZip)
        }

        return address.join(', ')
    }

    const getFullName = () => {
        return `${vehicleDetails.firstName ? vehicleDetails.firstName : ''} ${vehicleDetails.lastName ? vehicleDetails.lastName : ''}`
    }

    const getVehicleName = () => {
        return `${vehicleDetails.modelYear ? vehicleDetails.modelYear : ''} ${vehicleDetails.makeDescription ? vehicleDetails.makeDescription : ''} ${vehicleDetails.baseModelName ? vehicleDetails.baseModelName : ''}`
    }

    const selectActiveTrip = (selectedId) => {
        setActiveTrip(selectedId);
    }

    const driverInsightsRoute = () => {
        if (deviceId || vehicleDetails && vehicleDetails.vin) {
            sessionStorage.setItem("tabUrl", 'trips')
            navigate({
                pathname: generatePath(RoutePath.DriverDetails, { driverId: deviceId, date: sessionStorage.getItem("selectedDate") ? sessionStorage.getItem("selectedDate") : initalSelectedDriverData.selectedDate })
            })
        }
    }

    const getSelectedComponent = () => {
        switch (selectedListItem) {
            case 'driver_insights':
                return driverInsightsRoute();
            case 'trips':
                return <Trips prismicData={prismicData} driverDetails={driverDetails} selectedTripDate={selectedTripDate} selectActiveTrip={selectActiveTrip} activeTrip={activeTrip} openCalendar={openCalendar} />
            case 'vehicle_maintenance':
                return <VehicleMaintenance vin={deviceVin} prismicData={prismicData} />
            case 'vehicle_health':
                return <VehicleHealth vin={deviceVin} prismicData={prismicData} driverId={deviceId} />
            case 'vehicle_Utilization':
                return <VehicleUtilization vin={deviceVin} prismicData={prismicData} vehicleDetailsSelector={vehicleDetailsSelector} />
            default:
                return <InformationTab componentName={''} />
        }
    }

    const getOBDIconColor = (lastCommunicationTime) => {
        let days = (Number(lastCommunicationTime) || 0) / 1440; //converting minutes in days
        if (days >= 0 && days <= 2) {
            return '#7ccc5a'; //green
        } else if (days > 2 && days <= 5) {
            return '#FDD835'; // yellow
        } else if (days > 5) {
            return 'red'
        } else {
            return '#7ccc5a'; // default green
        }

    }

    const handleDateChange = (date) => {
        let currentDateTimestamp = new Date()
        const offset = currentDateTimestamp.getTimezoneOffset()
        currentDateTimestamp = new Date(new Date().getTime() - (offset * 60 * 1000))
        let selectedDateTimestamp = new Date(new Date(date).getTime() - (offset * 60 * 1000))
        const selectedDate = selectedDateTimestamp.toISOString().split('T')[0];
        const currentDate = currentDateTimestamp.toISOString().split('T')[0];
        if (selectedDate > currentDate) {
            dispatch(addToast({
                title: "Future dates are not supported",
                type: "error",
            }));
            return;
        } else {
            setSelectedTripDate(selectedDate);
            sessionStorage.setItem("selectedDate", selectedDate)
            dispatch(fetchSingleDeviceLastLocation([deviceId, selectedDate]));
            dispatch(insertInitialFromTime(date));
        }
    };

    const onDateDialogClose = () => {
        setIsOpen(false);
    }

    const openCalendar = () => {
        setIsOpen(!isOpen);
    }

    const toggleSideBar = () => {
        setIsCollapsed(isCollapsed => !isCollapsed)
    }

    const onItemClick = (item) => {
        if (isCollapsed) {
            setIsCollapsed(isCollapsed => !isCollapsed)
        }
        sessionStorage.setItem("tabUrl", item)
        setSelectedListItem(item)
    }

    return (
        <div className={isCollapsed ? styles.mainWrapperCollapsed : styles.mainWrapper}>
            <div className={styles.driverDetails}>
                <Grid gutter={false} justifyContent="space-between">
                    <GridItem>
                        <Text component="p" size="xs" color="petrolBlue" gutter>{getLabel('vehicle_insight', 'Vehicle Insight', prismicData)} / {vehicleDetails.deviceName}</Text>
                    </GridItem>
                </Grid>
                <div className={styles.row}>
                    <Heading component="p" size="xs" color="petrolBlue" gutter>{getVehicleName()}</Heading>
                </div>

                {isVehicleAssigned && <div>
                    <VehicleInfo
                        vehicleIcon={vehicleDetails.vehicleImageName ? `https://imageonthefly.autodatadirect.com/images/?QUALITY=50&IMG=${vehicleDetails.vehicleImageName}&HEIGHT=800` : MissingCarIcon}
                        vehicleName={null}
                        plateNo={vehicleDetails.tagNumber ? vehicleDetails.tagNumber : ''}
                        unitNo={vehicleDetails.clientVehicleId ? vehicleDetails.clientVehicleId : ''}
                        vin={vehicleDetails.vin ?? '-'}
                        address={getGarageAddress()}
                    />
                    {poolDrivers.length ? <div className={styles.recentPoolDrivers}>
                        <Heading component="p" size="xxs" color="petrolBlue" gutter>{getLabel('pool_drivers', 'Pool Drivers', prismicData)}</Heading>
                        {
                            poolDrivers.map((item, index) => {
                                return (
                                    <PoolDrivers
                                        key={index}
                                        firstName={item.firstName}
                                        lastName={item.lastName}
                                        driverScore={item.driverScore}
                                        scoreColor={item.scoreColor}
                                    />
                                )
                            })
                        }
                    </div> : null}

                    <div className={styles.obd}>
                        <div className={styles.left}>
                            <div className={styles.icon} style={{ backgroundColor: vehicleDetails.minutesSinceLastCommunicated === null ? 'grey' : getOBDIconColor(vehicleDetails.minutesSinceLastCommunicated) }}>
                                <WifiIcon htmlColor={'#FFF'} style={{ transform: 'rotate(90deg)', width: 15, height: 15 }} fontSize={"small"} />
                            </div>
                            <Text component="span" size="xxs" color="petrolBlue" gutter>{vehicleDetails.deviceType}</Text>
                        </div>
                        <Text fontWeight="bold" component="span" size="xxs" color="petrolBlue" gutter>{vehicleDetails.deviceStatus ? (vehicleDetails.deviceStatus).charAt(0).toUpperCase() + vehicleDetails.deviceStatus.slice(1) : '--'}</Text>
                        <Text component="span" size="xxs" color="petrolBlue" gutter>{vehicleDetails.minutesSinceLastCommunicated === null ? 'unknown' : getTimeGapInDays(vehicleDetails.minutesSinceLastCommunicated || 0)}</Text>
                    </div>
                </div>
                }

                {!isVehicleAssigned && <div className={styles.centeredDiv}>
                    <div className={styles.notAssignedVehicle}>
                        <img src={MissingCarIcon} className={styles.vehicleIcon} alt={'Vehicle icon'} />
                        <Heading component="p" size="xxs" color="petrolBlue" gutter>{getLabel('vehicle_not_assigned', 'Vehicle Not Assigned', prismicData)}</Heading>
                    </div>
                </div>}


                {/* Note :- func guard To Display Vehicle Utilization tab in vehcile inside for 9939 user  */}
                <div className={styles.list}>
                    <List data={authSelector.user.authUser.clientNumber === 9939 ? VEHICLE_INSIGHTS_LINKS_9939 : VEHICLE_INSIGHTS_LINKS} selectedListItem={selectedListItem} onClick={onItemClick} />
                </div>
            </div>
            {
                !isCollapsed && <div className={styles.tripDetails}>{getSelectedComponent()}</div>
            }
            <div className={styles.collapsedTrip} onClick={toggleSideBar}>
                {
                    isCollapsed ? (
                        <div className={styles.hiddenIcon}>
                            <ChevronRightIcon />
                        </div>
                    ) : (
                        <div className={styles.hiddenIcon}>
                            <ChevronLeftIcon />
                        </div>
                    )
                }
                {/* </Paper> */}
            </div>
            <div className={styles.emptySpace}></div>
            <div className={styles.hiddenDiv}>
                {/* Note :- for vehicel Utilization the date functionality should be disab  */}
                {selectedListItem != 'vehicle_Utilization' &&
                    <DatePicker
                        isOpen={isOpen}
                        selectedDate={selectedTripDate}
                        handleDateChange={handleDateChange}
                        onClose={onDateDialogClose}
                    />
                }
            </div>
            <div className={styles.dateDetail}>
            <Text component="span" size="s" color="petrolBlue" gutter>{moment(selectedTripDate).format('MMM DD, YYYY')}</Text>
            </div>
            <div className={styles.middleDrawer}>
                {<img src={directionCardinalIcon} alt="cardinal Icon" className={styles.cardinalIcon} />}
            </div>
        </div>
    );
}

export default React.memo(VehicleDetails);