export const REPORTING_PAGE = {
    FETCH_REPORTING_SIDE_BAR_INFO: "FETCH_REPORTING_SIDE_BAR_INFO",
    FETCH_REPORTS: "FETCH_REPORTS",
    FETCH_REPORTS_IDS: "FETCH_REPORTS_IDS",
    SAVE_REPORT: "SAVE_REPORT",
    DELETE_REPORT: "DELETE_REPORT",
    ADD_FAVOURITE: "ADD_FAVOURITE",
    DOWNLOAD_REPORT: "DOWNLOAD_REPORT",
    FETCH_SINGLE_REPORTS_IDS: "FETCH_SINGLE_REPORTS_IDS",
    CHECK_DOWNLOAD_REPORT_STATUS: "CHECK_DOWNLOAD_REPORT_STATUS",
    SORT_REPORT: "SORT_REPORT"
};

export const GRAPH = {
    LINE: 'LINE',
    BAR: 'BAR',
    DONUT: 'DONUT',
    TABLE: 'TABLE',
    NUMBER: 'NUMBER',
    PIE: 'PIE'
}

export const GRAPH_TYPES = {
    DEVICE_COMMUNICATION_STATUS: {
        ID: 1,
        NAME: 'Device Communication Status',
        VALUE: 'DEVICE_COMMUNICATION_STATUS',
        TYPE: GRAPH.DONUT,
    },
    DEVICE_STATUS: {
        ID: 2,
        NAME: 'Device Status',
        VALUE: 'DEVICE_STATUS',
        TYPE: GRAPH.DONUT,
    },
    FLEET_MILEAGE: {
        ID: 3,
        NAME: 'Fleet Mileage',
        VALUE: 'FLEET_MILEAGE',
        TYPE: GRAPH.LINE,
    },
    MAX_SPEEDING_EVENTS: {
        ID: 4,
        NAME: 'Max Speeding Events',
        VALUE: 'MAX_SPEEDING_EVENTS',
        TYPE: GRAPH.LINE,
    },
    SPEEDING_EVENTS: {
        ID: 5,
        NAME: 'Speeding Events',
        VALUE: 'SPEEDING_EVENTS',
        TYPE: GRAPH.LINE,
    },
    SEAT_BELT_EVENTS: {
        ID: 6,
        NAME: 'Seat Belt Events',
        VALUE: 'SEAT_BELT_EVENTS',
        TYPE: GRAPH.LINE,
    },
    HIGHEST_IDLING_DURATION: {
        ID: 7,
        NAME: 'Highest Idling Duration',
        VALUE: 'HIGHEST_IDLING_DURATION',
        TYPE: GRAPH.LINE,
    },
    HARD_BRAKING_EVENTS: {
        ID: 8,
        NAME: 'Hard Braking Events',
        VALUE: 'HARD_BRAKING_EVENTS',
        TYPE: GRAPH.LINE,
    },
    HARSH_ACCELERATION: {
        ID: 9,
        NAME: 'Harsh Acceleration',
        VALUE: 'HARSH_ACCELERATION',
        TYPE: GRAPH.LINE,
    },
    REVERSING_EVENTS: {
        ID: 10,
        NAME: 'Reversing Events',
        VALUE: 'REVERSING_EVENTS',
        TYPE: GRAPH.LINE,
    },
    MAX_MILEAGE: {
        ID: 11,
        NAME: 'Max Milage',
        VALUE: 'MAX_MILEAGE',
        TYPE: GRAPH.TABLE,
    },
    DAILY_TRIPS: {
        ID: 12,
        NAME: 'Daily Trips',
        VALUE: 'DAILY_TRIPS',
        TYPE: GRAPH.BAR,
    },
    EV_READINESS: {
        ID: 13,
        NAME: 'EV Readiness',
        VALUE: 'EV_READINESS',
        TYPE: GRAPH.PIE,
    },
    PM_OVERDUE: {
        ID: 14,
        NAME: 'PM Overdue',
        VALUE: 'PM_OVERDUE',
        TYPE: GRAPH.TABLE,
    },
    AVERAGE_MPG: {
        ID: 15,
        NAME: 'Average MPG',
        VALUE: 'AVERAGE_MPG',
        TYPE: GRAPH.BAR,
    },
    CLIENT_VEHICLE_UTILIZATION: {
        ID: 21,
        NAME: 'Client Vehicle Utilization',
        VALUE: 'CLIENT_VEHICLE_UTILIZATION',
        TYPE: GRAPH.LINE,
    },
}