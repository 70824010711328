export const SidebarConstant = {
    id:'accountDetails',
    title: "Edit Account Details",
    actionText: "Save",
    inputs: [
        {
            id: 1,
            propertyName: "graphType",
            alias: "graph_type",
            name: "Graph Type",
            fieldType: "select",
            valueType: "",
            value: "",
            isDisabled: false,
            isRequired: true,
            defaultValue: "",
            span: 12,
            errorMessage: "",
            validation: "text",
            validationState: "pristine",
            disableDefault: false,
            options: []
        },
        {
            id: 2,
            propertyName: "dateRange",
            alias: "date_range",
            name: "Date Range",
            fieldType: "select",
            valueType: "",
            value: "",
            isDisabled: false,
            isRequired: false,
            defaultValue: "",
            span: 12,
            errorMessage: "",
            validation: "text",
            validationState: "pristine",
            disableDefault: false,
            options: []
        }
    ],
};
