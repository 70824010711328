import { Grid, GridItem, Text, Spacing, Button, Loader } from '@leaseplan/ui';
import { Person, Phone, Mail } from '@material-ui/icons';
import React, {useState, useEffect} from 'react';
import useFetch from '../../../Common/CustomHook/useFetch';
import {baseURL} from '../../../Store/base-url';
import axios from "../../../AxiosInterceptor";
import styles from './NationalShopPopup.module.scss';

const present = (value) => {
    return (value && value !== ' ') ? value: '--';
}

const method = 'POST';
const url = '/point-of-interest/get-by-id';

const NationalShopPopup = ({map, data, onClick, clientNumber}) => {

    const [isLoading, setIsLoading] = useState(true);
    const [apiData, setApiData] = useState([]);
    const [serverError, setServerError] = useState(null);

    const {
        poiLongitude,
        poiLatitude,
        poiStreet,
        poiDescription,
        poiContactEmail,
        poiContactFirstName,
        poiContactLastName,
        poiContactPhone,
        poiCity,
        poiName,
        poiState,
        poiZip,
        poiUid
    } = data


    // useEffect(() => {
    //     setIsLoading(true);
    //     const fetchData = async () => {
    //       try {
    //         const resp = await axios({
    //           baseURL: baseURL,
    //           responseType: "json",
    //           url: url,
    //           method: method,
    //           data: {
    //             clientNumber:clientNumber,
    //             id: id
    //         }
    //         });
    //         const data = await resp?.data;
    //         setApiData(data);
    //         setIsLoading(false);
    //       } catch (error) {
    //         setServerError(error);
    //         setIsLoading(false);
    //       }
    //     };
    //     fetchData();
    //   }, []);

    const sendToDriver = (e) => {
        console.log(map.closePopup());
    }

    // if(isLoading){
    //     return (<Loader className={styles.Loader__NationalShopPopup}/>)
    // }

    return (
        <>
        {/* <p style={{fontSize: '3px'}}>Hello</p> */}
                <Grid gutter={false}>
                        <GridItem span={12}>
                            <Spacing pb={0.2}>
                                <Text style={{fontSize: '8px'}} color="midOrange">{present(poiDescription)}</Text>
                            </Spacing>
                        </GridItem>
                        <GridItem span={12}>
                            <Spacing pb={0.2}>
                                <Text fontWeight="bold" size="m" color="petrolBlue">{poiName || '--'}</Text>
                            </Spacing>
                        </GridItem>
                        <GridItem span={12}>
                            <Spacing pb={0.5}>
                                <Grid gutter={false} direction="column">
                                    <GridItem>
                                        <Text size="xxs" color="petrolBlue">{present(poiStreet)}</Text>
                                    </GridItem>
                                    <GridItem>
                                        <Text size="xxs" color="petrolBlue">{present(`${poiCity}, ${poiState} ${poiZip}`)}</Text>
                                    </GridItem>
                                </Grid>
                            </Spacing>
                        </GridItem>
                    <GridItem p={1} span={12}>
                        <Grid gutter={false}>
                            <Person fontSize="small" style={{ marginRight:'10px', color: '#f06400' }}/>
                            <Text size="xs" fontWeight="bold" color="petrolBlue">{present(`${poiContactFirstName?poiContactFirstName: ''} ${poiContactLastName ? poiContactLastName: ''}`)}</Text>
                        </Grid>
                    </GridItem>
                    <GridItem p={1} span={12}>
                        <Grid gutter={false}>
                            <Phone fontSize="small" style={{ marginRight:'10px',color: '#f06400' }}/>
                            <Text size="xs" color="petrolBlue">{present(poiContactPhone)}</Text>
                        </Grid>
                    </GridItem>
                    <GridItem  span={12}>
                        <Grid gutter={false}>
                            <Mail fontSize="small" style={{ marginRight:'10px', color: '#f06400' }}/>
                            <Text size="xs" color="petrolBlue">{present(poiContactEmail)}</Text>
                        </Grid>
                    </GridItem>
                    <GridItem  span={12}>
                        <Spacing pt={1}>
                            <Text size="xxs" color="petrolBlue">
                                
                            </Text>
                        </Spacing>
                    </GridItem>
                    {/* <GridItem  span={12}>
                        <Spacing pt={1.5}>
                                 <Button size="s" onClick={sendToDriver} stretch>SEND TO DRIVER</Button>
                        </Spacing>
                    </GridItem> */}
                </Grid>
        </>
    )
}


export default NationalShopPopup
