import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { InputBase } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search';

import { fetchLiveFleetSeachInfo, fetchLiveSeachInfo } from '../../Actions/searchPage.action';
import styles from './LiveSearch.module.scss';
import _ from "lodash";
import LiveSearchRow from './Table/LiveSearchRow';
import { LPLoader } from '../../Common/LPLoader/LPLoader';




const LiveMapSearch = (props) => {


  const [debouncedValue, setDebouncedValue] = useState('');
  const timelineSelector = useSelector(state => state.timeline)
  const [searchItem, setSearchItem] = useState('');
  const { prismicData } = props;
  const dispatch = useDispatch();
  const [showSearchArea, setshowSearchArea] = useState(false);
  const [searchType, setSearchType] = useState('');
  const [message, setmessage] = useState("");
  const [searchString, setSearchString] = useState('');
  const [clearAll, setClearAll] = useState(false);
  const searchPageSelector = useSelector(state => state.searchPage);

  const [isLoading, setIsLoading] = useState([false])
  const apiData = useSelector((state) => state.driver.fetchLogRecordTrips);

  useEffect(() => {
    if (apiData) {
      setIsLoading(apiData.isLoading)
    }
  }, [apiData])

  const generateRequestPayload = (
    mainSearch = {}
  ) => {
    return {
      driverVehicleSearchInput: {
        name: mainSearch.name ? mainSearch.name : '',
        // fromDate: timelineSelector.date,
        type: mainSearch.type ? mainSearch.type : '',

      }

    };

  };



  const ref = useRef()

  useEffect(() => {
    if (searchPageSelector.data.driverVehicleFilterResponseDataDto === null) {
      let lat = searchPageSelector.data.latitude
      let long = searchPageSelector.data.longitude
      if (lat == 0 && long == 0) {
        setmessage("No Address Found");
      } else { setmessage("") }
    }
  }, [searchPageSelector])

  useEffect(() => {
    if (searchPageSelector.data.driverVehicleFilterResponseDataDto == 0) {
      setshowSearchArea(false);
      setmessage("");
    }

  }, [searchPageSelector])

  useEffect(() => {
    if (searchString.length <= 2) {
      setshowSearchArea(false);
    }
    const checkIfClickedOutside = e => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (showSearchArea && ref.current && !ref.current.contains(e.target)) {
        setshowSearchArea(false);
        setSearchString('');
        setDebouncedValue("")
        setmessage("");
      }
    }

    document.addEventListener("mousedown", checkIfClickedOutside)

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [showSearchArea, searchString])

  // To make it re usable making it false again
  useEffect(() => {
    setClearAll(false);
  }, [clearAll]);

  const handleChange = (event) => {
    if (searchType === "" && event.target.value) {
      setSearchItem(event.target.value);
    }
  }

  const searchData = _.debounce((event) => {
    setDebouncedValue(event.target.value);
  }, 1000);

  useEffect(() => {
    // API call goes here
    if (searchString && searchString.length >= 3) {
      dispatch(fetchLiveSeachInfo(
        generateRequestPayload(

          {
            name: searchString,
            type: "",
          }
        )
      ))
    }
    setshowSearchArea(true);
  }, [debouncedValue]);

  const handleChange2 = (event) => {
    if (searchType === "map" && event.target.value != "") {
      dispatch(fetchLiveFleetSeachInfo(
        generateRequestPayload(

          {
            name: event.target.value,
            type: "map",
          }
        )
      ));
    }
  }




  const optionHandler = (event) => {
    setSearchString('');
    setshowSearchArea(false);
    setmessage("");
    setSearchType(event.target.value);
  }



  const handleSearchStringChange = (event) => {
    setSearchString(event.target.value);
    if (searchType == "") {
      handleChange(event);
    } else {
      handleChange2(event);
    }

  };

  const handleSearchStringChangeMap = (event) => {
    setSearchString(event.target.value);
  };

  const searchLiveMap = (event) => {
    if (event.key === 'Enter') {
      handleChange2(event);
    }
  };

  const clearSearch = (newValue) => {
    setshowSearchArea(newValue);
    setDebouncedValue("")
    setSearchString("")
  }

  return (
    <div className={styles.mainWrapper} ref={ref}>



      {searchType === "" && (
        <>
          <SearchIcon htmlColor={'#767878'} className={styles.SearchIcon} />
          <InputBase
            className={styles.searchTextInput}
            value={searchString}
            onChange={handleSearchStringChange}
            placeholder='Enter driver/vehicle info here'
            style={{ overflowWrap: "break-word", fontWeight: '700', fontSize: '14px', fontFamily: 'LeasePlan, Arial, Helvetica, sansSerif' }}
            // inputProps={{ maxLength: 100 }}
            onKeyDown={searchData}
          />
        </>
      )}
      {searchType === "map" && (
        <>
          <SearchIcon htmlColor={'#767878'} className={styles.SearchIcon} onKeyDown={searchData} />
          <InputBase type="text"
            className={styles.searchTextInput}
            value={searchString}
            onChange={handleSearchStringChangeMap}
            placeholder='Enter address/city/state here'
            style={{ overflowWrap: "break-word", fontWeight: '700', fontSize: '14px', fontFamily: 'LeasePlan, Arial, Helvetica, sansSerif' }}
            // inputProps={{ maxLength: 100 }}
            onKeyDown={searchLiveMap}
          />

        </>)}
      <select className={styles.selectDiv}
        onChange={optionHandler}
      >
        <option value="">Fleet</option>
        <option value="map">Map</option>
      </select>
      {searchPageSelector.isLoading == true && (
        <div className={styles.dataResult}>
          {searchPageSelector.isLoading && (
            <div className={styles.lpLoader}>
              <LPLoader
                loading={searchPageSelector.isLoading}
                message={"Fetching Data... Please Wait"}
              />
            </div>
          )}
        </div>
      )}

      {searchPageSelector.data.
        driverVehicleFilterResponseDataDto != null && showSearchArea === true && (
          // <div className={styles.dataResult1} ref={ref}>
          //   <span className={styles.dataItem} >Top Results Near Me</span> <span style={{ padding: '20px', position: 'left' }}>Within</span><span style={{ fontWeight: 'bold' }}> 5mi</span>
          <div className={styles.dataResult}>

            {isLoading == true && (
              <div className={styles.lpLoader}>
                <LPLoader
                  loading={isLoading}
                  message={"Loading Data... Please Wait"}
                />
              </div>
            )}

            {
              searchPageSelector.data.driverVehicleFilterResponseDataDto.length > 0 && isLoading != true && searchPageSelector.data.driverVehicleFilterResponseDataDto.map((row, index) => {
                return (
                  <LiveSearchRow key={index} id={index} rowData={row} onChange={clearSearch} />
                )
              })
            }


          </div>
          // </div>
        )}



    </div>

  )
};

export default LiveMapSearch;