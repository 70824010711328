import axios from "axios";

(function () {
    axios.interceptors.request.use(
      function (config) {
        let originalRequest = config;
        const token = sessionStorage.getItem("authorization-client");

        if (token) {
          originalRequest.headers['authorization-client'] = token;
          originalRequest.withCredentials = true;
          return Promise.resolve(originalRequest);
        } else {
          return Promise.resolve(originalRequest);
        }
      },
      function (error) {
        return Promise.reject(error);
      }
  );
})();

// axios.interceptors.response.use(response => {
//   return response;
// }, error => {
//  if (error.response.status === 401) {
//     window.location.reload();
//  }
//  return error;
// });

const axiosClient = axios;

export default axiosClient;
