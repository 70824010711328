import {
    Text,
    Heading,
  } from "@leaseplan/ui";
import React, { useState, useEffect, useCallback } from 'react';
import styles from './FleetOverview.module.scss';
import { getLabel } from '../../../utils';
import CircularProgress from '@material-ui/core/CircularProgress';
import CircularStatic from "../../../Common/CircularProgressWithLabel/CircularProgressWithLabel";
import { useDispatch, useSelector } from "react-redux";
import { fetchOneScore } from "../../../Actions/fleetOverview.action";

const FleetOverview = props => {
    const {prismicData} = props;
    const dispatch = useDispatch();
    const [oneScore, setOneScore] = useState(0);
    const [oneScoreColor, setOneScoreColor] = useState(0);
    const [safety, setSafety] = useState(0);
    const [safetyColor, setSafetyColor] = useState(0);
    const [efficiency, setEfficiency] = useState(0);
    const [efficiencyColor, setEfficiencyColor] = useState(0);
    const [compliance, setCompliance] = useState(0);
    const [complianceColor, setComplianceColor] = useState(0);

    const oneScoreSelector = useSelector(state => state.fleetOverview.oneScore);

    useEffect(() => {
        if(oneScoreSelector.data){
            setOneScore(oneScoreSelector.data?.oneScore || 0);
            setOneScoreColor(oneScoreSelector.data?.oneScoreColor?.toLowerCase() || 'green');
            setSafety(oneScoreSelector.data?.safetyScore || 0);
            setSafetyColor(oneScoreSelector.data?.safetyScoreColor?.toLowerCase() || 'green');
            setEfficiency(oneScoreSelector.data?.efficiencyScore || 0);
            setEfficiencyColor(oneScoreSelector.data?.efficiencyScoreColor?.toLowerCase() || 'green');
            setCompliance(oneScoreSelector.data?.complianceScore || 0);
            setComplianceColor(oneScoreSelector.data?.complianceScoreColor?.toLowerCase() || 'green');
        }
    }, [oneScoreSelector])

    useEffect(() => {
        dispatch(fetchOneScore());
    }, [dispatch]);


    const getComponentColor = useCallback(() => {
        if(oneScoreColor === 'green'){
            return styles.circle_green;
        }else if(oneScoreColor === 'yellow'){
            return styles.circle_yellow;
        }else if(oneScoreColor === 'red'){
            return styles.circle_red;
        }else if(oneScoreColor === 'gray'){
            return styles.circle_grey;
        }
    }, [oneScoreColor]);

    return (
        <div className={styles.leftPanelWrapper}>
            <Heading component="p" size="xxs" color="petrolBlue" gutter>{getLabel("fleet_overview","Fleet Overview", prismicData)}</Heading>
            <div className={styles.content}>
                <CircularProgress className={getComponentColor()} variant="determinate" value={oneScore} size={140} thickness={1.5} color={'primary'} style={{'color': 'white', transform: 'rotate(-90deg) scaleY(-1)'}} >
                    
                </CircularProgress>
                <div className={styles.circularContent}>
                    <Text component="p" size="s">{getLabel("one_score","ONE SCORE", prismicData)}</Text>
                    <Heading component="span" size="l" color={'black'}>{oneScore}</Heading>
                    <Text component="p" size="s" className={styles.bottomText}>/100</Text>
                </div>
            </div>
            
            <div className={styles.generalProgress}>
                <div className={styles.progressLabel}>
                    <CircularStatic progress={safety} color={safetyColor} size={50}/>
                    <Text  component="p" size="xs">{getLabel("safety","Safety", prismicData)}</Text>
                </div>
                <div className={styles.progressLabel}>
                    <CircularStatic progress={efficiency} color={efficiencyColor} size={50}/>
                    <Text  component="p" size="xs">{getLabel("efficiency","Efficiency", prismicData)}</Text>
                </div>
                <div className={styles.progressLabel}>
                    <CircularStatic progress={compliance} color={complianceColor} size={50}/>
                    <Text  component="p" size="xs">{getLabel("compliance","Compliance", prismicData)}</Text>
                </div>
            </div>
        </div>
    )
}


export default FleetOverview;
