import {
    Heading,
  } from "@leaseplan/ui";
import React, {useEffect, useState} from 'react';
import styles from './DriverEvents.module.scss';
import { getLabel, timezoneOffSet } from '../../../utils';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useDispatch, useSelector } from "react-redux";
import { fetchDriverEvents, removeDriverEvents } from "../../../Actions/driverEvents.action";
import EventsRows from "./EventRow";
import FilterIcon from "../../../Asset/Images/filter.png";
import 'reactjs-popup/dist/index.css';
import Popup from 'reactjs-popup';
import moment from 'moment';
import CarIcon from '../../../Asset/Images/car.png';
import PopupFilterComponent from '../../../Common/PopupFilter/PopupFilter';
import Images from '../../../Asset/Images';
import { isPanningMap, logRecordLoadingStatusWorker } from "../../../Actions/level.action";
import { insertInitialFromTime } from "../../../Actions/driver.action";
import { checkDownloadReportStatus } from "../../../Actions/reporting.action";

const contentStyle = { background: '#FFF' };
const overlayStyle = { background: 'rgba(0,0,0,0.5)' };
const arrowStyle = { color: '#FFF' }; // style for an svg element

const todaysDate = moment().format('YYYY-MM-DD');

const DriverEvents = props => {
    const dispatch = useDispatch();
    const driverEventsSelector = useSelector(state => state.driverEvents);
    const {prismicData} = props;
    const [driverEvents, setDriverEvents] = useState([]);
    const [eventFilters, setEventFilters] = useState([]);
    const [selectedEvents, setSelectedEvents] = useState([]);

    useEffect(() => {
        const {isLoading, data} = driverEventsSelector;
        const createFilters = () => {
            const events = {};
            const filtersData = [];
            data.map(row => {
                events[row.ruleId] = row.ruleLabel
                return row;
            });

            Object.keys(events).forEach(key => {
                filtersData.push({
                    label: events[key],
                    value: key
                })
            });
            setEventFilters(filtersData);
        }
        
        if (!isLoading && data) {
            createFilters();
            setDriverEvents(data);
        } else {
            setDriverEvents([]);
        }
    }, [driverEventsSelector]);

    useEffect(() => {
        setTimeout(() => {
            if(sessionStorage.getItem('TimezoneOffset') != ''){
                const dateFromUTC = timezoneOffSet(todaysDate,'00:00:00');
                const dateToUTC = timezoneOffSet(todaysDate,'23:59:59');
                dispatch(fetchDriverEvents({date: dateFromUTC+'/'+dateToUTC}));
            }
            
        }, 2000);
        dispatch(checkDownloadReportStatus())
        dispatch(logRecordLoadingStatusWorker(false));
        dispatch(insertInitialFromTime());
        dispatch(isPanningMap(false))
        
    }, [dispatch]);

    const onEventRemoved = (eventId) => {
        dispatch(removeDriverEvents(eventId));
    }

    const onRefresh = () => {
        if(sessionStorage.getItem('TimezoneOffset') != ''){
        const dateFromUTC = timezoneOffSet(todaysDate,'00:00:00');
        const dateToUTC = timezoneOffSet(todaysDate,'23:59:59');
        dispatch(fetchDriverEvents({date: dateFromUTC+'/'+dateToUTC}));
        }
    }

    const onFilterChange = (values) => {
        let {data} = driverEventsSelector;
        if(data) {
            data = data.filter((row) => {
                let index = values.value.indexOf(row.ruleId);
                if(index > -1) {
                    return row;
                }
            })
        }

        setSelectedEvents(values.value);
        setDriverEvents(data);
    }

    return (
        <div className={styles.rightPanelWrapper}>
            <div className={styles.filterRow}>
                <Heading component="p" size="xxs" color="petrolBlue" gutter>{getLabel("latest_events","Latest Events", prismicData)}</Heading>
                <div className={styles.filterIcons}>
                    <div >
                        <Popup 
                            trigger={<img src={FilterIcon} alt="Filter Icon" className={styles.iconFilter}/>} 
                            position="left top"
                            {...{ contentStyle, overlayStyle, arrowStyle }}>
                            <PopupFilterComponent
                                heading={getLabel("event_types","Event Types", prismicData)}
                                data={eventFilters}
                                onFilterChange={onFilterChange}
                                values={selectedEvents}
                            />
                        </Popup>
                    </div>
                
                    
                    <RefreshIcon htmlColor='#004a5d' onClick={onRefresh}/>
                </div>
            </div>

            {
                driverEvents.map((row, index) => {
                    
                    return (
                        <EventsRows
                        
                            key={index.toString()} 
                            id={row.eventUid}
                            eventName={row.ruleLabel}
                            timeStamp={row.activeFrom}
                            utcOffset = {row.utcOffset}
                            timeZoneId = {row.timeZoneId}
                            timezoneShortName ={row.timezoneShortName}
                            label={row.label}
                            vehicleIcon={Images.eventIcons[row.ruleId]?.default || CarIcon}
                            onEventRemoved={onEventRemoved}
                        />
                    )
                })
            }


        </div>
    )
}


export default DriverEvents;
