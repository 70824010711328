export const DRIVER_INSIGHTS_LINKS = [
    { label: 'Vehicle Profile', key: 'vehicle_insights' },
    // {label: 'Communication Log', key: 'communication_log'},
    { label: 'Events', key: 'events' },
    { label: 'OneScore', key: 'onescore' },
    { label: 'Trips', key: 'trips' },
    { label: 'Courses', key: 'courses' }
];

export const VEHICLE_INSIGHTS_LINKS = [
    { label: 'Driver Profile', key: 'driver_insights' },
    { label: 'Vehicle Maintenance', key: 'vehicle_maintenance' },
    { label: 'Vehicle Health', key: 'vehicle_health' },
    { label: 'Trips', key: 'trips' }
];

export const VEHICLE_INSIGHTS_LINKS_9939 = [
    { label: 'Driver Profile', key: 'driver_insights' },
    { label: 'Vehicle Maintenance', key: 'vehicle_maintenance' },
    { label: 'Vehicle Health', key: 'vehicle_health' },
    { label: 'Vehicle Utilization', key: 'vehicle_Utilization' },
    { label: 'Trips', key: 'trips' }
];

export const SPEED_CONSTANTS = [
    {
        label: "1x",
        value: 1
    },
    {
        label: "2x",
        value: 2
    },
    {
        label: "3x",
        value: 3
    },
    {
        label: "5x",
        value: 5
    },
    {
        label: "10x",
        value: 10
    }
]