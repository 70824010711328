import { useState, useEffect } from 'react';
import axios from "../../AxiosInterceptor/index";
import { baseURL } from "../../Store/base-url";
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { timezoneOffSet, timezoneOffSetAdd } from '../../utils';
import { fetchLogDeviceViewportDataUsingCancelToken } from '../../AxiosInterceptor/fetchLogDeviceViewportDataUsingCancelToken';
import { logRecordLoadingStatusWorker } from '../../Actions/level.action';
import { addToast } from '../../Actions/toasts';

const todaysDate = moment().tz('America/New_York').format('YYYY-MM-DD');

const useFetchSingleVehicleData = (method, url, eventUrl, serviceEventUrl, body, deviceId, date) => {
  const [isLoadingLP, setIsLoadingLP] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [apiData, setApiData] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [serviceEventData, setServiceEventData] = useState([]);
  const [serverError, setServerError] = useState(null);
  const authSelector = useSelector(state => state.auth);
  const lastKnownLocation = useSelector((state) => state.driver.fetchSingleDeviceLastLocation);
  const dispatch = useDispatch();

  const DriverDateFromUTC = timezoneOffSetAdd(date, '00:00:00', sessionStorage.getItem("driverTimezoneOffset"))
  const DriverDateToUTC = timezoneOffSetAdd(date, '23:59:59', sessionStorage.getItem("driverTimezoneOffset"))

  let secondResponse;
  let thirdResponse;
  let newData = [];

  useEffect(() => {
    setIsLoading(true);
    setIsLoadingLP(true);
    dispatch(logRecordLoadingStatusWorker(isLoadingLP));
    const fetchData = async () => {
      try {
        const { cancelPrevQuery, result, error } = await fetchLogDeviceViewportDataUsingCancelToken(
          baseURL,
          "json",
          url,
          method,
          {
            ...body,
            deviceId: [deviceId],
            clientNumber: authSelector.user.authUser.clientNumber,
            fromDateTime: DriverDateFromUTC,
            toDateTime: DriverDateToUTC,
            isHistoric: date === todaysDate ? false : true
          }
        );
        if (cancelPrevQuery) return;
        try {
          secondResponse = await axios({
            baseURL: baseURL,
            responseType: "json",
            url: eventUrl,
            method: method,
            data: {
              ...body,
              clientNumber: authSelector.user.authUser.clientNumber,
              userId: authSelector.user.authUser.username,
              fromDate: DriverDateFromUTC,
              toDate: DriverDateToUTC,
              deviceId: deviceId
            }
          });
        }
        catch (error) {
          dispatch(addToast({
            title: error.response.data.message + " for events",
            type: "error",
          }));
        }
        try {
          thirdResponse = await axios({
            baseURL: baseURL,
            responseType: "json",
            url: serviceEventUrl,
            method: method,
            data: {
              ...body,
              clientNumber: authSelector.user.authUser.clientNumber,
              userId: authSelector.user.authUser.username,
              fromDate: DriverDateFromUTC,
              toDate: DriverDateToUTC,
              deviceId: deviceId
            }
          });
        } catch (error) {
          dispatch(addToast({
            title: error.response.data.message + " for service Events",
            type: "error",
          }));
        }
        const data = result;
        const events = await secondResponse?.data;
        const serviceEvents = await thirdResponse?.data;
        setServiceEventData(serviceEvents)
        for (let i = 0; i < data.length; i++) {
          const deviceId = data[i].deviceId;
          const deviceName = data[i].data.deviceName;
          const clientVehicleId = data[i].data.clientVehicleId;
          const timezoneShortName = data[i].data.timezoneShortName;
          //setting UTC offset in log record
          // convertLogRecordToLocalTime(data[i].data.locations); 
          const logRecordData = data[i].data.locations;
          if (logRecordData.length > 0) {
            newData.push({ deviceId, logRecordData, deviceName, clientVehicleId, timezoneShortName });
            if (newData[0].logRecordData.length < 2) {
              let firstLat = newData[0].logRecordData[0].latitude;
              let firstLon = newData[0].logRecordData[0].longitude;
              newData[0].logRecordData.splice(0, 0, { "latitude": firstLat, "longitude": firstLon, "dateTime": `${date} 00:00:00`, "device": newData.deviceId, "client": newData.clientVehicleId });
            }
          }
        }
        setApiData(newData);
        setEventData(events);
        setIsLoadingLP(false);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setServerError(error);
        setIsLoadingLP(false);
        setEventData([]);
      }
    };
    fetchData();
  }, [url, method, deviceId, date]);

  useEffect(() => {
    dispatch(logRecordLoadingStatusWorker(isLoadingLP));
    if (lastKnownLocation && lastKnownLocation.data == null && !lastKnownLocation.isLoading && apiData.length == 0 && !isLoadingLP) {
      dispatch(addToast({
        title: "No Location Found",
        type: "error",
      }));
    }
  }, [!isLoadingLP]);

  return { isLoading, apiData, eventData, serviceEventData, serverError };
};

export default useFetchSingleVehicleData;

