import car_blue from '../../../Asset/MapIcons/car-blue.png';
import posted_speed from '../../../Asset/NewEventsIcons/posted-speed-teal.svg';
import harsh_acceleration from '../../../Asset/NewEventsIcons/harsh-acc-teal.svg';
import idling from '../../../Asset/NewEventsIcons/idling-teal.svg';
import overdue_maintenance from '../../../Asset/Images/Overdue_Maintenance.png';
import seat_belt from '../../../Asset/NewEventsIcons/seatbelt-teal.svg';
import reverse from '../../../Asset/NewEventsIcons/reverse-teal.svg';
import hard_braking from '../../../Asset/NewEventsIcons/braking-teal.svg';
import warning_max_speed from '../../../Asset/NewEventsIcons/max-speed-teal.svg';
import clock_In_Out from '../../../Asset/NewEventsIcons/clock-in-teal.svg';
import ticket_Green from '../../../Asset/NewEventsIcons/ticket-green.svg';
import ticket_Red from '../../../Asset/NewEventsIcons/ticket-red.svg';
import ticket_Yellow from '../../../Asset/NewEventsIcons/ticket-yellow.svg';
import voltage_Yellow from '../../../Asset/NewEventsIcons/voltage-yellow.svg'
import location_Masking_Green from '../../../Asset/NewEventsIcons/location-Masking-Green.svg'
import location_Masking_Red from '../../../Asset/NewEventsIcons/location-Masking-Red.svg'
import red from '../../../Asset/Images/Reverse.png'
import fueling_Event_Icon from '../../../Asset/NewEventsIcons/Fueling-Event-Icon.svg'
import { Icon } from "leaflet";

export const carIcon = new Icon({
    iconUrl: car_blue,
    iconSize: [15, 22],
    tooltipAnchor: [20, 0],
})

export const breadscrumbs = new Icon({
    iconUrl: red,
    iconSize: [7, 7],
})

export const postedSpeedIcon = new Icon({
    iconUrl: posted_speed,
    iconSize: [5, 5],
})

export const idlingIcon = new Icon({
    iconUrl: idling,
    iconSize: [20, 20],
})

export const harshAccelerationIcon = new Icon({
    iconUrl: harsh_acceleration,
    iconSize: [20, 20],
})

export const overdueMaintenanceIcon = new Icon({
    iconUrl: overdue_maintenance,
    iconSize: [20, 20],
})

export const seatBeltIcon = new Icon({
    iconUrl: seat_belt,
    iconSize: [20, 20]
})

export const reverseIcon = new Icon({
    iconUrl: reverse,
    iconSize: [20, 20]
})

export const hardBrakingIcon = new Icon({
    iconUrl: hard_braking,
    iconSize: [20, 20]
})

export const warningMaxSpeedIcon = new Icon({
    iconUrl: warning_max_speed,
    iconSize: [20, 20]
})

export const postedSpeedIconBig = new Icon({
    iconUrl: posted_speed,
    iconSize: [30, 30],
})

export const harshAccelerationIconBig = new Icon({
    iconUrl: harsh_acceleration,
    iconSize: [30, 30],
})

export const idlingIconBig = new Icon({
    iconUrl: idling,
    iconSize: [30, 30],
})

export const overdueMaintenanceIconBig = new Icon({
    iconUrl: overdue_maintenance,
    iconSize: [30, 30],
})

export const seatBeltIconBig = new Icon({
    iconUrl: seat_belt,
    iconSize: [30, 30],
})

export const reverseIconBig = new Icon({
    iconUrl: reverse,
    iconSize: [30, 30],
})

export const hardBrakingIconBig = new Icon({
    iconUrl: hard_braking,
    iconSize: [30, 30],
})

export const warningMaxSpeedIconBig = new Icon({
    iconUrl: warning_max_speed,
    iconSize: [30, 30],
})

export const clockInOutIconBig = new Icon({
    iconUrl: clock_In_Out,
    iconSize: [30, 30],
})

export const clockInOutIcon = new Icon({
    iconUrl: clock_In_Out,
    iconSize: [20, 20],
})

export const ticketGreenIconBig = new Icon({
    iconUrl: ticket_Green,
    iconSize: [30, 30],
})

export const ticketGreenIcon = new Icon({
    iconUrl: ticket_Green,
    iconSize: [20, 20],
})

export const ticketRedIconBig = new Icon({
    iconUrl: ticket_Red,
    iconSize: [30, 30],
})

export const ticketRedIcon = new Icon({
    iconUrl: ticket_Red,
    iconSize: [20, 20],
})

export const ticketYellowIconBig = new Icon({
    iconUrl: ticket_Yellow,
    iconSize: [30, 30],
})

export const ticketYellowIcon = new Icon({
    iconUrl: ticket_Yellow,
    iconSize: [20, 20],
})

export const voltageYellowIconBig = new Icon({
    iconUrl: voltage_Yellow,
    iconSize: [30, 30],
})

export const voltageYellowIcon = new Icon({
    iconUrl: voltage_Yellow,
    iconSize: [20, 20],
})

export const locationMaskingGreenIconBig = new Icon({
    iconUrl: location_Masking_Green,
    iconSize: [30, 30],
})

export const locationMaskingGreenIcon = new Icon({
    iconUrl: location_Masking_Green,
    iconSize: [20, 20],
})

export const locationMaskingRedIconBig = new Icon({
    iconUrl: location_Masking_Red,
    iconSize: [30, 30],
})

export const locationMaskingRedIcon = new Icon({
    iconUrl: location_Masking_Red,
    iconSize: [20, 20],
})

export const fuelingEventIconBig = new Icon({
    iconUrl: fueling_Event_Icon,
    iconSize: [30, 30],
})

export const fuelingEventIcon = new Icon({
    iconUrl: fueling_Event_Icon,
    iconSize: [20, 20],
})