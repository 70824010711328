import React, {useState, useEffect} from 'react';
import styles from './Filters.module.scss';
import { Text, Grid, GridItem,ChevronDownIcon, ChevronUpIcon, TextInput } from "@leaseplan/ui";
// import { Text, CheckboxList, Checkbox, CheckboxGroup, CheckboxGroupItem, ChevronDownIcon, ChevronUpIcon, Grid, GridItem } from "@leaseplan/ui";
import {FormControl, FormLabel,FormGroup, FormControlLabel, Checkbox} from '@material-ui/core'
import { makeStyles } from '@material-ui/core';
import {capitalizeFirstLetter} from '../../../utils/index';


const ShowMoreButton = ({handleOnClick, showMore}) => {
  return (
    <Grid onClick={handleOnClick} gutter={false}>
      <GridItem gutter={false}>
        {
          showMore ? (
            <ChevronUpIcon color="midOrange" style={{marginRight: '5px', marginTop: '1px'}}/>
          ): (
            <ChevronDownIcon color="midOrange" style={{marginRight: '5px', marginTop: '1px'}}/>
          )
        }
      </GridItem>
      <GridItem >
        <Text component="p" size="xxs" color="midOrange" gutter={false}>{showMore? 'Show Less': 'Show More'}</Text>
      </GridItem>
    </Grid>
  )
}

const Filters = (props) => {
    const { heading, data, onFilterChange, defaultValue, clearAll } = props;
    const [searchFilter, setSearchFilter] = useState('');
    const [filterData, setFilterData] = useState(data);
    // const [value, setValue] = useState([]);
    const [showMore, setShowMore] = useState(false);
    // const classes = useStyles();

    // useEffect(() => {
    //     if(clearAll) setValue([]);
    // }, [clearAll]);

    useEffect(() => {
      setFilterData(data);
    }, [data]);

    const handleChange = (event) => {
        // setValue(values.value);
        onFilterChange(event.target.name,heading, event.target.checked)
    }

    const handleOnClick = (event) => {
      setShowMore(!showMore);
    }

    const isMore = data.length > 5;
    const numberOfItems = showMore ? data.length : 5;

    useEffect(() => {
      let filteredData = [...data];
      filteredData = data.filter(row => row.label.toLowerCase().includes(searchFilter.toLowerCase()));

      setFilterData(filteredData);
    }, [searchFilter])

    return (
        <div className={styles.popupContent}>
            <Text component="p" size="s" fontWeight="bold" color="petrolBlue" gutter>{capitalizeFirstLetter(heading)}</Text>
            {heading.toLowerCase().startsWith('level') && <TextInput
              name="default"
              placeholder="Search"
              className={styles.searchInput}
              onChange={(event) => setSearchFilter(event.value)}
            />}
            <div className={styles.checkboxList}>
                <FormControl component="fieldset">
                  <FormGroup>
                        {
                            filterData.slice(0, numberOfItems).map((row, index) => {
                                return (
                                    <FormControlLabel
                                      key={index}
                                      control={<Checkbox checked={row.checked} onChange={handleChange} name={row.value} />}
                                      label={row.label}
                                  />
                                )
                            })
                        }
                  </FormGroup>
                </FormControl>
                {
                  isMore && <ShowMoreButton showMore={showMore} handleOnClick={handleOnClick}/>
                }  
            </div>
            
        </div>
    )
}

export default Filters;