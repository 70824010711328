import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import {
    Text,
    Grid,
    GridItem,
} from "@leaseplan/ui";
import CircularProgressWithLabel from '../../OneConnectCommon/CircularProgressWithLabel/CircularProgressWithLabel';
import { SvgIcon, withStyles } from '@material-ui/core';
import { ReactComponent as Driver } from '../../../Asset/SearchIcons/Driver.svg';
import { ReactComponent as Vehicle } from '../../../Asset/SearchIcons/Vehicle.svg';
import { ReactComponent as VehicleUnderRepair } from '../../../Asset/SearchIcons/VehicleUnderRepair.svg';
import { ReactComponent as VehicleRepairStand } from '../../../Asset/SearchIcons/VehicleRepairStand.svg';
import WifiIcon from '@material-ui/icons/Wifi';
import styles from './VehicleInsightsRow.module.scss';
import { COLOR_CODE_VEHICLE } from '../../../Constants/DriverEvents';
import RowEvents from '../../OneConnectCommon/RowEvents/RowEvents';
import { useNavigate, generatePath } from "react-router-dom";
import { RoutePath } from '../../../Routers/Routes';
import { useSelector } from 'react-redux';
import moment from 'moment';

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: "white",
        },
        '&:nth-of-type(even)': {
            backgroundColor: "#f6f8fb",
        },
    },
}))(TableRow);

{/*  
//logic has been changed as same like selected vehcile 
const getOBDIconColor = (lastCommunication) => {
    if(lastCommunication === 'DISCONNECTED'){
        return 'red'
    }else if(lastCommunication === 'CONNECTED'){
        return '#7ccc5a'; //green
    }else if(lastCommunication === 'PARTIALLY_CONNECTED'){
        return '#F9C74B'; // yellow
    }else{
        return 'grey'; //grey
    }
}
*/}

const getOBDIconColor = (lastCommunicationTime) => {
    let days = (Number(lastCommunicationTime) || 0) / 1440; //converting minutes in days
    if (days >= 0 && days <= 2) {
        return '#7ccc5a'; //green
    } else if (days > 2 && days <= 5) {
        return '#FDD835'; // yellow
    } else if (days > 5) {
        return 'red'
    } else {
        return '#7ccc5a'; // default green
    }

}

const VehicleInsightsRow = ({ id, columns, rowData }) => {

    const timelineSelectorDate = useSelector(state => state.timeline.date);
    const history = useNavigate()

    const moveToDriver = () => {
        sessionStorage.setItem("driverTimezoneOffset", rowData.utcOffset)
        history({
            pathname: generatePath(RoutePath.DriverDetails, { driverId: rowData.deviceId, date: timelineSelectorDate })
        });
    }

    const moveToVehicle = () => {
        sessionStorage.setItem("driverTimezoneOffset", rowData.utcOffset)
        let selectedDate = moment(timelineSelectorDate).format('YYYY-MM-DD')
        history({
            pathname: generatePath(RoutePath.VehicleDetails, { vin: rowData.vin, driverId: rowData.deviceId, date: selectedDate })
        })
    }

    return (
        <StyledTableRow>
            <TableCell
                className={styles.tableCell}
            >
                <CircularProgressWithLabel style={{ color: rowData.scoreColor ? (rowData.scoreColor.toLowerCase() === 'yellow' ? '#F9C74B' : rowData.scoreColor.toLowerCase()) : 'red' }} size='2rem' value={rowData.score ? rowData.score : 0} />
            </TableCell>
            <TableCell

                className={styles.tableCell}
            >
                <Text
                    size="xs"
                    styles={{ color: '#4a4a4a' }}
                    fontWeight="bold"
                >
                    {rowData.deviceName ? rowData.deviceName : '--'}
                </Text>
            </TableCell>
            {/* <TableCell
                    
                    className={styles.tableCell}
                >
                        <Text
                        size="xs"
                        styles={{color: '#4a4a4a'}}
                        fontWeight="bold"
                        >
                            {rowData.firstName ? rowData.firstName : '--'}
                        </Text>       
                </TableCell>
                <TableCell
                    
                    className={styles.tableCell}
                >
                    <Text
                        size="xs"
                        styles={{color: '#4a4a4a'}}
                        fontWeight="bold"
                    >
                        {rowData.lastName ? rowData.lastName : '--'}
                    </Text>        
                </TableCell> */}
            <TableCell

                className={styles.tableCell}
            >
                <Text
                    size="xs"
                    styles={{ color: '#4a4a4a' }}
                >
                    {`${rowData.year} ${rowData.make} ${rowData.model}`}
                </Text>
            </TableCell>
            <TableCell
                className={styles.tableCell}
            >
                <Text
                    size="xs"
                    styles={{ color: '#4a4a4a' }}
                >
                    <div className={styles.icon} style={{ backgroundColor: rowData.minutesSinceLastCommunicated === null ? 'grey' : getOBDIconColor(rowData.minutesSinceLastCommunicated) }}>
                        <WifiIcon htmlColor={'#FFF'} style={{ transform: 'rotate(90deg)', width: 15, height: 15 }} fontSize={"small"} />
                    </div>
                </Text>
            </TableCell>
            <TableCell

                className={styles.tableCell}
            >
                <Text
                    size="xs"
                    styles={{ color: '#4a4a4a' }}
                >
                    <Grid gutter={false}>
                        {
                            rowData.vehicleHealth && rowData.vehicleHealth.map((data, key) => (
                                <GridItem key={key}>
                                    <RowEvents color={COLOR_CODE_VEHICLE[data.healthType]} type={data.healthDescription} number={data.counter && data.counter} enablePopup={data.popupEnable} />
                                </GridItem>
                            ))
                        }
                    </Grid>
                </Text>
            </TableCell>
            <TableCell
                className={styles.tableCell}
            >
                <Grid justifyContent='center'>
                    <GridItem>
                        <SvgIcon className={styles.button} onClick={moveToDriver}>
                            <Driver />
                        </SvgIcon>
                    </GridItem>
                </Grid>
            </TableCell>
            <TableCell
                colSpan={2} align="center"
                className={styles.tableCell}
            >
                <Grid justifyContent='center'>
                    <GridItem className={styles.stackedSvgButton}>
                        {
                            !rowData.isRepair ?
                                (<SvgIcon className={styles.button} onClick={moveToVehicle}>
                                    <Vehicle />
                                </SvgIcon>) :
                                (<div className={styles.vehicleRepairSvg} onClick={moveToVehicle}>
                                    <SvgIcon className={styles.repairSvg}>
                                        <VehicleUnderRepair />
                                    </SvgIcon>
                                    <SvgIcon className={styles.standSvg}>
                                        <VehicleRepairStand />
                                    </SvgIcon>
                                </div>
                                )
                        }
                    </GridItem>
                </Grid>
            </TableCell>
        </StyledTableRow>
    )
}

VehicleInsightsRow.propTypes = {

}

export default VehicleInsightsRow
