import {AUTH} from '../Constants/auth';
import {put, takeEvery, takeLeading, take, call} from 'redux-saga/effects';
import { F, S } from "../utils/actions";
import { baseURL } from "../Store/base-url";
import authService from '../Services/Auth/authService';
import axios from "../AxiosInterceptor";
import Cookies from 'js-cookie';


function userLoadWithoutHeader(userId, clientId) {
    return axios.request({
      baseURL: baseURL,
      responseType: "json",
      url: `/user`,
      method: "get",
    });
  }

  function userLoadWithHeader(userId, clientId) {
    return axios.request({
      baseURL: baseURL,
      responseType: "json",
      url: `/user`,
      method: "get",
      headers: {
        'authorization-username': userId,
        'authorization-client' : clientId,
      },
    });
  }
  
  export function* loginWorker(action) {

      try{
          let { data } = yield call(userLoadWithHeader, action.payload.userId, action.payload.clientId);
          if (data) {
              yield put({type: S(action.type), payload: data})
          } 
      } catch (e) {
          yield put({ type: F(action.type), payload: e.message });
      }
  }

  export function* userLoadWorker(action) {
    try{
        let { data } = yield call(userLoadWithoutHeader);
        if (data) {
            yield put({type: S(action.type), payload: data })
        } 
    } catch (e) {
        yield put({ type: F(action.type), payload: e.message });
    }
}
  
  
  function* authSaga() {
      yield takeEvery(AUTH.LOGIN , loginWorker);
      yield takeEvery(AUTH.USER_FETCH , userLoadWorker);
  }
  
  
  export default authSaga;
