import { S, F } from "../utils/actions";
import { AUTH } from '../Constants/auth';
import Cookies from 'js-cookie';

const initialState = {
    clientIdToken: sessionStorage.getItem('authorization-client'),
    isAuthenticated: false,
    isLoading: true,
    user: null,
    error: null,
}

const auth = (state=initialState, action) => {
    const {type, payload} = action;
    switch (type){
        case AUTH.LOGIN:
            return {
                ...state,
                isLoading: true,
            };
        case S(AUTH.LOGIN):
            return {
                ...state,
                user: payload,
                isAuthenticated: true,
                isLoading: false,
                error: null,
            };
        case F(AUTH.LOGIN):
            return {
                clientIdToken: null,
                isAuthenticated: false,
                isLoading: false,
                user: null,
                error: payload,
            };
        case AUTH.USER_FETCH:
            return {
                ...state,
                isLoading: true,
            }
        case S(AUTH.USER_FETCH):
            return {
                ...state,
                user: payload,
                isAuthenticated: true,
                isLoading: false,
                error: null,
            };
        case F(AUTH.USER_FETCH):
            return {
                ...state,
                isAuthenticated: false,
                isLoading: false,
                user: null,
                error: payload,
            };
        default:
            return state
    }
};

export default auth;