import { Heading, Text } from "@leaseplan/ui";
import { Icon } from "@velocity/ui.Icon";
import React from "react";
import styles from "./InformationTab.module.scss";

const InformationTab = ({
    componentName,
    icon="lock",
    iconSize="xl",
    headerText = "Coming Soon...",
    text = "We are currently working hard building this page."
}) => {
    return (
        <div className={styles.row}>
            <Heading component="p" size={'xs'} color={'petrolBlue'} className={styles.heading} gutter>{componentName}</Heading>
            <div className={styles.CommingSoon}>
                <Icon name={icon} size={iconSize} />
                <Heading size="xxs">{headerText}</Heading>
                <Text size="xs" color="steel">
                    {text}
                </Text>
            </div>
        </div>
    
    );
};
InformationTab.propTypes = {};

InformationTab.defaultProps = {};
export default InformationTab;
