import { Grid, GridItem, Text, Spacing, Loader } from '@leaseplan/ui';
import CircularProgressWithLabel from './CircularProgressWithLabel';
import React, { useEffect, useState } from 'react';
import axios from "../../../AxiosInterceptor";
import { baseURL } from '../../../Store/base-url';
import styles from './Popup.module.scss';
import { generatePath } from 'react-router-dom';
import { RoutePath } from '../../../Routers/Routes';
import moment from 'moment';

const method = 'POST';
const url = '/device/retrieve-device-vehicle-data';

const VehiclePopup = ({ deviceId, date, history }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [apiData, setApiData] = useState([]);
    const [serverError, setServerError] = useState(null);

    const moveToDriver = () => {
        sessionStorage.setItem("driverTimezoneOffset", apiData.utcOffset)
        sessionStorage.removeItem("tabUrl")
        sessionStorage.removeItem("selectedDate")
        history({
            pathname: generatePath(RoutePath.DriverDetails, { driverId: deviceId, date: date })
        });
    }
    const moveToVehicle = (vehicleVin) => {
        sessionStorage.setItem("driverTimezoneOffset", apiData.utcOffset)
        sessionStorage.removeItem("tabUrl")
        sessionStorage.removeItem("selectedDate")
        if (vehicleVin) {
            let selectedDate = moment(date).format('YYYY-MM-DD')
            history({
                pathname: generatePath(RoutePath.VehicleDetails, { vin: vehicleVin, driverId: deviceId, date: selectedDate })
            });
        }
    }
    useEffect(() => {
        setIsLoading(true);
        const fetchData = async () => {
            try {
                const resp = await axios({
                    baseURL: baseURL,
                    responseType: "json",
                    url: url,
                    method: method,
                    data: {
                        deviceId: deviceId
                    }
                });
                const data = await resp?.data;
                setApiData(data);
                setIsLoading(false);
            } catch (error) {
                setServerError(error);
                setIsLoading(false);
            }
        };
        fetchData();
    }, [deviceId]);

    if (isLoading) {
        return (<Loader className={styles.Loader__NationalShopPopup} />)
    }


    return (
        <>
            <div className="GridStyle__VehiclePopup">
                <Spacing pb={1} />
                <Grid gutter={false} justifyContent="center">
                    <GridItem span={12}>
                        <Spacing pb={0.2}>
                            <Text fontWeight="bold" size="s" color="petrolBlue">{"Vehicle Data"}</Text>
                        </Spacing>
                    </GridItem>
                    <GridItem>
                        <Grid gutter={false}>
                            <GridItem span={5}>
                                <Grid gutter={false}>
                                    <GridItem p={1} span={12}>
                                        <Grid gutter={false}>
                                            <GridItem span={12}>
                                                <Text fontWeight='bold' size="xs" color="midOrange">Device Name{` `}</Text>
                                            </GridItem>
                                            <GridItem span={12}>
                                                <Text size="xs" color="petrolBlue">{`${apiData.deviceName ? apiData.deviceName : "--"}`}</Text>
                                            </GridItem>
                                        </Grid>
                                        <Spacing pv={.2} />
                                    </GridItem>
                                    <GridItem span={12}>
                                        <Grid gutter={false}>
                                            <GridItem span={12}>
                                                <Text fontWeight='bold' size="xs" color="midOrange">Vehicle{` `}</Text>
                                            </GridItem>
                                            <GridItem span={12}>
                                                <Text size="xs" color="petrolBlue">{`${apiData.modelYear ? apiData.modelYear : "--"} ${apiData.makeDescription ? apiData.makeDescription : "--"} ${apiData.baseModelName ? apiData.baseModelName : "--"}`}</Text>
                                            </GridItem>
                                        </Grid>
                                    </GridItem>
                                    <Spacing pv={.2} />
                                    <GridItem span={12}>
                                        <Grid gutter={false}>
                                            <GridItem span={12}>
                                                <Text fontWeight='bold' size="xs" color="midOrange">VIN{` `}</Text>
                                            </GridItem>
                                            <GridItem span={12}>
                                                <Text className={styles.textHover} onClick={() => { moveToVehicle(apiData.vin) }} size="xs" color="petrolBlue">{`${apiData.vin ? apiData.vin : "--"}`}</Text>
                                            </GridItem>
                                        </Grid>
                                    </GridItem>
                                    <Spacing pv={.2} />
                                    <GridItem span={12}>
                                        <Grid gutter={false}>
                                            <GridItem span={12}>
                                                <Text fontWeight='bold' size="xs" color="midOrange">Driver{` `}</Text>
                                            </GridItem>
                                            <GridItem span={12}>
                                                <Text className={styles.textHover} onClick={() => { moveToDriver() }} size="xs" color="petrolBlue">{`${apiData.firstName ? apiData.firstName + " " + apiData.lastName : "--"}`}</Text>
                                            </GridItem>
                                        </Grid>
                                    </GridItem>
                                </Grid>
                            </GridItem>
                            <Spacing pv={1} />
                            <GridItem span={7}>
                                {
                                    apiData && (
                                        <GridItem span={12} justifyContent="right" alignItem="rigth" >
                                            <Grid gutter={false}>
                                                <Text fontWeight='bold' size="xs" color="midOrange">Level{` `}</Text>
                                            </Grid>
                                            <Grid gutter={false}>
                                                {
                                                    apiData.level1Description !== "" && (
                                                        <GridItem span={12}>
                                                            <Text fontWeight='bold' size="xs" color="midOrange">1{` `}</Text>
                                                            <Text size="xs" color="petrolBlue">{apiData.level1Description}</Text>
                                                        </GridItem>
                                                    )
                                                }
                                                {
                                                    apiData.level2Description !== "" && (
                                                        <GridItem span={12}>
                                                            <Text fontWeight='bold' size="xs" color="midOrange">2{` `}</Text>
                                                            <Text size="xs" color="petrolBlue">{apiData.level2Description}</Text>
                                                        </GridItem>
                                                    )
                                                }
                                                {
                                                    apiData.level3Description !== "" && (
                                                        <GridItem span={12}>
                                                            <Text fontWeight='bold' size="xs" color="midOrange">3{` `}</Text>
                                                            <Text size="xs" color="petrolBlue">{apiData.level3Description}</Text>
                                                        </GridItem>
                                                    )
                                                }
                                                {
                                                    apiData.level4Description !== "" && (
                                                        <GridItem span={12}>
                                                            <Text fontWeight='bold' size="xs" color="midOrange">4{` `}</Text>
                                                            <Text size="xs" color="petrolBlue">{apiData.level4Description}</Text>
                                                        </GridItem>
                                                    )
                                                }
                                                {
                                                    apiData.level5Description !== "" && (
                                                        <GridItem span={12}>
                                                            <Text fontWeight='bold' size="xs" color="midOrange">5{` `}</Text>
                                                            <Text size="xs" color="petrolBlue">{apiData.level5Description}</Text>
                                                        </GridItem>
                                                    )
                                                }
                                                {
                                                    apiData.level6Description !== "" && (
                                                        <GridItem span={12}>
                                                            <Text fontWeight='bold' size="xs" color="midOrange">6{` `}</Text>
                                                            <Text size="xs" color="petrolBlue">{apiData.level6Description}</Text>
                                                        </GridItem>
                                                    )
                                                }
                                                {
                                                    apiData.level7Description !== "" && (
                                                        <GridItem span={12}>
                                                            <Text fontWeight='bold' size="xs" color="midOrange">7{` `}</Text>
                                                            <Text size="xs" color="petrolBlue">{apiData.level7Description}</Text>
                                                        </GridItem>
                                                    )
                                                }
                                                {
                                                    apiData.level8Description !== "" && (
                                                        <GridItem span={12}>
                                                            <Text fontWeight='bold' size="xs" color="midOrange">8{` `}</Text>
                                                            <Text size="xs" color="petrolBlue">{apiData.level8Description}</Text>
                                                        </GridItem>
                                                    )
                                                }
                                                {
                                                    apiData.level9Description !== "" && (
                                                        <GridItem span={12}>
                                                            <Text fontWeight='bold' size="xs" color="midOrange">9{` `}</Text>
                                                            <Text size="xs" color="petrolBlue">{apiData.level9Description}</Text>
                                                        </GridItem>
                                                    )
                                                }
                                                {
                                                    apiData.level10Description !== "" && (
                                                        <GridItem span={12}>
                                                            <Text fontWeight='bold' size="xs" color="midOrange">10{` `}</Text>
                                                            <Text size="xs" color="petrolBlue">{apiData.level10Description}</Text>
                                                        </GridItem>
                                                    )
                                                }
                                            </Grid>
                                        </GridItem>
                                    )
                                }
                                 <Grid gutter={false} justifyContent="center" alignItem="center">
                                    <Text fontWeight='bold' size="xs" color="midOrange">One Score</Text>
                                </Grid>
                                <Grid gutter={false} justifyContent="center" alignItem="center">
                                    <Spacing pl={1} pr={1}>
                                        <CircularProgressWithLabel style={{ color: apiData.scoreColor ? apiData.scoreColor.toLowerCase() : 'green' }} size={60} value={apiData.driverScore ? apiData.driverScore : 0} />
                                    </Spacing>
                                </Grid>
                                {/* <Spacing pv={.5} />
                                <GridItem span={12}>
                                    <Grid gutter={false}>
                                        <GridItem span={12}>
                                            <Text fontWeight='bold' size="xs" color="midOrange">Status{`  `}</Text>
                                        </GridItem>
                                        <GridItem span={12}>
                                            <Text size="xs" color="petrolBlue">{`${apiData.vehicleUtilizationStatus ? apiData.vehicleUtilizationStatus : "--"}`}</Text>
                                        </GridItem>
                                    </Grid>
                                </GridItem>
                                <Spacing pv={.5} /> */}
                            </GridItem>
                        </Grid>
                    </GridItem>
                </Grid>
                <Spacing pb={1} />
            </div>
        </>
    )
}


export default React.memo(VehiclePopup)
