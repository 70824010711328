import { S, F } from "../utils/actions";
import { CLIENT_CONFIG } from "../Constants/clientConfig";

const INITIAL_STATE = {
  clientHQInfo: {
      data: null,
      error: null,
      isLoading: false,
}
};

const clientConfig = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CLIENT_CONFIG.CLIENTHQ_FETCH:
          return { 
              ...state, 
              clientHQInfo: {
                  ...state.clientHQInfo, 
                  isLoading: true
                } 
            };
        case S(CLIENT_CONFIG.CLIENTHQ_FETCH):
            return { 
                ...state, 
                clientHQInfo: {
                    data: action.payload,
                    error: null,
                    isLoading: false,
                  } 
              };
        case F(CLIENT_CONFIG.CLIENTHQ_FETCH):
          return { 
            ...state, 
            clientHQInfo: {
                position: null,
                error: action.payload,
                isLoading: false,
              } 
          };

          case CLIENT_CONFIG.CLIENTHQ_SAVE:
          return { 
              ...state, 
              clientSaveInfo: {
                  data: null,
                  error: null, 
                  isLoading: true
                } 
            };
        case S(CLIENT_CONFIG.CLIENTHQ_SAVE):
            return { 
                ...state, 
                clientSaveInfo: {
                    data: action.payload,
                    error: null,
                    isLoading: false,
                  } 
              };
        case F(CLIENT_CONFIG.CLIENTHQ_SAVE):
          return { 
            ...state, 
            clientSaveInfo: {
                data: null,
                error: action.payload,
                isLoading: false,
              } 
          };
        default:
          return state;
      }
}

export default clientConfig;