import React from 'react';
import {Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import {findStyleSetting, RenderCustomizedLabelForPie} from './GraphComponents';
import styles from './Graph.module.scss';
import { COLORS } from '../GeneralSettings';

const PieGraph = ({
        height, 
        width, 
        isModal, 
        heading,
        styleType,
        graphSpecification,
    }) => {


    const data = [{name: 'EV Ready', value: 17}, {name: 'Not EV Ready', value: 83}]
    const settings = findStyleSetting(styleType, "pie");
    

    return (
      <div className={styles.container}>
        <ResponsiveContainer width={width} height={height} className={styles.graphArea}>
            <PieChart 
                width={width}
                height={height}
            >
                <Legend 
                        payload={
                        data.map(
                            (item, index) => ({
                                id: item.name,
                                type: "circle",
                                value: `${item.name}`,
                                color: COLORS[index % COLORS.length]
                            })
                        )
                        }
                        iconSize="6"
                        verticalAlign="bottom" 
                        wrapperStyle={settings?.legend?.wrapperStyle}
                    />
                <Pie 
                    data={data} 
                    innerRadius={settings.innerRadius}
                    outerRadius={settings.outerRadius} 
                    fill="#8884d8"
                    paddingAngle={2}
                    label={<RenderCustomizedLabelForPie/>}
                    labelLine={false}
                    // isAnimationActive={false}
                >
                    {
                        data.map((entry, index) => <Cell key={index} fill={COLORS[index % COLORS.length]}/>)
                    }
                </Pie>
                <Tooltip/>
            </PieChart>
      </ResponsiveContainer>
      </div>
    )
}


export default React.memo(PieGraph);