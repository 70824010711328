import {TIMELINE} from "../Constants/timeline";

export const insertTimelineClockData = (params) => {
    return {
        type: TIMELINE.TIMELINE_CLOCKDATA_INSERT,
        payload: params
    }
}
  
export const playTimeLine = () => {
    return {
        type: TIMELINE.TIMELINE_PLAY_INSERT
    }
}

export const pauseTimeLine = () => {
    return {
        type: TIMELINE.TIMELINE_PAUSE_INSERT
    }
}

export const speedTimeLine = (speed) => {
    return {
        type: TIMELINE.TIMELINE_SPEED_INSERT,
        payload: speed
    }
}

export const insertDate = (date) => {
    return {
        type: TIMELINE.TIMELINE_DATE_INSERT,
        payload: date
    }
}