import React from 'react';
import { TableCell, TableRow, SvgIcon, withStyles } from '@material-ui/core';
import {
    Text,
    Grid,
    GridItem,
} from "@leaseplan/ui";
import CircularProgressWithLabel from '../../OneConnectCommon/CircularProgressWithLabel/CircularProgressWithLabel';
import { ReactComponent as Driver } from '../../../Asset/SearchIcons/Driver.svg';
import { ReactComponent as Vehicle } from '../../../Asset/SearchIcons/Vehicle.svg';
import styles from './SearchRow.module.scss';
import { useNavigate, generatePath } from "react-router-dom";
import { RoutePath } from '../../../Routers/Routes';
import { useSelector } from 'react-redux';
import moment from 'moment';

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: "white",
        },
        '&:nth-of-type(even)': {
            backgroundColor: "#f6f8fb",
        },
    },
}))(TableRow);


const SearchRow = ({ id, columns, rowData }) => {

    const timelineSelectorDate = useSelector(state => state.timeline.date);

    const history = useNavigate()

    const moveToDriver = () => {
        sessionStorage.setItem("driverTimezoneOffset", rowData.utcOffset)
        history({
            pathname: generatePath(RoutePath.DriverDetails, { driverId: rowData.deviceId, date: timelineSelectorDate })
        });
    }

    const moveToVehicle = () => {
        sessionStorage.setItem("driverTimezoneOffset", rowData.utcOffset)
        let selectedDate = moment(timelineSelectorDate).format('YYYY-MM-DD')
        history({
            pathname: generatePath(RoutePath.VehicleDetails, { vin: rowData.vin, driverId: rowData.deviceId, date: selectedDate })
        });
    }

    return (
        <StyledTableRow>
            <TableCell

                className={styles.tableCell}
            >
                <CircularProgressWithLabel style={{ color: rowData.scoreColor ? (rowData.scoreColor.toLowerCase() === 'yellow' ? '#F9C74B' : rowData.scoreColor.toLowerCase()) : 'red' }} size='2rem' value={rowData.driverScore ? rowData.driverScore : 0} />
            </TableCell>
            <TableCell

                className={styles.tableCell}
            >
                <Text
                    size="xs"
                    styles={{ color: '#4a4a4a' }}
                    fontWeight="bold"
                >
                    {rowData.firstName ? rowData.firstName : '--'}
                </Text>
            </TableCell>
            <TableCell

                className={styles.tableCell}
            >
                <Text
                    size="xs"
                    styles={{ color: '#4a4a4a' }}
                    fontWeight="bold"
                >
                    {rowData.lastName ? rowData.lastName : '--'}
                </Text>
            </TableCell>
            <TableCell

                className={styles.tableCell}
            >
                <Text
                    size="xs"
                    styles={{ color: '#4a4a4a' }}
                >
                    {`${rowData.modelYear ? rowData.modelYear : ''} ${rowData.makeDescription ? rowData.makeDescription : ''} ${rowData.baseModelName ? rowData.baseModelName : ''}`}
                </Text>
            </TableCell>
            <TableCell

                className={styles.tableCell}
            >
                <Text
                    size="xs"
                    styles={{ color: '#4a4a4a' }}
                >
                    {rowData.vehicleUsage ? rowData.vehicleUsage : '--'}
                </Text>
            </TableCell>
            <TableCell

                className={styles.tableCell}
            >
                <Text
                    size="xs"
                    styles={{ color: '#4a4a4a' }}
                >
                    {rowData.deviceName ? rowData.deviceName : '--'}
                </Text>
            </TableCell>
            <TableCell

                className={styles.tableCell}
            >
                <Text
                    size="xs"
                    styles={{ color: '#4a4a4a' }}
                >
                    {rowData.serialNumber ? rowData.serialNumber : '--'}
                </Text>
            </TableCell>
            <TableCell

                className={styles.tableCell}
            >
                <Text
                    size="xs"
                    styles={{ color: '#4a4a4a' }}
                >
                    {rowData.vin ? rowData.vin : '--'}
                </Text>
            </TableCell>
            <TableCell
                className={styles.tableCell}
            >
                <Grid justifyContent='center'>
                    <GridItem>
                        <SvgIcon className={styles.button} onClick={moveToDriver}>
                            <Driver />
                        </SvgIcon>
                    </GridItem>
                </Grid>
            </TableCell>
            <TableCell
                className={styles.tableCell}
            >
                <Grid justifyContent='center'>
                    <GridItem>
                        <SvgIcon className={styles.button} onClick={moveToVehicle}>
                            <Vehicle />
                        </SvgIcon>
                    </GridItem>
                </Grid>
            </TableCell>
        </StyledTableRow>
    )
}

SearchRow.propTypes = {

}

export default SearchRow
