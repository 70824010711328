import React from 'react';
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createTheme({
    overrides: {
        MuiPickersToolbar: {
            toolbar: { 
                backgroundColor: '#f06400'
            }
        },
        MuiPickersDay: {
            day: {
                color: "light-gray",
                backgroundColor: "white",
                borderRadius:"0px",
            },
            container:{
                backgroundColor:"#f06400 !important"
            },
            daySelected: {
                backgroundColor: "#f06400",
                color:"black",
                '&:hover': {
                    backgroundColor : '#ed8b00'
                }
            },
            dayDisabled: {
                color: "black",
            },
            current: {
                color: "#f06400",
            },
        },
        MuiButton:{
            root:{
                
            },
            textPrimary: {
                color: '#f06400'
            }
        }
    },
  });

const DatePicker = (props) => {
    const {selectedDate, isOpen, handleDateChange, onClose} = props;
    return (
        <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} style={{display: 'none'}}>
                <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="Date picker dialog"
                    format="yyyy-mm-dd"
                    value={selectedDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                        "aria-label": "change date"
                    }}
                    style={{display: 'hidden'}}
                    open={isOpen}
                    onClose={onClose}
                />
            </MuiPickersUtilsProvider>
        </ThemeProvider>
    )
}

export default DatePicker;