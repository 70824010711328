import axios from "./index";

let tokenSource;
export const fetchDataUsingCancelToken = async (baseURL, responseType, url, method, data) => {
  try {
    if (typeof tokenSource !== typeof undefined) {
      tokenSource.cancel('Operation canceled due to new request.');
    }

    // save the new request for cancellation
    tokenSource = axios.CancelToken.source();

    const {data: res} = await axios({
      baseURL,
      responseType,
      url,
      method, 
      data,
      cancelToken: tokenSource.token
    });

      return { result: res };
  } catch (err) {
      if (axios.isCancel(err)) return { cancelPrevQuery: true, error: err };
      return {error: err};
  }
};