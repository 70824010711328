import { Grid, GridItem, Text, Spacing } from "@leaseplan/ui";
import React from "react";
import { getdatetime } from "../../../utils";
import road_img from "../../../Asset/Images/streetViewIcon.svg";
import styles from "./Popup.module.scss";
import LPTooltip from "../../../Common/LPTooltip/LPTooltip";

const PolylineTooltip = ({
  leafletLatLng,
  clientVehicleId,
  Location,
}) => {
  var Time = getdatetime(
    new Date(Location[0].logRecordData.dateTime),
    Location[0].timezoneShortName
  );
  var lat = parseFloat(Location[0].logRecordData.latitude).toFixed(7);
  var lng = parseFloat(Location[0].logRecordData.longitude).toFixed(7);

  return (
    <>
      <div className={styles.GridStyle__PolyLineTooltip}>
        <Grid gutter={false} justifyContent="center">
          <GridItem span={12}>
            <Spacing pb={0.2}>
              <Text fontWeight="bold" size="xs" color="bloodOrange">
                {clientVehicleId}
              </Text>
            </Spacing>
          </GridItem>
          <GridItem></GridItem>
        </Grid>
        <Grid gutter={false} justifyContent="left">
          <GridItem span={3}>
            <Text size="xs" color="petrolBlue">
              Speed
            </Text>
            <GridItem>
              <Text size="xs" fontWeight="bold" color="petrolBlue">
                {Location[0].logRecordData.speed}
              </Text>
            </GridItem>
          </GridItem>
          <div className={styles.vertical}></div>
          <GridItem span={4}>
            <Text size="xs" color="petrolBlue">
              Time
            </Text>
            <GridItem>
              <Text size="xs" fontWeight="bold" color="petrolBlue">
                {Time.split(" ")[0] +
                  " " +
                  Time.split(" ")[1] +
                  " " +
                  Time.split(" ")[2]}
              </Text>
            </GridItem>
          </GridItem>
          <div className={styles.verticalLine}></div>
          <Spacing ph={0.2} />
          <GridItem span={4}>
            <Text size="xs" color="petrolBlue">
              Date
            </Text>
            <GridItem>
              <Text size="xs" fontWeight="bold" color="petrolBlue">
                {Time.split(" ")[3]}
              </Text>
            </GridItem>
          </GridItem>
        </Grid>
        <Grid gutter={false} justifyContent="left">
          <Spacing pb={0.5}></Spacing>
          <GridItem span={9}>
            <Text size="xs" color="petrolBlue">
              Location
            </Text>
            <GridItem>
              <Text size="xs" fontWeight="bold" color="petrolBlue">
                {`${lat}, ${lng}`}
              </Text>

              <LPTooltip
                style={{ paddingLeft: "10px" }}
                title={"Show Street View"}
                placement="bottom"
                arrow
              >
                <img
                  src={road_img}
                  className={styles.img}
                  onClick={() => {
                    window.open(
                      `https://www.google.com/maps?layer=c&cbll=${leafletLatLng.lat},${leafletLatLng.lng}`,
                      "_blank",
                      "noopener"
                    );
                  }}
                />
              </LPTooltip>
            </GridItem>
          </GridItem>
        </Grid>
      </div>
    </>
  );
};

export default React.memo(PolylineTooltip);
