import React, { useEffect, useState } from 'react';
import styles from './PopupFilter.module.scss';
import { Heading, CheckboxList, Checkbox, CheckboxGroup, CheckboxGroupItem, Spacing, Text } from "@leaseplan/ui";
import electric from '../../Asset/MapIcons/poi-electric.svg';
import client from '../../Asset/MapIcons/poi-client.svg';
import nationalAccount from '../../Asset/MapIcons/poi-shop.svg';
import gas from '../../Asset/MapIcons/poi-gas.svg';

import SettingsIcon from '@material-ui/icons/Settings';

const POIpopUpFilterComponent = (props) => {
    const { heading, data, onFilterChange, defaultValue, values } = props;
    const [value, setValue] = useState(values);

    const onChange = (values) => {
        // sessionStorage.setItem("POI", JSON.stringify(values.value))
        sessionStorage.setItem("POIvalue", JSON.stringify(values.value))
        setValue(values.value);
        let POI = JSON.parse(sessionStorage.getItem("POIvalue"))
        if (POI) {
            POI.includes("ALL_NAS") ? sessionStorage.setItem("showNationalAccountShops", 1) : sessionStorage.setItem("showNationalAccountShops", 0)
            POI.includes("ALL_EVCS") ? sessionStorage.setItem("showEvChargingStations", 1) : sessionStorage.setItem("showEvChargingStations", 0)
            POI.includes("ALL_FS") ? sessionStorage.setItem("showFuelStations", 1) : sessionStorage.setItem("showFuelStations", 0)
        }
        onFilterChange({ "value": JSON.parse(sessionStorage.getItem("POIvalue")) })
    }

    const getImg = (imgType) => {
        // console.log(imgType)
        switch (imgType) {
            case 'ALL_NAS':
                return nationalAccount

            case 'ALL_EVCS':
                return electric

            case 'ALL_FS':
                return gas
            default:
                return nationalAccount
        }
    }

    //     const optionHandler = (e) => {
    //         let targetValue = e.target.value;
    //         if (e.target.value == ''){
    //             const targetValue = Intl.DateTimeFormat().resolvedOptions().timeZone;
    //             console.log(targetValue)
    //             //  targetValue = 'America/New_York'
    //         }

    //         sessionStorage.setItem("Timezone", targetValue);
    //         let Timezonedata = Timezonedata_Api(targetValue);
    //     }

    return (

        <div className={styles.popupContent}>
            <SettingsIcon size='xs' className={styles.settingsIcon} htmlColor={'#989a9a'} />
            <Text component="p" style={{ padding: '5px 5px', marginTop: '-2.5em', marginLeft: '2em' }} size='xs' color="#989a9a" >DEFAULT SETTINGS</Text>
            <br />
            <Spacing pv={.5} />
            <Heading component="p" size="xs" color="petrolBlue" gutter={false}>POI types</Heading>
            <Spacing pv={.5}>
                <Text className={styles.locationText} size='xs' color="petrolBlue" >Select the Point-of-Interest types you want to show on the map by default.</Text>
            </Spacing>
            <CheckboxGroup name={'Layer Filter'} value={JSON.parse(sessionStorage.getItem("POIvalue"))} onChange={(values) => onChange(values)} defaultValue={defaultValue || []}>
                <CheckboxList >
                    {
                        data.map((row, index) => {
                            return (
                                <CheckboxGroupItem key={index.toString()}>
                                    {/* <img src={getImg(row.value)} className={styles.poiIcon} />
                                    <div style={{ marginLeft: '1.8em' }}> */}
                                    <Checkbox name="default" checked={true} label={row.label} value={row.value} />
                                    {/* </div> */}
                                </CheckboxGroupItem>
                            )
                        })
                    }
                </CheckboxList>
            </CheckboxGroup>
            <Spacing pv={1}>
                <Heading component="p" size="xs" color="petrolBlue" gutter={false}>Add POIs</Heading>

                <Text className={styles.locationText} size='xs' color="petrolBlue" >Want to see more points of interest in OneConnect? We can help!</Text>
                <br />
                <a className={styles.href} href="">Contact us</a>
            </Spacing>



        </div>

        // <div className={styles.popupContent}>
        //     <CheckboxGroup name={'Layer Filter'} value={JSON.parse(sessionStorage.getItem("POI"))} onChange={(values) => onChange(values)} defaultValue={defaultValue || []}>
        //         <CheckboxList >
        //             {
        //                 data.map((row, index) => {
        //                     return (
        //                         <CheckboxGroupItem key={index.toString()}>
        //                             <Checkbox name="default" checked={true} label={row.label} value={row.value} />
        //                         </CheckboxGroupItem>
        //                     )
        //                 })
        //             }
        //         </CheckboxList>
        //     </CheckboxGroup>


        // </div>
    )
}
export default POIpopUpFilterComponent;