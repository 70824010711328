import axios from "../AxiosInterceptor";
import { call, put, takeEvery } from "redux-saga/effects";
import CONSTANT from "../Constants/fleetOverview";
import { baseURL } from "../Store/base-url";
import { F, S } from "../utils/actions";
import authService from '../Services/Auth/authService';

function fetchOneScore() {
  const clientNumber = authService.getClientNumber();
  return axios.request({
    baseURL: baseURL,
    responseType: "json",
    url: `/one-score`,
    method: "get",
  });
}

export function* getOneScoreWorker(action) {
  try {
    const { data } = yield call(fetchOneScore);
    yield put({ type: S(action.type), payload: data });
  } catch (e) {
    yield put({ type: F(action.type), error: e });
  }
}


function* fleetOverviewSaga() {
    yield takeEvery(CONSTANT.FETCH_ONE_SCORE, getOneScoreWorker);
}


export default fleetOverviewSaga;