import React, { useState, useEffect } from 'react';
import styles from './DriverInsights.module.scss';
import {
    Heading,
    ChevronLeftIcon,
    ChevronRightIcon,
    Text
} from "@leaseplan/ui";
import { ReactComponent as Download } from '../../Asset/InsightIcons/Download.svg';
import { SvgIcon, InputBase } from '@material-ui/core';
import TableWrapperOneConnect from '../../Common/TableWrapperOneConnect/TableWrapperOneConnect';
import { TableConstants } from './Table/TableConstant';
import DriverInsightRow from './Table/DriverInsightRow';
import { useSelector, useDispatch } from 'react-redux';
import { fetchDriverInsightsFilters } from '../../Actions/filters.action';
import { fetchDriverInsightsPageInfo } from '../../Actions/driverInsightsPage.action';
import Filters from '../OneConnectCommon/Filters/Filters';
import { removeSpaceFromString, getLabel, getTime, getValueByTime } from '../../utils';
import SearchIcon from '@material-ui/icons/Search';
import _ from "lodash";
import { baseURL } from '../../Store/base-url';
import { fetchDataUsingCancelToken } from '../../AxiosInterceptor/fetchDataUsingCacelToken';
import CircularProgress from '@material-ui/core/CircularProgress';
import authService from '../../Services/Auth/authService';
import { LPLoader } from '../../Common/LPLoader/LPLoader';
import FilterDisplayer from '../Search/FilterDisplayer/FilterDisplayer';
import { isPanningMap, logRecordLoadingStatusWorker } from '../../Actions/level.action';
import { fetchDeviceLastLocation, fetchLogRecordTrips, insertInitialFromTime } from '../../Actions/driver.action';
import { generatePath, useNavigate } from 'react-router-dom';
import { Icon } from '@velocity/ui.Icon';
import { RoutePath } from '../../Routers/Routes';
import { insertDate, insertTimelineClockData } from '../../Actions/timeline.actions';
import moment from 'moment';


const DriverInsights = props => {
    const [tableData, setTableData] = useState({
        ...TableConstants
    })
    const { prismicData } = props;
    const [filtersData, setFiltersData] = useState({});
    const [formattedFiltersData, setFormattedFiltersData] = useState({});
    const [selectedFilters, setSelectedFilters] = useState({});
    //ForPagination
    const PAGE_LIMIT = 20
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(PAGE_LIMIT);
    const [isHidden, setIsHidden] = useState(false);
    const [clearAll, setClearAll] = useState(false);
    const [fieldOrder, setFieldOrder] = useState([]);
    const [searchString, setSearchString] = useState('');
    const [debouncedValue, setDebouncedValue] = useState('');
    const [globalSearch, setGlobalSearch] = useState({});
    const [exportLoading, setExportLoading] = useState(false);

    //Selectors
    const filtersSelector = useSelector(state => state.filters.search);
    const dispatch = useDispatch()
    const history = useNavigate();

    const driverInsightsPageSelector = useSelector(state => state.driverInsightsPage);

    // today's date using timezone is effecting calendar date so using the system new Date func.
    // const todaysDate = moment().tz(sessionStorage.getItem("Timezone") ? sessionStorage.getItem("Timezone") : "America/New_York").format('YYYY-MM-DD');

    //This function is used to get the usage label or description as required.
    const getFilterDataUsageFromPrismic = (usageData, usagePrismicData, isReverse) => {
        if (!isReverse) {
            return (JSON.parse(usagePrismicData).filter(g => usageData.includes(g.label)).map(g => g.description))
        } else {
            return (JSON.parse(usagePrismicData).filter(g => usageData.includes(g.description)).map(g => g.label))

        }
    }

    // need to make a generic function for this for the system (later)
    const getCurrentTimeValue = () => {
        dispatch(insertDate(new Date()));
        let date = new Date();
        // let tzid = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let tzid = sessionStorage.getItem("Timezone");
        if (tzid == "") { tzid = 'America/New_York' }

        let dateAndTime = date.toLocaleString('en-US', { hour12: false, timeZone: tzid });
        dateAndTime = dateAndTime.split(',')
        let time = dateAndTime[1].substring(1);
        date = dateAndTime[0];

        let value = getValueByTime(time);
        let dayDate = `${moment(date).format('DD')}`;
        let fromTime = `${moment(date).format('YYYY-MM-DD')} ${getTime(0)}:00`;
        let toTime = `${moment(date).format('YYYY-MM-DD')} ${getTime(value)}:00`;
        dispatch(insertTimelineClockData({ dayDate, fromTime, toTime, event: 'mouseup', speed: 1 }));
    }

    const generateRequestPayload = (
        filteredList = {},
        start = 1,
        limit,
        fieldOrder = [],
        mainSearch = {}
    ) => {
        return {
            driverVehicleMainSearchInput: {
                name: mainSearch.name ? mainSearch.name : '',
                type: mainSearch.type ? mainSearch.type : '',
            },
            driverVehicleFilterCriteriaInput: {
                //event: filteredList.event ? filteredList.event : [],
                usage: filteredList.usage ? getFilterDataUsageFromPrismic(filteredList.usage, prismicData.driverinsightusagefilter, true) : [],
                status: filteredList.status ? filteredList.status : [],
                make: filteredList.make ? filteredList.make : [],
                model: filteredList.model ? filteredList.model : [],
                year: filteredList.year ? filteredList.year : [],
                level1: filteredList["Level 1"] ? filteredList["Level 1"] : [],
                level2: filteredList["Level 2"] ? filteredList["Level 2"] : [],
                level3: filteredList["Level 3"] ? filteredList["Level 3"] : [],
                level4: filteredList["Level 4"] ? filteredList["Level 4"] : [],
                level5: filteredList["Level 5"] ? filteredList["Level 5"] : [],
                level6: filteredList["Level 6"] ? filteredList["Level 6"] : [],
                level7: filteredList["Level 7"] ? filteredList["Level 7"] : [],
                level8: filteredList["Level 8"] ? filteredList["Level 8"] : [],
                level9: filteredList["Level 9"] ? filteredList["Level 9"] : [],
                level10: filteredList["Level 10"] ? filteredList["Level 10"] : []
            },
            sortCriteriaInput: fieldOrder.length > 0 ? [fieldOrder[0]] : [{ field: "eventWeight", order: "desc" }],
            pagingCriteriaInput: {
                pageNumber: start,
                pageSize: limit
            },
            eventFilters: filteredList["rulename"] ? filteredList["rulename"] : [],
        };
    };



    useEffect(() => {
        const { isLoading, data } = filtersSelector;

        if (!isLoading && data) {
            setFiltersData(data);
        }
    }, [filtersSelector]);

    useEffect(() => {
        //set the url for hardrefreshing functionality #444
        sessionStorage.setItem("urlEndPoint", window.location.pathname)
        sessionStorage.removeItem("tabUrl")
        sessionStorage.removeItem("selectedDate")
        getCurrentTimeValue()
        dispatch(fetchDeviceLastLocation([false]));
        dispatch(fetchLogRecordTrips(false));
        //removed this filter api and using /dvl/lables endpoint for filters
        // dispatch(fetchDriverInsightsFilters());
        dispatch(logRecordLoadingStatusWorker(false));
        dispatch(insertInitialFromTime());
        dispatch(isPanningMap(false))
    }, [dispatch]);


    useEffect(() => {
        if (filtersData.usage) {
            filtersData.usage = getFilterDataUsageFromPrismic(filtersData.usage, prismicData.driverinsightusagefilter, false)
            const getFilters = (key) => {
                const filters = []
                const filterItem = filtersData[key];
                filterItem.forEach(row => {
                    let obj = {}
                    obj['label'] = row;
                    obj['value'] = row;
                    obj['checked'] = false;
                    filters.push(obj);
                });
                return filters;
            }

            const finalData = {};
            /* This is because we want the filters in this order only (usage, status, year, make, model) 
                and after that levels
            */
            finalData['rulename'] = getFilters('rulename');
            finalData['usage'] = getFilters('usage');
            finalData['status'] = getFilters('status');
            finalData['year'] = getFilters('year');
            finalData['make'] = getFilters('make');
            finalData['model'] = getFilters('model');

            let filters = [];
            Object.keys(filtersData['levelDescription']).forEach(row => {
                if (filtersData['levelDescription'][row].length) {
                    filters = filtersData['levelDescription'][row].map(subItem => {
                        let obj = {}
                        obj['label'] = subItem[1];
                        obj['value'] = subItem[1];
                        obj['checked'] = false;
                        return obj;
                    }
                    );

                    finalData[`Level ${row}`] = filters;
                }

            });
            setFormattedFiltersData(finalData);
        }
    }, [filtersData]);

    useEffect(() => {
        let newTableData = { ...tableData };
        const field_order = [];
        for (let i = 0; i < newTableData.columns.length; i++) {
            if (newTableData.columns[i].sortEnabled) {
                if (newTableData.columns[i].initialSort) {
                    field_order.unshift({ field: newTableData.columns[i].field, order: newTableData.columns[i].sort })
                    continue;
                }
                field_order.push({ field: newTableData.columns[i].field, order: newTableData.columns[i].sort })
            }
        };

        dispatch(fetchDriverInsightsPageInfo(
            generateRequestPayload(
                selectedFilters,
                1,
                rowsPerPage,
                field_order,
                globalSearch
            )
        ));

        setFieldOrder(field_order);
    }, [])

    useEffect(() => {
        setClearAll(false);
    }, [clearAll]);

    useEffect(() => {
        // API call goes here
        dispatch(fetchDriverInsightsPageInfo(
            generateRequestPayload(
                selectedFilters,
                1,
                rowsPerPage,
                fieldOrder,
                {
                    name: searchString
                }
            )
        ))
        setGlobalSearch({
            name: searchString
        })
        setCurrentPage(1);
    }, [debouncedValue]);

    const onPageOrPageLimitChange = (pageNumber = 1, rowsPerPage = PAGE_LIMIT) => {
        dispatch(fetchDriverInsightsPageInfo(
            generateRequestPayload(
                selectedFilters,
                pageNumber,
                rowsPerPage,
                fieldOrder,
                globalSearch
            )
        ))

        setCurrentPage(pageNumber);
        setRowsPerPage(rowsPerPage);
    }

    const handleOnPageChange = (e, newPage) => {
        onPageOrPageLimitChange(newPage + 1, rowsPerPage);
    }

    const handleOnRowPerPageChange = (e) => {
        onPageOrPageLimitChange(1, parseInt(e.target.value, 10))
    }

    const onSortColumn = (
        field_name,
        direction = "asc",
        rowsPerPage = PAGE_LIMIT
    ) => {
        let newTableData = { ...tableData };
        newTableData.columns = newTableData.columns.map((col, index) => {
            if (col.sortEnabled && field_name === col.field)
                return {
                    ...col,
                    sort: direction,
                };
            return col;
        });

        let field_order = [...fieldOrder];

        field_order.splice(field_order.findIndex(a => a.field === field_name), 1)
        field_order.unshift({ field: field_name, order: direction });

        setFieldOrder(field_order);
        setTableData(newTableData);

        dispatch(fetchDriverInsightsPageInfo(
            generateRequestPayload(
                selectedFilters,
                1,
                rowsPerPage,
                field_order,
                globalSearch
            )
        ))
        setCurrentPage(1);
        setRowsPerPage(rowsPerPage)
    };

    const toggleSideBar = () => {
        setIsHidden((isHidden => !isHidden));
    }

    const onFiltersChanged = (name, heading, checked) => {
        let globalFilters = { ...formattedFiltersData };
        globalFilters[heading] = globalFilters[heading].map(el => (el.value === name ? { ...el, checked: checked } : el));
        let value = []
        for (let item of globalFilters[heading]) {
            if (item.checked) {
                value.push(item.value);
            }
        }
        const filters = { ...selectedFilters };
        if (!heading.startsWith('level')) {
            filters[heading] = value;
        }
        else {
            filters[removeSpaceFromString(heading)] = name;
        }
        dispatch(fetchDriverInsightsPageInfo(
            generateRequestPayload(
                filters,
                1,
                rowsPerPage,
                fieldOrder,
                globalSearch
            )
        ))
        setCurrentPage(1);
        setSelectedFilters(filters);
        setFormattedFiltersData(globalFilters);
    }
    const clearAllFilters = () => {
        let globalFilters = { ...formattedFiltersData };

        let newGlobalFilter = {}

        Object.keys(globalFilters).forEach((key) => {
            let newKeyValue = globalFilters[key].map((data) => {
                return {
                    ...data,
                    checked: false,
                }
            })
            Object.assign(newGlobalFilter, { [key]: newKeyValue });
        });


        setFormattedFiltersData(newGlobalFilter);
        setSelectedFilters({})
        dispatch(fetchDriverInsightsPageInfo(
            generateRequestPayload(
                {},
                currentPage,
                rowsPerPage,
                fieldOrder,
                globalSearch
            )
        ))
        setClearAll(true);
    }
    const handleSearchStringChange = (event) => {
        setSearchString(event.target.value);
    };

    const moveToDashboard = () => {
        history({
            pathname: generatePath(RoutePath.Dashboard)
        });
    }

    const searchData = _.debounce((event) => {
        setDebouncedValue(event.target.value);
    }, 2000);

    const exportDrivers = async () => {
        setExportLoading(true);

        const { cancelPrevQuery, result, error } = await fetchDataUsingCancelToken(
            baseURL,
            "json",
            '/driver-insights/driver-insights-export',
            'post',
            {
                ...generateRequestPayload(
                    selectedFilters,
                    1,
                    rowsPerPage,
                    fieldOrder,
                    globalSearch
                ),
                pageNumber: 1,
                pageSize: 16000
            },
        );

        if (cancelPrevQuery) return;

        if (result) {
            setExportLoading(false);
            window.open(result.url);
        } else if (error) {
            setExportLoading(false);
        }
    }

    /**
     * TODO: Could be added once clearALl feature is implemented
     * 
     * 
     * @Code
     * 
     * const clearAllFilters = () => {
        let globalFilters = {...formattedFiltersData};

        let newGlobalFilter = {}

        Object.keys(globalFilters).forEach((key) => {
            let newKeyValue = globalFilters[key].map((data) => {
                return {
                    ...data,
                    checked: false,
                }
            })
            Object.assign(newGlobalFilter, {[key]: newKeyValue}); 
        });
        
        
        setFormattedFiltersData(newGlobalFilter);
        setSelectedFilters({})
        dispatch(fetchDriverInsightsPageInfo(
            generateRequestPayload(
                {},
                currentPage,
                rowsPerPage,
                fieldOrder
            )
        ))
        setClearAll(true);
    }
     * 
     */


    return (
        <div className={styles.mainWrapper}>
            <div className={styles.topHeader}>
                <div className={styles.heading}>
                    <Heading component="p" size="s" color="petrolBlue" gutter={false}>Driver Insights</Heading>
                </div>
                <div className={styles.row}>
                    <div className={styles.searchDiv}>
                        <div className={styles.left}>
                            <SearchIcon htmlColor={'#767878'} />
                            <InputBase
                                className={styles.searchTextInput}
                                value={searchString}
                                onChange={handleSearchStringChange}
                                style={{ width: '100%' }}
                                inputProps={{ maxLength: 100 }}
                                onKeyDown={searchData}
                            />
                        </div>
                    </div>
                    <div className={styles.downloadIcon}>
                        {exportLoading ? <CircularProgress /> : <SvgIcon className={styles.svgStyle} onClick={() => exportDrivers()}>
                            <Download />
                        </SvgIcon>}

                    </div>
                    <button type="button" id={"drawer_close"} onClick={moveToDashboard} className={styles.CloseButton}>
                        <Icon name="close" size="s" />
                    </button>
                </div>

            </div>
            <div className={styles.lploader}>
                {(driverInsightsPageSelector.isLoading) && (
                    <LPLoader
                        loading={driverInsightsPageSelector.isLoading}
                        message={"Loading Data ..."}
                    />
                )}
            </div>
            {
                isHidden && (
                    <div className={styles.sideDrawer}>
                        {!filtersSelector.isLoading ? (
                            <div className={styles.filterBody}>
                                <div className={styles.filtersHeader}>
                                    <Heading component="p" size="xs" color="petrolBlue" gutter>{getLabel("filter", "Filter", prismicData)}</Heading>
                                    <Text component="p" size="xs" color="petrolBlue" gutter onClick={clearAllFilters}>{getLabel("clear_all", "Clear All", prismicData)}</Text>
                                </div>
                                {
                                    Object.keys(formattedFiltersData).map(item => {
                                        return (
                                            <Filters
                                                keys={item}
                                                heading={item}
                                                data={formattedFiltersData[item] || []}
                                                onFilterChange={onFiltersChanged}
                                                clearAll={clearAll}
                                            />
                                        )
                                    })
                                }
                            </div>
                        ) : (
                            <div className={styles.filterLoader}>
                                <LPLoader
                                    loading={filtersSelector.isLoading}
                                    message={"Loading Driver Filters"}
                                />
                            </div>
                        )
                        }
                    </div>
                )
            }
            <div className={styles.isHiddenIcon} onClick={toggleSideBar}>
                {/* <Paper elevation={2} styles={styles.isHiddenIcon}> */}
                {
                    isHidden ? (
                        <ChevronLeftIcon />
                    ) : (
                        <ChevronRightIcon />
                    )
                }
                {/* </Paper> */}
            </div>
            <div className={styles.mainTable}>
                <div className={styles.mainTableHeader}>
                    <FilterDisplayer searchString={searchString} rowLength={driverInsightsPageSelector.resultSetCount} filters={selectedFilters} onTagCancelClick={onFiltersChanged} />
                </div>
                <TableWrapperOneConnect
                    currentPage={currentPage}
                    rowsPerPage={rowsPerPage}
                    columns={tableData.columns}
                    currentRowLength={driverInsightsPageSelector.data.length}
                    resultSetCount={driverInsightsPageSelector.resultSetCount ? driverInsightsPageSelector.resultSetCount : 0}
                    isLoading={driverInsightsPageSelector.isLoading}
                    onSortColumn={onSortColumn}
                    handleOnPageChange={handleOnPageChange}
                    handleOnRowPerPageChange={handleOnRowPerPageChange}
                    maxHeight={'calc(100vh - 420px)'}
                    minHeight={'calc(100vh - 420px)'}
                >
                    {
                        driverInsightsPageSelector.data.length > 0 && driverInsightsPageSelector.data.map((row) =>
                            <DriverInsightRow key={row.deviceId} id={row.deviceId} rowData={row} />
                        )
                    }
                </TableWrapperOneConnect>
            </div>
        </div>
    )
}

DriverInsights.propTypes = {

}

export default DriverInsights
