import React, { useEffect, useRef, useState } from "react";
import styles from "./HeaderWrapper1.module.scss";
import { Link } from "react-router-dom";
import { hasAuthority, getLabel } from "../../utils";
import searchIcon from "../../Asset/NewEventsIcons/searchIcon.svg";
import logo from "../../Asset/Logo/OneConnect_Logo.png";
import userIcon from "../../Asset/NewEventsIcons/user.svg";
import LiveMapSearch from "../Search/LiveMapSearch";
import { LPLoader } from "../../Common/LPLoader/LPLoader";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, GridItem } from "@leaseplan/ui";
import { hitViewportApi, isPanningMap } from "../../Actions/level.action";
import LPTooltip from "../../Common/LPTooltip/LPTooltip";
import { Tooltip } from "@material-ui/core";
import { openSettingDrawer } from "../../Actions/mapFilters.action";
import UserProfileSetting from "../Dashboard/BottomSlider/UserProfileSetting";

const HeaderWrapper = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [showSettingDrawer, setShowSettingDrawer] = useState(false);
  const apiData = useSelector((state) => state.driver.fetchLogRecordTrips);
  const levelFilteredDrivers = useSelector((state) => state.levels);
  const [downloadReportCount, setDownloadReportCount] = useState("");
  const filtersSelector = useSelector((state) => state.filters.search);
  const dispatch = useDispatch();
  const ref = useRef();

  useEffect(() => {
    openSettingDrawer(showSettingDrawer);
  }, [showSettingDrawer]);

  const opensetting = () => {
    if (!filtersSelector.isLoading) {
      setShowSettingDrawer(true);
      dispatch(openSettingDrawer(true));
    }
  };

  useEffect(() => {
    if (apiData) {
      setIsLoading(apiData.isLoading);
    }
  }, [apiData]);

  //changes related to tooltip and button popup while panning/zomming map
  useEffect(() => {
    if (levelFilteredDrivers.isPanningMap) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  }, [levelFilteredDrivers.isPanningMap]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (showSearchBar && ref.current && !ref.current.contains(e.target)) {
        setShowSearchBar(false);
      }
    };
    if (showSearchBar) {
      document.addEventListener("mousedown", checkIfClickedOutside);
    }

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showSearchBar]);

  const viewport = () => {
    // setTooltipIsOpen(false)
    setShowButton(false);
    dispatch(isPanningMap(false));
    dispatch(hitViewportApi(true));
  };

  return (
    <div className={styles.HeaderWrapper}>
      <div className={styles.HeaderLeft}>
        <div className={styles.Logo}>
          {/* Need to add the wheels logo here. */}
          <a href={process.env.REACT_APP_APPLICATION_URL_LANDING_PAGE}>
            <img
              alt="LeasePlan"
              data-e2e-id="mainMenuLogoImage"
              srcSet="/static/images/logo-navigation@2x.png 2x"
              src={logo}
            />
          </a>
        </div>
        <nav>
          <ul className={styles.Menu}>
            {props.routes.map(({ path, name, hidden, functionName }, i) => {
              if (
                (functionName && !hasAuthority(functionName, props.userInfo)) ||
                hidden
              ) {
                return <React.Fragment key={i} />;
              }
              return (
                <li key={i} className={styles.MenuItem}>
                  <Link to={path}>
                    {getLabel(name, name, props.prismicData)}
                    {name === "Reporting" && (
                      <LPTooltip
                        style={{ paddingLeft: "10px" }}
                        title={"Files Ready To Download"}
                        placement="bottom"
                        arrow
                      >
                        <div className={styles.notify}>
                          <label>
                            {downloadReportCount ? downloadReportCount : ""}
                          </label>
                        </div>
                      </LPTooltip>
                    )}
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
      </div>

      <div className={styles.MenuItem2} ref={ref}>
        <Grid direction="row">
          <GridItem>
            {!showSearchBar && (
              <img
                onClick={() => setShowSearchBar(!showSearchBar)}
                src={searchIcon}
                alt="Filter Icon"
                className={styles.searchIcon}
              />
            )}

            {showSearchBar && (
              <div>
                <LiveMapSearch />
              </div>
            )}
          </GridItem>
          <GridItem>
            {
              <img
                src={userIcon}
                onClick={() => opensetting()}
                alt="Filter Icon"
                className={styles.searchIcon}
              />
            }
            {showSettingDrawer && <UserProfileSetting />}
          </GridItem>
        </Grid>
      </div>

      {/* condition for button and loading spinner to show on dashboard map for viewport and log-record api */}
      {levelFilteredDrivers.viewportLoadingStatus || isLoading == true ? (
        <div className={styles.lploader}>
          <LPLoader loading={true} message={"Loading Data ..."} />
        </div>
      ) : (
        <div className={styles.stickyButton}>
          {window.location.pathname.split("/").length === 2 &&
            showButton &&
            window.location.pathname.split("/")[1] == "" && (
              <Tooltip
                // open={tooltipIsOpen}
                title={getLabel(
                  "ViewPortRefreshButtonToolTip",
                  "Please Click the Refresh Button",
                  props.prismicData
                )}
                placement="bottom"
                arrow
              >
                <Button size="s" iconposition="right" onClick={viewport}>
                  {getLabel(
                    "ViewPortRefreshButton",
                    "Refresh display on map",
                    props.prismicData
                  )}
                </Button>
              </Tooltip>
            )}
        </div>
      )}

      <div className={styles.lploaderLog}>
        {levelFilteredDrivers.logRecordLoadingStatus && (
          <LPLoader loading={true} message={"Loading Data ..."} />
        )}
      </div>
    </div>
  );
};

export default HeaderWrapper;
