import axios from "../AxiosInterceptor";
import { call, put, takeEvery, select } from "redux-saga/effects";
import CONSTANT from "../Constants/vehicle";
import { baseURL } from "../Store/base-url";
import { F, S } from "../utils/actions";
import { setSelectedEventsUId } from "../Actions/vehicle.action";

export const getUser = (state) => state.auth.user

function getVehicleDetailsApi(params) {
  return axios.request({
    baseURL: baseURL,
    responseType: "json",
    url: `/selected-vehicle-insights`,
    method: "post",
    data: params
  });
}

function getPoolVehicleDriversApi(params) {
  return axios.request({
    baseURL: baseURL,
    responseType: "json",
    url: `/retrieve-pool-drivers`,
    method: "post",
    data: params
  });
}

function getVehicleHealthApi(params, user) {
  return axios.request({
    baseURL: baseURL,
    responseType: "json",
    url: `/retrieve-vehicle-health`,
    method: "post",
    data: {
      ...user,
      ...params
    }
  });
}

function getVoltageEventsApi(params) {
  return axios.request({
    baseURL: baseURL,
    responseType: "json",
    url: `events/retrieve-low-voltage-events/${params}`,
    method: "get",
  });
}

function getLocationMaskingEventsApi(params) {
  return axios.request({
    baseURL: baseURL,
    responseType: "json",
    url: `events/retrieve-location-masking-events/${params}`,
    method: "get",
  });
}

function getVehicleMaintenanceApi(params, user) {
  return axios.request({
    baseURL: baseURL,
    responseType: "json",
    url: `/retrieve-vehicle-maintenance`,
    method: "post",
    data: {
      ...user,
      ...params
    }
  });
}

function getVehicleMaintenanceHistoryApi(params) {
  return axios.request({
    baseURL: baseURL,
    responseType: "json",
    url: `/retrieve-vehicle-maintenance-history`,
    method: "post",
    data: params
  });
}

function getVehicleUtilizationApi(params) {
  return axios.request({
    baseURL: baseURL,
    responseType: "json",
    url: `reports/get-vehicle-downtime-report`,
    method: "post",
    data: params
  });
}

export function* getVehicleDetailsWorker(action) {
  try {
    const { data } = yield call(getVehicleDetailsApi, action.payload);
    yield put({ type: S(action.type), payload: data });
  } catch (e) {
    yield put({ type: F(action.type), payload: e });
  }
}

export function* getPoolVehicleDriversWorker(action) {
  try {
    const { data } = yield call(getPoolVehicleDriversApi, action.payload);
    yield put({ type: S(action.type), payload: data });
  } catch (e) {
    yield put({ type: F(action.type), payload: e });
  }
}

export function* getVoltageEventsApiWorker(action) {
  try {
    const { data } = yield call(getVoltageEventsApi, action.payload);
    yield put({ type: S(action.type), payload: data });
  } catch (e) {
    yield put({ type: F(action.type), payload: e });
  }
}

export function* getLocationMaskingEventsApiWorker(action) {
  try {
    const { data } = yield call(getLocationMaskingEventsApi, action.payload);
    yield put({ type: S(action.type), payload: data });
  } catch (e) {
    yield put({ type: F(action.type), payload: e });
  }
}

export function* getVehicleHealthWorker(action) {
  try {
    const user = yield select(getUser);
    const { data } = yield call(getVehicleHealthApi, action.payload, { clientNumber: user.clientNumber, userId: user.username });
    yield put({ type: S(action.type), payload: data });
  } catch (e) {
    yield put({ type: F(action.type), payload: e });
  }
}

export function* getVehicleMaintenanceWorker(action) {
  try {
    const user = yield select(getUser);
    const { data } = yield call(getVehicleMaintenanceApi, action.payload, { clientNumber: user.clientNumber, userId: user.username });
    yield put({ type: S(action.type), payload: data });
  } catch (e) {
    yield put({ type: F(action.type), payload: e });
  }
}

export function* getVehicleMaintenanceHistoryWorker(action) {
  try {
    const { data } = yield call(getVehicleMaintenanceHistoryApi, action.payload);
    yield put({ type: S(action.type), payload: data });
  } catch (e) {
    yield put({ type: F(action.type), payload: e });
  }
}

export function* getVehicleUtilizationWorker(action) {
  try {
    const user = yield select(getUser);
    const { data } = yield call(getVehicleUtilizationApi, action.payload, { clientNumber: user.clientNumber, userId: user.username });
    yield put({ type: S(action.type), payload: data });
  } catch (e) {
    yield put({ type: F(action.type), payload: e });
  }
}

export function* resetVehicleInfoWorker(action) {
  yield put({ type: S(action.type) });
}

export function* setSelectedEventsUIdWorker(action) {
  yield put({ type: S(action.type), payload: action.payload });
}

export function* setFuleMaintenanceHistoryWorker(action) {
  yield put({ type: S(action.type), payload: action.payload });
}

function* vehicleSaga() {
  yield takeEvery(CONSTANT.FETCH_VEHICLE_INFO, getVehicleDetailsWorker);
  yield takeEvery(CONSTANT.FETCH_POOL_VEHICLE_DRIVERS, getPoolVehicleDriversWorker);
  yield takeEvery(CONSTANT.FETCH_VOLTAGE_EVENTS, getVoltageEventsApiWorker);
  yield takeEvery(CONSTANT.FETCH_LOCATION_MASKING_EVENTS, getLocationMaskingEventsApiWorker);
  yield takeEvery(CONSTANT.SET_SELECTED_EVENTS_ID, setSelectedEventsUIdWorker);
  yield takeEvery(CONSTANT.SET_FUEL_MAINTENANCE_HISTORY, setFuleMaintenanceHistoryWorker);
  yield takeEvery(CONSTANT.FETCH_VEHICLE_HEALTH, getVehicleHealthWorker);
  yield takeEvery(CONSTANT.FETCH_VEHICLE_MAINTENANCE, getVehicleMaintenanceWorker);
  yield takeEvery(CONSTANT.FETCH_VEHICLE_MAINTENANCE_HISTORY, getVehicleMaintenanceHistoryWorker);
  yield takeEvery(CONSTANT.FETCH_VEHICLE_UTILIZATION, getVehicleUtilizationWorker);
  yield takeEvery(CONSTANT.RESET_VEHICLE_INFO, resetVehicleInfoWorker);
}


export default vehicleSaga;