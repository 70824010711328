import React from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styles from './Toasts.module.scss';
import Toast from "./Components/Toast/Toast";
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { removeToast } from "../../Actions/toasts";
import { CSSTransition, TransitionGroup } from 'react-transition-group';

const Toasts = ({ actions, toasts }) => {
	// console.log('TOASTS', toasts);

	if (toasts.length > 0) {
		const elem = toasts.slice(-1);
		// console.log('TOastr', elem, toasts);
		setTimeout(() => removeToast(elem[0].id), elem[0].timeout);
	} else {
		return <React.Fragment />;
	}

	const { removeToast } = actions;
	return (
		<div className={styles.toastrWrapper}>
			<PerfectScrollbar>
				<div className={styles.toasts}>
					<TransitionGroup>
						{toasts.map(toast => {
							const { id } = toast;
							return (
								<CSSTransition
									key={id}
									in={true}
									timeout={350}
									classNames="lp-toast"
								>
									<Toast {...toast} onDismissClick={() => removeToast(id)} />
								</CSSTransition>
							);
						})}
					</TransitionGroup>
				</div>
			</PerfectScrollbar>
		</div>
	);
};

Toasts.propTypes = {
	actions: PropTypes.shape({
		removeToast: PropTypes.func.isRequired
	}).isRequired,
	toasts: PropTypes.arrayOf(PropTypes.object).isRequired
};

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({ removeToast }, dispatch)
});

const mapStateToProps = state => ({
	toasts: state.toasts
});

export default connect(mapStateToProps, mapDispatchToProps)(Toasts);
