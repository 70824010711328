
import { S, F } from "../utils/actions";
import Types from "../Constants/action-types";

const INITIAL_STATE = { isLoading: false, isProposalDetailLoading: false, isMasterInputLoading: false, user:{ isLoaded: false, info:null, error: null } }

const global = (state = INITIAL_STATE, action) => {
  // console.log('REDUCER', action);

  switch (action.type) {
    case S(Types.GLOBAL_LOADER):
      return { ...state, isLoading: action.isLoading };
    case F(Types.GLOBAL_LOADER):
      // toasterFail('codeList!', action.error.response.data)
      return { ...state, payload: null, error: { ...action.error } };
    case S(Types.LOADER_PROPOSAL_BUILDER):
      return { ...state, isProposalDetailLoading: action.isLoading };
    case F(Types.LOADER_PROPOSAL_BUILDER):
      // toasterFail('codeList!', action.error.response.data)
      return { ...state, payload: null, error: { ...action.error } };
    case S(Types.LOADER_MASTER_INPUT):
      return { ...state, isMasterInputLoading: action.isLoading };
    case F(Types.LOADER_MASTER_INPUT):
      // toasterFail('codeList!', action.error.response.data)
      return { ...state, payload: null, error: { ...action.error } };
    case S(Types.FETCH_USER_INFO):
        return { ...state, user:{isLoaded: true, info:action.payload } };
    case F(Types.FETCH_USER_INFO):
      return { ...state, user:{isLoaded: true, error: { ...action.error }} };
    default:
      return state;
  }
}

export default global;