import {CLIENT_CONFIG} from "../Constants/clientConfig";

export const fetchClientInfo = (params) => ({
    type: CLIENT_CONFIG.CLIENTHQ_FETCH,
    payload: params
  });

  export const saveClientInfo = (params) => ({
    type: CLIENT_CONFIG.CLIENTHQ_SAVE,
    payload: params
  });
  