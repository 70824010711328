import React from "react";
// import PropTypes from 'prop-types';
import "@leaseplan/ui/fonts/LeasePlan.css";
import {
  Select,
  TextInput,
  Text,
  Checkbox,
  CheckboxGroup,
  CheckboxList,
  CheckboxGroupItem,
  RadioGroup,
  Radio,
  RadioGroupItem,
  RadioList,
  Heading,
  RadioCard,
  Grid,
  GridItem,
  Textarea
} from "@leaseplan/ui";
import { Icon } from "@velocity/ui.Icon";
import styles from "./FieldManager.module.scss";
import {
  formatNumeric,
  defaultCountryCurrency,
  formatPhoneNumber,
  validatePhoneNumber,
  validateTextInput,
  validateNumber,
  validateZipCode,
  validatePhoneExtension,
  validateAccountNumber,
  validateRoutingNumber,
  validateIdNumber,
  validateEmail,
  validateCurrency
} from "../../utils";

const TextInputComp = ({
  curr,
  alias,
  label,
  subLabel,
  value,
  handleChange,
  texttypeicon,
  inputTypeChange,
  errorMessage,
  validationState,
  groupId = null,
  inputProps = {},
  validation = "",
  placeholder = "",
  isRequired = false,
  isDisabled = false,
  isValid = true,
  maxFractionDigits = 2,
  type = "text",
  isPassword = false,
  maxLength
}) => {
  const textTypeIcon = texttypeicon || "";
  let displayValue = "";
  let mFracDigit = maxFractionDigits;
  if (curr === "currency" && maxFractionDigits > 2) {
    mFracDigit = 2;
  } else if (curr === "percent" && value > 100) {
    value = "";
  }
  if (validation === "percent" || validation === "currency") {
    displayValue = formatNumeric(
      value,
      defaultCountryCurrency,
      validation,
      false,
      mFracDigit
    );
  } else if (validation === "phone") {
    displayValue = formatPhoneNumber(value);
  }

  if(validation === "percent" || validation === "currency"){
    maxLength=9
  }else{
    maxLength= maxLength || 50;
  }
  return (
    <div className={styles.InputWrapper}>
      {/* <label className={styles.Label}><Text component="p" size="s" gutter>{label}</Text></label> */}
      <div
        className={`${
          texttypeicon
            ? curr
              ? styles.InputFieldCurr
              : styles.InputField
            : styles.NormalInputField
        }  ${value !== "" && styles.inputActive}`}
      >
        <TextInput
          label={label}
          subLabel={subLabel}
          required={isRequired}
          disabled={isDisabled}
          inputProps={inputProps}
          placeholder={placeholder}
          textTypeIcon={textTypeIcon}
          // validationState="pristine"
          type={type}
          errorMessage={errorMessage}
          validationState={validationState}
          withAsterisk={isRequired}
          maxLength={maxLength}
          name={groupId !== null ? alias + "_" + groupId : alias}
          value={
            validation === "percent" ||
            validation === "currency" ||
            validation === "phone"
              ? displayValue
              : value
          }
          onBlur={(e) =>
            checkValue(
              e,
              e.target.value,
              alias,
              groupId,
              handleChange,
              validation,
              mFracDigit,
              isRequired
            )
          }
          // onKeyUp={(e) => validate(e, value, alias, groupId, handleChange, validation)}
          // placeholder="Type your name"
          onChange={(e) =>
            validate(
              e,
              e.target.value,
              alias,
              groupId,
              handleChange,
              validation,
              textTypeIcon === "%",
              mFracDigit
            )
          }
        />
        {isPassword && (
          <div className={styles.ButtonGroup}>
            {type === "text" && (
              <button
                type="button"
                disabled={isDisabled}
                id={alias + "_text_" + groupId}
                onClick={() =>
                  !isDisabled && inputTypeChange("password", alias, groupId)
                }
              >
                <Icon color="steel80" name="eyeClose" size="s" />
              </button>
            )}
            {type === "password" && (
              <button
                type="button"
                disabled={isDisabled}
                id={alias + "_password_" + groupId}
                onClick={() =>
                  !isDisabled && inputTypeChange("text", alias, groupId)
                }
              >
                <Icon color="steel80" name="eyeOpen" size="s" />
              </button>
            )}
          </div>
        )}
        {curr && (
          <div className={styles.ButtonGroup}>
            <button
              type="button"
              disabled={isDisabled}
              id={alias + "_currency_" + groupId}
              className={curr === "currency" ? styles.Active : ""}
              onClick={() =>
                !isDisabled && inputTypeChange("currency", alias, groupId)
              }
            >
              <Text component="p" size="l" gutter>
                $
              </Text>
            </button>
            <button
              type="button"
              disabled={isDisabled}
              id={alias + "_percent_" + groupId}
              className={curr === "percent" ? styles.Active : ""}
              onClick={() =>
                !isDisabled && inputTypeChange("percent", alias, groupId)
              }
            >
              <Text component="p" size="l" gutter>
                %
              </Text>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

function validate(
  e,
  value,
  alias,
  groupId,
  handleChange,
  validation,
  isPercent = "",
  mFracDigit
) {
  let valueString = e.value.toString();
  let validateState = "";
  let errorMessage = "";
  let inputData = { value: valueString };
  if (validation === "phone") {
    inputData = { value: valueString.replace(/[(),-/ ]/g, "") };
  }
  // console.log("/^[A-Za-z0-9 ]*$/.test(value)", /^[A-Za-z0-9 ][\w() -]*$/.test(value));

  if(validation=="currency"){
    value=formatNumeric(
      value,
      defaultCountryCurrency,
      validation,
      false,
      mFracDigit
    );
    
    inputData= {value: value };
  }
  switch (validation) {
    case "text":
      validateState = value
        ? validateTextInput(value, alias)
          ? "valid"
          : "error"
        : "pristine";
      errorMessage = value
        ? validateTextInput(value, alias)
          ? ""
          : "Please Enter Valid Text"
        : "";
      break;
    case "phone":
      validateState = value
        ? validatePhoneNumber(value)
          ? "valid"
          : "error"
        : "pristine";
      errorMessage = value
        ? validatePhoneNumber(value)
          ? ""
          : "Please Enter Valid Number"
        : "";
      break;
    case "number":
      validateState = value
        ? validateNumber(value)
          ? "valid"
          : "error"
        : "pristine";
      errorMessage = value
        ? validateNumber(value)
          ? ""
          : "Please Enter Valid Number"
        : "";
      break;
    case "email":
      validateState = value
        ? validateEmail(value)
          ? "valid"
          : "error"
        : "pristine";
      errorMessage = value
        ? validateEmail(value)
          ? ""
          : "Please Enter Valid Email Id"
        : "";
      break;
    case "zipcode":
      validateState = value
        ? validateZipCode(value)
          ? "valid"
          : "error"
        : "pristine";
      errorMessage = value
        ? validateZipCode(value)
          ? ""
          : "Please Enter Valid Zip Code"
        : "";
      break;
    case "extn":
      validateState = value
        ? validatePhoneExtension(value)
          ? "valid"
          : "error"
        : "pristine";
      errorMessage = value
        ? validatePhoneExtension(value)
          ? ""
          : "Please Enter Valid Extension"
        : "";
      break;
    case "account_number":
      validateState = value
        ? validateAccountNumber(value)
          ? "valid"
          : "error"
        : "pristine";
      errorMessage = value
        ? validateAccountNumber(value)
          ? ""
          : "Please Enter Valid Account Number"
        : "";
      break;
    case "routing_number":
      validateState = value
        ? validateRoutingNumber(value)
          ? "valid"
          : "error"
        : "pristine";
      errorMessage = value
        ? validateRoutingNumber(value)
          ? ""
          : "Please Enter Valid Routing / ABA No."
        : "";
      break;
    case "employer_identification_number":
      validateState = value
        ? validateIdNumber(value)
          ? "valid"
          : "error"
        : "pristine";
      errorMessage = value
        ? validateIdNumber(value)
          ? ""
          : "Please Enter Valid Employer ID Number"
        : "";
      break;
    case "social_security_number":
      validateState = value
        ? validateIdNumber(value)
          ? "valid"
          : "error"
        : "pristine";
      errorMessage = value
        ? validateIdNumber(value)
          ? ""
          : "Please Enter Valid SSN Number"
        : "";
      break;
    case "currency":
      const decimalRegex = new RegExp(`^(\\d*,*\\d*)*[.]{0,1}[0-9]{1,${mFracDigit}}$`);
      let isValid = decimalRegex.test(value);
      validateState = value ? (isValid ? "valid" : "error") : "pristine";
      errorMessage = value ? (isValid ? "" : "Please Enter Valid Amount") : "";
      break;
    default:
      validateState = "pristine";
      errorMessage = "";
  }
  handleChange(
    {
      ...inputData,
      alias: alias,
      validationState: validateState,
      errorMessage: errorMessage,
    },
    alias,
    groupId
  );
}

function checkValue(
  e,
  value,
  alias,
  groupId,
  handleChange,
  validation,
  mFracDigit,
  isRequired
) {
  let valueString = e.target.value.toString().replace(/,/g, "");
  if (
    ((valueString && validation === "number") || validation === "percent") &&
    valueString.indexOf(".") !== -1
  ) {
    const digitLength = valueString.split(".")[1].length;
    if (mFracDigit - digitLength > 0) {
      for (let i = 0; i < mFracDigit - digitLength; i++) {
        valueString += "0";
      }
      const inputData = { value: valueString };
      handleChange(inputData, alias, groupId);
    }
  } else if (!value && isRequired) {
    handleChange(
      {
        value: valueString,
        validationState: "error",
        errorMessage: "This Field is required",
      },
      alias,
      groupId
    );
  }
}

const TextAreaInputComp = ({
  curr,
  alias,
  label,
  value,
  handleChange,
  texttypeicon,
  inputTypeChange,
  validationState,
  groupId = null,
  inputProps = {},
  validation = "",
  placeholder = "",
  isRequired = false,
  isDisabled = false,
  maxFractionDigits = 2,
  type = "text",
}) => {
  const textTypeIcon = texttypeicon || "";
  let mFracDigit = maxFractionDigits;
  return (
    <div className={styles.InputWrapper}>
      {/* <label className={styles.Label}><Text component="p" size="s" gutter>{label}</Text></label> */}
      <div
        className={`${
          texttypeicon
            ? curr
              ? styles.InputFieldCurr
              : styles.InputField
            : styles.NormalInputField
        }  ${value !== "" && styles.inputActive}`}
      >
        <TextInput
          label={label}
          required={isRequired}
          disabled={isDisabled}
          inputProps={inputProps}
          placeholder={placeholder}
          textTypeIcon={textTypeIcon}
          // validationState="pristine"
          type={type}
          validationState={validationState}
          name={groupId !== null ? alias + "_" + groupId : alias}
          value={value}
          onBlur={(e) =>
            checkValue(
              e,
              e.target.value,
              alias,
              groupId,
              handleChange,
              validation,
              mFracDigit
            )
          }
          // onKeyUp={(e) => validate(e, value, alias, groupId, handleChange, validation)}
          // placeholder="Type your name"
          onChange={(e) =>
            validate(
              e,
              e.target.value,
              alias,
              groupId,
              handleChange,
              validation,
              textTypeIcon === "%",
              mFracDigit
            )
          }
        />
      </div>
    </div>
  );
};

const TextAreaInputComp2 = ({
  curr,
  alias,
  label,
  value,
  handleChange,
  texttypeicon,
  errorMessage,
  validationState,
  groupId = null,
  placeholder = "",
  isRequired = false,
  isDisabled = false,
  type = "text",
  rows=5,
  isCharacterCountRequired=false,
  currentCharacterCount=0,
  maxCharacterCount=50,
}) => {
      return (
        <div className={styles.InputWrapper}>
          <div
            className={`${
              texttypeicon
                ? curr
                  ? styles.InputFieldCurr
                  : styles.InputField
                : styles.NormalInputField
            }  ${value !== "" && styles.inputActive}`}
          >
            <div className={styles.characterCount}>
              <Textarea
                  label={label}
                  required={isRequired}
                  disabled={isDisabled}
                  placeholder={placeholder}
                  type={type}
                  errorMessage={errorMessage}
                  validationState={validationState}
                  name={groupId !== null ? alias + "_" + groupId : alias}
                  value={value}
                  onChange={handleChange}
                  rows={rows}
                />
                {
                  isCharacterCountRequired ?
                  <span className="characterCount">{`${currentCharacterCount}/${maxCharacterCount} characters`}</span>: 
                  null
                }
            </div>
          </div>
        </div>
  );
};

const SelectComp = ({
  alias,
  label,
  value,
  options,
  selectProps,
  defaultValue,
  handleChange,
  groupId = null,
  isDisabled = false,
  disableDefault = false,
  isRequired,
  isPhoneNumber,
  validationState,
  errorMessage,
  span,
}) => {
  return (
    <div
      className={
        isPhoneNumber ? styles.PhoneNumberSelectField : styles.SelectField
      }
    >
      <Select
        key={alias}
        name={groupId !== null ? alias + "_" + groupId : alias}
        label={label}
        disabled={isDisabled}
        selectProps={selectProps}
        withAsterisk={isRequired}
        validationState={validationState}
        errorMessage={errorMessage}
        onChange={(e) => {
          const validationState = e.value ? "valid" : "error";
          const errorMessage = e.value ? "" : "Please Select one value";
          handleChange(
            {
              ...e,
              validationState: validationState,
              errorMessage: errorMessage,
              alias: alias,
            },
            alias,
            groupId
          );
        }}
        onBlur={(e) => {
          if (!value && isRequired) {
            handleChange(
              {
                validationState: "error",
                errorMessage: span > 2 ? "This Field is Required" : "",
              },
              alias,
              groupId
            );
          }
        }}
        value={value ? value : defaultValue}
      >
        <option value="" disabled={!disableDefault}>
          Please select
        </option>
        {options &&
          options.map((elem, index) => {
            let name = typeof elem === "string" ? elem : elem.name;
            let val = typeof elem === "string" ? elem : elem.val;

            return (
              <option key={index} value={val}>
                {name}
              </option>
            );
          })}
      </Select>
    </div>
  );
};

const RadioGroupComp = ({
  alias,
  label,
  value,
  items,
  selectProps,
  defaultValue,
  handleChange,
  errorMessage,
  validationState,
  groupId = null,
  isDisabled = false,
  disableDefault = false,
  isRequired = false,
  description,
  subLabel,
  direction = "row",
}) => {
  return (
    <RadioGroup
      key={alias}
      name={groupId !== null ? alias + "_" + groupId : alias}
      disabled={isDisabled}
      selectProps={selectProps}
      withAsterisk={isRequired}
      errorMessage={errorMessage}
      validationState={validationState}
      label={label}
      subLabel={subLabel}
      onChange={(e) => {
        const validationState = e.value.toString() !== "" ? "valid" : "error";
        const errorMessage =
          e.value.toString() !== "" ? "" : "Please Select one value";
        handleChange(
          {
            ...e,
            validationState: validationState,
            errorMessage: errorMessage,
            alias
          },
          alias,
          groupId
        );
      }}
      value={value ? value : defaultValue}
    >
      <RadioList direction={direction}>
        {items &&
          items.map((elem, index) => {
            let name = typeof elem === "string" ? elem : elem.name;
            let val = typeof elem === "string" ? elem : elem.val;
            let description =
              typeof elem === "string" ? elem : elem.description;
            return (
              <RadioGroupItem key={groupId + "_" + alias + "_" + index}>
                <Radio label={description} value={val}>
                  {" "}
                </Radio>
              </RadioGroupItem>
            );
          })}
      </RadioList>
    </RadioGroup>
  );
};

const RadioCardComp = ({
  alias,
  label,
  value,
  items,
  selectProps,
  defaultValue,
  handleChange,
  errorMessage,
  validationState,
  span = 12,
  groupId = null,
  isDisabled = false,
  disableDefault = false,
  isRequired = false,
}) => {
  return (
    // <div className={styles.RadioCard}>
    <RadioGroup
      name={groupId !== null ? alias + "_" + groupId : alias}
      label={label}
      disabled={isDisabled}
      selectProps={selectProps}
      withAsterisk={isRequired}
      errorMessage={errorMessage}
      validationState={validationState}
      onChange={(e) => {
        const validationState = e.value.toString() !== "" ? "valid" : "error";
        const errorMessage =
          e.value.toString() !== "" ? "" : "Please Select one value";
        handleChange(
          {
            ...e,
            validationState: validationState,
            errorMessage: errorMessage,
            alias
          },
          alias,
          groupId
        );
      }}
      value={value ? value : defaultValue}
    >
      <Grid>
        {items &&
          items.map((elem, index) => {
            let name = typeof elem === "string" ? elem : elem.name;
            let description =
              typeof elem === "string" ? elem : elem.description;

            return (
              <GridItem
                span={{ mobile: 12, tablet: span }}
                key={groupId + "_" + alias + "_" + index}
              >
                <RadioGroupItem>
                  <RadioCard value={name}>
                    <Heading size="xxs">{name}</Heading>
                    <Text size="xs">{description}</Text>
                  </RadioCard>
                </RadioGroupItem>
              </GridItem>
            );
          })}
      </Grid>
    </RadioGroup>
  );
};
const CheckboxGroupComp = ({
  alias,
  label,
  value,
  items,
  selectProps,
  defaultValue,
  handleChange,
  checked,
  groupId = null,
  isDisabled = false,
  disableDefault = false,
  isRequired = false,
  errorMessage = "",
  validationState = "pristine",
}) => {
  return (
    <CheckboxGroup
      key={alias}
      name={groupId !== null ? alias + "_" + groupId : alias}
      label={label}
      disabled={isDisabled}
      selectProps={selectProps}
      withAsterisk={isRequired}
      errorMessage={errorMessage}
      validationState={validationState}
      onChange={(e) => {
        let validateState = "valid";
        let errorMessage = "";
        if (isRequired && e.value.length === 0) {
          validateState = "error";
        }
        handleChange(
          {
            value: e.value,
            validationState: validateState,
            errorMessage: errorMessage,
          },
          alias,
          groupId
        );
      }}
      value={value ? value : defaultValue}
    >
      <CheckboxList direction="grid" maxheight={3}>
        {items &&
          items.map((elem, index) => {
            if (!elem.isHidden) {
              let description =
                typeof elem === "string" ? elem : elem.description;
              let val = typeof elem === "string" ? elem : elem.val;
              let isDisabled = !!elem.disabled;
              return (
                <CheckboxGroupItem key={groupId + "_" + alias + "_" + index}>
                  <Checkbox
                    label={description}
                    value={val}
                    disabled={isDisabled}
                  ></Checkbox>
                </CheckboxGroupItem>
              );
            }
          })}
      </CheckboxList>
    </CheckboxGroup>
  );
};
const CheckBoxComp = ({
  alias,
  label,
  value,
  checked,
  selectProps,
  defaultValue,
  handleChange,
  groupId = null,
  isDisabled = false,
  disableDefault = false,
  isRequired = false,
}) => {
  return (
    <Checkbox
      key={alias}
      name={groupId !== null ? alias + "_" + groupId : alias}
      label={label}
      required={isRequired}
      disabled={isDisabled}
      checked={checked}
      onChange={(e) => {
        handleChange({ checked: e.checked }, alias, groupId);
      }}
      value={value}
    ></Checkbox>
    // </div >
  );
};

const FieldManager = {
  input: (data) => {
    return (
      <TextInputComp
        curr={data.curr}
        value={data.value}
        alias={data.alias}
        label={data.label}
        subLabel={data.subLabel}
        groupId={data.groupId}
        handleChange={data.func}
        inputProps={data.inputProps}
        validation={data.validation}
        isRequired={data.isRequired}
        isDisabled={data.isDisabled}
        placeholder={data.placeholder}
        texttypeicon={data.textTypeIcon}
        inputTypeChange={data.inputTypeChange}
        errorMessage={data.errorMessage}
        validationState={data.validationState}
        maxFractionDigits={data.maxFractionDigits}
        checkFormValidation={data.checkFormValidation}
        isValid={data.isValid}
        type={data.type}
        isPassword={data.isPassword}
        maxLength={data.maxLength}
      />
    );
  },
  select: (data = []) => {
    return (
      <SelectComp
        label={data.label}
        value={data.value}
        alias={data.alias}
        groupId={data.groupId}
        options={data.options}
        handleChange={data.func}
        isDisabled={data.isDisabled}
        selectProps={data.selectProps}
        defaultValue={data.defaultValue}
        disableDefault={data.disableDefault}
        errorMessage={data.errorMessage}
        validationState={data.validationState}
        isRequired={data.isRequired}
        isPhoneNumber={data.isPhoneNumber}
      />
    );
  },
  checkbox: (data) => {
    return (
      <CheckBoxComp
        label={data.label}
        alias={data.alias}
        checked={data.checked}
        handleChange={data.func}
        defaultValue={data.defaultValue}
        value={data.value || ""}
        isDisabled={data.isDisabled || false}
        isRequired={data.isRequired}
      />
    );
  },
  radiogroup: (data) => {
    // console.log("data in RadioGroup: ", data);
    return (
      <RadioGroupComp
        label={data.label}
        subLabel={data.subLabel}
        alias={data.alias}
        handleChange={data.func}
        defaultValue={data.defaultValue}
        value={data.value || ""}
        items={data.items}
        isDisabled={data.isDisabled || false}
        isRequired={data.isRequired}
        errorMessage={data.errorMessage}
        validationState={data.validationState}
        description={data.description}
        direction={data.direction}
      />
    );
  },
  radiocard: (data) => {
    return (
      <RadioCardComp
        label={data.label}
        alias={data.alias}
        handleChange={data.func}
        defaultValue={data.defaultValue}
        value={data.value || ""}
        items={data.items}
        isDisabled={data.isDisabled || false}
        isRequired={data.isRequired}
        errorMessage={data.errorMessage}
        validationState={data.validationState}
        span={data.span}
      />
    );
  },
  checkboxGroup: (data) => {
    return (
      <CheckboxGroupComp
        label={data.label}
        alias={data.alias}
        handleChange={data.func}
        defaultValue={data.defaultValue}
        value={data.value || ""}
        items={data.items}
        checked={data.checked}
        isDisabled={data.isDisabled || false}
        isRequired={data.isRequired}
        errorMessage={data.errorMessage}
        validationState={data.validationState}
      />
    );
  },
  textarea: (data) => {
    return (
      <TextAreaInputComp
        curr={data.curr}
        value={data.value}
        alias={data.alias}
        label={data.label}
        subLabel={data.subLabel}
        groupId={data.groupId}
        handleChange={data.func}
        inputProps={data.inputProps}
        validation={data.validation}
        isRequired={data.isRequired}
        isDisabled={data.isDisabled}
        placeholder={data.placeholder}
        texttypeicon={data.textTypeIcon}
        inputTypeChange={data.inputTypeChange}
        errorMessage={data.errorMessage}
        validationState={data.validationState}
        maxFractionDigits={data.maxFractionDigits}
        checkFormValidation={data.checkFormValidation}
        isValid={data.isValid}
        type={data.type}
        isPassword={data.isPassword}
      />
    );
  },
  textarea2: (data) => {
    return (
      <TextAreaInputComp2
        curr={data.curr}
        value={data.value}
        alias={data.alias}
        label={data.label}
        subLabel={data.subLabel}
        groupId={data.groupId}
        handleChange={data.func}
        inputProps={data.inputProps}
        validation={data.validation}
        isRequired={data.isRequired}
        isDisabled={data.isDisabled}
        placeholder={data.placeholder}
        texttypeicon={data.textTypeIcon}
        inputTypeChange={data.inputTypeChange}
        errorMessage={data.errorMessage}
        validationState={data.validationState}
        maxFractionDigits={data.maxFractionDigits}
        checkFormValidation={data.checkFormValidation}
        isValid={data.isValid}
        type={data.type}
        isPassword={data.isPassword}
        rows={data.rows}
        isCharacterCountRequired={data.isCharacterCountRequired}
        currentCharacterCount={data.currentCharacterCount}
        maxCharacterCount={data.maxCharacterCount}
      />
    );
  },
};

FieldManager.propTypes = {};

FieldManager.defaultProps = {};

export default FieldManager;
