import React from 'react'
import PropTypes from 'prop-types';
import styles from './RowEvents.module.scss';
import { ArrowDropDown } from '@material-ui/icons';
import Popup from 'reactjs-popup';

const RowEvents = ({
    type="",
    number="",
    enablePopup=false,
    popupParams=[],
    color='rgb(216, 233, 168)'
}) => {

    //opacity: 0.308;

    let filter = "";

    if(type != ""){
        filter = type.split("_").map(data => {
            return data.charAt(0).toUpperCase() + data.substring(1);
        }).join(' ');
    }

    return (
        <div className={`${styles.tagBody}`} style={{
            backgroundColor: `rgba(${color.substring(color.lastIndexOf('(')+1, color.lastIndexOf(')'))}, 0.308)`,
            border: `1px solid ${color}`
        }}>
                <span className={styles.filterValue}>{filter}</span>
                <span className={styles.filterNumber}>{number}</span>
                <span>

                </span>
                {/* {
                    enablePopup && (
                        <Popup
                            trigger={<span className={styles.filterDownArrow}>&#8964;</span>}
                            position="bottom"
                            on="click"
                            closeOnDocumentClick
                            contentStyle={{ padding: '0px', width: '118px' }}
                        >
                            <div className={styles.menu}>
                                <div className={styles.menuItem}>View Details</div>
                                <div className={styles.menuItem}>Communication Log</div>
                                <div className={styles.menuItem}>Assign Course</div>
                            </div>
                        </Popup>
                    )
                } */}
        </div>
    )
}

RowEvents.propTypes = {

}

export default RowEvents
