import axios from "../AxiosInterceptor";
import { call, put, takeLatest } from "redux-saga/effects";
import { SEARCH_PAGE } from "../Constants/searchPage";
import { baseURL } from "../Store/base-url";
import { F, S } from "../utils/actions";
import authService from '../Services/Auth/authService';
import { CANCEL } from 'redux-saga';

function getSearchPageInfoAPI(payload) {
  const tokenSource = axios.CancelToken.source();
  const request = axios.request({
    baseURL: baseURL,
    responseType: "json",
    url: `/dvl/filter-data`,
    method: "post",
    data: {
      ...payload
    },
    cancelToken: tokenSource.token
  });
  request[CANCEL] = () => tokenSource.cancel();
  return request;
}

function getLiveSearchInfoAPI(payload) {
  const tokenSource = axios.CancelToken.source();
  const request = axios.request({
    baseURL: baseURL,
    responseType: "json",
    url: `/dvl/driver-list`,
    method: "post",
    data: {
      ...payload
    },
    cancelToken: tokenSource.token
  });
  request[CANCEL] = () => tokenSource.cancel();
  return request;

}

export function* getLiveSearchInfoWorker(action) {
  try {
    const { data } = yield call(getLiveSearchInfoAPI, action.payload);
    yield put({
      type: S(action.type),
      data: data
    });
  } catch (e) {
    yield put({ type: F(action.type), payload: e });
  }
}

export function* getLiveFleetSearchInfoWorker(action) {
  try {
    const { data } = yield call(getLiveSearchInfoAPI, action.payload);
    yield put({
      type: S(action.type),
      data: data
    });
  } catch (e) {
    yield put({ type: F(action.type), payload: e });
  }
}

export function* getSearchPageInfoWorker(action) {
  try {
    const { data } = yield call(getSearchPageInfoAPI, action.payload);
    yield put({
      type: S(action.type),
      data: [...data.content],
      resultSetCount: data.totalElements,
      currentPage: action.payload.pagingCriteriaInput.pageNumber,
      pageLimit: action.payload.pagingCriteriaInput.pageSize
    });
  } catch (e) {
    yield put({ type: F(action.type), payload: e });
  }
}

function* isGlobalSearchFieldWorker(action) {
  yield put({type: S(action.type), payload: action.payload? action.payload : []})
}

function* isTripFilterSearchFieldWorker(action) {
  yield put({type: S(action.type), payload: action.payload? action.payload : []})
}


function* searchPageSaga() {
  yield takeLatest(SEARCH_PAGE.SEARCH_PAGE_FETCH, getSearchPageInfoWorker);
  yield takeLatest(SEARCH_PAGE.SEARCH_TYPE_FETCH, getLiveSearchInfoWorker);
  yield takeLatest(SEARCH_PAGE.SEARCH_TYPE_FLEET_FETCH, getLiveFleetSearchInfoWorker);
  yield takeLatest(SEARCH_PAGE.IS_GLOBAL_SEARCH_FIELD, isGlobalSearchFieldWorker);
  yield takeLatest(SEARCH_PAGE.IS_TRIP_FILTER_SEARCH_FIELD, isTripFilterSearchFieldWorker);
}


export default searchPageSaga;