import createToast from "../Factories/createToast";
import { ADD_TOAST, REMOVE_TOAST } from "../Constants/toasts";

export function addToast(options = {}) {
    const payload = createToast(options);
    // console.log('addToast', payload);
    
    return {
        payload,
        type: ADD_TOAST
    };
}

export function removeToast(id) {
    return {
        payload: id,
        type: REMOVE_TOAST
    };
}
