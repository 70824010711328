import React, { useState } from 'react';
import styles from './PopupFilter.module.scss';
import { Heading, CheckboxList, Checkbox, CheckboxGroup, CheckboxGroupItem, Grid, GridItem, Text, Spacing } from "@leaseplan/ui";
import { Button, Link, MuiThemeProvider, createTheme } from '@material-ui/core';
import { LinkComponent } from '@leaseplan/ui/es5esm/link/components/Link';


const { palette } = createTheme();
const { augmentColor } = palette;
const theme = createTheme({
    palette: {
        secondary: {
            main: '#004a5d'
        },
        secondary: {
            main: '#004a5d'
        }
    }

});
const ReportPopupFilter = (props) => {
    const { heading, data, onFilterChange, onCancelFilter, defaultValue, values } = props;
    const [value, setValue] = useState(values);
    const [valuesOnChange, setValuesOnChange] = useState(values);


    const onChange = (values) => {
        setValue(values.value);
        setValuesOnChange(values)
    }

    const selectFilter = (select) => {
        setValue(select ? defaultValue : [])
        setValuesOnChange(select ? defaultValue : [])
    }

    const applyFilter = () => {
        onFilterChange(valuesOnChange)
    }


    return (
        <div className={styles.popupContent}>
            <Heading component="p" size="xs" color="petrolBlue" gutter={false}>{heading}</Heading>
            <Spacing pv={1}>
                <Grid justifyContent='left'>
                    <GridItem>
                        <Link component="button" variant="body2" underline="always" fontWeight='bold' onClick={() => { selectFilter(true); }}>Select all</Link>
                    </GridItem>
                    <Spacing ph={.5} />
                    <GridItem>
                        <Link component="button" variant="body2" underline="always" fontWeight='bold' onClick={() => { selectFilter(false); }}>Clear all</Link>
                    </GridItem>
                </Grid>
                <Spacing pv={.5} />
                <Text component="p" size='s' fontWeight='bold' color="petrolBlue" >{"Utilized"}</Text>
                <Spacing ph={1}>
                    <CheckboxGroup name={'Layer Filter'} value={value} onChange={(values) => onChange(values)} defaultValue={defaultValue || []}>
                        <CheckboxList >
                            {
                                data.map((row, index) => {
                                    return (
                                        <>
                                            {row.label === "Driver Assigned" &&
                                                <>
                                                    <CheckboxGroupItem key={index.toString()}>

                                                        <Checkbox name="default" checked={true} label={row.label} value={row.value} />


                                                    </CheckboxGroupItem>

                                                    <Text component="p" style={{ marginLeft: '1em' }} size='s' fontWeight='bold' color="petrolBlue" >{"Not Utilized"}</Text>
                                                </>
                                            }
                                            {row.label !== "Driver Assigned" &&
                                                <CheckboxGroupItem key={index.toString()}>
                                                    <Checkbox name="default" checked={true} label={row.label} value={row.value} />


                                                </CheckboxGroupItem>
                                            }
                                        </>
                                    )
                                })
                            }
                        </CheckboxList>
                    </CheckboxGroup>
                </Spacing>
            </Spacing>
            <div className={styles.rightSide}>
                <Grid justifyContent='center'>
                    <MuiThemeProvider theme={theme}>
                        <GridItem>
                            <Spacing pv={.5}>
                                <Link component="button" variant="body1" underline="always" fontWeight='bold' onClick={() => { onCancelFilter(false) }}>cancel</Link>
                            </Spacing>
                        </GridItem>
                        <GridItem>
                            <Button color='secondary' variant="contained" onClick={() => { applyFilter(); }} >Apply</Button>
                        </GridItem>
                    </MuiThemeProvider>
                </Grid>
            </div>



        </div>
    )
}

export default ReportPopupFilter;