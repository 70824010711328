export const TableConstants = {
    columns:[
        {
            id: 0,
            label: "Score",
            field: "one_score",
            align: 'left',
            disablePadding: false,
            width: '5%',
            prismicKey:"one_score",
            requestName:"oneScore",
            customComponent: true,
        },
        {
            id: 1,
            label:"Device Name",
            field: "deviceName",
            // styles: {
            //     minWidth: 90
            // },
            prismicKey:"deviceName",
            width: '10%',
            requestName:"deviceName",
            // sortEnabled: true,
            // sort: 'desc',
        },
        // {
        //     id: 1,
        //     label:"First Name",
        //     field: "firstName",
        //     // styles: {
        //     //     minWidth: 90
        //     // },
        //     prismicKey:"firstName",
        //     width: '10%',
        //     requestName:"firstName",
        //     sortEnabled: true,
        //     sort: 'desc',
        // },
        // {
        //     id: 2,
        //     label:"Last Name",
        //     field: "lastName",
        //     // styles: {
        //     //     minWidth: 90
        //     // },
        //     prismicKey:"lastName",
        //     width: '10%',
        //     requestName:"lastName",
        //     sortEnabled: true,
        //     sort: 'desc',
        // },
        {
            id: 3,
            label: "Vehicle",
            field: "vehicle",
            prismicKey: "vehicle",            
            requestName:"vehicle",
            width: '15%',
        },
        {
            id: 4,
            label: "Device",
            field: "device",
            minWidth: 90,
            prismicKey: "device",            
            requestName:"device",
            width: '5%',
        },
        {
            id: 5,
            label: "Vehicle Health",
            field: "healthWeight",
            minWidth: 90,
            prismicKey: "vehicleHealth",            
            requestName:"vehicleHealth",
            width: '25%',
            sortEnabled: true,
            sort: 'desc',
        },
        {
            id: 6,
            label:"Driver Profile",
            field: "vehicle_profile",
            align: 'center',
            // styles: {
            //     minWidth: 90
            // },
            prismicKey: "vehicle_profile",            
            requestName:"vehicle_profile",
            width: '5%',
        },
        {
            id: 7,
            label:"Vehicle Profile",
            field: "vehicle_profile",
            align: 'center',
            // styles: {
            //     minWidth: 90
            // },
            prismicKey: "vehicle_profile",            
            requestName:"vehicle_profile",
            width: '5%',
        },
    ],
    rows:[
        // {score: 98, firstName: "John" ,lastName:"Smith", vehicle: "21 Chevy Colorado",device:'high', vehicleHealth: [{type:'checkengine', value: 2, label:'Check Engine', popupEnable: true}], action:[{type:'notification', value: 12, label: 'Notification'}]},
        // {score: 98, firstName: "John" ,lastName:"Smith", vehicle: "21 Chevy Colorado",device:'high', vehicleHealth: [{type:'checkengine', value: 2, label:'Check Engine', popupEnable: true}, {type:'overdue', value: 42, label:'Overdue', popupEnable: true}, {type:'tpms', value: 32, label:'TPMS', popupEnable: true}],},
        // {score: 98, firstName: "John" ,lastName:"Smith", vehicle: "21 Chevy Colorado",device:'high', vehicleHealth: [{type:'overdue', value: 42, label:'Overdue', popupEnable: true}], action:[{type:'notification', value: 12, label: 'Notification'}]},
        // {score: 98, firstName: "John" ,lastName:"Smith", vehicle: "21 Chevy Colorado",device:'medium', vehicleHealth: [{type:'overdue', value: 42, label:'Overdue', popupEnable: true}], action:[{type:'notification', value: 12, label: 'Notification'}]},
        // {score: 98, firstName: "John" ,lastName:"Smith", vehicle: "21 Chevy Colorado",device:'high', vehicleHealth: [{type:'overdue', value: 42, label:'Overdue', popupEnable: true}], action:[{type:'notification', value: 12, label: 'Notification'}]},
        // {score: 98, firstName: "John" ,lastName:"Smith", vehicle: "21 Chevy Colorado",device:'high', vehicleHealth: [{type:'overdue', value: 42, label:'Overdue', popupEnable: true}], action:[{type:'notification', value: 12, label: 'Notification'}]},
        // {score: 98, firstName: "John" ,lastName:"Smith", vehicle: "21 Chevy Colorado",device:'high', vehicleHealth: [{type:'overdue', value: 42, label:'Overdue', popupEnable: true}, {type:'recall', value: 1, label:'Recall', popupEnable: true}], action:[{type:'notification', value: 12, label: 'Notification'}]},
        // {score: 98, firstName: "John" ,lastName:"Smith", vehicle: "21 Chevy Colorado",device:'low', vehicleHealth: [{type:'overdue', value: 42, label:'Overdue', popupEnable: true}, {type:'oillife', value: 2, label:'Oil Life 13%', popupEnable: true}], action:[{type:'notification', value: 12, label: 'Notification'}]},
        // {score: 98, firstName: "John" ,lastName:"Smith", vehicle: "21 Chevy Colorado",device:'high', vehicleHealth: [{type:'checkengine', value: 2, label:'Check Engine', popupEnable: true}], action:[{type:'notification', value: 12, label: 'Notification'}]},
        // {score: 98, firstName: "John" ,lastName:"Smith", vehicle: "21 Chevy Colorado",device:'high', vehicleHealth: [{type:'checkengine', value: 2, label:'Check Engine', popupEnable: true}, {type:'overdue', value: 42, label:'Overdue', popupEnable: true}, {type:'tpms', value: 32, label:'TPMS', popupEnable: true}],},
        // {score: 98, firstName: "John" ,lastName:"Smith", vehicle: "21 Chevy Colorado",device:'high', vehicleHealth: [{type:'overdue', value: 42, label:'Overdue', popupEnable: true}], action:[{type:'notification', value: 12, label: 'Notification'}]},
        // {score: 98, firstName: "John" ,lastName:"Smith", vehicle: "21 Chevy Colorado",device:'medium', vehicleHealth: [{type:'overdue', value: 42, label:'Overdue', popupEnable: true}], action:[{type:'notification', value: 12, label: 'Notification'}]},
        // {score: 98, firstName: "John" ,lastName:"Smith", vehicle: "21 Chevy Colorado",device:'high', vehicleHealth: [{type:'overdue', value: 42, label:'Overdue', popupEnable: true}], action:[{type:'notification', value: 12, label: 'Notification'}]},
        // {score: 98, firstName: "John" ,lastName:"Smith", vehicle: "21 Chevy Colorado",device:'high', vehicleHealth: [{type:'overdue', value: 42, label:'Overdue', popupEnable: true}], action:[{type:'notification', value: 12, label: 'Notification'}]},
        // {score: 98, firstName: "John" ,lastName:"Smith", vehicle: "21 Chevy Colorado",device:'high', vehicleHealth: [{type:'overdue', value: 42, label:'Overdue', popupEnable: true}, {type:'recall', value: 1, label:'Recall', popupEnable: true}], action:[{type:'notification', value: 12, label: 'Notification'}]},
        // {score: 98, firstName: "John" ,lastName:"Smith", vehicle: "21 Chevy Colorado",device:'low', vehicleHealth: [{type:'overdue', value: 42, label:'Overdue', popupEnable: true}, {type:'oillife', value: 2, label:'Oil Life 13%', popupEnable: true}], action:[{type:'notification', value: 12, label: 'Notification'}]},
    ]
}