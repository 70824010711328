import React from 'react';
import {
    Text,
    Spacing,
  } from "@leaseplan/ui";
import styles from './Row.module.scss';
import { convertSecondsInHoursAndMinutes, getOffsetTimeZone, timezoneOffSetSub } from '../../../utils';
import {insertInitialFromTime, insertInitialToTime, insertSelectedEvent} from '../../../Actions/driver.action';
import { useDispatch } from 'react-redux';
import RoadIcon from '../../../Asset/Images/road.png'
import LocalTaxiRoundedIcon from '@material-ui/icons/LocalTaxiRounded';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import NavigationRoundedIcon from '@material-ui/icons/NavigationRounded';
import ArrowRightAltRoundedIcon from '@material-ui/icons/ArrowRightAltRounded';
import { IconButton } from '@material-ui/core';
import moment from 'moment';

const Row = (props) => {
    const { startAddr, endAddr, driveDistance, driveTime, eventCount, startTime, endTime, timezoneId, utcOffset, timezoneShortName, endLat, endLon} = props.data;
    const {selectActiveTrip, activeTrip, id} = props;
    const dispatch = useDispatch();
    
    

    const onHandleClick = (e ,startTime, endTime, selectedId, utcOffset) => {
        const endDate = moment(endTime).format('YYYY-MM-DD');
        const endDateTime = moment(endTime).format('HH:mm:ss');
        const endTimeUTC = timezoneOffSetSub(endDate, endDateTime, utcOffset);
        const startDate = moment(startTime).format('YYYY-MM-DD');
        const startDateTime = moment(startTime).format('HH:mm:ss');
        const startTimeUTC = timezoneOffSetSub(startDate, startDateTime, utcOffset);
        selectActiveTrip(selectedId);
        dispatch(insertSelectedEvent(-1));
        dispatch(insertInitialFromTime(startTimeUTC));
        dispatch(insertInitialToTime(endTimeUTC));
    }


    return (
        <div className={styles.wrapper}>

            <div
                className={styles.tableCell}
            >
                <div className={styles.tripTimeCell}>
                    <div className={styles.timeline}>
                        <div className={styles.greyCircle}></div>
                        <div className={styles.vl}></div>
                        <div className={styles.orangeCircle}></div>
                    </div>
                    <Spacing ph={.5} />
                    <div className={styles.tripTime}>
                        <Text component="p" size={'xs'} >{startAddr}</Text>
                        <Text component="p" size={'xs'} >{endAddr}</Text>
                    </div>
                </div>
            </div>


            <div className={styles.tripDuration}>
                <Text component="p" size="xs" color={'petrolBlue'} gutter>{getOffsetTimeZone(startTime, timezoneShortName, utcOffset)}</Text>
                <ArrowRightAltRoundedIcon className={styles.arrowIcon} />
                <Text component="p" size="xs" color={'petrolBlue'} gutter>{getOffsetTimeZone(endTime, timezoneShortName, utcOffset)}</Text>
            </div>
            
            <div className={styles.tripDetails}>
            
                <div className={styles.left}>
                
                    <div className={styles.element}>
                        <img src={RoadIcon} className={styles.roadIcon} alt={'Road icon'}/>
                        <Text component="p" size="xs" color={'petrolBlue'} gutter>{`${parseFloat(driveDistance).toFixed(2)} Miles`}</Text>
                    </div>
                    <div className={styles.element}>
                        <LocalTaxiRoundedIcon htmlColor={'#ee4c14'} fontSize={'small'}/>
                        <Text component="p" size="xs" color={'petrolBlue'} gutter>{`${convertSecondsInHoursAndMinutes(driveTime)}`}</Text>
                    </div>
                    <div className={styles.element}>
                        <WarningRoundedIcon htmlColor={'#ee4c14'} fontSize={'small'}/>
                        <Text component="p" size="xs" color={'petrolBlue'} gutter>{eventCount}</Text>
                    </div>
                </div>
                <IconButton onClick={(e) => onHandleClick(e, startTime, endTime, id, utcOffset)}>
                    <NavigationRoundedIcon htmlColor={'#ee4c14'} style={{ transform: 'rotate(45deg)', color: (activeTrip != null && activeTrip === id) ? '#ee4c14': '#004a5d' }}/>
                </IconButton>
            </div>
           
        </div>
    )
}

export default Row;