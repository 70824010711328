import CONSTANTS from "../Constants/filters";

export const fetchFilters = (params) => ({
    type: CONSTANTS.FETCH_FILTERS,
    payload: params
});

export const fetchUserDefaultConfig = (params) => ({
  type: CONSTANTS.FETCH_USER_DEFAULT_CONFIG,
  payload: params
});

export const fetchPoiFilters = () => ({
  type: CONSTANTS.FETCH_POI_FILTERS
})

export const putPoiFiltersSession = (params) => ({
  type: CONSTANTS.PUT_POI_FILTER_SESSION,
  payload: params
})

export const fetchDriverInsightsFilters = () => ({
  type: CONSTANTS.FETCH_DRIVER_INSIGHTS_FILTERS
})

export const fetchVehicleInsightsFilters = () => ({
  type: CONSTANTS.FETCH_VEHICLE_INSIGHTS_FILTERS
});

export const fetchTripAdminFilters = () => ({
  type: CONSTANTS.FETCH_TRIP_ADMIN_FILTERS
});
  