import React, { useEffect, useState } from 'react';
import { ResponsiveContainer } from 'recharts';
import styles from './Graph.module.scss';
import { Text } from '@leaseplan/ui';
import { CloseIcon, DownloadIcon } from '@leaseplan/ui';

import { Star, StarBorder } from '@material-ui/icons';
import { Grid, GridItem } from '@leaseplan/ui';
import { addReportToFavourite, deleteUserReport, downloadReport } from '../../../Actions/reporting.action';
import { useDispatch } from 'react-redux';


const modifyGraphData = (data = []) => {
    let result = [];
    if (data.length > 0) {
        result = data[0]?.values?.map(value => (
            {
                name: value.name,
                value: value.count,
            }
        )
        )
    }
    return result;
}

const ColoredLine = ({ color }) => (
    <hr
        style={{
            marginTop: '25px',
            color: color,
            height: 1
        }}
    />
);

export const NumberGraph = ({ height, width, graphData, mergeData }) => {

    const [totalCount, setTotalCount] = useState(0);
    const dispatch = useDispatch();

    const removeReportHandler = (graphId) => {
        dispatch(deleteUserReport(graphId));
    }

    const addToFavouriteHandler = (graphId) => {
        dispatch(addReportToFavourite(graphId));
    }

    const downloadReportHandler = (graphId) => {
        dispatch(downloadReport(graphId));
    }

    useEffect(() => {
        const data = modifyGraphData(graphData);
        if (data) {
            const total = Object.values(data).reduce((t, { value }) => t + value, 0)
            setTotalCount(total);
        }
    }, [])

    return (
        <ResponsiveContainer width={width} height={mergeData.mergeData?.data ? height : 80} className={styles.graphArea}>
            <div className={styles.container}>
                <div width={200} height={400} className={styles.number}>
                    {totalCount}
                    <Text component="p" size={'xs'} color={'petrolBlue'} className={styles.heading} gutter={false}>Vehicles</Text>
                </div>

                {/* To display the 2nd Number graphType in same card using the mergeData               */}

                <ColoredLine color="#dddddd" />
                {mergeData && mergeData.mergeData?.data ? (
                    <><Grid gutter={false} justifyContent="center" alignItems="center">
                        <Grid justifyContent="left" alignItems="left">
                            <div onClick={() => addToFavouriteHandler(mergeData.mergeData.graphId)} className={styles.favouriteButton}>
                                {
                                    mergeData.mergeData.isFavourite ? <Star className={styles.filledStar} /> : <StarBorder className={styles.borderedStar} />
                                }
                            </div>
                            <div onClick={() => downloadReportHandler(mergeData.mergeData.graphId)} className={styles.downloadButton}><DownloadIcon className={styles.downloadIcon} />
                            </div>
                        </Grid>
                        <GridItem>
                            <Text component="p" size={'xs'} fontWeight="bold" color={'petrolBlue'} className={styles.heading} gutter={false}>{mergeData.mergeData.graphTitle}

                            </Text>
                        </GridItem>
                        <Grid justifyContent="right" alignItems="rigth">
                            <div onClick={() => removeReportHandler(mergeData.mergeData.graphId)} style={{ margin: '-5px 6px 4px', cursor: "pointer" }}>
                                <CloseIcon className={styles.cancelIcon} />
                            </div>
                        </Grid>
                    </Grid>

                        <div width={'200'} height={'400'} className={styles.number}>
                            {mergeData.mergeData.data[0].values[0].count}
                            <Text component="p" size={'xs'} color={'petrolBlue'} className={styles.heading} gutter={false}>Vehicles</Text>
                        </div></>
                ) :
                    <></>
                }

            </div>

        </ResponsiveContainer>
    )
}


export default React.memo(NumberGraph);