import axios from "../AxiosInterceptor";
import { call, put, takeEvery } from "redux-saga/effects";
import CONSTANT from "../Constants/DriverEvents";
import { baseURL } from "../Store/base-url";
import { F, S } from "../utils/actions";

function getDriverEventApi(params) {
  const {date} = params;
  return axios.request({
    baseURL: baseURL,
    responseType: "json",
    url: `events/all-events/${date}`,
    method: "get",
  });
}

export function* getDriverEventData(action) {
  try {
    const { data } = yield call(getDriverEventApi, action.payload);
    yield put({ type: S(action.type), payload: data });
  } catch (e) {
    yield put({ type: F(action.type), error: e });
  }
}


function* driverEventSaga() {
    yield takeEvery(CONSTANT.FETCH_DRIVER_EVENTS, getDriverEventData);
}


export default driverEventSaga;