import React from "react";
import { Tooltip, makeStyles } from "@material-ui/core";
import { Color } from "@velocity/core.Color";

const useStylesBootstrap = makeStyles(() => ({
    arrow: {
        color: Color['midOrange'],
    },
    tooltip: {
        fontSize: 12,
        borderRadius: 2,
        padding: '8px 12px',
        backgroundColor: Color['midOrange'],
    },
}));

function CustomTooltip(props) {
    const classes = useStylesBootstrap();
  
    return <Tooltip arrow classes={classes} {...props} />;
}

const LPTooltip = (props) => {
    // console.log('LPTooltip', props);
    
    return (
        <CustomTooltip title={props.title} placement={props.placement || "top"}>
            {props.disabled
                ? <span>{props.children}</span>
                : <span>{props.children}</span>
            }
        </CustomTooltip>
    )
}

export default LPTooltip;