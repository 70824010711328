import React, { useEffect, useRef, useState } from "react";
import { useMap, Polyline, Circle } from 'react-leaflet';
import L, { Icon, marker } from "leaflet";
import { useSelector, useDispatch } from 'react-redux';
import "leaflet-rotatedmarker";
import "../MovingMarker";
import { subtractTime } from '../../../utils';
import car_blue from '../../../Asset/MapIcons/car-blue.png';
import ReactDOMServer from "react-dom/server";
import VehiclePopup from '../VehiclesPopupAndTooltip/VehiclePopup';
import { render } from 'react-dom';
import VehicleTooltip from "../VehiclesPopupAndTooltip/VehicleTooltip";
import { useNavigate } from "react-router-dom";

const carIcon = new Icon({
  iconUrl: car_blue,
  iconSize: [15, 22],
  tooltipAnchor: [20, 0],
})

const pathStyle = { color: '#004a5d', stroke: true, lineCap: 'round' }

const radians_to_degrees = rad => (rad * 180.0) / Math.PI;

const bearingBetweenLocations = (destinationCoords = [], sourceCoords = []) => {
  if (sourceCoords.length === 2 && destinationCoords.length === 2) {
    const PI = Math.PI;
    const lat1 = sourceCoords[0] * PI / 180;
    const long1 = sourceCoords[1] * PI / 180;
    const lat2 = destinationCoords[0] * PI / 180;
    const long2 = destinationCoords[1] * PI / 180;

    const dLon = (long2 - long1);

    const y = Math.sin(dLon) * Math.cos(lat2);
    const x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);

    let bearingAngle = (radians_to_degrees(Math.atan2(y, x)) + 360 + 180) % 360;

    return bearingAngle;
  }
}

const findTimeRange = (deviceId, vehicleData, fromTime, toTime) => {
  let formattedFromTime = new Date(fromTime);
  let formattedToTime = new Date(toTime);
  let startOfDay = new Date(`${formattedFromTime.getFullYear()}-${(formattedFromTime.getMonth() + 1)}-${(formattedFromTime.getDate())}`);
  let polylinePathBeforeToTime = []
  let polylinePathAfterToTime = []
  let pathLengthBeforeToTime = 0;

  let data = vehicleData;
  let clientVehicleId = data[0].client;
  let firstLat = data[0].latitude;
  let firstLon = data[0].longitude;
  // remove and replace the first point in the array with the new timeline selector 'toTime' to ensure no vehicle pop in
  data.shift();
  data.unshift({ "latitude": firstLat, "longitude": firstLon, "dateTime": fromTime, "device": deviceId, "client": clientVehicleId });

  for (let i = 0; i < data.length; i++) {
    if (data.length <= 2) {
      let endLat = data[i].latitude;
      let endLon = data[i].longitude;
      data.length = data.length - 1;
      data.push({ "latitude": endLat, "longitude": endLon, "dateTime": toTime, "device": deviceId, "client": clientVehicleId });
      // console.log(data) 
    }
    //changes
    // let endLat = data[i].latitude;
    // let endLon = data[i].longitude;
    // data.length = data.length - 1;
    // data.push({"latitude":endLat, "longitude":endLon, "dateTime":toTime, "device":deviceId, "client":clientVehicleId});

    polylinePathBeforeToTime.push([data[i].latitude, data[i].longitude]);
    pathLengthBeforeToTime = i;
  }

  polylinePathAfterToTime = data.slice(pathLengthBeforeToTime);
  return { polylinePathBeforeToTime, polylinePathAfterToTime, pathLengthBeforeToTime };
}

const RotatingTripMarker = ({ children, deviceId, vehicleData, deviceName, clientVehicleId }) => {
  const [coords, setCoords] = useState({
    deviceId: "",
    polylinePathBeforeToTime: [],
    polylinePathAfterToTime: [],
    pathLengthBeforeToTime: 0
  });

  const [path, setPath] = useState([])
  const [currentEvent, setCurrentEvent] = useState(0);
  const movingMarkerRef = useRef(null);
  const map = useMap();
  const timelineSelector = useSelector(state => state.timeline)
  const [popupOpen, setPopupOpen] = useState(0);
  const dispatch = useDispatch();
  const history = useNavigate()

  useEffect(() => {
    // if(timelineSelector.clockData.event === "mouseup"){
    const { polylinePathBeforeToTime, polylinePathAfterToTime, pathLengthBeforeToTime } = findTimeRange(deviceId, vehicleData, timelineSelector.clockData.fromTime, timelineSelector.clockData.toTime)
    setCoords({ ...coords, deviceId: deviceId, polylinePathAfterToTime, polylinePathBeforeToTime, pathLengthBeforeToTime });
    // }
  }, [])


  useEffect(() => {
    if (coords.polylinePathBeforeToTime.length > 1) {
      let latitude = coords.polylinePathBeforeToTime[coords.polylinePathBeforeToTime.length - 1][0];
      let longitude = coords.polylinePathBeforeToTime[coords.polylinePathBeforeToTime.length - 1][1];
      let latitude1 = coords.polylinePathBeforeToTime[coords.polylinePathBeforeToTime.length - 2][0];
      let longitude1 = coords.polylinePathBeforeToTime[coords.polylinePathBeforeToTime.length - 2][1];
      movingMarkerRef.current = new L.Marker.movingMarker([[latitude, longitude], [latitude1, longitude1]], [0], { icon: carIcon }).addTo(map);
    }


    return () => {
      if (movingMarkerRef.current) {
        map.removeLayer(movingMarkerRef.current)
        setPath([])
      }
    }
  }, [coords.pathLengthBeforeToTime])

  useEffect(() => {
    let marker = movingMarkerRef.current
    const popup = L.popup();
    if (marker) {
      marker.on('click', () => {
        const container = L.DomUtil.create('div');
        render(
          <VehiclePopup deviceId={deviceId} date={timelineSelector.date} history={history} />,
          container,
        );
        popup.setContent(container);
        marker.unbindPopup().bindPopup(popup, { autoPan: false });
        marker.openPopup();
      })
      marker.on('mouseover', function () {
        marker.unbindTooltip();
        if (!marker.isPopupOpen()) marker.bindTooltip(ReactDOMServer.renderToString(<VehicleTooltip deviceName={deviceName} clientVehicleId={clientVehicleId} />)).openTooltip();
        setPopupOpen(1)
      });
      marker.on('mouseout', function () {
        setPopupOpen(0)
      });
    }

    return () => {
      if (movingMarkerRef.current) {
        movingMarkerRef.current.off('mouseover')
        movingMarkerRef.current.off('mouseover')
        movingMarkerRef.current.off('click')
      }
    }
  }, [coords.pathLengthBeforeToTime, movingMarkerRef.current])

  return (
    <>
      {
        (popupOpen && coords.polylinePathBeforeToTime.length > 0) && (
          <Circle center={coords.polylinePathBeforeToTime[0]} pathOptions={{ fillColor: 'black' }} radius={75} />
        )
      }
      {
        coords.pathLengthBeforeToTime > 0 && (
          <Polyline pathOptions={{
            ...pathStyle,
            color: popupOpen ? '#f06400' : '#004a5d'
          }}
            positions={coords.polylinePathBeforeToTime}
          />
        )
      }
      {
        path.length >= 2 && <Polyline
          pathOptions={{
            ...pathStyle,
            color: popupOpen ? '#f06400' : '#004a5d'
          }}
          positions={path}
        />
      }
    </>
  );
};

RotatingTripMarker.propTypes = {

}

export default React.memo(RotatingTripMarker)



