import React, { useState, useRef, useEffect } from "react";
import PropTypes from 'prop-types';
import styles from './CustomDateRangePicker.module.scss';
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar } from "@amir04lm26/react-modern-calendar-date-picker";
import { Grid, GridItem, Text } from "@leaseplan/ui";
import moment from "moment";

const convertMonth = (month) => {
  if (month === 1) {
    return "Jan";
  } else if (month === 2) {
    return "Feb";
  } else if (month === 3) {
    return "Mar";
  } else if (month === 4) {
    return "Apr";
  } else if (month === 5) {
    return "May";
  } else if (month === 6) {
    return "Jun";
  } else if (month === 7) {
    return "Jul";
  } else if (month === 8) {
    return "Aug";
  } else if (month === 9) {
    return "Sept"
  } else if (month === 10) {
    return "Oct";
  } else if (month === 11) {
    return "Nov";
  } else if (month === 12) {
    return "Dec";
  }
  return null
}

const printDateString = (day, month, year) => {

  if (day && month && year) {
    return `${convertMonth(month)} ${day}`
  }
}

const myCustomLocale = {
  months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],

  weekDays: [
    {
      name: 'Sunday', // used for accessibility 
      short: 'Su', // displayed at the top of days' rows
    },
    {
      name: 'Monday',
      short: 'Mo',
    },
    {
      name: 'Tuesday',
      short: 'Tu',
    },
    {
      name: 'Wednesday',
      short: 'We',
    },
    {
      name: 'Thursday',
      short: 'Th',
    },
    {
      name: 'Friday',
      short: 'Fr',
    },
    {
      name: 'Saturday',
      short: 'Sa',
    },
  ],

  // just play around with this number between 0 and 6
  weekStartingIndex: 0,

  // return a { year: number, month: number, day: number } object
  getToday(gregorainTodayObject) {
    return gregorainTodayObject;
  },

  // return a native JavaScript date here
  toNativeDate(date) {
    return new Date(date.year, date.month - 1, date.day);
  },

  // return a number for date's month length
  getMonthLength(date) {
    return new Date(date.year, date.month, 0).getDate();
  },

  // return a transformed digit to your locale
  transformDigit(digit) {
    return digit;
  },


  // for input range value
  from: 'from',
  to: 'to',


  // used for input value when multi dates are selected
  digitSeparator: ',',

  // if your provide -2 for example, year will be 2 digited
  yearLetterSkip: 0,

  // is your language rtl or ltr?
  isRtl: false,
}

const convertToDateTimeFormat = (date) => {
  if (date) {
    return new Date(date.year, date.month - 1, date.day);
  }
}

const convertMomentDate = (date) => {
  if (date) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return {
      year,
      month,
      day
    }
  }
  return null;
}

const CustomDateRangePicker = ({ currentDateRange = {}, onSelectedDateRange, onToggleCalendarIsOpen, isGraphType }) => {

  const [selectedDayRange, setSelectedDayRange] = useState(
    () =>
    ({
      from: convertMomentDate(currentDateRange.from),
      to: convertMomentDate(currentDateRange.to)
    })
  );


  const minfunc = () => {
    // console.log( new Date( convertMomentDate(currentDateRange.from).getTime()+3888000000))
    let getdate
    if (isGraphType && isGraphType == "DAILY_TRIPS") {
      getdate = new Date(convertToDateTimeFormat(selectedDayRange.from).getTime() - 2592000000)
    } else {
      getdate = new Date(convertToDateTimeFormat(selectedDayRange.from).getTime() - 3888000000)
    }
    return (
      convertMomentDate(getdate))
  }

  const maxfunc = () => {
    let getdate
    if (isGraphType && isGraphType == "DAILY_TRIPS") {
      getdate = new Date(convertToDateTimeFormat(selectedDayRange.from).getTime() + 2592000000)
    } else {
      getdate = new Date(convertToDateTimeFormat(selectedDayRange.from).getTime() + 3888000000)
    }
    return (
      convertMomentDate(getdate))
  }

  const minimumDate = minfunc()

  const maximumDate = maxfunc()

  useEffect(() => {
    // alert(convertToDateTimeFormat(selectedDayRange.from))
    minfunc()
  }, [selectedDayRange]);


  const selectDateHandler = () => {
    onSelectedDateRange({
      from: convertToDateTimeFormat(selectedDayRange.from),
      to: convertToDateTimeFormat(selectedDayRange.to)
    });
  }


  return (
    <div className={styles.pickerContainer}>
      <Grid direction="column" justifyContent="center" alignContent="center" gutter={false}>
        <GridItem>
          <div className={styles.headerComponent}>
            <Grid justifyContent="center" alignItems="center" gutter={false}>
              <GridItem>
                <Text component="p" color={'white'} className={styles.headerText} gutter={false}>{printDateString(selectedDayRange?.from?.day, selectedDayRange?.from?.month, selectedDayRange?.from?.year)}</Text>
              </GridItem>
              <GridItem>
                <Text component="span" color={'white'} className={styles.headerHypen} gutter={false}>{selectedDayRange.to && " - "}</Text>
              </GridItem>
              <GridItem>
                <Text component="p" color={'white'} className={styles.headerText} gutter={false}>{printDateString(selectedDayRange?.to?.day, selectedDayRange?.to?.month, selectedDayRange?.to?.year)}</Text>
              </GridItem>
            </Grid>
          </div>
        </GridItem>
        <GridItem>
          <Calendar
            value={selectedDayRange}
            onChange={setSelectedDayRange}
            minimumDate={minimumDate}
            maximumDate={maximumDate}
            calendarClassName={styles.boxCalendar}
            calendarTodayClassName={styles.boxCalendarToday}
            calendarSelectedDayClassName={styles.boxCalendarSelected}
            locale={myCustomLocale}
            colorPrimary="#f06400" // added this
            colorPrimaryLight="rgba(237,139,0, 0.4)" // and this
            renderFooter={() => (
              <div className={styles.footerComponent}>
                <div
                  onClick={onToggleCalendarIsOpen}
                  className={`${styles.buttonStyle} ${styles.footerCancelButton}`}
                >
                  CANCEL
                </div>
                <div
                  onClick={selectDateHandler}
                  className={`${styles.buttonStyle} ${styles.footerOkButton}`}
                >
                  OK
                </div>
              </div>
            )}
          />
        </GridItem>
      </Grid>
    </div>
  );
};

CustomDateRangePicker.propTypes = {
  currentDateRange: PropTypes.shape({
    from: PropTypes.instanceOf(Date),
    to: PropTypes.instanceOf(Date)
  }).isRequired,
  onSelectedDateRange: PropTypes.func.isRequired,
  onToggleCalendarIsOpen: PropTypes.func.isRequired,
}

export default CustomDateRangePicker;