const EVENTS_CONSTSNTS = {
    FETCHING_DRIVER_EVENTS: 'FETCHING_DRIVER_EVENTS',
    FETCH_DRIVER_EVENTS: 'FETCH_DRIVER_EVENTS',
    RESET_DRIVER_EVENTS: 'RESET_DRIVER_EVENTS',
    REMOVE_DRIVER_EVENTS: 'REMOVE_DRIVER_EVENTS'
}

export const COLOR_CODE_DRIVER = {
    HARSH_ACCELERATION: 'rgb(53, 255, 221)',
    HARD_BRAKING: 'rgb(64,171,58)',
    MAX_SPEED: 'rgb(255,191,0)',
    POSTED_SPEED: 'rgb(255, 208, 127)',
}

export const COLOR_CODE_VEHICLE = {
    SEAT: 'rgb(53, 255, 221)',
    COURSE: 'rgb(64,171,58)',
    MESSAGE: 'rgb(255,191,0)',
    MOTIFICATION: 'rgb(53, 255, 221)',
    RECALL: 'rgb(255,0,0)',
    OVERDUE: 'rgb(255,191,0)',
    OIL_LIFE: 'rgb(33,33,33)',
    ENGINE_LIGHT: 'rgb(255,0,0)',
    TMPS: 'rgb(124,204,90)',
    DEVICE_OFFLINE: 'rgb(255,191,0)'
}


export default EVENTS_CONSTSNTS;