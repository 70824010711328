
import { ObjectUtils } from "./validatorUtil";
import moment from "moment";
import axios from "axios";
import { baseURL } from "../Store/base-url";

const BREAK = 10;

export const defaultCountryCurrency = "usd";

export const currencies = {
  usd: { name: "USD", country: "en-US" },
  jpy: { name: "JPY", country: "ja-JP" },
  eur: { name: "EUR", country: "de-DE" },
};

export const titleCase = (str) => {
  var splitStr = str ? str.toLowerCase().split(' ') : [];
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
}

export const formatPhoneNumber = (value = "") => {
  let valueString = value ? value.toString().trim() : value;
  let formattedString = valueString.replace(/\D/g, "");
  const match = formattedString.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/);
  if (match) {
    return `(${match[1]}${match[2] ? ") " : ""}${match[2]}${match[3] ? "-" : ""
      }${match[3]}`;
  }
  return valueString;
};

export const formatNumeric = (
  value = "",
  countryCurrency = defaultCountryCurrency,
  validation = "",
  prefix = false,
  maxFractionDigits = 2,
  minFractionDigits = 0
) => {
  let formatter;
  let result = value ? value.toString() : value;
  let valueString = value ? value.toString() : value;
  if (result === "" || !result) {
    return value;
  }

  if (valueString.length === 1 && valueString === ".") {
    result = "0.";
  }

  // if '$' prefix required
  if (prefix) {
    formatter = new Intl.NumberFormat(currencies[countryCurrency].country, {
      style: "currency",
      currency: currencies[countryCurrency].name,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    result = result.replace(/,/g, "");
    result = formatter.format(result);
  } else {
    //for Number types
    if (validation === "number") {
      formatter = new Intl.NumberFormat(currencies[countryCurrency].country, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    } else {
      //for Decimal types
      formatter = new Intl.NumberFormat(currencies[countryCurrency].country, {
        style: "currency",
        currency: currencies[countryCurrency].name,
        minimumFractionDigits: 0,
        maximumFractionDigits: maxFractionDigits,
      });
    }
    //replace all the character expect 0-9 and . with ""
    result = result.replace(/[^0-9\.]/g, "");
    //check if the result does not have more than one period symbol
    result = result.replace(/([0-9]*)\.+.*\./g, "$1")
    result = formatter.format(result);
    result = result.replace("$", "");

    // console.log('Before dot check >> ', result, valueString.length > 2 && valueString[valueString.length - 2] === '.', valueString, valueString.length, valueString[valueString.length - 2], valueString.indexOf(".") !== -1 && valueString.split(".")[1].length);
    if (valueString[valueString.length - 1] === ".") {
      result = result + "."; //to keep 9. as 9. and not convert it to 9
    } else if (
      valueString.length > 1 &&
      valueString[valueString.length - 1] === "0"
    ) {
      const valueDecimalIndex = valueString.indexOf(".");
      const resultDecimalIndex = result.indexOf(".");
      if (
        valueString.length > 2 &&
        valueString[valueString.length - 2] === "."
      ) {
        result += "." + valueString[valueString.length - 1]; //to keep 9.0 as 9.0 and not convert it to 9
      } else if (
        valueString.length > 3 &&
        maxFractionDigits > 2 &&
        valueString[valueString.length - maxFractionDigits] === "."
      ) {
        result = result.split(".")[0];
        result +=
          "." +
          valueString[valueString.length - 2] +
          valueString[valueString.length - 1]; //to keep 9.00 as 9.00 and not convert it to 9
      } else if (
        valueDecimalIndex > -1 &&
        valueString.split(".")[1].length === maxFractionDigits &&
        resultDecimalIndex > -1 &&
        result.split(".")[1].length < maxFractionDigits
      ) {
        // result = result + "0"; //to keep 9.10 as 9.10 and not convert it to 9.1
        for (
          let i = maxFractionDigits - result.split(".")[1].length;
          i > 0;
          i--
        ) {
          result += "0";
        }
      }
    }
  }
  return result;
};
export const addFailedFunction = (previousState, name, parameter) => {
  const failedRequest = [...previousState];
  const isDuplicate = failedRequest.some(
    (f) =>
      f.functionName === name &&
      ObjectUtils.isEqual(f.functionParameter, parameter)
  );
  if (!isDuplicate) {
    failedRequest.push({
      functionName: name,
      functionParameter: parameter,
    });
  }
  return failedRequest;
};

export const removeSuccessFunction = (previousState, functionName) => {
  const failedRequest = [...previousState];
  const index = failedRequest.findIndex((f) => f.functionName === functionName);
  if (index > -1) {
    failedRequest.splice(index, 1);
  }
  return failedRequest;
};
export const getLabel = (labelKey, defaultLabel, prismicData) => {
  let label = convertValueToPrismicKey(labelKey);
  return prismicData ? prismicData[label] || defaultLabel : defaultLabel;
  // if (
  //   prismicData &&
  //   Object.keys(prismicData).length > 0 &&
  //   prismicData[label] &&
  //   prismicData[label].length > 0
  // ) {
  //   return prismicData[label][0];
  // }
  // return defaultLabel || labelKey;
};

export const hasAuthority = (functionName, userInfo = { functions: [] }) => {
  if (ObjectUtils.isEmpty(userInfo) || ObjectUtils.isArrayEmpty(userInfo.functions)) {
    return false;
  }
  return userInfo.functions.some(funcName => funcName.authority === functionName)
};

/**
 * @desc: Function is defined to validate email address which a text input in the form
 * @param {String} emailTextInput String value passed to check the validation of the email input type text field
 * @return {Boolean} isEmailTextInputValid
 */
export const validateEmail = (emailTextInput) => {
  const isEmailTextInputValid = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(emailTextInput);
  return isEmailTextInputValid;
};

/**
 * @desc: Function is defined to validate routing number to accept only 9 digits
 * @param {Text} routingNumber
 * @return {Boolea} isRoutingNumberValid
 */
export const validateRoutingNumber = (routingNumber) => {
  const isRoutingNumberValid = /^(\d{9})?$/.test(routingNumber);
  return isRoutingNumberValid;
};

/**
 * @desc: Function is defined to validate account number
 * @param {number} accountNumber
 * @return {Boolean} isAccountNumberValid
 */
export const validateAccountNumber = (accountNumber) => {
  if (accountNumber) {
    const isAccountNumberValid = /^[0-9]{1,20}$/.test(accountNumber.toString());
    return isAccountNumberValid;
  }
};


/**
 * @desc: Function is defined to validate SSN and EID number to accept only 9 digits
 * @param {Text} idNumber
 * @return {Boolea} isIdNumberValid
 */
export const validateIdNumber = (idNumber) => {
  const isIdNumberValid = (/^(\d{9})?$/.test(idNumber) && !/^([0])\1*$/.test(idNumber));
  return isIdNumberValid;
};
/**
 * @desc: Function is defined to valid number text input
 * @param {String} textNumberInput : String value passed to check the validation of the number input type text field
 * @return {Boolean} isTextNumberInputValid
 */
export const validateNumber = (textNumberInput) => {
  const isTextNumberInputValid = /^[0-9]+$/.test(textNumberInput);
  return isTextNumberInputValid;
};

/**
 * @desc: Function is defined to valid currency text input
 * @param {String} currencyInput : String value passed to check the validation of the number input type text field
 * @return {Boolean} isCurrencyValid
 */
export const validateCurrency = (currencyInput) => {
  const isCurrencyValid = /^[0-9]+(\.[0-9]{1,2})?$/.test(currencyInput);
  return isCurrencyValid;
};

/**
 * @desc: Function is defined to valid phone extension text input
 * @param {String} textPhoneExtendionInput
 * @return {Boolean} isTextPhoneEntensionInputValid
 */
export const validatePhoneExtension = (textPhoneExtendionInput) => {
  const isTextPhoneEntensionInputValid = /^[0-9]{1,10}$/.test(
    textPhoneExtendionInput
  );
  return isTextPhoneEntensionInputValid;
};

/**
 * @desc: Function is defined to validated phoneNumber
 * @param {*} phoneNumber: String value passed to check the validation of the phone Number
 */
export const validatePhoneNumber = (phoneNumber) => {
  const isValid = /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/.test(
    phoneNumber
  );
  return isValid;
};


/**
 * @desc: Function is defined to valid normal text input
 * @param {*} textInput : String value passed to check the validation of the input type text field.
 */
export const validateTextInput = (textInput, alias) => {
  const regexCondition =
    (alias === "business_name" || alias === "profile_name")
      ? new RegExp(/^[a-zA-Z0-9\s-'&]*$/)
      : new RegExp(/^[a-zA-Z0-9\s',.#]*$/);
  const isTextInputValid = regexCondition.test(textInput);
  return isTextInputValid;
};



export const convertValueToPrismicKey = (value) => {
  if (value) {
    // remove special character
    let newValue = value.replace(/[^a-zA-Z\d _]/g, "");
    // remove multiple continuous spaces
    newValue = newValue.replace(/  +/g, " ");
    return newValue.replace(/ /g, "_").toLowerCase();
  }
  return value;
};



export const convertKeysToNormalText = (keys) => {
  return keys.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) { return str.toUpperCase(); })
}

/**
 * @desc: Function is defined to validate zip code which is a text input in the form
 * @param {String} zipCodeTextInput String value passed to check the validation of the zipcode input type text field
 * @return {Boolean} isZipCodeValidated
 */
export const validateZipCode = (zipCodeTextInput) => {
  const isZipCodeValidated = /^(\d{5})?$/.test(zipCodeTextInput);
  return isZipCodeValidated;
};



/**
  * @desc: Function is defined to get codelist description
  * @param {*} codeListArray CodeList Array
  * @param {*} codeValue CodeValue Value
  */
export const getCodeListData = (codeListArray, codeValue) => {
  if (Array.isArray(codeListArray)) {
    var codeListObject = codeListArray.find(
      (ele) => ele.codeValue === codeValue
    )
    if (codeListObject) {
      return codeListObject["codeValueDescription"];
    }
  }
  return codeValue;
}

/**
 * @desc: Function is defined to check Form Validation
 * @param {*} formData 
 * @returns Boolean
 */
export const checkFormValidation = (formData) => {
  return formData.inputs.some((field) => {
    return (
      field["validationState"] === "error" ||
      (field["validationState"] === "pristine" && field["isRequired"]) ||
      (field["isRequired"] &&
        (field["value"] === "" || field["value"].length === 0))
    );
  });
};

/**
 * @desc: Function is defined to convert ISO to xxxx-xx-xx date format
 * @param {date} DateObject
 * @returns String
 */
export const getDateInFormat = (date) => {
  if (date) {
    return date.getFullYear() + '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + ("0" + date.getDate()).slice(-2);
  }
  return "";
}

/**
 * @desc: Function to map product uid to vendor product present in differnt redux store 
 * @param {currentId} ProductUid
 * @param {secondData} ListOfProducts
 * @returns String
 */
export const dataMapperVendorProduct = (currentId, secondData) => {
  if (currentId && secondData) {
    let result = secondData.filter(account => account.vendorProductUid === currentId)[0];
    return result.productDescription;
  }
  return ""
}

/**
 * @desc: Function to map product uid to vendor pos prompt present in differnt redux store 
 * @param {currentId} ProductUid
 * @param {secondData} ListOfProducts
 * @returns String
 */
export const dataMapperPOSPrompt = (currentId, secondData) => {
  if (currentId && secondData) {
    let result = secondData.filter(account => account.vendorProductUid === currentId)[0];
    return result.pinPromptType;
  }
  return ""
}


export const filteringCheckedValues = (filterData = []) => {
  let filterValues = filterData.filter(data => data.value === true).map(filterValue => filterValue.alias.toUpperCase());
  return filterValues;
}

function padLeadingZeros(num, size) {
  var s = num + "";
  while (s.length < size) s = "0" + s;
  return s;
}

export const getValueByTime = (time) => {
  let hours = time.split(':')[0]
  let minutes = time.split(':')[1];

  hours = parseInt(hours)
  minutes = parseInt(minutes)

  let hoursValue = hours * 10;
  let minuteValue = minutes * 100 / 60

  minuteValue = minuteValue * 10 / 100
  return hoursValue + minuteValue;
}

export const getTime = (value) => {
  let hour = Math.floor(value / BREAK);
  let remainder = value % BREAK;

  let percentage = (remainder / BREAK) * 100;

  let minute = Math.floor((percentage * 60) / 100);

  return `${padLeadingZeros(hour, 2)}:${padLeadingZeros(minute, 2)}`
}

export const subtractTime = (date1, date2, speed = 1) => {
  // if(date1 && date2 ){
  let min = 60000;
  let toDate = moment(date1);
  let fromDate = moment(date2);
  let diff = fromDate.diff(toDate);
  // console.log('000', diff/speed);
  return parseInt(diff / speed)
  // }
}

export const extractDataFromStaticFile = (data) => {
  if (data) {
    const allCoords = data.map((data) => {
      return { position: [parseFloat(data.latitude), parseFloat(data.longitude)], timeStamp: data.dateTime }
    })
    return allCoords;
  }
  return [];
}

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const removeSpaceFromString = (string) => {
  return string.replace(/ /g, '');
}

export const convertSecondsInHoursAndMinutes = (d) => {
  d = Number(d);
  let h = Math.floor(d / 3600);
  let m = Math.floor(d % 3600 / 60);
  let s = Math.floor(d % 60);

  return `${h}h ${m}m ${s}s`
}

// According to the date time wise get the geolocation from log-records
export const moveToTheLocation = (target, newData) => {

  let targetInTime = new Date(target).getTime()
  let newData1 = [];
  var mid;
  var lo = 0;
  var hi = newData[0].logRecordData.length - 1;
  const deviceId = newData[0].deviceId;
  const deviceName = newData[0].deviceName;
  const clientVehicleId = newData[0].clientVehicleId;
  while (hi - lo > 1) {
    mid = Math.floor((lo + hi) / 2);
    if (new Date(newData[0].logRecordData[mid].dateTime).getTime() < targetInTime) {
      lo = mid;
    } else {
      hi = mid;
    }
  }
  if (targetInTime - new Date(newData[0].logRecordData[lo].dateTime).getTime() <= new Date(newData[0].logRecordData[hi].dateTime).getTime() - targetInTime) {

    let logRecordData = [newData[0].logRecordData[lo]]
    newData1.push({ deviceId, logRecordData, deviceName, clientVehicleId })
    return newData1;
  }
  let logRecordData = [newData[0].logRecordData[hi]]
  newData1.push({ deviceId, logRecordData, deviceName, clientVehicleId })
  return newData1;
};

//Get the nearest location using lat lng from log-record
export function closestLocationforlogs(targetLocation, locationData) {
  let result = []
  function vectorDistance(dx, dy) {
    return Math.sqrt(dx * dx + dy * dy);
  }

  function locationDistance(location1, location2) {
    var dx = location1.lat - location2.latitude,
      dy = location1.lng - location2.longitude;

    return vectorDistance(dx, dy);
  }

  return locationData[0].logRecordData.reduce(function (prev, curr) {
    var prevDistance = locationDistance(targetLocation, prev),
      currDistance = locationDistance(targetLocation, curr);
    let logRecordData = (prevDistance < currDistance) ? prev : curr;
    return logRecordData
  });
}

// According to the Location 1st Index get the array index from log-records
export const getArrayIndexByLocation = (target, Data) => {

  let initailLat = Data[0].logRecordData[0].latitude;
  let result = []
  let logRecordData

  const deviceId = Data[0].deviceId;
  const deviceName = Data[0].deviceName;
  const clientVehicleId = Data[0].clientVehicleId;
  const timezoneShortName = Data[0].timezoneShortName;
  // Traverse the array
  for (let i = 1; i < Data[0].logRecordData.length; i++) {

    // If absolute difference of target and initailLat exceeds absolute difference of target and current element

    if (Math.abs(target.lat - initailLat) > Math.abs(target.lat - Data[0].logRecordData[i].latitude)) {
      initailLat = Data[0].logRecordData[i].latitude;
      logRecordData = Data[0].logRecordData[i]
    }
  }
  result.push({ deviceId, logRecordData, deviceName, clientVehicleId, timezoneShortName })
  return result
}



// According to the date time wise get the array index of that log-records
export const getArrayIndexByDateTime = (target, newData) => {

  let targetInTime = new Date(target).getTime()
  let newData1 = [];
  var mid;
  var lo = 0;
  var hi = (newData[0].logRecordData.length) - 1;
  while (hi - lo > 1) {
    mid = Math.floor((lo + hi) / 2);
    // console.log(mid)
    // console.log(Date.parse(newData[0].logRecordData[mid].dateTime))
    // console.log(new Date(newData[0].logRecordData[mid].dateTime))
    if (new Date(newData[0].logRecordData[mid].dateTime).getTime() < targetInTime) {
      lo = mid;
    } else {
      hi = mid;
    }
  }
  if (targetInTime - new Date(newData[0].logRecordData[lo].dateTime).getTime() <= new Date(newData[0].logRecordData[hi].dateTime).getTime() - targetInTime) {

    newData1.push([lo])
    return newData1;
  }
  newData1.push([hi])
  return newData1;
};

//to set the timezoneoffset as per the selected timezone area
export const Timezonedata_Api_nowindowload = async (e) => {
  const resp = await axios({
    baseURL: baseURL,
    responseType: "json",
    url: `/timezone-data`,
    method: "get",
  });
  const data = resp?.data;
  let result = data.filter(obj => {
    return obj.timezoneId === e
  });
  if (result && result.length > 0) {
    sessionStorage.setItem("Timezone", e);
    sessionStorage.setItem("TimezoneOffset", result.map(a => a.utcOffset)[0])
    sessionStorage.setItem("TimezoneShortname", result.map(a => a.timezoneShortName)[0])
  }
  else {
    sessionStorage.setItem("Timezone", data[0].timezoneId);
    sessionStorage.setItem("TimezoneOffset", data[0].utcOffset)
    sessionStorage.setItem("TimezoneShortname", data[0].timezoneShortName)
  }

}

//to set the timezoneoffset as per the selected timezone area with browser reload
export const Timezonedata_Api = async (e) => {
  const resp = await axios({
    baseURL: baseURL,
    responseType: "json",
    url: `/timezone-data`,
    method: "get",
  });
  const data = resp?.data;
  let result = data.filter(obj => {
    return obj.timezoneId === e
  });
  if (result && result.length > 0) {
    sessionStorage.setItem("Timezone", e);
    sessionStorage.setItem("TimezoneOffset", result.map(a => a.utcOffset)[0])
    sessionStorage.setItem("TimezoneShortname", result.map(a => a.timezoneShortName)[0])
  }
  else {
    sessionStorage.setItem("Timezone", data[0].timezoneId);
    sessionStorage.setItem("TimezoneOffset", data[0].utcOffset)
    sessionStorage.setItem("TimezoneShortname", data[0].timezoneShortName)
  }
  window.location.reload();

}
// for adding the timezoneoffset 
export const timezoneOffSet = (date, timestamp) => {
  // let timezoneid = sessionStorage.getItem('Timezone');
  // if(timezoneid == ""){timezoneid = Intl.DateTimeFormat().resolvedOptions().timeZone}
  let timetest = new Date(date + 'T' + timestamp);
  var numberOfMlSeconds = timetest.getTime();
  var timeParts = sessionStorage.getItem('TimezoneOffset').split(":");
  var timeinmin = Number(timeParts[0]) * 60 + Number(timeParts[1]);
  var timeinsec = timeinmin * 60 * 1000;
  let newtiming = new Date(numberOfMlSeconds - timeinsec);
  let time = newtiming.toString().split(' ');
  newtiming = convert(newtiming) + 'T' + time[4]
  return newtiming
}
// for sub the timezoneoffset
export const timezoneOffSetSub = (date, timestamp, utcOffset) => {
  // let timezoneid = sessionStorage.getItem('Timezone');
  // if(timezoneid == ""){timezoneid = Intl.DateTimeFormat().resolvedOptions().timeZone}
  let timetest = new Date(date + 'T' + timestamp);
  var numberOfMlSeconds = timetest.getTime();
  var timeParts = utcOffset ? utcOffset.split(":") : sessionStorage.getItem('TimezoneOffset').split(":");
  var timeinmin = Number(timeParts[0]) * 60 + Number(timeParts[1]);
  var timeinsec = timeinmin * 60 * 1000;
  let newtiming = new Date(numberOfMlSeconds + timeinsec);
  let time = newtiming.toString().split(' ');
  newtiming = convert(newtiming) + 'T' + time[4]
  return newtiming
}

// for add the timezoneoffset
export const timezoneOffSetAdd = (date, timestamp, utcOffset) => {
  // let timezoneid = sessionStorage.getItem('Timezone');
  // if(timezoneid == ""){timezoneid = Intl.DateTimeFormat().resolvedOptions().timeZone}
  let timetest = new Date(date + 'T' + timestamp);
  let numberOfMlSeconds = timetest.getTime();
  let timeParts = utcOffset != 'null' ? utcOffset.split(":") : sessionStorage.getItem('TimezoneOffset').split(":");
  let timeinmin = Number(timeParts[0]) * 60 + Number(timeParts[1]);
  let timeinsec = timeinmin * 60 * 1000;
  let newtiming = new Date(numberOfMlSeconds - timeinsec);
  let time = newtiming.toString().split(' ');
  newtiming = convert(newtiming) + 'T' + time[4]
  return newtiming
}

//setting the timezone offset in log-record
export const convertLogRecordToLocalTime = (data) => {
  for (let index = 0; index < data.length; index++) {
    let dateTime = data[index].dateTime;
    var numberOfMlSeconds = new Date(dateTime).getTime();
    var timeParts = sessionStorage.getItem('TimezoneOffset').split(":");
    var timeinmin = Number(timeParts[0]) * 60 + Number(timeParts[1]);
    var timeinsec = timeinmin * 60 * 1000;
    let newtiming = new Date(numberOfMlSeconds + timeinsec);
    let dformat = [newtiming.getFullYear() + '-' + (newtiming.getMonth() + 1) + '-' + newtiming.getDate()]
      + ' ' + [newtiming.getHours() + ':' + newtiming.getMinutes() + ':' + newtiming.getSeconds()];
    data[index].dateTime = dformat
  }
  return data
}

export const convert = (str) => {
  let mnth = ("0" + (str.getMonth() + 1)).slice(-2),
    day12 = ("0" + str.getDate()).slice(-2);
  return [str.getFullYear(), mnth, day12].join("-");
}

export const validateUsZipCode = (str) => {
  if (!!str) {
    str = str.match(/[^\s\-]+?/g).join("");
    var c = (str.length < 9) ? 5 : 9;
    var re = new RegExp("^\\d{" + c + "}$");
    var msg = (!!str.match(re))
    return msg
  }
}

export const getOffsetTimeZone = (startTime, timezoneShortName, utcOffset) => {
  if (!utcOffset) { utcOffset = '00:00' }
  var currentDateObj = new Date(startTime);
  var timeParts = utcOffset.split(":");
  var timeinmin = Number(timeParts[0]) * 60 + Number(timeParts[1]);
  var numberOfMlSeconds = currentDateObj.getTime();
  var addMlSeconds = timeinmin * 60 * 1000;
  var newDateObj = new Date(numberOfMlSeconds + addMlSeconds);
  let newtime = getdatetime(newDateObj, timezoneShortName)
  return newtime
}

export const toISOStringLocal = (d) => {
  const z = (n) => { return (n < 10 ? '0' : '') + n }
  return d.getFullYear() + '-' + z(d.getMonth() + 1) + '-' +
    z(d.getDate()) + 'T' + z(d.getHours()) + ':' +
    z(d.getMinutes()) + ':' + z(d.getSeconds())

}

export const getdatetime = (timeStamp, timezoneShortName) => {

  let date = convert(timeStamp); //MM/DD/YYYY
  let time = tConvert(timeStamp.toString().split(' ')[4]); //12:34:32 AM
  // let timeWithoutSeconds = (time.split(' ')[0]).split(':').slice(0,2).join(':');
  return time + ' ' + '(' + timezoneShortName + ')' + ' ' + date; // return adjusted time or original string
}



export const tConvert = (time) => {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

  if (time.length > 1) { // If time format correct
    time = time.slice(1);  // Remove full string match value
    time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
    time.splice(3, 1);
    time[3] = ' '
  }
  return time.join(''); // return adjusted time or original string
}

export const getOffsetTime = (timeStamp, timezoneId) => {
  if (!timeStamp || !timezoneId) return '-'
  let formattedDate = new Date(timeStamp);
  formattedDate = formattedDate.toLocaleString('en-US', { hour12: true, timeZone: timezoneId });
  let date = formattedDate.split(', ')[0]; //MM/DD/YYYY
  let time = formattedDate.split(', ')[1]; //12:34:32 AM
  let timeWithoutSeconds = (time.split(' ')[0]).split(':').slice(0, 2).join(':');
  return timeWithoutSeconds + ' ' + time.split(' ')[1] + ' ' + date.split('/').join('-'); // hh:mm A MM-DD-YYYY
}

//Expecting time in seconds
export const getTimeGap = (time) => {
  time = Number(time);
  var h = Math.floor(time / 3600);
  var m = Math.floor(time % 3600 / 60);
  var s = Math.floor(time % 3600 % 60);

  if (h === 0 && m === 0) {
    return s > 1 ? `${s} seconds ago` : `${s} second ago`;
  } else if (m > 0 && h === 0) {
    return m > 1 ? `${m} mins ago` : `${m} min ago`;
  } else if (m > 0 && h > 0) {
    return (h > 1 ? `${h} hours ` : `${h} hour `) + (m > 1 ? `${m} mins ago` : `${m} min ago`);
  } else {
    return h > 1 ? `${h} hours ago` : `${h} hour ago`;
  }
}

export const hoursToDaysAndHours = (hours) => {
  const days = Math.floor(hours / 24);
  const remainingHours = hours % 24;

  return `${days} Days ${remainingHours} hr`;
}

export const getTimeGapInDays = (time) => {
  time = Number(time);
  let d = Math.floor(time / 24 / 60);
  let h = Math.floor(time / 60 % 24);
  let m = Math.floor(time % 60);

  const getDay = (day) => {
    return day > 1 ? `${day} days ` : `1 day `;
  }

  const getHour = (hour) => {
    return hour > 1 ? `${hour} hours ` : `1 hour `;
  }

  const getMinute = (min) => {
    return min > 1 ? `${min} mins ` : `1 min `;
  }

  let gap = '';
  if (d === 0 && h === 0) {
    gap = getMinute(m);
  } else if (d === 0 && h > 0) {
    gap = getHour(h) + (m > 0 ? getMinute(m) : '');
  } else if (d > 0) {
    gap = getDay(d) + (h > 0 ? getHour(h) : '') + (m > 0 ? getMinute(m) : '')
  }

  return gap + 'ago';
}

export const getCurrentTimeInHT = () => {
  let date = new Date();
  let time = date.toLocaleString('en-US', { hour12: false, timeZone: 'US/Hawaii' });
  time = time.split(',')
  time = time[1].substring(1);

  return time;
}

export const getCurrentTimeInEST = () => {
  let date = new Date();
  let time = date.toLocaleString('en-US', { hour12: false, timeZone: 'America/New_York' });
  time = time.split(',')
  time = time[1].substring(1);

  return time;
}