import React, { useState, useEffect } from 'react';
import styles from './BottomSlider.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import LevelFilters from '../LevelFilters/LevelFilters';
import moment from 'moment-timezone';

//Actions
import { fetchFilters } from '../../../Actions/filters.action';
import { insertDate } from '../../../Actions/timeline.actions';
import { setDrivers, putDriverLevels } from '../../../Actions/level.action';


const LAYOUTS = [
    {
        label: 'Fleet Overview',
        value: 'fleet_overview',
    },
    {
        label: 'Latest Events',
        value: 'latest_events',
    }
]

const todaysDate = moment().tz('America/New_York').format('YYYY-MM-DD');

const UserProfileSetting = (props) => {
    const { prismicData } = props;
    const [levelFiltersData, setLevelFiltersData] = useState({});
    const [selectedLevelFilters, setSelectedLevelFilter] = useState({});
    const [isSideDrawerOpen, setIsSideDrawerOpen] = useState(false);
    const [clientPoiFilters, setClientPoiFilters] = useState([]);
    const [poiFilters, setPoiFilters] = useState([]);

    const filtersSelector = useSelector(state => state.filters.search);
    const filtersSelectordefaultValue = useSelector(state => state.filters.defaultConfig);
    const poiFiltersSelector = useSelector(state => state.filters.poi);
    const timelineSelector = useSelector(state => state.timeline);
    const driverDataViaLevelSelectors = useSelector(state => state.levels.data);
    const authSelector = useSelector(state => state.auth.user.clientUserDefaultDto);
    const openSettingSelector = useSelector(state => state.mapFilters.openSettingDrawer)

    const dispatch = useDispatch();

    useEffect(() => {
        setIsSideDrawerOpen(openSettingSelector?.data == true ? openSettingSelector.data : false)
    }, [openSettingSelector])

    // use to set the default setting as per the auth api response.
    useEffect(() => {
        let pannelArray = []
        let poiDefaultValueArray = []
        let poiDefaultArray = []
        //clearing session when login for POIS
        sessionStorage.setItem("ALL_NAS", "")
        sessionStorage.setItem("ALL_EVCS", "")
        sessionStorage.setItem("ALL_FS", "")

        if (filtersSelectordefaultValue && filtersSelectordefaultValue.data) {
            if (filtersSelectordefaultValue.data.showFleetOverview == 1) {
                pannelArray.push('fleet_overview')
            }
            if (filtersSelectordefaultValue.data.showLatestEvents == 1) {
                pannelArray.push('latest_events')
            }
            sessionStorage.setItem("pannelArray", JSON.stringify(pannelArray))

            // if (sessionStorage.getItem("ALL_EVCS") == "true" || sessionStorage.getItem("ALL_FS") == "true" || sessionStorage.getItem("ALL_NAS") == "true" ||
            //     sessionStorage.getItem("ALL_EVCS") == "false" || sessionStorage.getItem("false") == "true" || sessionStorage.getItem("false") == "true") {
            //     var evcsSession = sessionStorage.getItem("ALL_EVCS") == "false" ? poiDefaultValueArray : poiDefaultValueArray.push('ALL_EVCS')
            //     var fsSession = sessionStorage.getItem("ALL_FS") == "false" ? poiDefaultValueArray : poiDefaultValueArray.push('ALL_FS')
            //     var nasSession = sessionStorage.getItem("ALL_NAS") == "false" ? poiDefaultValueArray : poiDefaultValueArray.push('ALL_NAS')
            // } else {
            if (filtersSelectordefaultValue.data.showEvChargingStations == 1) {
                poiDefaultValueArray.push('ALL_EVCS')
            } if (filtersSelectordefaultValue.data.showFuelStations == 1) {
                poiDefaultValueArray.push('ALL_FS')
            } if (filtersSelectordefaultValue.data.showNationalAccountShops == 1) {
                poiDefaultValueArray.push('ALL_NAS')
            }
            // }
            if (filtersSelectordefaultValue.data.showEvChargingStations == 1) {
                poiDefaultArray.push('ALL_EVCS')
            } if (filtersSelectordefaultValue.data.showFuelStations == 1) {
                poiDefaultArray.push('ALL_FS')
            } if (filtersSelectordefaultValue.data.showNationalAccountShops == 1) {
                poiDefaultArray.push('ALL_NAS')
            }
        } else if (authSelector) {
            if (authSelector.showFleetOverview == 0) {
                pannelArray.push('fleet_overview')
            }
            if (authSelector.showLatestEvents == 0) {
                pannelArray.push('latest_events')
            }
            sessionStorage.setItem("pannelArray", JSON.stringify(pannelArray))
            if (authSelector.showEvChargingStations == 1) {
                poiDefaultValueArray.push('ALL_EVCS')

            } if (authSelector.showFuelStations == 1) {
                poiDefaultValueArray.push('ALL_FS')
            } if (authSelector.showNationalAccountShops == 1) {
                poiDefaultValueArray.push('ALL_NAS')
            }
        }
        sessionStorage.setItem("POI", JSON.stringify(poiDefaultValueArray))
        sessionStorage.setItem("POIvalue", JSON.stringify(poiDefaultArray))
    }, [filtersSelectordefaultValue, authSelector])

    useEffect(() => {
        const drivers = [];

        driverDataViaLevelSelectors.deviceSearchDtoList != null && (driverDataViaLevelSelectors?.deviceSearchDtoList.forEach((item) => {
            drivers.push(item.deviceId);
        }));

        dispatch(setDrivers(drivers));
    }, [dispatch, driverDataViaLevelSelectors]);

    useEffect(() => {
        const { isLoading, data } = filtersSelector;

        if (!isLoading && data) {
            setLevelFiltersData(data['levelDescription']);
        }
    }, [filtersSelector]);

    // useEffect for default level filter tab
    useEffect(() => {
        let filtersSelectordefaultValueArrayLevel1 = []
        let filtersSelectordefaultValueArrayLevel2 = []
        let filtersSelectordefaultValueArrayLevel3 = []
        let filtersSelectordefaultValueArrayLevel4 = []
        let filtersSelectordefaultValueArrayLevel5 = []
        let filtersSelectordefaultValueArrayLevel6 = []
        let filtersSelectordefaultValueArrayLevel7 = []
        let filtersSelectordefaultValueArrayLevel8 = []
        let filtersSelectordefaultValueArrayLevel9 = []
        let filtersSelectordefaultValueArrayLevel10 = []
        if (filtersSelectordefaultValue && filtersSelectordefaultValue.data) {
            filtersSelectordefaultValueArrayLevel1 = filtersSelectordefaultValue.data.level1 ? filtersSelectordefaultValue.data.level1.split(',') : filtersSelectordefaultValue.data.level1
            filtersSelectordefaultValueArrayLevel2 = filtersSelectordefaultValue.data.level2 ? filtersSelectordefaultValue.data.level2.split(',') : filtersSelectordefaultValue.data.level2
            filtersSelectordefaultValueArrayLevel3 = filtersSelectordefaultValue.data.level3 ? filtersSelectordefaultValue.data.level3.split(',') : filtersSelectordefaultValue.data.level3
            filtersSelectordefaultValueArrayLevel4 = filtersSelectordefaultValue.data.level4 ? filtersSelectordefaultValue.data.level4.split(',') : filtersSelectordefaultValue.data.level4
            filtersSelectordefaultValueArrayLevel5 = filtersSelectordefaultValue.data.level5 ? filtersSelectordefaultValue.data.level5.split(',') : filtersSelectordefaultValue.data.level5
            filtersSelectordefaultValueArrayLevel6 = filtersSelectordefaultValue.data.level6 ? filtersSelectordefaultValue.data.level6.split(',') : filtersSelectordefaultValue.data.level6
            filtersSelectordefaultValueArrayLevel7 = filtersSelectordefaultValue.data.level7 ? filtersSelectordefaultValue.data.level7.split(',') : filtersSelectordefaultValue.data.level7
            filtersSelectordefaultValueArrayLevel8 = filtersSelectordefaultValue.data.level8 ? filtersSelectordefaultValue.data.level8.split(',') : filtersSelectordefaultValue.data.level8
            filtersSelectordefaultValueArrayLevel9 = filtersSelectordefaultValue.data.level9 ? filtersSelectordefaultValue.data.level9.split(',') : filtersSelectordefaultValue.data.level9
            filtersSelectordefaultValueArrayLevel10 = filtersSelectordefaultValue.data.level10 ? filtersSelectordefaultValue.data.level10.split(',') : filtersSelectordefaultValue.data.level10
        }
        var levelDescriptionDefaultvalue = [filtersSelectordefaultValueArrayLevel1, filtersSelectordefaultValueArrayLevel2, filtersSelectordefaultValueArrayLevel3, filtersSelectordefaultValueArrayLevel4, filtersSelectordefaultValueArrayLevel5,
            filtersSelectordefaultValueArrayLevel6, filtersSelectordefaultValueArrayLevel7, filtersSelectordefaultValueArrayLevel8, filtersSelectordefaultValueArrayLevel9, filtersSelectordefaultValueArrayLevel10]
        dispatch(putDriverLevels(levelDescriptionDefaultvalue));
        let tempData2 = {}
        if (levelFiltersData) {
            Object.keys(levelFiltersData).forEach((level) => {
                let filteredArray1 = [];
                levelFiltersData[level].forEach(filter => {
                    if (filter) {
                        switch (level) {
                            case "1":
                                if (filtersSelectordefaultValueArrayLevel1 && (filtersSelectordefaultValueArrayLevel1.includes(filter[0]) || filtersSelectordefaultValueArrayLevel1.includes(' ' + filter[0]))) {
                                    if (filter[0] != "") {
                                        filteredArray1.push({ value: filter[0], label: filter[1] });
                                    }
                                }
                                break;
                            case "2":
                                if (filtersSelectordefaultValueArrayLevel2 && (filtersSelectordefaultValueArrayLevel2.includes(filter[0]) || filtersSelectordefaultValueArrayLevel2.includes(' ' + filter[0]))) {
                                    if (filter[0] != "") {
                                        filteredArray1.push({ value: filter[0], label: filter[1] });
                                    }
                                }
                                break;
                            case "3":
                                if (filtersSelectordefaultValueArrayLevel3 && (filtersSelectordefaultValueArrayLevel3.includes(filter[0]) || filtersSelectordefaultValueArrayLevel3.includes(' ' + filter[0]))) {
                                    if (filter[0] != "") {
                                        filteredArray1.push({ value: filter[0], label: filter[1] });
                                    }
                                }
                                break;
                            case "4":
                                if (filtersSelectordefaultValueArrayLevel4 && (filtersSelectordefaultValueArrayLevel4.includes(filter[0]) || filtersSelectordefaultValueArrayLevel4.includes(' ' + filter[0]))) {
                                    if (filter[0] != "") {
                                        filteredArray1.push({ value: filter[0], label: filter[1] });
                                    }
                                }
                                break;

                            default:
                                break;
                        }
                    }
                })
                tempData2[level] = filteredArray1;
            });

            setSelectedLevelFilter(tempData2);
        }
    }, [levelFiltersData, filtersSelectordefaultValue])

    useEffect(() => {
        const { isLoading, data } = poiFiltersSelector;
        if (!isLoading && data) {
            setClientPoiFilters(data);
        }
    }, [poiFiltersSelector]);

    useEffect(() => {
        const filters = [];
        clientPoiFilters.forEach(item => {
            filters.push(item.filterType)
        });
        setPoiFilters(filters);
    }, [clientPoiFilters]);

    useEffect(() => {

        dispatch(insertDate(new Date()));
        dispatch(fetchFilters());
    }, [dispatch])


    const onLayerFilterChange = (values) => {
        setPoiFilters(values.value);
    }

    const onLevelFilterChange = (selected, level) => {
        let options = { ...selectedLevelFilters };
        options[level] = selected;
        setSelectedLevelFilter(options);
    }


    return (
        <div className={styles.root}>
            {/* for now default location drawer not needed but can be used for further enhancement */}

            {
                isSideDrawerOpen && (
                    <LevelFilters
                        key={0}
                        defaultData={filtersSelectordefaultValue}
                        prismicData={prismicData}
                        data={levelFiltersData}
                        isSideDrawerOpen={isSideDrawerOpen}
                        setIsSideDrawerOpen={setIsSideDrawerOpen}
                        onLayerFilterChange={onLayerFilterChange}
                        poiFilters={poiFilters}
                        onLevelFilterChange={onLevelFilterChange}
                        levelFilter={selectedLevelFilters}
                    />
                )
            }

        </div>
    )
};

export default React.memo(UserProfileSetting);