import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../../Providers/authProvider";

export const PrivateRoute = ({
  component: NewComp,
  user,
  functionName,
  path,
  error,
  isPrivate,
  ...rest
}) => {
  const authContext = useContext(AuthContext);

  if (isPrivate && !authContext.isAuthenticated) {
    return <Navigate to="/login" />;
  } else if (authContext.isAuthenticated && path === "/login") {
    return <Navigate to="/" />;
  }

  return <NewComp {...rest} />;
};
