import { MAP_FILTERS } from '../Constants/mapFilters'

export const insertPOIFilters = (params) => ({
    type: MAP_FILTERS.POI_FILTER_INSERT,
    payload: params
});

export const insertMapStyle = (params) => ({
    type: MAP_FILTERS.MAP_STYLE_INSERT,
    payload: params
});

export const openSettingDrawer = (params) => ({
    type: MAP_FILTERS.OPEN_SETTING_DRAWER,
    payload: params
});
