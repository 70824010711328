import React from 'react';
import PropTypes from 'prop-types';
//import shop from '../../../Asset/MapIcons/shop.png';
import oval from '../../../Asset/MapIcons/Oval.png';
import electric from '../../../Asset/MapIcons/poi-electric.svg';
import client from '../../../Asset/MapIcons/poi-client.svg';
import nationalAccount from '../../../Asset/MapIcons/poi-shop.svg';
import gas from '../../../Asset/MapIcons/poi-gas.svg';
import { Icon} from 'leaflet';
import { useSelector, useDispatch } from 'react-redux';
import {Marker, useMap, Tooltip, Popup, FeatureGroup} from 'react-leaflet';
import useFetchPOIDataUsingZoom from '../../../Common/CustomHook/useFetchPOIDataUsingZoom';

const nationalAccountMarker = new Icon({
    iconUrl: oval,
    iconSize: [15, 15],
    tooltipAnchor: [20, 0],
})

const nationalAccountMarkerOnHover = new Icon({
    iconUrl: oval,
    iconSize: [20, 20],
    tooltipAnchor: [20, 0],
})

const MarkersPOI = ({onMarkerClick, clientNumber}) => {
    const map = useMap();

    const {isLoading, apiData, serverError} = useFetchPOIDataUsingZoom(
        clientNumber,
        "post",
        "/point-of-interest/get-pois-in-box/paged",
        map,
        1000
    );

    const onMarkerHover = (e) => {
        const icon = e.target.getIcon();
        icon.options.iconSize = [35,35];
        e.target.setIcon(icon);
    }

    const onMarkerHoverOut = (e) => {
        const icon = e.target.getIcon();
        icon.options.iconSize = [30,30];
        e.target.setIcon(icon);
    }

    const getPoiMarker = (poiType) => {
        let icon = ''
        switch(poiType) {
            case 'ALL_EVCS': 
                icon = electric
                break;
            case 'ALL_NAS': 
                icon = nationalAccount
                break;
            case 'ALL_FS': 
                icon = gas
                break;
            case 'ALL_DS': 
            case 'AMZ_DS': 
            case 'LP_DL': 
                icon = client
                break;
            default:
                icon = client
        }

        return new Icon({
            iconUrl: icon,
            iconSize: [30, 30],
            tooltipAnchor: [20, 0],
        })
    }


    return (
        <div>
              {apiData?.content?.map((data, i) => {
                  return(
                    <Marker
                    key={i}
                    position={
                        [
                            data.poiLatitude,
                            data.poiLongitude
                        ]
                    }
                    draggable={false}
                    icon={getPoiMarker(data.poiType)}
                    eventHandlers={{
                        click: (e) => {
                            onMarkerClick(e, data)
                        },
                        mouseover: (e) => {
                            onMarkerHover(e)
                        },
                        mouseout: (e) => {
                            onMarkerHoverOut(e)
                        }
                        }}
                        zIndexOffset={-1000}
                >
                </Marker>
                )}
              )}
        </div>
    )
}

MarkersPOI.propTypes = {

}

export default React.memo(MarkersPOI);
