import CONSTANTS from "../Constants/DriverEvents";


export const fetchDriverEvents = (params) => ({
    type: CONSTANTS.FETCH_DRIVER_EVENTS,
    payload: params,
});

export const removeDriverEvents = (params) => ({
  type: CONSTANTS.REMOVE_DRIVER_EVENTS,
  payload: params,
});
  
export const resetDriverEvents = () => ({
    type: CONSTANTS.RESET_DRIVER_EVENTS
});
  

export default {
  fetchDriverEvents,
  resetDriverEvents
};
