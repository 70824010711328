import { S, F } from "../utils/actions";
import {DRIVERLOCATION} from '../Constants/driverLocation'


//Status can be LIVE or OLD representing whether the data is real time or not
const INITIAL_STATE = {
  
  Location: null,
};

const driverLocation = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case DRIVERLOCATION.driver_Location:
          return { 
              ...state,
            };
        case S(DRIVERLOCATION.driver_Location):
            return { 
                ...state,
                    Location: action.payload,
               
        };
        default:
          return state;
      }
}

export default driverLocation;