import CONSTANTS from "../Constants/tripAdmin";

export const fetchTrips = (params) => ({
    type: CONSTANTS.FETCH_TRIP_ADMIN,
    payload: params
});

export const fetchDriverSearch = (params) => ({
    type: CONSTANTS.FETCH_DRIVER_SEARCH,
    payload: params
});

export const fetchEligibleDrivers = (params) => ({
    type: CONSTANTS.FETCH_ELIGIBLE_DRIVERS,
    payload: params
});

export const updateProjectCode = (params) => ({
    type: CONSTANTS.UPDATE_PROJECT_CODE,
    payload: params
});

export const resetUpdateStatusProjectCode = () => ({
    type: CONSTANTS.RESET_UPDATE_STATUS_PROJECT_CODE
});