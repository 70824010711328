import CONSTANTS from '../Constants/vehicle';
import { F, S } from "../utils/actions";

const initialState = {
    details: {
        data: {},
        isLoading: false,
        error: null
    },
    poolDrivers: {
        data: [],
        isLoading: false,
        error: null
    },
    vehicleHealth: {
        data: {},
        isLoading: false,
        error: null,
    },
    vehicleMaintenance: {
        data: {},
        isLoading: false,
        error: null,
    },
    vehicleMaintenanceHistory: {
        data: {},
        isLoading: false,
        error: null,
    },
    vehicleUtilization: {
        data: {},
        isLoading: false,
        error: null,
    },
    voltageEvents: {
        data: {},
        isLoading: false,
        error: null,
    },
    locationMaskingEvents: {
        data: {},
        isLoading: false,
        error: null,
    },
    setSelectedEvents: {
        data: null
    },
    setFuelMaintenanceHistory: {
        data: []
    }
};

const vehicle = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case CONSTANTS.FETCH_VEHICLE_INFO:
            return {
                ...state,
                details: {
                    ...state.details,
                    isLoading: true,
                    error: null
                }
            };
        case S(CONSTANTS.FETCH_VEHICLE_INFO):
            return {
                ...state,
                details: {
                    data: payload,
                    isLoading: false,
                    error: null
                }
            };
        case F(CONSTANTS.FETCH_VEHICLE_INFO):
            return {
                ...state,
                details: {
                    ...state.details,
                    isLoading: false,
                    error: payload
                }

            };
        case CONSTANTS.FETCH_POOL_VEHICLE_DRIVERS:
            return {
                ...state,
                poolDrivers: {
                    ...state.poolDrivers,
                    isLoading: true,
                    error: null
                }
            };
        case S(CONSTANTS.FETCH_POOL_VEHICLE_DRIVERS):
            return {
                ...state,
                poolDrivers: {
                    data: payload,
                    isLoading: false,
                    error: null
                }
            };
        case F(CONSTANTS.FETCH_POOL_VEHICLE_DRIVERS):
            return {
                ...state,
                poolDrivers: {
                    ...state.poolDrivers,
                    isLoading: false,
                    error: payload
                }

            };
        case CONSTANTS.FETCH_LOCATION_MASKING_EVENTS:
            return {
                ...state,
                locationMaskingEvents: {
                    ...state.voltageEvents,
                    isLoading: true,
                    error: null
                }
            };
        case S(CONSTANTS.FETCH_LOCATION_MASKING_EVENTS):
            return {
                ...state,
                locationMaskingEvents: {
                    data: payload,
                    isLoading: false,
                    error: null
                }
            };
        case F(CONSTANTS.FETCH_LOCATION_MASKING_EVENTS):
            return {
                ...state,
                locationMaskingEvents: {
                    data: {},
                    isLoading: false,
                    error: payload
                }

            };
        case CONSTANTS.SET_SELECTED_EVENTS_ID:
            return {
                ...state,
                setSelectedEvents: {
                    ...state.setSelectedEvents,
                    isLoading: true,
                    error: null
                }
            };
        case S(CONSTANTS.SET_SELECTED_EVENTS_ID):
            return {
                ...state,
                setSelectedEvents: {
                    data: payload,
                    isLoading: false,
                    error: null
                }
            };
        case CONSTANTS.SET_FUEL_MAINTENANCE_HISTORY:
            return {
                ...state,
                setFuelMaintenanceHistory: {
                    ...state.setFuelMaintenanceHistory,
                    isLoading: true,
                    error: null
                }
            };
        case S(CONSTANTS.SET_FUEL_MAINTENANCE_HISTORY):
            return {
                ...state,
                setFuelMaintenanceHistory: {
                    data: payload,
                    isLoading: false,
                    error: null
                }
            };

        case CONSTANTS.FETCH_VOLTAGE_EVENTS:
            return {
                ...state,
                voltageEvents: {
                    ...state.voltageEvents,
                    isLoading: true,
                    error: null
                }
            };
        case S(CONSTANTS.FETCH_VOLTAGE_EVENTS):
            return {
                ...state,
                voltageEvents: {
                    data: payload,
                    isLoading: false,
                    error: null
                }
            };
        case F(CONSTANTS.FETCH_VOLTAGE_EVENTS):
            return {
                ...state,
                voltageEvents: {
                    data: {},
                    isLoading: false,
                    error: payload
                }

            };

        case CONSTANTS.FETCH_VEHICLE_HEALTH:
            return {
                ...state,
                vehicleHealth: {
                    ...state.vehicleHealth,
                    isLoading: true,
                    error: null
                }
            };
        case S(CONSTANTS.FETCH_VEHICLE_HEALTH):
            return {
                ...state,
                vehicleHealth: {
                    data: payload,
                    isLoading: false,
                    error: null
                }
            };
        case F(CONSTANTS.FETCH_VEHICLE_HEALTH):
            return {
                ...state,
                vehicleHealth: {
                    data: {},
                    isLoading: false,
                    error: payload
                }

            };

        case CONSTANTS.FETCH_VEHICLE_MAINTENANCE:
            return {
                ...state,
                vehicleMaintenance: {
                    ...state.vehicleMaintenance,
                    isLoading: true,
                    error: null
                }
            };
        case S(CONSTANTS.FETCH_VEHICLE_MAINTENANCE):
            return {
                ...state,
                vehicleMaintenance: {
                    data: payload,
                    isLoading: false,
                    error: null
                }
            };
        case F(CONSTANTS.FETCH_VEHICLE_MAINTENANCE):
            return {
                ...state,
                vehicleMaintenance: {
                    data: {},
                    isLoading: false,
                    error: payload
                }

            };

        case CONSTANTS.FETCH_VEHICLE_MAINTENANCE_HISTORY:
            return {
                ...state,
                vehicleMaintenanceHistory: {
                    ...state.vehicleMaintenanceHistory,
                    isLoading: true,
                    error: null
                }
            };
        case S(CONSTANTS.FETCH_VEHICLE_MAINTENANCE_HISTORY):
            return {
                ...state,
                vehicleMaintenanceHistory: {
                    data: payload,
                    isLoading: false,
                    error: null
                }
            };
        case F(CONSTANTS.FETCH_VEHICLE_MAINTENANCE_HISTORY):
            return {
                ...state,
                vehicleMaintenanceHistory: {
                    data: {},
                    isLoading: false,
                    error: payload
                }

            };

        case CONSTANTS.FETCH_VEHICLE_UTILIZATION:
            return {
                ...state,
                vehicleUtilization: {
                    data: {},
                    isLoading: true,
                    error: null
                }
            };
        case S(CONSTANTS.FETCH_VEHICLE_UTILIZATION):
            return {
                ...state,
                vehicleUtilization: {
                    data: payload,
                    isLoading: false,
                    error: null
                }
            };
        case F(CONSTANTS.FETCH_VEHICLE_UTILIZATION):
            return {
                ...state,
                vehicleUtilization: {
                    data: {},
                    isLoading: false,
                    error: payload
                }

            };
        case CONSTANTS.RESET_VEHICLE_INFO:
            return {
                ...state,
            }
        case S(CONSTANTS.RESET_VEHICLE_INFO):
            return {
                details: {
                    data: {},
                    isLoading: false,
                    error: null
                },
                poolDrivers: {
                    data: [],
                    isLoading: false,
                    error: null
                },
                vehicleHealth: {
                    data: {},
                    isLoading: false,
                    error: null,
                },
                vehicleMaintenance: {
                    data: {},
                    isLoading: false,
                    error: null,
                },
                vehicleMaintenanceHistory: {
                    data: {},
                    isLoading: false,
                    error: null,
                }
            }
        default:
            return state;
    }
};

export default vehicle;