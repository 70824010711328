import React from 'react';
import styles from './List.module.scss'
import {
    Heading,
  } from "@leaseplan/ui";

const List = (props) => {
    const { data, selectedListItem, onClick } = props;
    return (
        data.map((row) => {
            return (
                <div className={row.key === selectedListItem ? styles.selectedListItem : styles.listItem} onClick={() => onClick(row.key)}>
                    <Heading component="p" size={'xxs'}>{row.label}</Heading>
                </div>
                
            )
        })
    )
}

export default List;