import CONSTANTS from '../Constants/driver';
import { F, S } from "../utils/actions";

const initialState = {
    initialDriverDetails: {
        deviceId: null,
        selectedDate: null,
        toTime: null,
        selectedEvent: null,
    },
    details: {
        data: {},
        isLoading: false,
        error: null
    },
    courses: {
        data: [],
        isLoading: false,
        error: null
    },
    trips: {
        data: [],
        isLoading: false,
        error: null
    },
    communication: {
        data: [],
        isLoading: false,
        error: null
    },
    events: {
        data: [],
        isLoading: false,
        error: null
    },
    serviceEvents: {
        data: [],
        isLoading: true,
        error: null
    },
    oneScore: {
        data: {},
        isLoading: false,
        error: null
    }
};

const driverDetails = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {

        case CONSTANTS.INSERT_INITIAL_DRIVER_DETAILS:
            return {
                ...state,
                initialDriverDetails: {
                    ...state.initialDriverDetails,
                },
            }
        case S(CONSTANTS.INSERT_INITIAL_DRIVER_DETAILS):
            return {
                ...state,
                initialDriverDetails: {
                    ...state.initialDriverDetails,
                    deviceId: payload.deviceId,
                    selectedDate: payload.selectedDate,
                    toTime: payload.toTime ? payload.toTime : `${payload.selectedDate} 24:00:00`,
                    getTrips: payload.getTrips
                },
            }
        case CONSTANTS.INSERT_TO_TIME:
            return {
                ...state,
                initialDriverDetails: {
                    ...state.initialDriverDetails
                }
            }
        case S(CONSTANTS.INSERT_TO_TIME):
            return {
                ...state,
                initialDriverDetails: {
                    ...state.initialDriverDetails,
                    toTime: payload
                }
            }
        case CONSTANTS.INSERT_FROM_TIME:
            return {
                ...state,
                initialDriverDetails: {
                    ...state.initialDriverDetails
                }
            }
        case S(CONSTANTS.INSERT_FROM_TIME):
            return {
                ...state,
                initialDriverDetails: {
                    ...state.initialDriverDetails,
                    fromTime: payload
                }
            }
        case CONSTANTS.INSERT_TO_EVENT:
            return {
                ...state,
                initialDriverDetails: {
                    ...state.initialDriverDetails
                }
            }
        case S(CONSTANTS.INSERT_TO_EVENT):
            return {
                ...state,
                initialDriverDetails: {
                    ...state.initialDriverDetails,
                    selectedEvent: payload
                }
            }
        case CONSTANTS.FETCH_DRIVER_DETAILS:
            return {
                ...state,
                details: {
                    ...state.details,
                    isLoading: true,
                    error: null
                }
            };
        case S(CONSTANTS.FETCH_DRIVER_DETAILS):
            return {
                ...state,
                details: {
                    data: payload,
                    isLoading: false,
                    error: null
                }
            };
        case F(CONSTANTS.FETCH_DRIVER_DETAILS):
            return {
                ...state,
                details: {
                    ...state.details,
                    isLoading: false,
                    error: payload
                }
            };
        case CONSTANTS.RESET_DRIVER_DETAILS:
            return {
                ...state,
                details: {
                    data: {},
                    isLoading: false,
                    error: null
                }
            };
        case CONSTANTS.FETCH_COURSES:
            return {
                ...state,
                courses: {
                    ...state.courses,
                    isLoading: true,
                    error: null
                }
            };
        case S(CONSTANTS.FETCH_COURSES):
            return {
                ...state,
                courses: {
                    data: payload,
                    isLoading: false,
                    error: null
                }
            };
        case F(CONSTANTS.FETCH_COURSES):
            return {
                ...state,
                courses: {
                    ...state.courses,
                    isLoading: false,
                    error: payload
                }
            };
        case CONSTANTS.FETCH_TRIPS:
            return {
                ...state,
                trips: {
                    ...state.trips,
                    isLoading: true,
                    error: null
                }
            };
        case S(CONSTANTS.FETCH_TRIPS):
            return {
                ...state,
                trips: {
                    data: payload,
                    isLoading: false,
                    error: null
                }
            };
        case F(CONSTANTS.FETCH_TRIPS):
            return {
                ...state,
                trips: {
                    ...state.trips,
                    isLoading: false,
                    error: payload
                }
            };

        case CONSTANTS.FETCH_LOG_RECORD_TRIPS:
            return {
                ...state,
                fetchLogRecordTrips: {
                    ...state.fetchLogRecordTrips,
                    // data: payload,
                    isLoading: true,
                    error: null
                }
            };
        case S(CONSTANTS.FETCH_LOG_RECORD_TRIPS):
            return {
                ...state,
                fetchLogRecordTrips: {
                    data: payload,
                    isLoading: false,
                    error: null
                }
            };
        case F(CONSTANTS.FETCH_LOG_RECORD_TRIPS):
            return {
                ...state,
                fetchLogRecordTrips: {
                    ...state.fetchLogRecordTrips,
                    // data: payload,
                    isLoading: false,
                    error: payload
                }
            };

        case CONSTANTS.FETCH_DEVICE_LAST_LOCATION:
            return {
                ...state,
                fetchDeviceLastLocation: {
                    ...state.fetchDeviceLastLocation,
                    // data: payload,
                    isLoading: true,
                    error: null
                }
            };
        case S(CONSTANTS.FETCH_DEVICE_LAST_LOCATION):
            return {
                ...state,
                fetchDeviceLastLocation: {
                    data: payload,
                    isLoading: false,
                    error: null
                }
            };
        case F(CONSTANTS.FETCH_DEVICE_LAST_LOCATION):
            return {
                ...state,
                fetchDeviceLastLocation: {
                    ...state.fetchDeviceLastLocation,
                    // data: payload,
                    isLoading: false,
                    error: payload
                }
            };

        case CONSTANTS.FETCH_SINGLE_DEVICE_LAST_LOCATION:
            return {
                ...state,
                fetchSingleDeviceLastLocation: {
                    // ...state.fetchSingleDeviceLastLocation,
                    // data: payload,
                    isLoading: true,
                    error: null
                }
            };
        case S(CONSTANTS.FETCH_SINGLE_DEVICE_LAST_LOCATION):
            return {
                ...state,
                fetchSingleDeviceLastLocation: {
                    data: payload,
                    isLoading: false,
                    error: null
                }
            };
        case F(CONSTANTS.FETCH_SINGLE_DEVICE_LAST_LOCATION):
            return {
                ...state,
                fetchSingleDeviceLastLocation: {
                    data: null,
                    isLoading: false,
                    error: payload
                }
            };

        case (CONSTANTS.FETCH_SINGLE_LOG_RECORD_TRIPS):
            return {
                ...state,
                fetchSingleLogRecordTrips: {
                    data: payload,
                    isLoading: false,
                    error: null
                }
            };

        case CONSTANTS.FETCH_COMMUNICATION:
            return {
                ...state,
                communication: {
                    ...state.communication,
                    isLoading: true,
                    error: null
                }
            };
        case S(CONSTANTS.FETCH_COMMUNICATION):
            return {
                ...state,
                communication: {
                    data: payload,
                    isLoading: false,
                    error: null
                }
            };
        case F(CONSTANTS.FETCH_COMMUNICATION):
            return {
                ...state,
                communication: {
                    ...state.communication,
                    isLoading: false,
                    error: payload
                }
            };
        case CONSTANTS.FETCH_EVENTS:
            return {
                ...state,
                events: {
                    ...state.events,
                    isLoading: true,
                    error: null
                }
            };
        case S(CONSTANTS.FETCH_EVENTS):
            return {
                ...state,
                events: {
                    data: payload,
                    isLoading: false,
                    error: null
                }
            };
        case F(CONSTANTS.FETCH_EVENTS):
            return {
                ...state,
                events: {
                    data: null,
                    isLoading: false,
                    error: payload
                }
            };
        case CONSTANTS.FETCH_SERVICE_EVENTS:
            return {
                ...state,
                serviceEvents: {
                    ...state.serviceEvents,
                    isLoading: true,
                    error: null
                }
            };
        case S(CONSTANTS.FETCH_SERVICE_EVENTS):
            return {
                ...state,
                serviceEvents: {
                    data: payload,
                    isLoading: false,
                    error: null
                }
            };
        case F(CONSTANTS.FETCH_SERVICE_EVENTS):
            return {
                ...state,
                serviceEvents: {
                    data: null,
                    isLoading: false,
                    error: payload
                }
            };
        case CONSTANTS.FETCH_ONE_SCORE:
            return {
                ...state,
                oneScore: {
                    ...state.oneScore,
                    isLoading: true,
                    error: null
                }
            };
        case S(CONSTANTS.FETCH_ONE_SCORE):
            return {
                ...state,
                oneScore: {
                    data: payload,
                    isLoading: false,
                    error: null
                }
            };
        case F(CONSTANTS.FETCH_ONE_SCORE):
            return {
                ...state,
                oneScore: {
                    ...state.oneScore,
                    isLoading: false,
                    error: payload
                }
            };
        default:
            return state;
    }
};

export default driverDetails;