import React, { useState, useEffect } from 'react';
import styles from './TripAdmin.module.scss';
import {
    Heading,
    ChevronLeftIcon,
    ChevronRightIcon,
    Button
} from "@leaseplan/ui";

import { ReactComponent as Download } from '../../Asset/InsightIcons/Download.svg';
import { SvgIcon, InputBase } from '@material-ui/core';
import TableWrapperOneConnect from '../../Common/TableWrapperOneConnect/TableWrapperOneConnect';
import { TableConstants } from './Table/TableConstant';
import TripAdminRow from './Table/TripAdminRow';
import { useSelector, useDispatch } from 'react-redux';
import { fetchTripAdminFilters } from '../../Actions/filters.action';
import Filters from '../OneConnectCommon/Filters/Filters';
import { removeSpaceFromString, subtractTime, timezoneOffSet } from '../../utils';
import SearchIcon from '@material-ui/icons/Search';
import _ from "lodash";
import CircularProgress from '@material-ui/core/CircularProgress';
import { LPLoader } from '../../Common/LPLoader/LPLoader';
import FilterDisplayer from '../Search/FilterDisplayer/FilterDisplayer';
import { getLabel } from '../../utils';
import UpdateTrips from './UpdateTrips/UpdateTrips';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import CustomDateRangePicker from '../../Common/CustomDateRangePicker/CustomDateRangePicker';
import moment from "moment";
import { fetchTrips, fetchEligibleDrivers, resetUpdateStatusProjectCode } from '../../Actions/tripAdmin.action';
import { fetchDataUsingCancelToken } from '../../AxiosInterceptor/fetchDataUsingCacelToken';
import { baseURL } from '../../Store/base-url';
import { addToast } from '../../Actions/toasts';
import { isPanningMap, logRecordLoadingStatusWorker } from '../../Actions/level.action';
import { fetchDeviceLastLocation, fetchLogRecordTrips, insertInitialFromTime } from '../../Actions/driver.action';
import { generatePath, useNavigate } from 'react-router-dom';
import { Icon } from '@velocity/ui.Icon';
import { RoutePath } from '../../Routers/Routes';

const generateRequestPayload = (
    filteredList = {},
    start = 1,
    limit,
    fieldOrder = [],
    mainSearch = {}
) => {
    return {
        driverVehicleMainSearchInput: {
            name: mainSearch.name ? mainSearch.name : '',
            type: mainSearch.type ? mainSearch.type : '',
        },
        driverVehicleFilterCriteriaInput: {
            //event: filteredList.event ? filteredList.event : [],
            vehicleUsage: filteredList.vehicleUsage ? filteredList.vehicleUsage : [],
            status: filteredList.status ? filteredList.status : [],
            make: filteredList.make ? filteredList.make : [],
            model: filteredList.model ? filteredList.model : [],
            year: filteredList.year ? filteredList.year : [],
        },
        sortCriteriaInput: fieldOrder.length > 0 ? [fieldOrder[0]] : [],
        pagingCriteriaInput: {
            pageNumber: start,
            pageSize: limit
        },
        eventFilters: filteredList["event"] ? filteredList["event"] : [],
    };
};

const generateQueryStringForUrl = (start = 1, limit, fieldOrder = [],) => {
    let queryString = '';
    queryString += 'page=' + start;
    queryString += '&size=' + limit;
    if (fieldOrder.length)
        queryString += '&sort=' + fieldOrder[0].field + ',' + fieldOrder[0].order
    return queryString;
}

const TripAdmin = props => {
    const { prismicData } = props;
    const [tableData, setTableData] = useState({
        ...TableConstants
    })

    const [filtersData, setFiltersData] = useState({});
    const [formattedFiltersData, setFormattedFiltersData] = useState({});
    const [selectedFilters, setSelectedFilters] = useState({});
    const [globalSearch, setGlobalSearch] = useState('');
    const [searchString, setSearchString] = useState('');
    const [debouncedValue, setDebouncedValue] = useState('');
    const timelineSelector = useSelector(state => state.timeline.clockData);
    const authSelector = useSelector(state => state.auth);


    //ForPagination
    const PAGE_LIMIT = 20
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(PAGE_LIMIT);
    const [isHidden, setIsHidden] = useState(false);
    const [clearAll, setClearAll] = useState(false);
    const [fieldOrder, setFieldOrder] = useState([]);
    const [exportLoading, setExportLoading] = useState(false);
    const [selectedTrips, setSelectedTrips] = useState({});
    const [isSideDrawerOpen, setIsSideDrawerOpen] = useState(false);
    //const [trips, setTrips] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedDateRange, setSelectedDateRange] = useState({
        from: moment().toDate(),
        to: moment().toDate()
    });
    const [potentialDrivers, setPotentialDrivers] = useState([]);
    //Selectors
    const filtersSelector = useSelector(state => state.filters.tripAdmin);
    const tripsSelector = useSelector(state => state.tripAdmin.trips);

    const potentialDriversSearch = useSelector(state => state.tripAdmin.driverSearch);
    const potentialDriversSelector = useSelector(state => state.tripAdmin.drivers);
    const updateProjectStatusSelector = useSelector(state => state.tripAdmin.updateProjectCode);

    const dispatch = useDispatch();
    const history = useNavigate();

    const dispatchForTrips = (pageNumber, pageLimit, fieldOrder = [], globalSearch = '', filters = {}) => {
        const fromDate = moment(selectedDateRange.from).format('YYYY-MM-DD');
        const toDate = moment(selectedDateRange.to).format('YYYY-MM-DD');
        const dateFromUTC = timezoneOffSet(fromDate, '00:00:00');
        const dateToUTC = timezoneOffSet(toDate, '23:59:59');

        let requestObj = {
            clientNumber: authSelector.user.authUser.clientNumber,
            dateFrom: dateFromUTC,
            dateTo: dateToUTC,
            globalSearch: globalSearch,
            sortCriteriaInput: fieldOrder.length > 0 ? [fieldOrder[0]] : [{ field: "startTime", order: "desc" }],
            pagingCriteriaInput: {
                pageNumber: pageNumber,
                pageSize: pageLimit
            },
            // queryString: generateQueryStringForUrl(pageNumber, pageLimit, fieldOrder)
        }
        requestObj.vehicleUsage = filters.vehicleUsage ? filters.vehicleUsage : [];
        requestObj.make = filters.make ? filters.make : [];
        requestObj.model = filters.model ? filters.model : [];
        requestObj.year = filters.year ? filters.year : [];
        requestObj.projectCode = filters.projectCode ? filters.projectCode : [];

        dispatch(fetchTrips(requestObj));
    }

    useEffect(() => {
        dispatchForTrips(currentPage, rowsPerPage, fieldOrder, globalSearch, selectedFilters);
    }, [selectedDateRange]);

    useEffect(() => {

        const data = { ...tableData }
        data.columns.map((row, index) => {
            if (row.checkbox) {
                row['onSelected'] = (event) => headerCheckbox(event, tripsSelector)
            }
            return row
        });
        // setTrips([...TableConstants.rows])
        setTableData(data);
    }, [tripsSelector]);

    useEffect(() => {
        const { isLoading, data } = potentialDriversSelector;
        const drivers = []
        if (data.length > 0) {
            data?.forEach((item) => {
                let obj = {
                    name: `${item.firstName} ${item.lastName}`,
                    value: item.driverRecordNumber
                }

                drivers.push(obj);
            })
        }
        setPotentialDrivers(drivers);
    }, [potentialDriversSelector]);

    useEffect(() => {
        dispatch(fetchDeviceLastLocation([false]));

        //set the url for hardrefreshing functionality #444
        sessionStorage.setItem("urlEndPoint", window.location.pathname)

        dispatch(fetchLogRecordTrips(false));
        let filters = {}
        const { isUpdated } = updateProjectStatusSelector;
        if (isUpdated) {
            const fromDate = moment(selectedDateRange.from).format('YYYY-MM-DD');
            const toDate = moment(selectedDateRange.to).format('YYYY-MM-DD');
            const dateFromUTC = timezoneOffSet(fromDate, '00:00:00');
            const dateToUTC = timezoneOffSet(toDate, '23:59:59');
            headerCheckbox({ checked: false }); // Clearing selected filters after api call success
            // setGlobalSearch('');
            // setSearchString('');
            let requestObj = {
                clientNumber: authSelector.user.authUser.clientNumber,
                dateFrom: dateFromUTC,
                dateTo: dateToUTC,
                globalSearch: globalSearch,
                sortCriteriaInput: fieldOrder.length > 0 ? [fieldOrder[0]] : [{ field: "startTime", order: "desc" }],
                pagingCriteriaInput: {
                    pageNumber: currentPage,
                    pageSize: rowsPerPage
                },
                // queryString: generateQueryStringForUrl(currentPage, rowsPerPage, fieldOrder),

            }
            requestObj.vehicleUsage = selectedFilters.vehicleUsage ? selectedFilters.vehicleUsage : [];
            requestObj.make = selectedFilters.make ? selectedFilters.make : [];
            requestObj.model = selectedFilters.model ? selectedFilters.model : [];
            requestObj.year = selectedFilters.year ? selectedFilters.year : [];
            requestObj.projectCode = selectedFilters.projectCode ? selectedFilters.projectCode : [];

            dispatch(fetchTrips(requestObj));


            dispatch(resetUpdateStatusProjectCode());
        }
    }, [updateProjectStatusSelector]);

    useEffect(() => {
        // if(tableData.rows){
        //     setTrips(tableData.rows)
        // }
    }, [tableData]);

    useEffect(() => {
        const { isLoading, data } = filtersSelector;

        if (!isLoading && data) {
            setFiltersData(data);
        }
    }, [filtersSelector]);

    useEffect(() => {
        dispatch(fetchTripAdminFilters());
        dispatch(logRecordLoadingStatusWorker(false));
        dispatch(insertInitialFromTime());
        dispatch(isPanningMap(false))
    }, [dispatch]);


    useEffect(() => {
        if (filtersData.make) {
            const getFilters = (key) => {
                const filters = []
                const filterItem = filtersData[key];
                filterItem.forEach(row => {
                    let obj = {}
                    obj['label'] = row;
                    obj['value'] = row;
                    obj['checked'] = false;
                    filters.push(obj);
                });

                return filters;
            }

            const finalData = {};
            /* This is because we want the filters in this order only (usage, status, year, make, model) 
                and after that levels
            */
            finalData['vehicleUsage'] = getFilters('vehicleUsage');
            finalData['year'] = getFilters('year');
            finalData['make'] = getFilters('make');
            finalData['model'] = getFilters('model');
            finalData['projectCode'] = getFilters('projectCode');

            setFormattedFiltersData(finalData);
        }
    }, [filtersData]);

    useEffect(() => {
        // let newTableData = { ...tableData };
        // const field_order = [];
        // for(let i=0; i< newTableData.columns.length; i++){
        //     if(newTableData.columns[i].sortEnabled){
        //         if(newTableData.columns[i].initialSort){
        //             field_order.unshift({field: newTableData.columns[i].field, order: newTableData.columns[i].sort})
        //             continue;
        //         }
        //         field_order.push({field: newTableData.columns[i].field, order: newTableData.columns[i].sort})
        //     }
        // };

        // dispatch(fetchDriverInsightsPageInfo(
        //     generateRequestPayload(
        //         selectedFilters,
        //         1,
        //         rowsPerPage,
        //         field_order,
        //         globalSearch
        //     )
        // ));

        // setFieldOrder(field_order);
    }, [])

    useEffect(() => {
        setClearAll(false);
    }, [clearAll]);

    useEffect(() => {
        // API call goes here
        dispatchForTrips(1, rowsPerPage, fieldOrder, searchString, selectedFilters);

        setGlobalSearch(searchString);
        setCurrentPage(1);
    }, [debouncedValue]);

    const onPageOrPageLimitChange = async (pageNumber = 1, rowsPerPage = PAGE_LIMIT) => {
        setCurrentPage(pageNumber);
        setRowsPerPage(rowsPerPage);
        setSelectedTrips({});
        dispatchForTrips(pageNumber, rowsPerPage, fieldOrder, globalSearch, selectedFilters);
    }

    const handleOnPageChange = (e, newPage) => {
        onPageOrPageLimitChange(newPage + 1, rowsPerPage);
    }

    const handleOnRowPerPageChange = (e) => {
        onPageOrPageLimitChange(1, parseInt(e.target.value, 10))
    }

    const onSortColumn = async (
        field_name,
        direction = "asc",
        rowsPerPage = PAGE_LIMIT
    ) => {
        let newTableData = { ...tableData };
        newTableData.columns = newTableData.columns.map((col, index) => {
            if (col.sortEnabled && field_name === col.field)
                return {
                    ...col,
                    sort: direction,
                };
            return col;
        });

        let field_order = [...fieldOrder];

        field_order.splice(field_order.findIndex(a => a.field === field_name), 1)
        field_order.unshift({ field: field_name === 'driverName' ? 'lastName' : field_name, order: direction });
        setFieldOrder(field_order);
        dispatchForTrips(currentPage, rowsPerPage, field_order, globalSearch, selectedFilters)
        setTableData(newTableData);

        setCurrentPage(1);
        setRowsPerPage(rowsPerPage)
    };

    const toggleSideBar = () => {
        setIsHidden((isHidden => !isHidden));
    }

    const onFiltersChanged = (name, heading, checked) => {
        headerCheckbox({ checked: false });
        let globalFilters = { ...formattedFiltersData };
        globalFilters[heading] = globalFilters[heading].map(el => (el.value === name ? { ...el, checked: checked } : el));
        let value = []
        for (let item of globalFilters[heading]) {
            if (item.checked) {
                value.push(item.value);
            }
        }
        const filters = { ...selectedFilters };
        if (!heading.startsWith('level')) {
            filters[heading] = value;
        }
        else {
            filters[removeSpaceFromString(heading)] = name;
        }
        dispatchForTrips(1, rowsPerPage, fieldOrder, globalSearch, filters)
        setCurrentPage(1);
        setSelectedFilters(filters);
        setFormattedFiltersData(globalFilters);
    }



    const onTripSelected = (id, event) => {
        const selectedTripsTemp = { ...selectedTrips };
        if (event.checked) {
            selectedTripsTemp[id] = event.checked;
        } else {
            delete selectedTripsTemp[id];
        }

        setSelectedTrips(selectedTripsTemp);
    }

    const exportsTrips = async () => {
        setExportLoading(true);

        const fromDate = moment(selectedDateRange.from).format('YYYY-MM-DD');
        const toDate = moment(selectedDateRange.to).format('YYYY-MM-DD');
        const dateFromUTC = timezoneOffSet(fromDate, '00:00:00');
        const dateToUTC = timezoneOffSet(toDate, '23:59:59');

        const { cancelPrevQuery, result, error } = await fetchDataUsingCancelToken(
            baseURL,
            "json",
            '/trip-admin-export',
            'post',
            {
                clientNumber: authSelector.user.authUser.clientNumber,
                dateFrom: dateFromUTC,
                dateTo: dateToUTC,
                globalSearch: globalSearch,
                vehicleUsage: selectedFilters.vehicleUsage ? selectedFilters.vehicleUsage : [],
                make: selectedFilters.make ? selectedFilters.make : [],
                model: selectedFilters.model ? selectedFilters.model : [],
                year: selectedFilters.year ? selectedFilters.year : [],
                projectCode: selectedFilters.projectCode ? selectedFilters.projectCode : [],
            },
        );

        if (cancelPrevQuery) return;

        if (result) {
            setExportLoading(false);
            window.open(result.url);
        } else if (error) {
            setExportLoading(false);
        }
    }

    const selectedDateRangeHandler = (date) => {
        if (new Date(date.to) > new Date() || new Date(date.from) > new Date()) {
            dispatch(addToast({
                title: "Future dates are not supported",
                type: "error",
            }));
            return;
        }

        if (date.to) {
            let diffTime = subtractTime(date.to, date.from)
            if (diffTime < -3888000000) {
                dispatch(addToast({
                    title: "Select dates range upto 45 days",
                    type: "error",
                }));
                return;
            }
        } else {
            let diffTime = subtractTime(new Date(), date.from)
            if (diffTime < -3888000000) {
                dispatch(addToast({
                    title: "Select dates range upto 45 days",
                    type: "error",
                }));
                return;
            }
        }

        setSelectedDateRange(date);
        setIsOpen(open => !open);
        setCurrentPage(1);
    }

    const toggleCalendarIsOpen = () => {
        setIsOpen(open => !open);
    }

    const handleSearchStringChange = (event) => {
        setSearchString(event.target.value);
    };

    const moveToDashboard = () => {
        history({
            pathname: generatePath(RoutePath.Dashboard)
        });
    }

    const searchData = _.debounce((event) => {
        setDebouncedValue(event.target.value);
    }, 2000);


    const updateTrips = () => {
        setIsSideDrawerOpen(true)
    }

    const headerCheckbox = (event, tripsSelector) => {
        const selectedTrips = {};
        if (event.checked) {
            tripsSelector.data.forEach(trip => {
                selectedTrips[trip.tripUid] = event.checked;
            });
        }

        setSelectedTrips(selectedTrips);
    }

    return (
        <div className={styles.mainWrapper}>
            {
                isSideDrawerOpen && (
                    <UpdateTrips
                        prismicData={prismicData}
                        allTrips={tripsSelector.data}
                        selectedTrips={selectedTrips}
                        isSideDrawerOpen={isSideDrawerOpen}
                        setIsSideDrawerOpen={setIsSideDrawerOpen}
                        // potentialDrivers={potentialDrivers}
                        potentialDriversSearch={potentialDriversSearch}
                    />
                )
            }
            <div className={styles.topHeader}>
                <div className={styles.heading}>
                    <Heading component="p" size="s" color="petrolBlue" gutter={false}>{getLabel("trip_administration", "Trip Administration", prismicData)}</Heading>
                </div>

                <div className={styles.row}>
                    <div className={styles.searchDiv}>
                        <div className={styles.left}>
                            <SearchIcon htmlColor={'#767878'} />
                            <InputBase
                                className={styles.searchTextInput}
                                value={searchString}
                                onChange={handleSearchStringChange}
                                style={{ width: '100%' }}
                                inputProps={{ maxLength: 100 }}
                                onKeyDown={searchData}
                            />
                        </div>
                    </div>
                    <div className={styles.rightSide}>
                        {Object.keys(selectedTrips).length ? <Button
                            size="s"
                            onClick={(event) => {
                                updateTrips();
                            }}
                        >
                            {`Update ${Object.keys(selectedTrips).length} ${Object.keys(selectedTrips).length > 1 ? 'trips' : 'trip'} `}
                        </Button> : null}

                        <div className={styles.downloadIcon}>
                            {exportLoading ? <CircularProgress /> : <SvgIcon className={styles.svgStyle} onClick={() => exportsTrips()}>
                                <Download />
                            </SvgIcon>}
                        </div>

                        <CalendarTodayIcon htmlColor={'#4a4a4a'} onClick={() => setIsOpen(!isOpen)} className={styles.calendarIcon} />
                        <div className={styles.calendarIconDate} onClick={() => setIsOpen(!isOpen)}>
                            {moment(selectedDateRange.from).format('DD')}
                        </div>
                        <button type="button" id={"drawer_close"} onClick={moveToDashboard} className={styles.CloseButton}>
                            <Icon name="close" size="s" />
                        </button>
                    </div>

                </div>


            </div>
            <div className={styles.lploader}>
                {(tripsSelector.isLoading) && (
                    <LPLoader
                        loading={tripsSelector.isLoading}
                        message={"Loading Data ..."}
                    />
                )}
            </div>
            {
                isHidden && (
                    <div className={styles.sideDrawer}>
                        {!filtersSelector.isLoading ? (
                            <div className={styles.filterBody}>
                                {
                                    Object.keys(formattedFiltersData).map(item => {
                                        return (
                                            <Filters
                                                keys={item}
                                                heading={item}
                                                data={formattedFiltersData[item] || []}
                                                onFilterChange={onFiltersChanged}
                                                clearAll={clearAll}
                                            />
                                        )
                                    })
                                }
                            </div>
                        ) : (
                            <div className={styles.filterLoader}>
                                <LPLoader
                                    loading={filtersSelector.isLoading}
                                    message={"Loading Trip Admin Filters"}
                                />
                            </div>
                        )
                        }
                    </div>
                )
            }
            <div className={styles.isHiddenIcon} onClick={toggleSideBar}>
                {/* <Paper elevation={2} styles={styles.isHiddenIcon}> */}
                {
                    isHidden ? (
                        <ChevronLeftIcon />
                    ) : (
                        <ChevronRightIcon />
                    )
                }
                {/* </Paper> */}
            </div>
            <div className={styles.mainTable}>
                <TableWrapperOneConnect
                    currentPage={currentPage}
                    rowsPerPage={rowsPerPage}
                    columns={tableData.columns}
                    currentRowLength={tripsSelector.data.length}
                    resultSetCount={tripsSelector.resultSetCount ? tripsSelector.resultSetCount : 0}
                    isLoading={tripsSelector.isLoading}
                    onSortColumn={onSortColumn}
                    handleOnPageChange={handleOnPageChange}
                    handleOnRowPerPageChange={handleOnRowPerPageChange}
                    maxHeight={'calc(100vh - 420px)'}
                    minHeight={'calc(100vh - 420px)'}
                >
                    {
                        tripsSelector.data.length > 0 && tripsSelector.data.map((row, index) => {
                            return (
                                <TripAdminRow key={index} id={row.tripUid} rowData={row} onSelected={onTripSelected} selectedTrips={selectedTrips} />
                            )
                        })
                    }
                </TableWrapperOneConnect>
            </div>

            {
                isOpen && (
                    <CustomDateRangePicker
                        currentDateRange={selectedDateRange}
                        onSelectedDateRange={selectedDateRangeHandler}
                        onToggleCalendarIsOpen={toggleCalendarIsOpen}
                        isGraphType={false}
                    />
                )
            }
        </div>
    )
}

TripAdmin.propTypes = {

}

export default TripAdmin;
