import { S, F } from "../utils/actions";
import { MAP_FILTERS } from '../Constants/mapFilters'


//Different Filters present for the map
const INITIAL_STATE = {
    poiFilter: {
        filter: [],
    },
    levelFilter: {
        filter: [],
    },
    openSettingDrawer: {
        data: false,
    }
};

const mapFilters = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MAP_FILTERS.POI_FILTER_INSERT:
            return {
                ...state,
            };
        case S(MAP_FILTERS.POI_FILTER_INSERT):
            return {
                ...state,
                poiFilter: {
                    filter: action.payload,
                }
            };
        case MAP_FILTERS.MAP_STYLE_INSERT:
            return {
                mapStyle: {
                    filter: null,
                }
            };
        case S(MAP_FILTERS.MAP_STYLE_INSERT):
            return {
                ...state,
                mapStyle: {
                    filter: action.payload,
                }
            };
        case MAP_FILTERS.OPEN_SETTING_DRAWER:
            return {
                openSettingDrawer: {
                    data: null,
                }
            };
        case S(MAP_FILTERS.OPEN_SETTING_DRAWER):
            return {
                ...state,
                openSettingDrawer: {
                    data: action.payload,
                }
            };
        default:
            return state;
    }
}

export default mapFilters;