import Sockets from "../Constants/sockets";

const INITIAL_STATE = {
    connected: false,
    channel: null,
    socketInfo: null,
    message: null
};

export default function (state = INITIAL_STATE, action) {
  // console.log('REDUCER', action);

  switch (action.type) {
    case Sockets.CONNECTED:
      return { ...state, connected: action.payload };
    case Sockets.CHANNEL_JOINED:
        return { 
            ...state, 
            channel: action.payload 
        };
    case Sockets.SOCKET_INFO:
      return { 
          ...state, 
          socketInfo: action.payload 
      };
    case Sockets.INCOMING_MESSAGE: 
      return {
        ...state,
        message: action.payload
      }
    default:
      return state;
  }
}
