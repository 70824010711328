import React from "react";
import { ResponsiveContainer } from "recharts";
import styles from "./Graph.module.scss";
import GraphPictogramIcon from "../../../Asset/Icons/GraphPictogram.svg";
import { Text } from "@leaseplan/ui";

export const NoDataGraph = ({ height, width, key }) => {
    return (
      <div className={styles.container}>
        <ResponsiveContainer
          key={key}
          width={width}
          height={height}
          className={styles.graphArea}
        >
          <img
            src={GraphPictogramIcon}
            alt="graphPictogram Icon"
            className={styles.graphPictogramIcon}
          />
          <Text
            component="p"
            size={"xs"}
            fontWeight="bold"
            color={"petrolBlue"}
            className={styles.graphPictogramText}
            gutter={false}
          >
            {"Data could not be collected"}
          </Text>
        </ResponsiveContainer>
      </div>
    );
};

export default React.memo(NoDataGraph);
