import axios from "../AxiosInterceptor";
import { call, put, takeEvery } from "redux-saga/effects";
import User from "../Constants/user";
import { baseURL } from "../Store/base-url";
import { F, S } from "../utils/actions";

const url = "/mm/api/users";
function postUserApi(params) {
  return axios.request({
    baseURL: baseURL,
    responseType: "json",
    url: url,
    data: params,
    method: "post",
  });
}

export function* postUserWorker(action) {
  try {
    let { data } = yield call(postUserApi, action.payload);
    if (data.payload) {
      yield put({ type: S(action.type), payload: data.payload });
    } else {
      // No entry exist
      yield put({ type: F(action.type), payload: {} });
    }
  } catch (e) {
    yield put({ type: F(action.type), error: e });
  }
}


function* userSaga() {
    yield takeEvery(User.POST_USER, postUserWorker);
    yield takeEvery(User.VALIDATE_USER, postUserWorker);
}


export default userSaga;