import React, { useEffect, useState } from 'react';
import styles from './PopupFilter.module.scss';

import { Toggle } from "react-toggle-component";
import { Grid, GridItem } from '@leaseplan/ui';
import { useSelector } from 'react-redux';
import { set } from 'lodash';


const ToggleFilterComponent = (props) => {
    const { heading, data, onFilterChange, defaultValue, values } = props;
    const [value, setValue] = useState(data);
    var objIndex = {}

    const onChange = (values) => {
        onFilterChange([values.name, values.checked])
    }


    if (value && value[0].label) {
        if (sessionStorage.getItem("fleet_overview") == "false" || sessionStorage.getItem("fleet_overview") == "true") {
            objIndex = value.findIndex((obj => obj.value == "fleet_overview"));
            value[objIndex].checked = sessionStorage.getItem("fleet_overview") == "true" ? "true" : ""
        }
        if (sessionStorage.getItem("latest_events") == "false" || sessionStorage.getItem("latest_events") == "true") {
            objIndex = value.findIndex((obj => obj.value == "latest_events"));
            //Update object's  property.
            value[objIndex].checked = sessionStorage.getItem("latest_events") == "true" ? "true" : ""
        }
    }
    // if condition to take session storage as priority.
    if (value && value[0].filterType) {
        if (sessionStorage.getItem("ALL_NAS") == "false" || sessionStorage.getItem("ALL_NAS") == "true") {
            var objIndex = value.findIndex((obj => obj.filterType == "ALL_NAS"));
            //Update object's  property.
            value[objIndex].checked = sessionStorage.getItem("ALL_NAS") == "true" ? "true" : ""
        }
        if (sessionStorage.getItem("ALL_EVCS") == "false" || sessionStorage.getItem("ALL_EVCS") == "true") {
            var objIndex = value.findIndex((obj => obj.filterType == "ALL_EVCS"));
            //Update object's  property.
            value[objIndex].checked = sessionStorage.getItem("ALL_EVCS") == "true" ? "true" : ""
        }
        if (sessionStorage.getItem("ALL_FS") == "false" || sessionStorage.getItem("ALL_FS") == "true") {
            var objIndex = value.findIndex((obj => obj.filterType == "ALL_FS"));
            //Update object's  property.
            value[objIndex].checked = sessionStorage.getItem("ALL_FS") == "true" ? "true" : ""
        }

    }

    return (
        <div className={styles.popupContent}>
            <Grid>

                {value && (
                    value.map((row, index) => {
                        // console.log(row.checked)
                        return (
                            <GridItem
                                span={{ mobile: 12, tablet: 12 }}
                            >
                                <label>
                                    <Toggle
                                        leftBackgroundColor="#bbbbbb"
                                        rightBackgroundColor="#004a5d"
                                        checked={row.checked}
                                        //   borderColor="black"
                                        knobColor="white"
                                        name={row.value ? row.value : row.filterType}
                                        onToggle={e => onChange(e.target)}
                                    />
                                    {row.label ? row.label : row.filterLabel}
                                </label>
                            </GridItem>
                        )
                    })
                )}
            </Grid>
        </div>
    )
}

export default React.memo(ToggleFilterComponent);