const VEHICLE = {
    FETCH_VEHICLE_INFO: "FETCH_VEHICLE_INFO",
    FETCH_POOL_VEHICLE_DRIVERS: "FETCH_POOL_VEHICLE_DRIVERS",
    FETCH_VOLTAGE_EVENTS: "FETCH_VOLTAGE_EVENTS",
    FETCH_LOCATION_MASKING_EVENTS: "FETCH_LOCATION_MASKING_EVENTS",
    SET_SELECTED_EVENTS_ID: "SET_SELECTED_EVENTS_ID",
    SET_FUEL_MAINTENANCE_HISTORY: "SET_FUEL_MAINTENANCE_HISTORY",
    FETCH_VEHICLE_HEALTH: "FETCH_VEHICLE_HEALTH",
    FETCH_VEHICLE_MAINTENANCE: "FETCH_VEHICLE_MAINTENANCE",
    FETCH_VEHICLE_MAINTENANCE_HISTORY: "FETCH_VEHICLE_MAINTENANCE_HISTORY",
    FETCH_VEHICLE_UTILIZATION: "FETCH_VEHICLE_UTILIZATION",
    RESET_VEHICLE_INFO: "RESET_VEHICLE_INFO",
};

export default VEHICLE;