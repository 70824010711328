import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import L from "leaflet";
import { useMap, Circle, Marker, Polyline } from 'react-leaflet';
import RotatingMarker from './RotatingMarker';
import { render } from "react-dom";
import {
    carIcon, breadscrumbs, postedSpeedIcon, harshAccelerationIcon, idlingIconBig, idlingIcon, warningMaxSpeedIcon, seatBeltIcon, hardBrakingIcon, reverseIcon,
    postedSpeedIconBig, hardBrakingIconBig, warningMaxSpeedIconBig, seatBeltIconBig, reverseIconBig, harshAccelerationIconBig, clockInOutIconBig, clockInOutIcon, ticketYellowIconBig,
    ticketYellowIcon, ticketRedIconBig, ticketRedIcon, ticketGreenIconBig, ticketGreenIcon, locationMaskingGreenIcon, locationMaskingGreenIconBig, voltageYellowIcon, voltageYellowIconBig
} from '../MarkerIcon/MarkerIcon';
import { closestLocationforlogs, getArrayIndexByDateTime, moveToTheLocation, timezoneOffSetSub } from '../../../utils';
import PolyLineTooltip from '../VehiclesPopupAndTooltip/PolyLineTooltip';
import EventsTooltip from '../VehiclesPopupAndTooltip/EventsTooltip';

// import "leaflet-arrowheads";
import "leaflet-polylinedecorator";
import { simplifyPath } from '../SimplifyPolyline';

const radians_to_degrees = rad => (rad * 180.0) / Math.PI;

const bearingBetweenLocations = (destinationCoords, sourceCoords) => {
    const PI = Math.PI;
    const lat1 = parseFloat(sourceCoords[0]) * PI / 180;
    const long1 = parseFloat(sourceCoords[1]) * PI / 180;
    const lat2 = parseFloat(destinationCoords[0]) * PI / 180;
    const long2 = parseFloat(destinationCoords[1]) * PI / 180;
    const dLon = (long2 - long1);
    const y = Math.sin(dLon) * Math.cos(lat2);
    const x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);
    let bearingAngle = (radians_to_degrees(Math.atan2(y, x)) + 360 + 180) % 360;
    return bearingAngle;
}

const genratePolyLineData = (logRecordData, apiData) => {
    let result = []
    const deviceId = apiData[0].deviceId;
    const deviceName = apiData[0].deviceName;
    const clientVehicleId = apiData[0].clientVehicleId;
    const timezoneShortName = apiData[0].timezoneShortName;
    result.push({ deviceId, logRecordData, deviceName, clientVehicleId, timezoneShortName })
    return result
}

const polylineColorAndIcon = (type, isSelectedEvent, color) => {
    // console.log(isSelectedEvent)
    switch (type) {
        case 'clock_in': // pin
            return { color: '#DBFF33', icon: isSelectedEvent == 'true' ? clockInOutIconBig : clockInOutIcon };
        case 'clock_out': // pin
            return { color: '#DBFF33', icon: isSelectedEvent == 'true' ? clockInOutIconBig : clockInOutIcon };
        case 'ticket': // pin
            if (!color) { return { color: '#DBFF33', icon: isSelectedEvent == 'true' ? ticketYellowIconBig : ticketYellowIcon } }
            else if (color == 'Green') { return { color: '#DBFF33', icon: isSelectedEvent == 'true' ? ticketGreenIconBig : ticketGreenIcon } }
            else if (color == 'Red') { return { color: '#DBFF33', icon: isSelectedEvent == 'true' ? ticketRedIconBig : ticketRedIcon } };
            break;
        case 'posted_speed': // segment
            return { color: '#FF5733', icon: isSelectedEvent == 'true' ? postedSpeedIconBig : postedSpeedIcon };
        case 'harsh_acceleration': // pin
            return { color: '#DBFF33', icon: isSelectedEvent == 'true' ? harshAccelerationIconBig : harshAccelerationIcon };
        case 'idling': // pin
            return { color: '#75FF33', icon: isSelectedEvent == 'true' ? idlingIconBig : idlingIcon };
        case 'max_speed': // segment
            return { color: '#33FF57', icon: isSelectedEvent == 'true' ? warningMaxSpeedIconBig : warningMaxSpeedIcon };
        case 'seatbelt': // pin
            return { color: '#33FFBD', icon: isSelectedEvent == 'true' ? seatBeltIconBig : seatBeltIcon };
        case 'hard_braking': // pin
            return { color: '#C70039', icon: isSelectedEvent == 'true' ? hardBrakingIconBig : hardBrakingIcon };
        case 'reverse': // based on distance
            return { color: '#900C3F', icon: isSelectedEvent == 'true' ? reverseIconBig : reverseIcon };
        case 'location_masking': // based on distance
            return { color: '#900C3F', icon: isSelectedEvent == 'true' ? locationMaskingGreenIconBig : locationMaskingGreenIcon };
        case 'low_voltage': // based on distance
            return { color: '#900C3F', icon: isSelectedEvent == 'true' ? voltageYellowIconBig : voltageYellowIcon };
        // case  'normal': // based on distance
        // return {color: (isSelectedEvent == -1) ? '#f06400' : '#000000'};
        default:
            return { color: '#004a5d' }
    }
}

const findTimeRange = (deviceId, apiData, vehicleData, fromTime, selectStartTime, toTime, events, date, eventUid, levelFilteredDrivers, eventSelectedIdSelector) => {

    let polylinePathBeforeToTime = []
    let initialEvent = events;
    let lastCoord = [];
    let lastLocation = [];
    let eventList = [];
    let panMap = [];
    let eventIcon = [];
    let data = vehicleData;
    let startTime = fromTime;
    let lastCoordIndex = -1;
    let firstCoord;
    let selectedRangeCords = [];


    for (let i = 0; i < events.length; i++) {
        if (events[i].ruleId === "clock_in" || events[i].ruleId === "clock_out" || events[i].ruleId === "harsh_acceleration" || events[i].ruleId === "hard_braking") {
            if (apiData && apiData[0].logRecordData && apiData[0].logRecordData.length > 0) {
                const activeFromTimeUTC = timezoneOffSetSub(events[i].activeFrom.split(" ")[0], events[i].activeFrom.split(" ")[1], events[i].utcOffset);
                let geoLocationForClockInOut = moveToTheLocation(activeFromTimeUTC, apiData)
                events[i].startLat = (geoLocationForClockInOut[0].logRecordData[0].latitude) * 1;
                events[i].startLon = (geoLocationForClockInOut[0].logRecordData[0].longitude) * 1;

            }

        }
        //conditions to remove the icons for events as per the filter button presentin events
        if (levelFilteredDrivers.removeEventsFromDisplay && levelFilteredDrivers.removeEventsFromDisplay.length > 0) {
            levelFilteredDrivers.removeEventsFromDisplay.map((data, i) => {
                if (data === 'ticket_green') {
                    events = events.filter((item) => item.color !== 'Green');
                }
                else if (data === 'ticket') {
                    events = events.filter((item) => item.ruleId === 'ticket' ? item.color !== null : item.color !== 'Red');
                    events = events.filter((item) => item.color !== 'Red');
                } else {
                    events = events.filter((item) => item.ruleId !== data);
                }
            })
        }
        //  eventList.push({ type: 'normal', fromTime: startTime, toTime: events[i].activeFrom })
        if (events && events.length > 0) {
            eventList.push({
                type: events[i].ruleId,
                fromTime: events[i].activeFrom,
                toTime: events[i].activeTo ? events[i].activeTo : events[i].activeFrom,
                isSelectedEvent: (events[i].ruleId == 'low_voltage' || events[i].ruleId == 'location_masking') ? (events[i].eventUid === eventSelectedIdSelector?.data?.eventUid) ? 'true' : eventUid && events[i].eventUid === eventUid ? 'true' : 'false' : eventUid && events[i].eventUid === eventUid ? 'true' : 'false',
                startLat: events[i].startLat ? events[i].startLat : events[i].latitude,
                startLon: events[i].startLon ? events[i].startLon : events[i].longitude,
                lastLocation: eventUid && events[i].eventUid === eventUid ? ([events[i].matchLatitude ? events[i].matchLatitude : 'null', events[i].matchLongitude ? events[i].matchLongitude : 'null']) : 'null',
                color: events[i].color,
                eventData: events[i]
            })

            startTime = events[i].activeTo ? events[i].activeTo : events[i].activeFrom;
        }
    }
    eventList.splice(0, 0, { type: 'normal', fromTime: startTime, toTime: toTime })

    if (data && fromTime && toTime) {
        let formattedFromTime = new Date(fromTime);
        let formattedToTime = new Date(toTime);
        let currentLength = 0;
        let totalLength = data.length;
        for (let i = 0; i < eventList.length; i++) {
            let coords = [];
            let formattedEventFromTime = new Date(eventList[i].fromTime);
            let formattedEventToTime = new Date(eventList[i].toTime);
            while (currentLength < totalLength) {
                let currentTime = new Date(data[currentLength].dateTime);
                // console.log(currentTime)
                if (
                    (currentTime >= formattedFromTime && currentTime <= formattedToTime) ||
                    (currentTime >= formattedEventFromTime && currentTime < formattedEventToTime)
                ) {

                    lastCoordIndex = currentLength;

                }
                coords.push([data[currentLength].latitude, data[currentLength].longitude]);
                currentLength++;
            }
            if (eventList[i].lastLocation && eventList[i].lastLocation != 'null') { lastLocation = eventList[i].lastLocation }

            polylinePathBeforeToTime.push({
                type: eventList[i].type,
                coords: eventList[i].type !== 'normal' ? [eventList[i].startLat ? eventList[i].startLat : null, eventList[i].startLon ? eventList[i].startLon : null] : coords,
                isSelectedEvent: eventList[i].isSelectedEvent ? eventList[i].isSelectedEvent : false,
                lastLocation: eventList[i].lastLocation,
                color: eventList[i].color,
                Data: eventList[i].eventData
            })

            if (eventList[i].isSelectedEvent == 'true' && eventList[i].startLat && eventList[i].startLon && currentLength > 0) {
                panMap = [eventList[i].startLat, eventList[i].startLon];
                let { color, icon } = polylineColorAndIcon(eventList[i].type, eventList[i].isSelectedEvent, eventList[i].color)
                eventIcon = icon

            }
        }
    }

    let bearingAngle = 0;

    if (lastCoordIndex > 1) {
        lastCoord = [data[lastCoordIndex].latitude, data[lastCoordIndex].longitude];
        let sourceCoords = [data[lastCoordIndex - 1].latitude, data[lastCoordIndex - 1].longitude];
        bearingAngle = bearingBetweenLocations(lastCoord, sourceCoords);
    } else if (lastCoordIndex === 1) {
        lastCoord = [data[lastCoordIndex].latitude, data[lastCoordIndex].longitude];
    }

    if (panMap.length !== 2) {
        panMap = lastCoord;
    }

    if (selectStartTime) {
        firstCoord = moveToTheLocation(selectStartTime, apiData)
        let getStartTimeIndex = getArrayIndexByDateTime(selectStartTime, apiData)
        let getEndTimeIndex = getArrayIndexByDateTime(toTime, apiData)
        for (let i = getStartTimeIndex[0]; i <= getEndTimeIndex[0] - 1; i++) {
            selectedRangeCords.push([data[i].latitude, data[i].longitude]);
        }
    }

    return { polylinePathBeforeToTime, lastCoord, lastLocation, bearingAngle, panMap, eventIcon, firstCoord, selectedRangeCords };
}

const pathStyle = { stroke: true, lineCap: 'round' }



const SingleDrivermarker = ({ children, clientVehicleId, events = [], serviceEvents = [], deviceId, vehicleData = [], apiData, clientNumber, deviceName, date, isLoading, fuelEventLocation }) => {
    const [coords, setCoords] = useState({
        deviceId: "",
        polylinePathBeforeToTime: [],
        lastCoord: [],
        bearingAngle: null,
        lastLocation: []
    });
    const [breadscrumbsDeactive, setBreadCrumsDeactive] = useState(true)

    const eventSelectedIdSelector = useSelector(state => state.vehicle.setSelectedEvents);
    const initalSelectedDriverData = useSelector((state) => state.driver.initialDriverDetails);
    const levelFilteredDrivers = useSelector(state => state.levels);
    const driverDetailsSelector = useSelector(state => state.driver.details);
    const map = useMap();
    let popup = L.popup()
    let container = L.DomUtil.create('div');
    let getArrowsMarker
    let polylineDecorator

    //for testin, need to find some alternative way
    useEffect(() => {
        map.on('zoomend', () => {
            if (window.location.pathname.split("/").length != 2) {
                let zoom = map._zoom;
                let returnZoomLevel
                switch (zoom) {

                    case 14:
                        let id = sessionStorage.getItem("getArrowsMarker._leaflet_id")
                        setBreadCrumsDeactive(true)
                        map.eachLayer(function (getArrowsMarker) {
                            let id2 = getArrowsMarker._leaflet_id
                            if (id == id2) {
                                map.removeLayer(getArrowsMarker);
                            }
                        });
                        break
                    case 15:
                        returnZoomLevel = '.00001'
                        if (breadscrumbsDeactive) {
                            arrowMarkerOnPolyline(returnZoomLevel)
                            setBreadCrumsDeactive(false)
                        }
                        break
                    default:
                        returnZoomLevel = '.00001'
                        if (zoom > 15 && breadscrumbsDeactive) {
                            arrowMarkerOnPolyline(returnZoomLevel)
                            setBreadCrumsDeactive(false)
                        }
                }
            }
        })
    }, [map, apiData])



    useEffect(() => {
        const { polylinePathBeforeToTime, firstCoord, lastCoord, lastLocation, bearingAngle, panMap, eventIcon, selectedRangeCords } = findTimeRange(deviceId, apiData, vehicleData, `${date} 00:00:00`, initalSelectedDriverData.fromTime, initalSelectedDriverData.toTime, events, date, initalSelectedDriverData.selectedEvent, levelFilteredDrivers, eventSelectedIdSelector);

        if (fuelEventLocation) {
            map.setView(fuelEventLocation, 14);
        } else if (panMap.length === 2) {
            map.setView([panMap[0], (parseFloat(panMap[1]) - 0.030).toString()], 14);
        }
        setCoords({ deviceId, polylinePathBeforeToTime, lastCoord, lastLocation, firstCoord, bearingAngle, panMap, eventIcon, selectedRangeCords });




        return () => {
            setCoords({
                ...coords,
                polylinePathBeforeToTime: [],
                lastCoord: [],
                lastLocation: [],
                firstCoord: [],
                selectedRangeCords: [],
                panMap: [],
                eventIcon: null,
                bearingAngle: null
            })
        }
    }, [isLoading, initalSelectedDriverData.toTime, initalSelectedDriverData.selectedEvent, events.length, vehicleData.length, levelFilteredDrivers.removeEventsFromDisplay])



    //function related to getarrow marker groups starts here ====>

    function getArrows(arrLatlngs, color, arrowCount, mapObj) {

        if (typeof arrLatlngs === undefined || arrLatlngs == null ||
            (!arrLatlngs.length) || arrLatlngs.length < 2)
            return [];

        if (typeof arrowCount === 'undefined' || arrowCount == null)
            arrowCount = 1;

        if (typeof color === 'undefined' || color == null)
            color = '';
        else
            color = 'color:' + color;


        let result = [];
        for (let i = 1; i < arrLatlngs.length; i++) {
            for (let c = 1; c <= arrowCount; c++) {
                result.push(L.marker(arrLatlngs[i], { icon: breadscrumbs }));
            }
        }
        return result;
    }

    //getarrow marker groups function ends here <=====

    //changes for visualisation of polylines
    const arrowMarkerOnPolyline = (tolerance) => {
        let simplfiedArrow = []
        const logRecordData = simplifyPath(apiData[0].logRecordData, tolerance ? tolerance : '.0001');
        // const logRecordData = apiData[0].logRecordData
        for (const element of logRecordData) {
            simplfiedArrow.push([element.latitude, element.longitude]);
        }
        let id = sessionStorage.getItem("getArrowsMarker._leaflet_id")
        map.eachLayer(function (getArrowsMarker) {
            let id2 = getArrowsMarker._leaflet_id
            if (id == id2) {
                map.removeLayer(getArrowsMarker);
            }
        });
        getArrowsMarker = L.featureGroup(getArrows(simplfiedArrow, 'red', 1, map)).addTo(map);
        sessionStorage.setItem("getArrowsMarker._leaflet_id", getArrowsMarker._leaflet_id)
        getArrowsMarker.on('mouseover', function (e) {
            let getLocation = closestLocationforlogs(e.latlng, apiData)
            let getDriverDetails = genratePolyLineData(getLocation, apiData)
            popup.setLatLng(e.latlng).openOn(map);
            render(
                <PolyLineTooltip leafletLatLng={e.latlng} clientVehicleId={clientVehicleId} Location={getDriverDetails} driverDetails={driverDetailsSelector.data} />,
                container,
            );
            popup.setContent(container).setLatLng(e.latlng).update();
        });
    }
    const addTripLine = (data, color) => {
        let polyLine
        let polyLineProperties = {
            color: color,
            opacity: 1,
            weight: 4,
            clickable: true
        }

        if (polyLine) {
            polyLine.setMap(null)
        }
        polyLine = L.polyline(data, polyLineProperties);

        // polyLine.on('mouseover', function() {
        //     // WHAT TO DO HERE to HIGHLIGHT that specific polyline.

        // })
        let id = sessionStorage.getItem("polylineDecorator._leaflet_id")
        map.eachLayer(function (polylineDecorator) {
            let id2 = polylineDecorator._leaflet_id
            if (id == id2) {
                map.removeLayer(polylineDecorator);
            }
        });

        polylineDecorator = new L.polylineDecorator(polyLine, {
            patterns: [{
                offset: 25,
                repeat: 50,
                symbol: L.Symbol.arrowHead({
                    pixelSize: 6,
                    pathOptions: {
                        fillOpacity: 1,
                        weight: 0,
                        color: 'White',
                    }
                })
            }]
        }).addTo(map);

        sessionStorage.setItem("polylineDecorator._leaflet_id", polylineDecorator._leaflet_id)
        // arrowMarkerOnPolyline('0.0001')
    }
    return (
        <>
            {
                coords.polylinePathBeforeToTime && coords.polylinePathBeforeToTime.map((data, i) => {
                    let { color, icon } = polylineColorAndIcon(data.type, data.isSelectedEvent, data.color);
                    if (data.coords.length > 0) {
                        return (
                            <>
                                {
                                    (coords.firstCoord && coords.polylinePathBeforeToTime.length > 0) && (
                                        <Circle center={[coords.firstCoord[0].logRecordData[0].latitude, coords.firstCoord[0].logRecordData[0].longitude]} pathOptions={{ fillColor: 'black' }} radius={75} />
                                    )
                                }
                                {data.type === "normal" && (
                                    addTripLine(data.coords, color)
                                )}

                                {data.type === "normal" && (

                                    <Polyline
                                        key={i}
                                        pathOptions={{
                                            ...pathStyle,
                                            color: color,
                                            weight: 4
                                        }}
                                        positions={data.coords}
                                    />
                                )}
                                {
                                    (coords.selectedRangeCords && coords.selectedRangeCords.length > 0) && (
                                        <Polyline
                                            // key={y}
                                            pathOptions={{
                                                ...pathStyle,
                                                color: '#f06400',
                                                zIndexOffset: '10000'
                                            }}
                                            positions={coords.selectedRangeCords}
                                        />
                                    )
                                }

                                {
                                    data.type !== 'normal' && data.coords.length > 0 && data.coords[0] != null &&
                                    <Marker
                                        icon={icon} position={data.coords} zIndexOffset={data.isSelectedEvent == "true" ? 20 : 0}
                                        eventHandlers={{
                                            click: (e) => {
                                                popup.setLatLng(e.latlng).openOn(map);
                                                render(
                                                    <EventsTooltip leafletLatLng={e.latlng} eventData={data.Data} />,
                                                    container,
                                                );
                                                popup.setContent(container).setLatLng(e.latlng).update();
                                            }
                                        }}>
                                    </Marker>
                                }
                            </>
                        )
                    }
                })
            }

            {
                coords.lastCoord.length === 2 && (
                    <RotatingMarker icon={carIcon} position={coords.lastLocation.length > 0 && coords.lastLocation[0] != 'null' ? coords.lastLocation : coords.lastCoord} rotationAngle={coords.bearingAngle} rotationOrigin={'center'} zIndexOffset={1000}>
                        {children}
                    </RotatingMarker>
                )
            }
        </>
    )
}

SingleDrivermarker.propTypes = {

}

export default React.memo(SingleDrivermarker)