const EVENTS_CONSTANTS = {
    INSERT_INITIAL_DRIVER_DETAILS: 'INSERT_INITIAL_DRIVER_DETAILS',
    INSERT_TO_TIME: 'INSERT_TO_TIME',
    INSERT_FROM_TIME: 'INSERT_FROM_TIME',
    INSERT_TO_EVENT: 'INSERT_TO_EVENT',
    FETCHING_DRIVER_DETAILS: 'FETCHING_DRIVER_DETAILS',
    FETCH_DRIVER_DETAILS: 'FETCH_DRIVER_DETAILS',
    RESET_DRIVER_DETAILS: 'RESET_DRIVER_DETAILS',
    FETCH_TRIPS: 'FETCH_TRIPS',
    FETCH_LOG_RECORD_TRIPS: 'FETCH_LOG_RECORD_TRIPS',
    FETCH_DEVICE_LAST_LOCATION:'FETCH_DEVICE_LAST_LOCATION',
    FETCH_SINGLE_DEVICE_LAST_LOCATION:'FETCH_SINGLE_DEVICE_LAST_LOCATION',
    FETCH_SINGLE_LOG_RECORD_TRIPS: 'FETCH_SINGLE_LOG_RECORD_TRIPS',
    FETCH_COURSES: 'FETCH_COURSES',
    FETCH_COMMUNICATION: 'FETCH_COMMUNICATION',
    FETCH_EVENTS: 'FETCH_EVENTS',
    FETCH_SERVICE_EVENTS: 'FETCH_SERVICE_EVENTS',
    FETCH_ONE_SCORE: 'FETCH_ONE_SCORE'
}

export default EVENTS_CONSTANTS;