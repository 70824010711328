import { S, F } from "../utils/actions";
import { VEHICLE_INSIGHTS_PAGE } from "../Constants/vehicleInsightsPage";

const INITIAL_STATE = {
  data: [],
  resultSetCount: null,
  pageLimit: null,
  currentPage: null,
  isLoading: false,
  error: null,
};

const vehicleInsightsPage = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case VEHICLE_INSIGHTS_PAGE.VEHICLE_INSIGHTS_PAGE_FETCH:
          return { 
                ...state,
                data: [],
                isLoading: true,
                error: null,
            };
        case S(VEHICLE_INSIGHTS_PAGE.VEHICLE_INSIGHTS_PAGE_FETCH):
            return { 
                data: action.data,
                resultSetCount: action.resultSetCount,
                pageLimit: action.pageLimit,
                currentPage: action.currentPage,
                isLoading: false,
              };
        case F(VEHICLE_INSIGHTS_PAGE.VEHICLE_INSIGHTS_PAGE_FETCH):
          return { 
                data: [],
                resultSetCount: null,
                pageLimit: null,
                currentPage: null,
                isLoading: false,
                error: action.payload,
          };
        default:
          return state;
      }
}

export default vehicleInsightsPage;