import axios from "../AxiosInterceptor";
import { call, put, takeEvery, select } from "redux-saga/effects";
import CONSTANT from "../Constants/filters";
import { baseURL } from "../Store/base-url";
import { F, S } from "../utils/actions";
import authService from '../Services/Auth/authService';

function getFiltersApi() {
  return axios.request({
    baseURL: baseURL,
    responseType: "json",
    url: `/dvl/labels`,
    method: "get",
  });
}

function fetchUserDefaultConfigApi() {
  return axios.request({
    baseURL: baseURL,
    responseType: "json",
    url: `client-config/get-user-default`,
    method: "get",
  });
}

function getPoiFiltersApi() {
  return axios.request({
    baseURL: baseURL,
    responseType: "json",
    url: `point-of-interest/get-client-filters`,
    method: "post",
    data: {}
  });
}

function getDriverInsightsFiltersApi() {
  return axios.request({
    baseURL: baseURL,
    responseType: "json",
    url: `driver-insights/retrieve-driver-insights-table-filters`,
    method: "get",
  });
}

function getVehicleInsightsFiltersApi() {
  return axios.request({
    baseURL: baseURL,
    responseType: "json",
    url: `retrieve-vehicle-insights-filters`,
    method: "get",
  });
}

function getTripAdminFiltersApi(clientNumber) {
  return axios.request({
    baseURL: baseURL,
    responseType: "json",
    url: `trip-admin-filter/${clientNumber}`,
    method: "get",
  });
}

export function* getFiltersWorker(action) {
  try {
    const { data } = yield call(getFiltersApi);
    yield put({ type: S(action.type), payload: data });
  } catch (e) {
    yield put({ type: F(action.type), error: e });
  }
}

export function* fetchUserDefaultConfigWorker(action) {
  try {
    const { data } = yield call(fetchUserDefaultConfigApi);
    yield put({ type: S(action.type), payload: data });
  } catch (e) {
    yield put({ type: F(action.type), error: e });
  }
}

export function* getPoiFiltersWorker(action) {
  try {
    const { data } = yield call(getPoiFiltersApi);

    const filters = [];
    data.forEach(item => {
      filters.push(item.filterType)
    });
    yield put({ type: S(action.type), payload: data });
  } catch (e) {
    yield put({ type: F(action.type), error: e });
  }
}

export function* getDriverInsightsFiltersWorker(action) {
  try {
    const { data } = yield call(getDriverInsightsFiltersApi);
    yield put({ type: S(action.type), payload: data });
  } catch (e) {
    yield put({ type: F(action.type), error: e });
  }
}

export function* getVehicleInsightsFiltersWorker(action) {
  try {
    const { data } = yield call(getVehicleInsightsFiltersApi);
    yield put({ type: S(action.type), payload: data });
  } catch (e) {
    yield put({ type: F(action.type), error: e });
  }
}

export function* getTripAdminFiltersWorker(action) {
  try {
    const clientNumber = yield select(state => state.auth.user.authUser.clientNumber)
    const { data } = yield call(getTripAdminFiltersApi, clientNumber);
    yield put({ type: S(action.type), payload: data });
  } catch (e) {
    yield put({ type: F(action.type), error: e });
  }
}

function* getPutPoiFiltersSession(action) {
  yield put({ type: S(action.type), payload: action.payload ? action.payload : [] })
}


function* filtersSaga() {
  yield takeEvery(CONSTANT.FETCH_FILTERS, getFiltersWorker);
  yield takeEvery(CONSTANT.FETCH_USER_DEFAULT_CONFIG, fetchUserDefaultConfigWorker);
  yield takeEvery(CONSTANT.FETCH_POI_FILTERS, getPoiFiltersWorker);
  yield takeEvery(CONSTANT.PUT_POI_FILTER_SESSION, getPutPoiFiltersSession);
  yield takeEvery(CONSTANT.FETCH_DRIVER_INSIGHTS_FILTERS, getDriverInsightsFiltersWorker);
  yield takeEvery(CONSTANT.FETCH_VEHICLE_INSIGHTS_FILTERS, getVehicleInsightsFiltersWorker);
  yield takeEvery(CONSTANT.FETCH_TRIP_ADMIN_FILTERS, getTripAdminFiltersWorker);
}


export default filtersSaga;