import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loadUser } from "../Actions/auth";

export const AuthContext = React.createContext();

export const AuthProvider = (props) => {

    const [loggedIn, setLoggedIn] = useState({
        clientId: '',
        userId: '',
        isAuthenticated: false
    })

    const authSelector = useSelector(state => state.auth);
    const dispatch = useDispatch();

    useEffect(() => {
        if(authSelector.clientIdToken && !authSelector.isAuthenticated){
          dispatch(loadUser());
        }
      }, []);
    
    useEffect(() => {
        if(authSelector.user != null){
          setLoggedIn({
            clientId: authSelector.user.authUser.clientNumber,
            userId: authSelector.user.authUser.username,
            isAuthenticated: authSelector.isAuthenticated,
          })
        }
    }, [authSelector.isLoading])

    return <AuthContext.Provider value={loggedIn}>{props.children}</AuthContext.Provider>;
}

export default AuthProvider