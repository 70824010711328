import { ADD_TOAST, REMOVE_TOAST } from "../Constants/toasts";
import { defaultOptions } from "../Factories/createToast";

let toastTitle = ''
let dismissTimeout = defaultOptions.timeout;
export default function toasts(state = [], action) {
  const { payload, type } = action;
  
  
  switch (type) {
    case ADD_TOAST:
      setTimeout(() => toastTitle = '', dismissTimeout)
      if (toastTitle.toLowerCase() !== payload.title.toLowerCase()) {
        toastTitle = payload.title;
        dismissTimeout = payload.timeout;
        return [payload, ...state];
      }
      return state;

    case REMOVE_TOAST:
      return state.filter(toast => toast.id !== payload);

    default:
      return state;
  }
}